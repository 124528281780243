import { useQuery } from "react-query";
import { fetchFn } from "../../external-apis";
import { useAuth0 } from "@auth0/auth0-react";

const useQueryWithAuthorization = (queryKey, apiObj, options = {}, setDecodedJwt=null) => {
	const { getAccessTokenSilently } = useAuth0();

	return useQuery(queryKey, async () => {
		if (!apiObj) {
			return [];
		}

		let accessToken;

		try {
			accessToken = await getAccessTokenSilently();
		} catch (e) {
			// TODO: Error handling
			console.error('An error occurred in useQueryWithAuthorization', e);

			return;
		}
		if(setDecodedJwt) {
			const decodedJwt = JSON.parse(atob(accessToken.split('.')[1]));

			setDecodedJwt(decodedJwt);
		}
		apiObj.headers = apiObj.headers ?? {};
		apiObj.headers.Authorization = `Bearer ${accessToken}`;

		return fetchFn(apiObj)();
	}, { ...options, retry: (count, { message }) => {
		try {
			const { status, method } = JSON.parse(message);

			return( status < 500 || method==='POST') ? false : count < 0;
		}catch (err) {
			return false;
		}
	} });
};

export default useQueryWithAuthorization;
