import React from 'react';
import { AccountPanel, ProductsPanel, ProjectsPanel, UsersPanel } from "../panels";
import { SidebarCollapse } from  '@ip-synamedia/syna-ui-library';
import { usePanelsStore } from "../../state-management";
import { ProductLogo } from "../../icons";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';

export const useSidebarItems = () => {
	const isDrawerOpen = usePanelsStore(state => state.isDrawerOpen);
	const setIsDrawerOpen = usePanelsStore(state => state.setIsDrawerOpen);

	return [
		{
			id: "drawerToggle",
			onClick:() => {
				setIsDrawerOpen(!isDrawerOpen);
			},
			icon: <SidebarCollapse fill='#ffffff' width='1em' height='1em' style={{ transform:!isDrawerOpen ?"rotate(180deg)" : "rotate(0deg)", marginLeft:5 }}/>,
			selectable:false,
			collapses:true
		},
		{
			id: "accountsManagement",
			title: "Accounts Management",
			component: AccountPanel,
			icon: <AccountCircleIcon/>
		},
		{
			id: "manageUsers",
			title: "Manage Users",
			component: UsersPanel,
			icon: <PeopleAltIcon/>
		},
		{
			id: "manageProjects",
			title: "Projects",
			component: ProjectsPanel,
			icon: <SnippetFolderIcon/>
		},
		{
			id: "products",
			title: "Products",
			component: ProductsPanel,
			icon: <ProductLogo/>
		},
	];
};

