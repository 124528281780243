import styles from './Row.module.scss'
import React, { useContext, useMemo } from 'react'
import Item from './Row/Item'
import Checkbox from '../Checkbox'
import CheckboxSingle from '../CheckboxSingle'
import Table from '../Table'
import clsx from 'clsx'
import OptionsButton from '../OptionsButton'
import Contexts from '../../../Core/Util/Contexts'
import { useID } from '../../../Core/Util/ID'

function mapItemsToElements(items, node, params) {
  return items
    .filter((item) => typeof item.visible === 'undefined' || item.visible)
    .map((item, index) => {
      return (
        <Item
          key={item.id}
          index={index}
          count={items.length}
          {...item}
          {...params}
          node={node}
        />
      )
    })
}

export function useElements({ node, ...params }) {
  const [items] = useContext(Table.Columns) || []
  const elements = useMemo(() => {
    if (items) {
      return mapItemsToElements(items, node, params)
    }
    return null
  }, [items, node])
  return elements
}

export default function Row({
  data: { isLeaf, node, identifier, props, id },
  isOpen,
  style,
  setOpen
}) {
  const baseID = useID()
  const [panelVisible, setPanelVisible] = []
  const [panelItem, setPanelItem] = []
  const [Panel] = []
  const [actions] = useContext(Table.Actions) || []
  const [selected, setSelected] = useContext(Table.Selected) || []
  const [clickedId, setClicked] = useContext(Table.ClickedRow) || []
  const [singleSelection] = useContext(Table.SingleSelection) || []
  const isSelected = (selected || []).includes(node[identifier])
  const setChecked = (checked) => {
    if (singleSelection) {
      if (checked) {
        setSelected([node[identifier]])
      } else {
        setSelected([])
      }
    } else {
      setSelected((selected) => {
        selected = selected.filter((id) => node[identifier] !== id)
        if (checked) {
          selected.push(node[identifier])
        }
        return selected
      })
    }
  }

  const customOnClickRow = props.onClickRow
  const onClickRow = () => {
    if (customOnClickRow) {
      setClicked(id)
      customOnClickRow(node)
      return
    }

    if (Panel) {
      setPanelItem(node)
      setPanelVisible((visible) => !visible)
    }
    if (singleSelection) {
      setSelected([node[identifier]])
    }
  }

  const components = [
    {
      Component: Row,
      Current: node
    }
  ]

  const elements = useElements({
    node,
    isLeaf,
    isOpen,
    setOpen,
    onClickRow,
    identifier
  })
  return (
    <Contexts components={components}>
      <div
        className={styles.root}
        style={style}
        data-testid={`row-${clickedId === id ? '' : 'not-'}clicked`}
      >
        <div
          className={clsx(
            styles.row,
            (isSelected ||
              (panelVisible && panelItem === node) ||
              clickedId === id) &&
              styles.selected
          )}
          data-cy={baseID + 'row-' + node.id}
        >
          {props.checkboxSelection && selected && !singleSelection && (
            <Checkbox checked={isSelected} setChecked={setChecked} />
          )}
          {props.checkboxSelection && selected && singleSelection && (
            <CheckboxSingle checked={isSelected} setChecked={setChecked} />
          )}
          <div className={clsx(styles.items, isSelected && styles.selected)}>
            {elements}
          </div>
          <OptionsButton
            className={styles.options}
            node={node}
            actions={actions}
            style={{ width: 'max-content', right: '40px' }}
          />
        </div>
        <div className={styles.separator} />
      </div>
    </Contexts>
  )
}

export function useRowValue(columnId) {
  const [node] = useContext(Row.Current) || []
  const [columns] = useContext(Table.Columns) || []
  const column = columns.find((item) => item.id === columnId)
  if (!column) {
    return undefined
  }
  let result = node[column.id]
  if (column.valueCb) {
    result = column.valueCb(node)
  }
  return result
}

Row.Current = React.createContext()
