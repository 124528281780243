import { makeStyles } from "@material-ui/core/styles";
import irisIconImg from '../../../../../assets/icons/tenants/IRIS.png';
import React, { useState } from "react";
import TenantDrawer from "../../Projects/TenantDrawer";
import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import Loader from "../../../../Loader";
import { getProductById } from "../../../../../external-apis";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useCreateTenantStore, useDecodedJwtStore } from "../../../../../state-management";
import shallow from "zustand/shallow";
import AddEditTenant from "../AddEditTenant";
import AppErrorNotification from "../../../../AppErrorNotification";
import AppCloseConfirmation from "../../../../AppCloseConfirmation";

const useStyles = makeStyles((theme) => {
	return {
		contentContainer: {
			background: 'hsla(0, 0%, 100%, 1)',
			gridArea:'overlap',
			boxShadow: '0px 0px 10px hsla(0, 0%, 16%, 0.15)',
			borderRadius: 8,
			width: 381,
			height: 107,
			margin: '20px 0 0 23px',
			overflow: 'hidden',
			display: 'flex',
			justifyContent: 'start',
			"&:hover": {
				border: "1px solid #3380FF",
				backgroundClip: 'border-box',
				cursor: 'pointer'
			}
		},
		mark: {
			background: 'hsla(217, 100%, 60%, 1)',
			width: 8,
			height: '100%',
			left: 0,
		},
		iconContainer: {
			marginTop: 16,
			marginLeft: 20,
			width: 43,
			height: 43,
		},
		icon: {
			width: '100%',
			height: '100%',
		},
		nameContainer: {
			display: 'flex',
			marginLeft: 12,
			marginTop: 16,
			flexDirection: 'column',
		},
		productName: {
			textTransform: 'uppercase',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: 17,
		},
		root: {
			display:"grid",

		},
		loader:{
			background: 'hsla(0, 0%, 100%, 1)',
			boxShadow: '0px 0px 10px hsla(0, 0%, 16%, 0.15)',
			borderRadius: 8,
			width: 381,
			height: 107,
			margin: '20px 0 0 23px',
			display: 'flex',
			justifyContent: "center",
		},
		error: {
			background: 'hsla(0, 0%, 100%, 1)',
			boxShadow: '0px 0px 10px hsla(0, 0%, 16%, 0.15)',
			borderRadius: 8,
			width: 381,
			height: 107,
			margin: '20px 0 0 23px',
			display: 'flex',
			justifyContent: "center",
		},
		tenantButton:{
			marginLeft:"auto"
		}

	};
});

export default function TenantCard({  accountId, projectId, tenantId, productId, setShowDrawer, handleTabStopPropagation, setIsDrawerOpen }) {
	const styles = useStyles();

	const setProjectId = useCreateTenantStore(state => state.setProjectId, shallow);
	const setProductId = useCreateTenantStore(state => state.setProductId, shallow);
	const setGeneralSettingStep = useCreateTenantStore(state => state.setGeneralSettingStep, shallow);

	const [openTenantDrawer, setOpenTenantDrawer] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [decodedJwt, setDecodedJwt] = useDecodedJwtStore(state => [state.decodedJwt, state.setDecodedJwt], shallow);
	const [updateTenantDialogVisible, setUpdateTenantDialogVisible] = useState(false);
	const [closeEditTenantConfirmationVisible, setCloseEditTenantConfirmationVisible] = useState(false);
	const [errorEditTenantMessage, setErrorEditTenantMessage] = useState();
	const allowsTenantUpdate = decodedJwt?.scope?.includes("account-admin:tenant:update");
	const resetState = useCreateTenantStore(state => state.reset, shallow);

	const staleTime = 60000;
	const {
		isLoading: productIsLoading,
		isError: productIsError,
		data: product,
		error: productError
	} = useQueryWithAuthorization(['get-product-by-id', productId], getProductById(productId),
		{
			enabled: productId != null,
			staleTime: staleTime
		},setDecodedJwt);

	const handleOnClick = (e) => {
		setOpenTenantDrawer(true);
		setShowDrawer(true);
		setIsDrawerOpen(true);
	};
	const handleDrawerClose = () => {
		setOpenTenantDrawer(false);
		setShowDrawer(false);
		setIsDrawerOpen(false);
	};
	const cababOnClick = (e) => {
		handleTabStopPropagation(e);
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const options = [
		{
			id: 'updateTenant',
			label: 'Update Tenant',
			show : allowsTenantUpdate,
			icon:<EditOutlinedIcon/>,
			cb:(e) => {
				handleTabStopPropagation(e);
				handleClose();
				setUpdateTenantDialogVisible(true);
				setProjectId(projectId);
				setProductId(productId);
				setGeneralSettingStep(4);
			}
		}
	];

	if (productIsLoading) return <div className={styles.loader}><Loader/></div>;
	if (productIsError) return <div className={styles.error}>Error fetching data: {productError.message}</div>;

	return (
		<div  onClick={handleTabStopPropagation}>
			<div className={styles.root}>
				{openTenantDrawer && <TenantDrawer productDisplayName={product?.displayName} handleDrawerClose={handleDrawerClose} tenantId={tenantId} productId={productId} accountId={accountId} projectId={projectId} productTenantAutomationEndpoint={product?.tenantAutomation}/>}
				<div className={styles.contentContainer} onClick={handleOnClick}>
					<div className={styles.mark}/>
					<div className={styles.iconContainer}>
						<img className={styles.icon} src={irisIconImg} alt={''}/>
					</div>
					<div className={styles.nameContainer}>
						<div className={styles.productName}>
							{product?.displayName}
						</div>
					</div>
					{ allowsTenantUpdate &&  <div className={styles.tenantButton} onClick={handleTabStopPropagation}>
						<IconButton
							aria-label="more"
							onClick={cababOnClick}
						>
							<MoreVertIcon/>
						</IconButton >
						{<Menu
							id="long-menu"
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{options.map((option) => (
								// showButton(option.id) && <MenuItem key={option.id} onClick={option.cb}>
								option.show && <MenuItem key={option.id} onClick={option.cb}>
									<ListItemIcon>
										{option.icon}
									</ListItemIcon>
									<ListItemText primary={option.label}/>
								</MenuItem>
							))}
						</Menu>}
						<AddEditTenant
							visible={updateTenantDialogVisible && !closeEditTenantConfirmationVisible}
							setVisible={setUpdateTenantDialogVisible}
							setCloseConfirmationVisible={setCloseEditTenantConfirmationVisible}
							setErrorMessage={setErrorEditTenantMessage}
							tenantId={tenantId}
						/>
						<AppCloseConfirmation visible={closeEditTenantConfirmationVisible} setVisible={setCloseEditTenantConfirmationVisible} setParentDialogVisible={setUpdateTenantDialogVisible} text={'Are you sure you want to cancel updating the tenant?'} resetState={resetState}/>
						<AppErrorNotification errorMessage={errorEditTenantMessage} setErrorMessage={setErrorEditTenantMessage}/>
					</div> }
				</div>
			</div>

		</div>
	);
}
