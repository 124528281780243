import React from 'react'
import styles from './Count.module.scss'
import Text from './Text'

export default function Count({ label = '', total }) {
  const number = (total || 0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return <Text className={styles.root}>{`${label} (${number})`}</Text>
}
