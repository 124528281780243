import * as React from 'react'

function SvgDropDown(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14px'
      height='14px'
      viewBox='0 0 7 5'
      {...props}
    >
      <path
        d='M6.71.811l.194.194a.328.328 0 010 .465L3.732 4.69a.327.327 0 01-.464.003L.096 1.473a.329.329 0 010-.466L.29.813a.33.33 0 01.465 0L3.5 3.608 6.245.811a.328.328 0 01.465 0z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgDropDown
