import * as React from 'react'

function SvgOptions(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 3 14'
      {...props}
    >
      <path
        d='M1.375 3.469A1.374 1.374 0 101.377.721a1.374 1.374 0 00-.002 2.748zm0 5.156c.76 0 1.375-.614 1.375-1.375 0-.76-.614-1.375-1.375-1.375C.615 5.875 0 6.489 0 7.25c0 .76.614 1.375 1.375 1.375zm0 5.156c.76 0 1.375-.614 1.375-1.375a1.374 1.374 0 10-1.375 1.375z'
        fill='#8F8F8F'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgOptions
