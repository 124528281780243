import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getProductRoles } from "../../../../external-apis";
import { useUsersInvitationsStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import modifyRoles from "../../../../assets/icons/modify/modifyRoles.svg";

const useStyles = makeStyles(() => ({
	root: {
		margin: '0 40px 16px 32px',
	},
	rolesContainer: {
		marginLeft: 24,
	},
	rolesLabel:{
		fontSize: 16
	},
	rolesSelections: {
		position: 'relative',
		height: 60,
		width: '100%'
	},
	dataGrid: {
		border: 'none',
		'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
	},
	tableHeader: {
		fontSize: 12,
		fontWeight: "bold"
	},
	title:{
		fontSize: '17px',
		fontWeight: 600,
		fontFamily: 'Source Sans Pro',
		marginLeft: 32,
		marginBottom: 20
	},
	iconContainer:{
		marginLeft: 5,

	},
	accordionSummary: {
		display: "flex",
		flexDirection: 'row-reverse',
	},
	MultiselectContainer:{
		position: "absolute",
		left: 168,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	multiselectLineLabel: {
		fontSize: 14,
		marginLeft: 45,
		fontWeight: "bold"
	},
	multiselectLine:{
		display: "flex",
		flexDirection: "row",
	},
	closeIcon: {
		padding: 4,
		color: '#8F8F8F',
		'&:hover': {
			padding: 3,
			color: '#3380FF',
		},
	},
	rotate:{
		transform: 'rotate(-90deg)'
	},
	rotateSelected:{
		transform: 'rotate(180deg)'
	},
	modify: {
		marginLeft: 8,
	},
	labelContainer: {
		display:"flex",
		flexDirection: "row",
		alignItems: "center"
	}
}));

const multiselectStyle = {
	chips: {
		background: "#FAFAFA",
		color: 'black',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		borderRadius: 3,
		padding: '3px 0px 0px 0px'
	},
	searchBox: {
		display: 'flex',
		borderRadius: 4,
		padding: '0px 40px 0 15px',
		height: 24,
		width: 500
	},
	inputField: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		width: 40,
		padding: '2px 0px 7px 0px',
	},
	optionContainer: {
		position: 'absolute',
		top: -48,
		height: 70,
		width: 500,
		overflowY: "scroll",
		backgroundColor: 'white',
	},
	optionListContainer:{

	},
	option: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'small',
		fontWeight: 400,
		fontSize: 14,
		margin: 0,
		padding: 5,
		borderRadius: 4,
	}
};

const mapAccountRoles = (rolesResponse) => {
	return (rolesResponse ?? []).map(role => {
		const { id, displayName, isProductAdmin, description } = role;

		return {
			id,
			label: displayName,
			isProductAdmin,
			description
		};
	});
};

export default function AccountRoles({ setFirstRender, changesMadeCmp, isEdit =false }) {
	const styles = useStyles();
	const staleTime = 120000;
	const [expended, setExpended] = useState(false);
	const [accountRolesIds, setAccountRolesIds] = useUsersInvitationsStore(state => [state.accountRolesIds, state.setAccountRolesIds], shallow);
	const { isLoading, isError, error, data: roles = []} = useQueryWithAuthorization('roles-account', getProductRoles('account'), {
		select: mapAccountRoles,
		staleTime: staleTime
	});

	const handleSelectionModelAdd = ({ id }) => {
		setFirstRender(false);
		if(!accountRolesIds.has(id)) {
			accountRolesIds.add(id);
			setAccountRolesIds(new Set(accountRolesIds));
		}

	};
	const handleSelectionModelRemove = ({ id }) => {
		setFirstRender(false);
		if(accountRolesIds.has(id)) {
			accountRolesIds.delete(id);
			setAccountRolesIds(new Set(accountRolesIds));
		}
	};

	if (isError) {
		console.error(error);
		toast.error('Failed to fetch account roles');
	}
	const options = roles.filter(obj => !obj.isProductAdmin).map(obj => obj.id);

	return (
		<div>
			{ options?.length > 0 && <div className={styles.root}>
				<Accordion
					onChange = {(e,data) => {
						setExpended(data);
					}}
					expanded={expended}
				>
					<AccordionSummary
						expandIcon={<div className={clsx(styles.iconContainer, expended ? styles.rotateSelected:styles.rotate)}><ExpandMoreIcon/></div>}
						className={styles.accordionSummary}
						sx={{ padding: "0px 0px 0px 3px" }}
						aria-controls="panel1a-content" id="panel1a-header"
					>
						<Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '7px', fontFamily: 'Source Sans Pro' }}>
							<div className={styles.labelContainer}>
								Account{isEdit && !changesMadeCmp && <img alt={''} src={modifyRoles} className={styles.modify}/>}
							</div>
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ backgroundColor: '#FAFAFA', height: 60 }}>
						<div className={styles.rolesContainer}>
							<div className={styles.rolesSelections}>
								{isLoading && <Loader width={50} height={60} type={'Rings'}/>}
								{!isLoading &&
								<div className={styles.multiselectLine}>
									<div className={styles.multiselectLineLabel}>
										Roles
									</div>
									<div className={styles.MultiselectContainer}>
										<Multiselect
											options={options}
											selectedValues={options.filter(opt => accountRolesIds.has(opt))}
											isObject={false}
											avoidHighlightFirstOption={true}
											placeholder='Select'
											hidePlaceholder={true}
											onSelect={(data ,id) => handleSelectionModelAdd({ id, data })}
											onRemove={(data, id) => handleSelectionModelRemove({ id, data })}
											displayValue={"displayName"}
											showCheckbox={true}
											showArrow={true}
											customCloseIcon={<CloseIcon fontSize='small' className={styles.closeIcon}/>}
											style={multiselectStyle}
										/>
									</div>
								</div>}
							</div>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>}
		</div>
	);
}
