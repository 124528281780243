import React, { useState, useEffect } from 'react';
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useUsersInvitationsStore, useUsersStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import {
	editAccountRoles,
	editUserDetails, editProjectRoles,
} from "../../../../external-apis";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import {
	useMutationWithAuthorization
} from "../../../../custom-hooks";
import AppInput from "../../../AppInput";
import AppDialog from "../../../AppDialog";
import AppTooltip from "../../../AppTooltip";
import AppButton from "../../../AppButton";
import ProductsPerProjectRoles from "../InviteUserDialog/ProductsPerProjectRoles";
import ProductAdminRoles from "../InviteUserDialog/ProductAdminRoles";
import AccountRoles from "../InviteUserDialog/AccountRoles";
import AppCloseConfirmation from "../../../AppCloseConfirmation";

const useStyles = makeStyles(() => ({
	dialogContainer: {
		width: 850,
		position: 'absolute'
	},
	dialogBody: {
		paddingBottom: 60,
		overflowY: "scroll",
		height: 621,
	},
	content:{
		width: 850
	},
	inviteBtmContainer: {
		marginLeft: 16,
		marginRight: 38,
	},
	generalDetailsContainer: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 12,
		marginTop: 4
	},
	userInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		height: 74,
		padding: 12,
		borderRadius: 10,
		boxShadow: '1px 1px 10px rgba(41, 41, 41, 0.15)',
		marginBottom: 12
	},
	selectRolesContainer: {
		borderRadius: 10,
		boxShadow: '1px 1px 10px rgba(41, 41, 41, 0.15)',
	},
	actionsContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		height: 50,
		width: "100%",
		backgroundColor: "white",
		zIndex: 100
	},
	cancelBtm: {
		marginLeft: "auto",
	},
	messageInput:{
		height: 30,
		width: 370
	},
	inputLabel: {
		color: '#292929'
	},
	required:{
		color: 'hsla(0, 100%, 66%, 1)',
		marginLeft: 5
	},
	assignRoles:{
		display: "flex",
		flexDirection: "row",
		margin: "14px 0px 13px 32px",
		fontWeight: 600,
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		width: '100%'
	},
	headerTitleContainer: {
		display: "flex",
		flexDirection: "row"
	},
	separator: {
		height: 20,
		borderLeft: "1px solid #C2C2C2",
		margin: "0px 8px 0px 8px"
	},
	headerTitle: {
		fontSize: 16,
		fontWeight :600
	},
	headerDetails: {
		fontSize: 14,
		fontWeight :400,
		color: "#8F8F8F"
	},
	provisioningStatus: {
		marginLeft: 12,
		marginRight: 12,
		height: 20,
		width: 67,
		backgroundColor: 'rgba(46, 209, 94, 0.1)',
		borderRadius: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	provisioningStatusLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		marginLeft: 4,
		color: 'rgba(0, 158, 47, 1)',
	},
	overrideTitle: {
		textTransform: "none"
	}
}));

const userInfoCmp = (obj1, obj2) => {
	if(Object.keys(obj2).length !== Object.keys(obj1).length) return {};

	const accountRoleCmp = obj1?.accountRolesIds?.size === obj2?.accountRolesIds?.size &&
		[...obj1.accountRolesIds].every((x) =>  obj2.accountRolesIds.has(x));

	const productAdminRolesCmp = obj1?.productAdminRolesIds?.size === obj2?.productAdminRolesIds?.size &&
		[...obj1.productAdminRolesIds].every((x) => obj2.productAdminRolesIds.has(x));

	let productRolesByProjectIdCmp = { lenCmp : obj1?.productRolesByProjectId?.length === obj2?.productRolesByProjectId?.length };

	productRolesByProjectIdCmp.lenCmp && Object.entries(obj1.productRolesByProjectId).forEach((projectData) => {

		const [curProjectId, data] = projectData;

		productRolesByProjectIdCmp[curProjectId] = (data.length === obj2.productRolesByProjectId[curProjectId].length) && [...obj2.productRolesByProjectId[curProjectId]].every(element => {

			return data.find(x => x.id === element.id);
		});
	});
	const fieldsCmp = obj1.jobTitle === obj2.jobTitle && obj1.userName === obj2.userName;

	return { accountRoleCmp, productAdminRolesCmp, productRolesByProjectIdCmp, fieldsCmp,
		finalCmp:  accountRoleCmp && productAdminRolesCmp && fieldsCmp && Object.values(productRolesByProjectIdCmp).every(x => x) };

};

export default function EditUsersDialog({ visible, setVisible, userData, status, closeEditConfirmationVisible, setCloseEditConfirmationVisible, projects, rolesQueries, accountRoles, firstRender, setFirstRender }) {
	const styles = useStyles();
	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const [resetState, setJobTitle, setUserName, setAccountRolesIds, setEmail, setProductRolesByProjectId] = useUsersInvitationsStore(state => [state.reset, state.setJobTitle, state.setUserName, state.setAccountRolesIds, state.setEmail, state.setProductRolesByProjectId], shallow);
	const [setProductAdminRolesIds] = useUsersInvitationsStore(state => [state.setProductAdminRolesIds], shallow);
	const [hasPermission, setHasPermission] = useState(false);
	const accountId = useUsersStore(state => state.accountId, shallow);
	const [focusedProjects, setFocusedProjects] = useState(new Set());
	const [changesMadeCmp, setChangesMadeCmp] = useState({});

	const userInvitationData = useUsersInvitationsStore(state => ({
		email: state.email,
		accountRolesIds: state.accountRolesIds,
		productAdminRolesIds: state.productAdminRolesIds,
		productRolesByProjectId: state.productRolesByProjectId,
		jobTitle: state.jobTitle,
		userName: state.userName
	}), shallow);

	const [initialState, setInitialState] = useState({});

	useEffect(() => {
		if(firstRender) {
			userData?.userName && setUserName(userData.userName);
			userData?.userRole && setJobTitle(userData.userRole);
			userData?.email && setEmail(userData.email);
			const filteredAccountRoles = accountRoles.filter(role => !role?.isProductAdmin).map(value => value.id);
			const filteredProductAdminRoles = accountRoles.filter(role => role?.isProductAdmin).map(value => value.id);

			setAccountRolesIds(new Set(...[filteredAccountRoles]));
			setProductAdminRolesIds(new Set(...[filteredProductAdminRoles]));

			let _productRolesByProjectId = {};

			projects.forEach((project, index) => {
				_productRolesByProjectId[project.id] = rolesQueries[index].data;
			});
			setInitialState({
				email: userData.email,
				accountRolesIds: new Set(...[filteredAccountRoles]),
				productAdminRolesIds: new Set(...[filteredProductAdminRoles]),
				productRolesByProjectId: _productRolesByProjectId,
				jobTitle: userData.userRole,
				userName: userData.userName
			});
			setProductRolesByProjectId(_productRolesByProjectId);
		}

	// },[setUserName, setProductRolesByProjectId, setAccountRolesIds, setEmail, setJobTitle, setProductAdminRolesIds, userData, projects, rolesQueries, accountRoles]);
	},[setUserName, setProductRolesByProjectId, setAccountRolesIds, setEmail, setJobTitle, setProductAdminRolesIds, userData, projects, rolesQueries, accountRoles, firstRender]);

	useEffect(() => {
		const hasProductRoleSelected = () => {
			if(!userInvitationData?.productRolesByProjectId) {

				return false;
			}
			for(const projectRoles of Object.values(userInvitationData.productRolesByProjectId)) {
				if(projectRoles.length > 0) {
					return true;
				}
			}

			return false;
		};

		setHasPermission(hasProductRoleSelected() || userInvitationData.accountRolesIds?.size > 0 || userInvitationData.productAdminRolesIds?.size > 0);
	}, [userInvitationData]);

	useEffect(() => {
		Object.keys(initialState).length > 0 && setChangesMadeCmp(userInfoCmp(userInvitationData, initialState));
	}, [initialState, userInvitationData, setChangesMadeCmp]);

	const handleJobTitleChange = (jobTitle) => {
		setFirstRender(false);
		setJobTitle(jobTitle);

	};

	const handleUserNameChange = (newUserName) => {
		setFirstRender(false);
		setUserName(newUserName);
	};

	const handleParamsEdit = () => {
		const isJobTitleEdit = userInvitationData.jobTitle !== initialState.jobTitle;
		const isUserNameEdit = userInvitationData.userName !== initialState.userName;

		try{
			isJobTitleEdit && toast.promise(mutateAsync(editUserDetails(accountId, userData.id, 'jobTitle', userInvitationData.jobTitle)),
				{
					pending: "Edit job title...",
					success: {
						render({ data } = {}) {
							queryClient.invalidateQueries(['users-active', accountId]);

							return "Edit job title succeed!";
						}
					},
					error: {
						render({ data: error }) {
							console.error('Error edit job title', error);

							return `Failed to edit job title`;
						},
					}
				}, {
					position: toast.POSITION.TOP_CENTER
				}
			);

			isUserNameEdit && toast.promise(mutateAsync(editUserDetails(accountId, userData.id, 'name', userInvitationData.userName)),
				{
					pending: "Edit user name...",
					success: {
						render({ data } = {}) {
							queryClient.invalidateQueries(['users-active', accountId]);

							return "Edit user name succeed!";
						}
					},
					error: {
						render({ data: error }) {
							console.error('Error edit userName', error);

							return `Failed to edit userName`;
						},
					}
				}, {
					position: toast.POSITION.TOP_CENTER
				}
			);
		}catch (e) {
			console.log(e.message);
		}
	};
	const handleAccountRolesEdit = () => {
		const isAccountEdit = !((userInvitationData.accountRolesIds.size === initialState.accountRolesIds.size)&&
			[...userInvitationData.accountRolesIds].every((x) =>  initialState.accountRolesIds.has(x)));
		const isProductAdminEdit = !((userInvitationData.productAdminRolesIds.size === initialState.productAdminRolesIds.size)&&
			[...userInvitationData.productAdminRolesIds].every((x) =>  initialState.productAdminRolesIds.has(x)));

		try{
			(isAccountEdit || isProductAdminEdit) && toast.promise(mutateAsync(editAccountRoles(accountId, userData.id, [...userInvitationData.productAdminRolesIds, ...userInvitationData.accountRolesIds])),
				{
					pending: "Edit account roles...",
					success: {
						render({ data } = {}) {
							queryClient.invalidateQueries(['userRoles', accountId, userData.id]).then();

							return "Edit account roles succeed!";
						}
					},
					error: {
						render({ data: error }) {
							const errorMessage = JSON.parse(error?.message);

							console.error('Error edit  account roles ', error.message);

							return `Failed to edit  account roles ${errorMessage.message || ''}`;
						},
					}
				}, {
					position: toast.POSITION.TOP_CENTER
				}
			);
		}catch (e) {
			console.log(e.message);
		}

	};

	const handleProductPerProjectRoles = () => {

		const filteredProjects = projects.filter(project => {
			const roles = userInvitationData.productRolesByProjectId[project.id];
			const initRoles = initialState.productRolesByProjectId[project.id];

			return !(roles.length === initRoles.length) || !roles.every(element => {

				return initRoles.find(x => x.id === element.id);
			});
		});

		const promises = filteredProjects.map(project => mutateAsync(editProjectRoles(accountId, project.id, userData.id, userInvitationData.productRolesByProjectId[project.id].map(role => role.id))));

		try{
			promises.forEach((promise, index) => toast.promise(promise,
				{
					pending: `Edit ${filteredProjects[index].label} roles...`,
					success: {
						render({ data } = {}) {
							queryClient.invalidateQueries(['get-user-roles-by-project', filteredProjects[index].id, userData.id]).then();
							setVisible(true);
							handleCancel(false);

							return `Edit ${filteredProjects[index].label} roles succeed!`;
						}
					},
					error: {
						render({ data: error }) {
							const errorMessage = JSON.parse(error?.message);

							console.error(`Error edit ${filteredProjects[index].label} roles`, error);
							
							return `Failed to edit ${filteredProjects[index].label} roles ${errorMessage.message || ''}`;
						},
					}
				}, {
					position: toast.POSITION.TOP_CENTER
				}
			));
		}catch (e) {
			console.log(e.message);
		}

	};

	const handleEdit = async () => {

		handleCancel(false);

		handleParamsEdit();
		handleAccountRolesEdit();
		handleProductPerProjectRoles();
	};

	const handleCancel = (isConfirmationNeeded) => {

		if(isConfirmationNeeded) {
			setCloseEditConfirmationVisible(true);
		}else{
			setVisible(false);

			setFirstRender(true);
			setInitialState({});
			resetState();
		}

	};

	const headerTitle = <div className={styles.headerTitleContainer}>
		<div className={styles.headerTitle}>Edit Users Details</div>
		<div className={styles.separator}/>
		<div className={styles.headerDetails}>{`User email: ${userData?.email}`}</div>
		<div className={styles.provisioningStatus}>
			<div className={styles.provisioningStatusLabel}>
				{status.charAt(0).toUpperCase() + status.slice(1)}
			</div>
		</div>

		<div className={styles.headerDetails}>{`Joined: ${userData?.joined}`}</div>

	</div>;

	const isParameterEmpty = () => {
		return userInvitationData?.userName === '' || userInvitationData?.jobTitle === '';
	};

	// console.log('initialState', initialState.accountRolesIds);
	// console.log("changesMade", changesMade);

	return (
		<div className={styles.dialogContainer}>
			<AppDialog open={visible && !closeEditConfirmationVisible} title={headerTitle} onClose={() => handleCancel(!changesMadeCmp.finalCmp)} overrideClasses={{ body: styles.dialogBody, content: styles.content, title: styles.overrideTitle }}>
				<div className={styles.generalDetailsContainer}>
					General Details
				</div>
				<div className={styles.userInputContainer}>
					<AppInput
						value={firstRender ? userData?.userName: userInvitationData?.userName}
						label={'Name'}
						overrideClasses={{ input: styles.messageInput, label: styles.inputLabel }}
						onChange={handleUserNameChange}
						error={userInvitationData?.userName === ''}
					/>
					<AppInput
						value={firstRender ? userData?.userRole : userInvitationData?.userRole }
						label={'Job title'}
						overrideClasses={{ input: styles.messageInput, label: styles.inputLabel }}
						onChange={handleJobTitleChange}
						error={userInvitationData?.userRole === ''}
					/>
				</div>
				<div className={styles.selectRolesContainer}>
					<div className={styles.assignRoles}>
						{'Assign Roles'}
						<div className={styles.required}>
                            *
						</div>
					</div>
					<div>
						<ProductsPerProjectRoles projects={projects} focusedProjects={focusedProjects} setFocusedProjects={setFocusedProjects} isEdit={true} initialState={initialState} firstRender={firstRender} setFirstRender={setFirstRender} changesMadeCmp={changesMadeCmp}/>
						<ProductAdminRoles projects={projects} isEdit={true} setFirstRender={setFirstRender} firstRender={firstRender} changesMadeCmp={changesMadeCmp.productAdminRolesCmp}/>
					</div>
					<AccountRoles setFirstRender={setFirstRender} changesMadeCmp={changesMadeCmp.accountRoleCmp} isEdit={true}/>
				</div>
				<div className={styles.actionsContainer}>
					<div className={styles.cancelBtm}>
						<Button onClick={() => handleCancel(!changesMadeCmp.finalCmp)}>cancel</Button>
					</div>
					<AppTooltip disabled={hasPermission && !changesMadeCmp.finalCmp && !isParameterEmpty() && userInvitationData.accountRolesIds.size} title={changesMadeCmp.finalCmp ?'Please make at least one change in order to save': !hasPermission ?'Please select at least one role':isParameterEmpty()? 'empty name or job title is not allowed':!userInvitationData.accountRolesIds.size?'Please select at least one account role':''}>
						<div className={styles.inviteBtmContainer}>
							<AppButton disabled={!hasPermission || changesMadeCmp.finalCmp || isParameterEmpty() || !userInvitationData.accountRolesIds.size} variant={'contained'} onClick={handleEdit}>Save</AppButton>
						</div>
					</AppTooltip>
				</div>
			</AppDialog>
			<AppCloseConfirmation visible={closeEditConfirmationVisible} setVisible={setCloseEditConfirmationVisible} setParentDialogVisible={setVisible} text={'Are you sure you want to cancel updating the user?'} resetState={resetState}/>
		</div>
	);
}
