import React, { useMemo } from "react";
import { Drawer } from "@ip-synamedia/syna-ui-library";
import { useQueryWithAuthorization, useQueriesWithAuthorization } from "../../../../custom-hooks";
import { getUserRolesByProject, getUserRolesByAccount } from "../../../../external-apis";
import Loader from "../../../Loader";
import { makeStyles } from "@material-ui/core/styles";
import emptyContentBackground from "../../../../assets/icons/emptyContent/emptyContentBackground.png";
import emptyUsersIcon from "../../../../icons/emptyUsersIcon.png";
import { SchemaUtils } from '../../../../model/utils';

const GET_PROJECT_ROLES_SCHEMA_PATH = "/accounts/{accountId}/projects/{projectId}/members/{userId}/roles";
const GET_ACCOUNT_ROLES_SCHEMA_PATH = "/accounts/{accountId}/users/{userId}/roles";

const useStyles = makeStyles((theme) => {
	return {
		separator: {
			borderTop: '1px solid #C2C2C2',
			height: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '4px 0px 16px 0px'
		},
		title:{
			color:"#292929",
			fontSize: 17,
			fontFamily: "Source Sans Pro",
			fontWeight: "bold"
		},
		usersRolesContainer:{
			width: 542,
			padding: 12,
			boxShadow: '0px 0px 10px rgba(41, 41, 41, 0.15)',
			borderRadius: 8,
			marginTop: 12

		},
		emptyUsersComponentContainer:{
			marginTop: 160,
			display: "flex",
			flexDirection: "column",
			alignItems: "center"
		},
		emptyIconContainer:{
			marginTop: 0,
			display: "grid",
			gridTemplateColumns: '1fr',
			gridTemplateRows: '1fr',
			gridTemplateAreas: "overlap",

		},
		emptyContentBackgroundIcon:{
			gridArea: 'overlap',
			width:100,
			height: 100,
		},
		emptyContentIcon:{
			gridArea: 'overlap',
			borderRadius: 0,
			margin: '32px 25px 32px 25px'
		},
		emptyUsersText:{
			marginTop: 12,
			fontSize: 17,
			fontWeight: 400,
			color: '#8F8F8F'
		},
		loader: {
			/* Center vertically and horizontally */
			position: "absolute",
			top: "50%",
			left: "50%",
			margin: "-25px 0 0 -25px", /* apply negative top and left margins to truly center the element */
		},
		accountRolesTitle:{
			fontSize: 14,
			fontWeight: 600,

		},
		accountRole: {
			borderRadius: 4,
			backgroundColor: "#EFEFEF",
			paddingLeft: 8,
			paddingRight: 8,
			marginTop: 12,
			marginRight: 8,
			fontSize: 14,
			fontWeight: 400,
		},
		accountRolesContainer:{
			display: "flex",
			flexDirection: "row",
			alignItems: "start",

		},
		productRolesAdminContainer :{
			width: 542,
			boxShadow: '0px 0px 10px rgba(41, 41, 41, 0.15)',
			borderRadius: 8,
			marginTop: 12,
			padding: '12px 12px 12px 12px'
		},
		projectRolesContainer: {
			width: 542,
			borderRadius: 8,
			marginTop: 12,
		},
		projectRoleTitle:{
			fontSize: 14,
			marginTop: 0,
			fontWeight: 600,

		},
		projectContainer:{
			borderRadius: 8,
			padding: '12px 12px 12px 12px',
			boxShadow: '0px 0px 10px rgba(41, 41, 41, 0.15)',

			marginBottom: 12,

		},
		projectRole: {
			marginTop: 12,
		},
		productTitle: {
			color: "#8F8F8F",
			fontSize: 14,
			marginTop: 0,
			marginBottom: 5,
			fontWeight: 400,
		},
		rolesLineContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			flexWrap: "wrap",
			marginTop: 4,
		},
		roleContainer: {
			borderRadius: 4,
			backgroundColor: "#EFEFEF",
			padding: '0px 8px 0px 8px',
			marginRight: 8,
			marginBottom: 8,
			fontSize: 14,
			fontWeight: 400,
			height: 20,
			color: "#292929",
			whiteSpace: "nowrap"
		}

	};
});
const checkLoadError = (queries) => {
	if (!queries.every(key => !key.isLoading)) return<Loader/>;

	if (queries.length>0 && queries.every(key => key.isError)) {
		const error = queries.map(key => key.error).join(',');

		return <div>Error fetching data: {error}</div>;
	}

	return false;
};

const getQueries = ( accountId, projects, userId, staleTime ) => {
	return projects.map((project) => {
		return { queryKey:['get-user-roles-by-project', project.id, userId], query: getUserRolesByProject(accountId, project.id, userId), staleTime: staleTime, enabled: userId !== undefined };
	});
};

function GetAdminComponentFromData(roleOfAccount, styles) {
	const filteredRoles = roleOfAccount ? roleOfAccount.filter(q => q?.isProductAdmin): [];

	return filteredRoles && filteredRoles.length > 0 && <div className={styles.usersRolesContainer}>
		<div className={styles.accountRolesTitle}>Product Administration</div>
		<div className={styles.accountRolesContainer}>
			<div className={styles.rolesLineContainer}>
				{filteredRoles.map((role, index) => (<div key={"user-admin-role" + index} className={styles.accountRole}>
					{role.displayName}
				</div>))
				}
			</div>
		</div>
	</div>;
}

function GetProjectComponentFromData(rolesQueries, projectRoles, styles, isExistRole) {
	return isExistRole ? <div className={styles.projectRolesContainer}>
		{Object.values(projectRoles).length > 0 && Object.entries(projectRoles).map((data, index) => {

			const roles = data[1];
			const projectLabel = data[0];
			const filteredRoles = (roles??[]);

			const sortedRoles = {};

			filteredRoles.forEach((role) => {
				if (!sortedRoles[role.productId]) {
					sortedRoles[role.productId] = [];
				}
				sortedRoles[role.productId]?.push(role);
			});

			return (Object.keys(sortedRoles).length > 0) ?<div key={'project-role' + index} className={styles.projectContainer}>
				<div className={styles.projectRoleTitle}>{projectLabel}</div>
				{Object.entries(sortedRoles).map((data, index) => {
					const productLabel = data[0];
					const roles = data[1];

					return <div key={'project-roles' + index} className={styles.projectRole}>
						<div className={styles.productTitle}>{productLabel[0].toUpperCase() + productLabel.slice(1) + ':'}</div>
						<div className={styles.rolesLineContainer}>
							{roles.map((role, index) => (<div key={index} className={styles.roleContainer}>{role.displayName}</div>))}
						</div>
						{index !== Object.keys(sortedRoles).length -1 && <div className={styles.separator}/>}
					</div>;
				}) }
			</div>: <div/>;
		})}
	</div> : undefined;
}

export default function UserRolesDrawer({ isDrawerOpen, onClose, projects, curData, accountId }) {

	const styles = useStyles();

	function EmptyUserRolesComponent() { return <div className={styles.emptyUsersComponentContainer}>
		<div className={styles.emptyIconContainer}>
			<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
			<img alt={''} src={emptyUsersIcon} className={styles.emptyContentIcon}/>
		</div>
		<div className={styles.emptyUsersText}>{'This user has no role yet'}</div>
	</div>;}

	const descriptionComponent = <div>
		<div>
			{curData?.lastLogin} | {curData?.lastLogin}
			<div />
			{`Project: ${projects.map(p => p.displayName).join(' ')}`}
		</div>
	</div>;

	const staleTime = 60000;
	const queries = getQueries(accountId, Object.values(projects), curData?.id, staleTime);
	const rolesQueries = useQueriesWithAuthorization(queries);

	const {
		data: roleOfAccount = [],
		isError: roleOfAccountIsError,
		isLoading: roleOfAccountIsLoading,
		error: roleOfAccountError
	} = useQueryWithAuthorization(['user-roles-of-account', accountId, curData?.id], getUserRolesByAccount(accountId, curData?.id), {
		enabled: accountId != null && curData?.id !== undefined,
		staleTime: staleTime

	});
	const accountRoles = useMemo(() => {

		if (roleOfAccountIsLoading) return<Loader/>;

		if (roleOfAccountIsError) return <div>{roleOfAccountError.message}</div>;
		
		const filteredRoles = roleOfAccount.filter(q => !q?.isProductAdmin).filter(role => SchemaUtils.validateArrayItem(role, GET_ACCOUNT_ROLES_SCHEMA_PATH));

		return filteredRoles.length > 0 ? <div className={styles.usersRolesContainer}>
			<div className={styles.accountRolesTitle}>Account Roles</div>
			<div className={styles.accountRolesContainer}>
				<div className={styles.rolesLineContainer}>
					{filteredRoles.map((role, index) => (<div key={"user-role" + index} className={styles.accountRole}>
						{role.displayName}
					</div>))
					}
				</div>
			</div>
		</div>: undefined;
	}, [roleOfAccountIsError, roleOfAccountIsLoading, roleOfAccount, styles, roleOfAccountError]);

	const productAdministration = useMemo(() => {
		return roleOfAccount && GetAdminComponentFromData(roleOfAccount, styles);
	}, [roleOfAccount, styles]);

	const projectRoles = useMemo(() => {
		
		const loadingError = checkLoadError(rolesQueries);

		if(loadingError) {
			return loadingError;
		}

		let projectRoles = {};

		// Filter out roles data that are not according to role schema
		const filteredRolesQueries = rolesQueries.map(roleQuery => ({
			...roleQuery,
			data: roleQuery?.data?.filter(role => SchemaUtils.validateArrayItem(role, GET_PROJECT_ROLES_SCHEMA_PATH)) || []
		}));

		filteredRolesQueries.map(q => q.data).forEach( (arrayRole,index) => projectRoles[projects[index].displayName] = arrayRole );
		let isExistRole = false;

		Object.keys(projectRoles).length > 0 && Object.values(projectRoles).forEach(data => {
			isExistRole = isExistRole || data.length > 0;
		});

		return GetProjectComponentFromData(filteredRolesQueries, projectRoles, styles, isExistRole);
	}, [rolesQueries, styles, projects]);

	return (
		<div>
			<Drawer
				isOpen={isDrawerOpen}
				onClose={onClose}
				title={curData?.userName}
				anchor={'right'}
				description={descriptionComponent}
				customStyle={{
					width: 590,
					top: 0,
					right: 234,
					height: '100%',
				}}
				headerCustomStyle={{
					height: 105,
					width: '100%',
					boxShadow: '1px 0px 7px grey',
					fontFamily: 'Source Sans Pro',
					marginBottom: 44,
				}}
			>
				{(accountRoles || productAdministration || projectRoles) ? (<div>
					<div className={styles.title}> Roles</div>
					{projectRoles}
					{productAdministration}
					{accountRoles}
				</div>) : EmptyUserRolesComponent({ drawer: true }) }
			</Drawer>
		</div>
	);
}
