import styles from './drawer.module.css'
import React from 'react'
import clsx from 'clsx'
import { Button } from '../button/Button'
import { Tag } from '../tag/Tag'
import { Clear } from '../iconComponents'
// import { CopyBlock } from "react-code-blocks";

export const Drawer = ({
  /**
   * Title :-
   *
   * If `present`, drawer comes with a title.
   * Default - null
   */
  title,
  /**
   * Description :-
   *
   * If `present`, drawer comes with a description below title.
   * Default - null
   */
  description,
  /**
   * onClose :-
   *
   * If `present`, function will be called onClose of the drawer,
   * Default - null
   */
  onClose,
  /**
   * Children :-
   *
   * If `present`, drawer comes with a child section which is isOpen on expand,
   * Default - null
   */
  children,
  /**
   * isOpen :-
   *
   * If `true`, drawer uses this to be isOpen
   * Default - null
   */
  isOpen,
  /**
   * anchor :-
   *
   * If `present`, drawer slides from given direction
   * Default - left
   */
  anchor = 'left',
  /**
   * titleTag :-
   *
   * If `true`, drawer uses this to show tag next to drawer title
   * Default - null
   */
  titleTag,
  /**
   * titleTagType :-
   *
   * If titleTag is true Tag component expects a tagType to display appropriate Tag
   * Default - null
   */
  titleTagType,
  /**
   * titleTagLabel :-
   *
   * If titleTag is true Tag component expects a tagLabel to display appropriate Tag label
   * Default - null
   */
  titleTagLabel,
  /**
   * titleTagRounded :-
   *
   * If titleTagRounded is true Tag component will be rounded
   * Default - false
   */
  titleTagRounded = false,
  /**
   * titleTagStyle :-
   *
   * If `present`, the given style will override the default tag style properties
   * Default - empty
   */
  titleTagStyle,
  /**
   * setIsOpen :-
   *
   * If `present`, drawer sets it to false onClose action
   * Default - null
   */
  setIsOpen,
  /**
   * customStyle :-
   *
   * If `present`, drawer gets the given style
   * Default - empty
   */
  customStyle,
  /**
   * childrenStyle :-
   *
   * If `present`, drawer's header gets the given style
   * Default - empty
   */
  childrenStyle,
  /**
   * headerCustomStyle :-
   *
   * If `present`, drawer's header gets the given style
   * Default - empty
   */
  headerCustomStyle,
  /**
   * headerCustomComponent :-
   *
   * If `present`, this component will be added to the bottom of the header
   * Default - null
   */
  headerCustomComponent = null,
  /**
   * enablePageClick :-
   *
   * If `present`, drawer's mask will enable click on page
   * Default - false
   */
  enablePageClick,
  /**
   * footer :-
   *
   * If `present`, drawer comes with a footer
   * Default - null
   */
  footer
}) => {
  let panelStyle = null

  const onCloseAction = () => {
    if (setIsOpen) {
      try {
        if (typeof setIsOpen === 'function') setIsOpen(false)
        else {
          throw new Error(
            `Expected a function as argument got ${typeof setIsOpen}`
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (onClose) {
      try {
        if (typeof onClose === 'function') {
          onClose()
        } else {
          throw new Error(
            `Expected a function as argument got ${typeof onClose}`
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
  if (anchor === 'right') {
    panelStyle = styles.visibleRight
  } else {
    panelStyle = styles.visibleLeft
  }
  return (
    <div className={isOpen ? styles.visible : styles.hidden}>
      <div
        data-testid='drawer'
        className={clsx(styles.panel, panelStyle)}
        style={customStyle}
      >
        <div>
          <div className={clsx(styles.drawerHeader)} style={headerCustomStyle}>
            {title && (
              <div className={clsx(styles.drawerTitle)}>
                <p data-testid='title' className={clsx(styles.title)}>
                  {title}
                </p>
                {titleTag && titleTagType && titleTagLabel && (
                  <div
                    data-testid='titleTag'
                    className={clsx(styles.titleTag)}
                    style={titleTagStyle}
                  >
                    <Tag
                      label={titleTagLabel}
                      variant={titleTagType}
                      rounded={titleTagRounded}
                    />
                  </div>
                )}
              </div>
            )}
            {description && (
              <div>
                <div
                  data-testid='description'
                  className={clsx(styles.description)}
                >
                  {description}
                </div>
              </div>
            )}
            {headerCustomComponent}
            <Button
              id='onClose'
              onClick={onCloseAction}
              className={styles.close}
              icon={<Clear />}
            />
          </div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
        {children && (
          <div
            data-testid='children'
            className={clsx(styles.drawerChildren, childrenStyle)}
          >
            {children}
          </div>
        )}
      </div>

      <div
        data-testid='drawer-mask'
        className={clsx(
          enablePageClick ? styles.enablePageClick : styles.mask,
          panelStyle
        )}
        onClick={() => !enablePageClick && onCloseAction()}
      />
    </div>
  )
}
