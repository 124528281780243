import React from 'react'
import '../../styles.css'
import styles from './button.module.css'
import clsx from 'clsx'
import DropDownIcon from '../../iconComponents/DropDown'
import { CopyBlock } from 'react-code-blocks'
export const Button = ({
  /**
   * className :-
   *
   * If `present`, button overrides the default styles,
   * Default - empty
   */
  className,
  /**
   * On Click :-
   *
   * If `present`, the action is triggered on click,
   * Default - empty
   */
  onClick,
  /**
   * Disbaled:-
   *
   * If `true`, button's on-click/on-hover actions are disabled,
   * Default - empty
   */
  disabled,
  /**
   * Styles :-
   *
   * If `present`, button overrides the default styles,
   * Default - empty
   */
  style,
  /**
   * Selectable :-
   *
   * If `true`, button select is enabled,
   * Default - empty
   */
  selectable,
  /**
   * Selected :-
   *
   * If `true`, button is considered selected and filled styles is applied ,
   * Default - empty
   */
  selected,
  /**
   * Drop Down :-
   *
   * If `true`, a dropdown is presnt on click ,
   * Default - empty
   */
  dropdown = false,
  page = true,
  /**
   * Upper Case :-
   *
   * If `true`, text in button is all in upper case ,
   * Default - true
   */
  uppercase = false,
  /**
   * Filled :-
   *
   * If `true`, button is considered primary ,
   * Default - false
   */
  filled = false,
  /**
   * Border :-
   *
   * If `true`, button is considered secondary ,
   * Default - false
   */
  border = false,
  /**
   * Icon :-
   *
   * If present, the respective icon is embedded inside the button ,
   * Default - false
   */
  icon,
  label,
  children,
  /**
   * Button Group :-
   *
   * If `true`, the button is considered to be within a buttongroup ,
   * Default - false
   */
  buttonGroup,
  /**
   * Id :-
   *
   * If present, the button is considered to have the mentioned ID ,
   * Default - undefined
   */
  id = undefined,
  /**
   * classes :-
   *
   * If present respective keys styles will be overriding to respective elements ,
   * Default - undefined
   */
  classes,
  ...props
}) => {
  const buttonStyle = clsx(
    styles.root,
    uppercase && styles.uppercase,
    !filled && !border && !selectable && styles.link,
    filled && styles.filled,
    buttonGroup && styles.buttonGroup,
    border && styles.border,
    page && styles.page,
    disabled && styles.disabled,
    selectable && styles.selectable,
    selected && styles.selected,
    className
  )

  return (
    <button
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      className={buttonStyle}
      data-testid={id ? id : 'Button'}
      style={style}
      {...props}
    >
      {!!icon && (
        <div
          data-testid='icon'
          className={clsx(styles.icon, classes && classes.icon)}
        >
          {icon}
        </div>
      )}
      <span
        className={clsx(
          styles.content,
          border && styles.border,
          filled && styles.filled,
          !filled && !border && !selectable && styles.link,
          page && styles.page,
          disabled && styles.disabled,
          classes && classes.content
        )}
      >
        {label}
        {children}
      </span>
      {!!dropdown && (
        <div className={clsx(styles.arrow, classes && classes.arrow)}>
          <DropDownIcon
            className={clsx(
              styles.dropdownIcon,
              disabled && styles.disabled,
              classes && classes.dropdownIcon
            )}
          />
        </div>
      )}
    </button>
  )
}
