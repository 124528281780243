import React from 'react';
import HeroBar from './sections/HeroBar';
import Features from './sections/Features';

export default function LandingPage() {
	return (
		<div style={{ height: 550 }}>
			<HeroBar/>
			<Features/>
		</div>
	);
}
