import { Button } from "@ip-synamedia/syna-ui-library";
import { makeStyles } from '@material-ui/core/styles';
import SelectAccount from "../../../SelectAccount";
import { useCreateTenantStore, useProjectsStore } from "../../../../state-management";
import React, { useState } from "react";
import shallow from "zustand/shallow";
import AddEditProjectDialog from "../Projects/AddEditProjectDialog";
import NewApplicationDialog from "../Applications/NewApplicationDialog";
import AddEditTenant from "../Tenants/AddEditTenant";
import AppErrorNotification from "../../../AppErrorNotification";
import AppCloseConfirmation from "../../../AppCloseConfirmation";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: "flex",
		width: '100%',
		height: '100%',
		background: '#FFFFFF',
		boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
	},
	secondaryContainer: {
		position: 'relative',
		width: '100%',
		height: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 20,
		paddingBottom: 0
	},
	titleContainer: {
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		alignItems: 'center',
		width: 'fit-content'
	},
	mainTitleContainer: {
		display: 'flex',
		width: '100%',
		height: 70,
	},
	buttons: {
		display: 'flex',
		marginLeft: 'auto'
	},
	button: {
		marginLeft: 20
	},
	title: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 20,
		color: '#292929',
	},
	synopsis: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14,
		color: '#8F8F8F',
	},
	separator: {
		border: '1px solid #C2C2C2',
		margin: '0px 12px',
		height: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectAccountDropdown: {
		width: "200px",
		marginLeft: 30,
		marginTop: -10
	}
}));

export default function Header() {
	const styles = useStyles();
	const resetTenantState = useCreateTenantStore(state => state.reset, shallow);

	const [addApplicationDialogVisible, setAddApplicationDialogVisible] = useState(false);
	const [addProjectDialogVisible, setAddProjectDialogVisible] = useState(false);
	const [addTenantDialogVisible, setAddTenantDialogVisible] = useState(false);
	const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [accountId, setAccountId] = useProjectsStore(state => [state.accountId, state.setAccountId], shallow);
	const [groups, setGroups] = useProjectsStore(state => [state.groups, state.setGroups], shallow);

	const handleAddApplicationClick = () => {
		setAddApplicationDialogVisible(true);
	};

	const handleAddTenantClick = () => {
		setAddTenantDialogVisible(true);
	};

	const handleAddProjectClick = () => {
		setAddProjectDialogVisible(true);
	};

	const handleAccountChange = (id, accounts) => {
		let account = accounts.find(account => account.id === id);

		setAccountId(id);
		setGroups(account?.groups|| []);

	};

	return (
		<div className={styles.rootContainer}>
			<div className={styles.secondaryContainer}>
				<div className={styles.mainTitleContainer}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>Your Projects</div>
						<div className={styles.separator}/>
						<div className={styles.synopsis}>Access and manage your tenants and applications</div>
					</div>
					<div className={styles.selectAccountDropdown}>
						<SelectAccount val={accountId} groups={groups} onChange={handleAccountChange} isValid={accountId != null}/>
					</div>
					<div className={styles.buttons}>
						<Button className={styles.button} filled={false} border={true} disabled={!accountId} onClick={handleAddApplicationClick} color="primary">ADD APPLICATION</Button>
						<Button className={styles.button} filled={false} border={true} disabled={!accountId} onClick={handleAddTenantClick} color="primary">ADD TENANT</Button>
						<Button className={styles.button} filled={true} disabled={!accountId} onClick={handleAddProjectClick} color="primary">ADD PROJECT</Button>
					</div>
				</div>
			</div>
			<AddEditProjectDialog visible={addProjectDialogVisible} setVisible={setAddProjectDialogVisible} accountId={accountId}/>
			<NewApplicationDialog visible={addApplicationDialogVisible}  setVisible={setAddApplicationDialogVisible} accountId={accountId}/>
			<AddEditTenant visible={addTenantDialogVisible && !closeConfirmationVisible} setVisible={setAddTenantDialogVisible} setCloseConfirmationVisible={setCloseConfirmationVisible} setErrorMessage={setErrorMessage}/>
			<AppCloseConfirmation visible={closeConfirmationVisible} setVisible={setCloseConfirmationVisible} setParentDialogVisible={setAddTenantDialogVisible} text={ 'Are you sure you want to cancel adding a tenant?' } resetState={resetTenantState}/>
			<AppErrorNotification errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
		</div>
	);
}
