import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AppDialog from "../AppDialog";
import AppButton from "../AppButton";

const useStyles = makeStyles(() => ({
	dialogContent: {
		width: 500,
		height: 175,
	},
	dialogBody: {
		paddingTop: 8,
	},
	warningContainer: {
		display: 'flex'
	},
	reportIcon: {
		marginTop: 2,
		color: '#FF3333'
	},
	textContainer: {
		height: 20,
		marginLeft: 10,
		paddingBottom: 40
	},
	buttonsContainer: {
		position: 'absolute',
		bottom: 24,
		right: 24,
		display: 'flex',
		marginLeft: 'auto',
		height: 30
	},
	yesButton: {
		marginLeft: 10
	},
}));

export default function AppCloseConfirmation({ visible, setVisible, setParentDialogVisible, text, resetState=false }) {
	const styles = useStyles();

	const handleYes = () => {
		setVisible(false);
		setParentDialogVisible(false);
		resetState && resetState();
	};

	const handleCancel = () => {
		setVisible(false);
		setParentDialogVisible(true);

	};

	return <AppDialog
		variant={'error'}
		open={visible}
		onClose={handleCancel}
		title={'Please Note'}
		overrideClasses={{ content: styles.dialogContent, body: styles.dialogBody }}
	>
		<div className={styles.warningContainer}>
			<ErrorOutlineIcon className={styles.reportIcon} sx={{ width: '16px', height: '16px' }}/>
			<div className={styles.textContainer}>
				{text}
				<br/>
                This action can not be undone.
				<br/>
			</div>
		</div>
		<div className={styles.buttonsContainer}>
			<AppButton variant={'outlined'} onClick={handleCancel}>CANCEL</AppButton>
			<AppButton variant={'contained'} onClick={handleYes} overrideClasses={{ button: styles.yesButton }}>YES</AppButton>
		</div>
	</AppDialog>;
}
