import styles from "./Item.module.scss"
import clsx from "clsx"
import Table from "../../Table"
import React, { useContext } from "react";
import Highlight from "../../Highlight"
import Button from "../../Button"
import Tooltip from "../../Tooltip"
import { useID } from "../../../Util/ID"

export default function Item({ id, selected, widthPx, valueCb, elementCb, tooltipCb, linkCb, onClick, icon, label }) {
    const baseID = useID();
    let result = id
    let tooltip = result;
    if (elementCb) {
        result = elementCb();
    }
    else if (valueCb) {
        tooltip = result = valueCb();
    } else {
        result = (
            <Button border={true} icon={icon} label={label}>
                ({selected && selected.length})
            </Button>
        );

    }
    const onClickToolbarAction = () => {
        let result = true;
        if (linkCb) {
            result = linkCb();
        }
        if (result && onClick) {
            onClick(selected);
        }
    };
    const isString = result && typeof result === "string" || typeof result === "number" || Array.isArray(result);
    const isLink = !!linkCb;
    const tooltipParams = tooltipCb && tooltipCb() || {};
    return <div data-cy={baseID + "rowItem-" + id} onClick={onClickToolbarAction} title={isString && !isLink ? tooltip : undefined} className={styles.root} style={{ width: widthPx }}>
        {!isLink && <div className={clsx(styles.content, isString && styles.string)}>
            {result}
        </div>}
        {!!isLink && <Tooltip {...tooltipParams}>
            <Button className={clsx(styles.link, styles.string)}>
                {result}
            </Button>
        </Tooltip>}
    </div>;
}
