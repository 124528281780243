import jsonschema from 'jsonschema';
import accountsSchema from './schemas/account-api_oapi.json';

const validator = new jsonschema.Validator();

function accessLocation(obj, path) {
	return path?.split('.')?.reduce((current, key) => current && current?.[key], obj);
}

function getPathResponseSchema(path, method="get", innerPath = "responses.200.content.*/*.schema") {

	if (!accountsSchema) {
		throw new Error("Failed loading accounts schema file");
	}

	const pathSchema = accountsSchema?.["paths"][path];
	const responsesSchema = pathSchema?.[method];
	const specificResponse = accessLocation(responsesSchema, innerPath);

	if (!specificResponse) {
		throw new Error(`The schema path paths.${path}.${method}.${innerPath} wasn't found`);
	}

	return specificResponse;
}

function validateSchema(data, schema) {

	// Validate the response against the schema
	const result = validator.validate(data, schema);
	const errors = [];

	if (result.errors?.length > 0) {
		result.errors.forEach(error => {
			errors.push(error.schema?.message?.[error.name] || error.toString());
		});
		console.error(`Data validation failed for data: ${JSON.stringify(data)} found ${errors.length} errors:\n${errors.toString()} `);

		return false;
	}

	return true;
}

function validateArrayItem(data, schemaPath, method = "get", httpCode = "200") {
	const innerPath =  `responses.${httpCode}.content.*/*.schema.items`;
	const schema = getPathResponseSchema(schemaPath, method, innerPath);

	return validateSchema(data, schema);
}

export const SchemaUtils = {
	validateSchema,
	validateArrayItem
};

const modelUtils = {
	SchemaUtils
};

export default modelUtils;
