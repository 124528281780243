import Search from '../Search'
import { useContextEx } from '../../Util/Contexts'
import styles from './MultiSelect.module.scss'
import Menu from '../Menu'
import Item from './Item'
import React from 'react'

export default function MultiSelect({ width, isAsyncAutocomplete }) {
  const [items] = useContextEx(Menu.Items) || []
  const [selected, setSelected] = useContextEx(Menu.Selected) || []
  const [value, setValue] = useContextEx(Menu.Search) || []
  const hasManyItems = items && items.length > 5
  const hasMultipleItems = items && items.length > 1
  const showSeparator = hasMultipleItems || hasManyItems
  const allChecked =
    selected && items && !!items.length && selected.length === items.length
  const toggleSelect = () => {
    if (allChecked) {
      setSelected && setSelected([])
    } else {
      setSelected && setSelected(items || [])
    }
  }
  return (
    <div className={styles.root}>
      {(!!hasManyItems || isAsyncAutocomplete) && (
        <Search
          className={styles.search}
          value={value}
          setValue={(str) => setValue(str)}
          width={width - 18}
        />
      )}
      {!!hasMultipleItems && (
        <Item
          multiSelect
          id='select-all'
          checked={allChecked}
          label='Select All'
          selectable={false}
          highlight={false}
          onClick={toggleSelect}
        />
      )}
      {!!showSeparator && <div className={styles.separator} />}
    </div>
  )
}
