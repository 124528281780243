import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQueryClient } from 'react-query';
import { editAccount as editAccountApi, getAccountById, getInvitedUsers, getUsers } from "../../../external-apis";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem } from '@material-ui/core';
import { useDeepCompareEffect, useMutationWithAuthorization, useQueryWithAuthorization } from "../../../custom-hooks";
import AppButton from "../../AppButton";
import AppTooltip from "../../AppTooltip";
import CloseIcon from "@mui/icons-material/Close";
import { getGroups } from "../../../config";
import Loader from "../../Loader";

const useStyles = makeStyles(() => ({
	additionalEmails: {
		margin: 0,
		marginTop: 20
	},
	dialogRoot: {
		borderTop: "10px solid #3381ff",
		minWidth: 850
	},
	fadeIn: {
		position: "relative",
		left: 30,
		objectFit: "contain"
	},
	loader: {
		position: "absolute",
		right: 1200
	},
	error: {
		position: "absolute",
		right: 1000
	},
	selectContainer: {
		marginTop: 12,
		width: 300
	},
	fieldLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
	},
	fieldContainer: {
		width: 800,
		marginTop: 12
	},
	titleRoot: {
		padding: '10px 24px 10px 24px',
		fontFamily: 'Source Sans Pro',
		fontWeight: 600,
		fontSize: 17,
		color: '#292929'
	},
	closeIconContainer: {
		position: "absolute",
		width: 9,
		height: 14,
		marginTop: 15,
		marginLeft: 800,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	formCheckboxLabel: {
		fontSize: 14
	},
	checkbox: {
		maxWidth: 13,
		maxHeight: 14,
		marginRight: 12,
		padding: "7px 6.5px 7px 6.5px",
		'&.Mui-checked': { color: "#3380FF" }
	},
	formCheckbox: {
		borderRadius: 4,
		padding: '0.01em 2px',
		marginTop: 5,
		marginLeft: 5,
		width: "flex",
		height: 10,
		color: '#292929'
	},
}));

const mapActiveUsers = (apiResponse) => {
	return apiResponse.map(userData => {
		const { email } = userData;

		return email;
	});
};
const mapInvitedUsers = (apiResponse) => {
	return apiResponse.map(invitedUserData => {
		const { email } = invitedUserData;

		return email;
	});
};

export default function EditAccountDialog({ editOpen, setEditOpen, data }) {
	const classes = useStyles();
	const staleTime = 60000;

	const [open, setOpen] = useState(false);
	const [editEmail, setEditEmail] = useState(data.ownerEmail);
	let [userGroups, setUserGroups] = useState(data?.groups || []);
	let tempUserGroups = data?.groups || [];
	let availableGroups = getGroups()?.split(" ");
	const orgUserGroups = useRef(userGroups);

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();

	const [crmAccountId, setCrmAccountId] = useState(data.crmAccountId || "");
	const [snowCompanySysID, setSnowCompanySysID] = useState(data.snowCompanySysID || "");
	const orgCrmAccountId = useRef(crmAccountId);
	const orgSnowCompanySysIDd = useRef(snowCompanySysID);

	useDeepCompareEffect(() => {
		setOpen(editOpen);
	}, [editOpen, tempUserGroups, userGroups, setUserGroups]);

	const handleChangeGroups = event => {

		if (event.target.checked) {
			setUserGroups([...userGroups, event.target.value]);
		} else {
			setUserGroups(userGroups.filter(a => a !== event.target.value));
		}
	};

	const handleClose = () => {
		setOpen(false);
		setEditOpen(false);
		setEditEmail('');
	};

	const editAccount = async (accountId, data) => {

		const editAccountPromise = mutateAsync(editAccountApi(accountId, data));

		try {
			await toast.promise(editAccountPromise,
				{
					pending: "Editing Account...",
					success: "Edited",
					error: {
						render({ data: error }) {
							const errorMessage = JSON.parse(error?.message);

							console.error('Error Editing Account', error.message);

							return `Error Editing Account - ${errorMessage.message || ''}`;

						}
					}
				}, { position: toast.POSITION.TOP_CENTER }
			);
			queryClient.invalidateQueries('accounts');
			queryClient.invalidateQueries(['get-account-by-id', accountId]);
			handleClose();
		} catch (e) {
			console.log(e.message);
		}
	};

	const handleEdit = () => {
		const accountToEdit = {
			ownerEmail: editEmail ? editEmail : account.owner.email,
			alias: account.alias,
			displayName: account.displayName,
			additionalEmails: account?.additionalEmails,
			groups: userGroups,
			crmAccountId,
			snowCompanySysID

		};

		editAccount(account.id, accountToEdit);
	};

	const {
		data: users = [],
		isError: userIsError,
		isLoading: userIsLoading,
		error: userError
	} = useQueryWithAuthorization(['users-active', data?.id], getUsers(data?.id), {
		select: mapActiveUsers,
		enabled: data?.id !== undefined,
		staleTime
	});
	const {
		data: invitees = [],
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
		error: inviteesError
	} = useQueryWithAuthorization(['users-invited', data?.id], getInvitedUsers(data?.id), {
		select: mapInvitedUsers,
		enabled: data?.id !== undefined,
		staleTime

	});

	const {
		data: account = {},
		isError: accountIsError,
		isLoading: accountIsLoading,
		error: accountError
	} = useQueryWithAuthorization(['get-account-by-id', data?.id], getAccountById(data?.id), {
		enabled: data?.id !== undefined,
		staleTime
	});

	if (!data) return <div/>;
	if (accountIsLoading || userIsLoading || inviteesIsLoading) return <Loader/>;
	if (accountIsError) return <div className={classes.error}>Error fetching data: {accountError.message}</div>;
	if (userIsError) return <div className={classes.error}>Error fetching data: {userError.message}</div>;
	if (inviteesIsError) return <div className={classes.error}>Error fetching data: {inviteesError.message}</div>;

	const optionalEmails = [...new Set([...users.concat(invitees), account.owner.email])];

	const comp = (arr1, arr2) => {

		return (arr1.length === arr2.length && !arr1?.some((v) => arr2?.indexOf(v) < 0));

	};

	const isSaveDisabled = () => {

		return (crmAccountId === '') ||
			((editEmail === '' || editEmail === account.owner.email) &&
				comp(orgUserGroups.current, userGroups) &&
				orgCrmAccountId.current === crmAccountId &&
				orgSnowCompanySysIDd.current === snowCompanySysID
			);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
				classes={{ paper: classes.dialogRoot }}>
				<div className={classes.closeIconContainer} onClick={handleClose}><CloseIcon fontSize='small'/></div>
				<DialogTitle id="form-dialog-title" classes={{ root: classes.titleRoot }}>Edit Account</DialogTitle>
				<DialogContent dividers>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldLabel}>
                            Account Name
						</div>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label={false}
							fullWidth
							disabled={true}
							value={account?.alias}
						/>
					</div>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldLabel}>
                            Display Name
						</div>
						<TextField
							margin="none"
							id="displayName"
							label={false}
							fullWidth
							disabled={true}
							value={account?.displayName}

						/>
					</div>

					<div className={classes.fieldContainer}>
						<div className={classes.fieldLabel}>
                            CRM Account ID
						</div>
						<TextField
							margin="none"
							id="crmAccountId"
							label={false}
							fullWidth
							value={crmAccountId}
							onChange={(e) => setCrmAccountId(e.target.value)}
						/>
					</div>

					<div className={classes.fieldContainer}>
						<div className={classes.fieldLabel}>
							SNOW Company Sys Id For Customer Notifications (optional)
						</div>
						<TextField
							margin="none"
							id="snowCompanySysID"
							label={false}
							fullWidth
							value={snowCompanySysID}
							onChange={(e) => setSnowCompanySysID(e.target.value)}
						/>
					</div>

					<form className={classes.fieldContainer}>
						<div className={classes.fieldLabel}>
                            Groups
						</div>
						<FormControl fullWidth component="fieldset" margin="normal">
							<FormGroup row={true}>
								{availableGroups.map((group, i) => (
									<FormControlLabel
										classes={{ root: classes.formCheckbox, label: classes.formCheckboxLabel }}
										key={i}
										value={group}
										control={<Checkbox className={classes.checkbox} onChange={handleChangeGroups}/>}
										label={group}
										checked={userGroups?.includes(group)}
									/>
								))}
							</FormGroup>
						</FormControl>
					</form>
					<div className={classes.selectContainer}>
						<div className={classes.fieldLabel}>
                            Owner Email
						</div>
						<TextField
							label={''}
							InputLabelProps={{
								style: { color: 'rgba(0, 0, 0, 0.87)' },
							}}
							id="select-owner-email-address"
							select
							margin='dense'
							value={editEmail}
							fullWidth
							onChange={event => setEditEmail(event.target.value)}
							variant='outlined'
							inputProps={{ style: { minHeight: 30 } }}
						>
							{optionalEmails.map((email, index) => {
								return (
									<MenuItem key={index} value={email}>
										{email}
									</MenuItem>
								);
							})}
						</TextField>

					</div>

				</DialogContent>
				<DialogActions>
					<AppButton onClick={handleClose} variant={'outlined'}>
                        Cancel
					</AppButton>
					<AppTooltip title={'No changes have been made or CRM ID (mandatory) not specified'}
						disabled={!isSaveDisabled()}>
						<div>
							<AppButton onClick={handleEdit} variant={'contained'} disabled={isSaveDisabled()}>
                                SAVE
							</AppButton>
						</div>
					</AppTooltip>
				</DialogActions>
			</Dialog>
			{/**/}
		</div>
	);
}

