import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from "@ip-synamedia/syna-ui-library";
import { useQueryClient } from 'react-query';
import { createAccount as apiCreateAccount } from "../../../external-apis";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { isValidEmail } from "../../../utils/validation";
import { produce } from "immer";
import { useMutationWithAuthorization } from "../../../custom-hooks";
import AppButton from "../../AppButton";
import clsx from "clsx";
import { Html5Entities } from 'html-entities';

const useStyles = makeStyles(() => ({
	dialogRoot: {
		borderTop: "10px solid #3381ff",
		minWidth: 900
	},
	fadeIn: {
		position: "relative",
		left: 30,
		objectFit: "contain"
	},
	helperText: {
		fontFamily: 'Source Sans Pro',

	},
	helperTextError: {
		color: '#ff3333'
	}
}));

const errorInitialState = Object.freeze({
	alias: false,
	email: false,
	crmAccountId: false,
	displayName: false,
	additionalEmails: false
});

const accountFieldsInitialState = Object.freeze({
	alias: '',
	email: '',
	displayName: '',
	additionalEmails: [],
	crmAccountId: ''
});

export default function AddAccountDialog() {
	const classes = useStyles();
	const entities = new Html5Entities();
	const [open, setOpen] = useState(false);
	const [createAccountFields, setCreateAccountFields] = useState({ ...accountFieldsInitialState });
	const [error, setError] = useState({ ...errorInitialState });

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const resetErrors = () => {
		setError({ ...errorInitialState });
	};

	const resetAccountFields = () => {
		setCreateAccountFields({ ...accountFieldsInitialState });
	};

	const handleClose = () => {
		setOpen(false);
		resetErrors();
		resetAccountFields();
	};

	const createAccount = async (data) => {

		setOpen(false);
		resetErrors();

		const createAccountPromise = mutateAsync(apiCreateAccount(data));

		try {
			await toast.promise(createAccountPromise,
				{
					pending: "Creating Account...",
					success: "Created",
					error: {

						render({ data: error }) {
							return `Error Creating Account: ${error.message}`;
						}
					}
				}, { position: toast.POSITION.TOP_CENTER }
			);
			queryClient.invalidateQueries('accounts');
		} catch (e) {
			console.log(e.message);
		}
		finally {
			handleClose();
		}
	};

	const validateUserInput = (createAccountFields) => {
		// Empty values not allowed
		const inputErrors = {
			alias: error.alias || !createAccountFields.alias.trim() || !createAccountFields.alias.match('^(?=.*[a-z])([a-z0-9-]{3,32})$'),
			email: error.email || !createAccountFields.email.trim(),
			displayName: error.displayName || !createAccountFields.displayName.trim(),
			additionalEmails: error.additionalEmails,
			crmAccountId: error.crmAccountId || !createAccountFields.crmAccountId.trim()
		};

		setError({ ...inputErrors });

		return Object.values(inputErrors).some(Boolean);
	};

	const handleCreate = () => {
		const hasError = validateUserInput(createAccountFields);

		if (hasError) return;

		const { alias, email, additionalEmails, displayName, crmAccountId } = createAccountFields;
		const accountToCreate = {
			ownerEmail: email,
			alias: alias,
			displayName,
			crmAccountId
		};

		const filteredAdditionalEmails = additionalEmails && additionalEmails.filter(email => email);

		if (filteredAdditionalEmails && filteredAdditionalEmails.length) {
			accountToCreate.additionalEmails = filteredAdditionalEmails;
		}

		createAccount(accountToCreate);
	};

	const setAccountAlias = (val) => {
		const nameContainsOnlyDigits = /^\d+$/.test(val);

		setError({ ...error, alias: nameContainsOnlyDigits || !val.trim() });

		!nameContainsOnlyDigits && setCreateAccountFields(produce(createAccountFields, draft => {
			draft.alias = val;
		}));
	};

	const setDisplayName = (val) => {
		setError({ ...error, displayName: !val });
		val && setCreateAccountFields(produce(createAccountFields, draft => {
			draft.displayName = val;
		}));
	};

	const setCrmAccountId = (val) => {
		setError({ ...error, crmAccountId: !val });
		val && setCreateAccountFields(produce(createAccountFields, draft => {
			draft.crmAccountId = val;
		}));
	};

	const setEmail = (val) => {
		setError({ ...error, email: !isValidEmail(val) });
		val && setCreateAccountFields(produce(createAccountFields, draft => {
			draft.email = val;
		}));
	};

	return (
		<div>
			<Button filled={true} style={{ marginRight: 12 }} onClick={handleClickOpen} color="primary">
                ADD ACCOUNT
			</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
				classes={{ paper: classes.dialogRoot }}>
				<DialogTitle id="form-dialog-title">Create Account</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>
                        To create an account, please fill the form
					</DialogContentText>
					<TextField
						error={error.alias}
						onChange={event => setAccountAlias(event.target.value)}
						autoFocus
						margin="dense"
						id="name"
						label="Account Name"
						fullWidth
						required
					/>
					<div className={clsx(classes.helperText, error.alias && classes.helperTextError)}>
						<text> {entities.decode('&#8226;')} </text>
                        Must be at least 3 characters long<br/>
						<text> {entities.decode('&#8226;')} </text>
                        Must include at least 1 alphabetic character<br/>
						<text> {entities.decode('&#8226;')} </text>
                        Must only contain lowercase letters (a-z) and numerical digits (0-9)<br/>
					</div>
					<TextField
						error={error.displayName}
						onChange={event => setDisplayName(event.target.value)}
						margin="dense"
						id="displayName"
						label="Display Name"
						fullWidth
						required
					/>
					<TextField
						inputMode="email"
						error={error.email}
						onChange={event => setEmail(event.target.value)}
						margin="dense"
						id="email"
						label="Owner Email Address"
						type="email"
						fullWidth
						required
					/>
					<TextField
						error={error.crmAccountId}
						onChange={event => setCrmAccountId(event.target.value)}
						margin="dense"
						id="crmAccountId"
						label="CRM Account ID"
						fullWidth
						required
					/>
				</DialogContent>
				<DialogActions>
					<AppButton onClick={handleClose} variant={'outlined'}>
                        Cancel
					</AppButton>
					<AppButton onClick={handleCreate} variant={'contained'}>
                        Create
					</AppButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}
