const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    const tier = Math.log10(number) / 3 | 0;
    // if zero, we don't need a suffix
    if(tier == 0) return number;
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = number / scale;
    // format number and add suffix
    return scaled.toFixed(1) + suffix;
}

export function title(string) {
    return string && string[0].toUpperCase() + string.substring(1);
}

export function camelCaseToTitle(string) {
    return title(string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1"));
}

export function replaceAll(string, find, replace="") {
    if(!string) {
        return "";
    }
    if(!find) {
        return string;
    }
    if (Object.prototype.toString.call(find).toLowerCase() === '[object regexp]') {
        return string.replace(find, replace);
    }
    if(typeof string === "string") {
        return string.replace(new RegExp(find, 'g'), replace);
    }
    return string;
}
