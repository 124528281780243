import React, { useState } from 'react';
import { useUsersStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import {
	getProjectsByAccountId,
	getUserRolesByAccount,
	getUserRolesByProject
} from "../../../../external-apis";
import { SchemaUtils } from '../../../../model/utils';

import {
	useQueriesWithAuthorization,
	useQueryWithAuthorization
} from "../../../../custom-hooks";
import Loader from "../../../Loader";
import EditUsersDialog from "./index";
import AppDialog from "../../../AppDialog";
import AppErrorNotification from "../../../AppErrorNotification";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
	dialogContainer: {
		width: 850,
		position: 'absolute'
	},
	actionsContainer: {
		display: "flex",
		alignItems: "center",
	},
	cancelBtm: {
		marginLeft: "auto",
	},
}));

const GET_PROJECT_ROLES_SCHEMA_PATH = "/accounts/{accountId}/projects/{projectId}/members/{userId}/roles";
const GET_ACCOUNT_ROLES_SCHEMA_PATH = "/accounts/{accountId}/users/{userId}/roles";

const checkLoadError = (queries) => {

	if (!queries.every(key => !key.isLoading)) return<Loader width={30} height={30} type={'Rings'}/>;

	const queriesError = queries.length > 0 && queries.find(key => key.isError === true);

	if (queriesError) {
		return "error";
	}

	return false;
};

const getQueries = ( accountId, projects, userId, staleTime ) => {
	return projects.map((project) => {
		return { queryKey:['get-user-roles-by-project', project.id, userId], query: getUserRolesByProject(accountId, project.id, userId), staleTime: staleTime, enabled: userId !== undefined && accountId!==undefined };
	});
};

const mapProjectsToUiElement = (projects) => {
	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			label: displayName
		};
	});
};

export default function EditWrapper({ visible, setVisible, userData, status, closeEditConfirmationVisible, setCloseEditConfirmationVisible }) {
	const staleTime = 120000;
	const accountId = useUsersStore(state => state.accountId, shallow);
	const styles = useStyles();
	const [firstRender, setFirstRender] = useState(true);
	const errorMessage = "An unexpected error occurred. Please try again later or contact customer support if the error persists";

	const {
		data:projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null && userData !== undefined,
		staleTime: staleTime
	});

	const {
		data:accountRoles = [],
		isError: accountRolesIsError,
		isLoading: accountRolesIsLoading,
	} = useQueryWithAuthorization(['userRoles', accountId, userData?.id], getUserRolesByAccount(accountId, userData?.id), {
		enabled: userData?.id && accountId !== undefined,
		staleTime: staleTime
	});
	const handleCancel = () => {
		setVisible(false);
	};

	const queries = getQueries(accountId, Object.values(projects), userData?.id, staleTime);
	const rolesQueries = useQueriesWithAuthorization(queries);

	function errorLoadingDialog(message) {
		if (message === "error") {
			return <AppErrorNotification errorMessage={errorMessage} setErrorMessage={setVisible}/>;
		}

		return <div className={styles.dialogContainer}>
			<AppDialog open={visible} title={'Edit user'} onClose={handleCancel}>
				{message}
				<div className={styles.actionsContainer}>
					<div className={styles.cancelBtm}>
						<Button onClick={handleCancel}>cancel</Button>
					</div>
				</div>
			</AppDialog>
		</div>;
	}

	if(accountRolesIsLoading || projectsIsLoading) return errorLoadingDialog(<Loader width={30} height={30} type={'Rings'}/>);
	if(checkLoadError(rolesQueries)) return errorLoadingDialog(checkLoadError(rolesQueries));

	// Filter out roles data that are not according to role schema
	const filteredRolesQueries = rolesQueries.map(roleQuery => ({ ...roleQuery, data: roleQuery?.data?.filter(role => SchemaUtils.validateArrayItem(role, GET_PROJECT_ROLES_SCHEMA_PATH)) || []}));

	if(projectsIsError) return errorLoadingDialog(<div>Error fetching data: {projectError}</div>);
	if(accountRolesIsError) return errorLoadingDialog("error");

	// Filter out roles data that are not according to role schema
	const filteredAccountRoles = accountRoles?.filter(role => SchemaUtils.validateArrayItem(role, GET_ACCOUNT_ROLES_SCHEMA_PATH));

	return (
		<div>
	 		<EditUsersDialog visible={visible} setVisible={setVisible} closeEditConfirmationVisible={closeEditConfirmationVisible} setCloseEditConfirmationVisible={setCloseEditConfirmationVisible} userData={userData} status={status} projects={projects} accountRoles={filteredAccountRoles} rolesQueries={filteredRolesQueries} firstRender={firstRender} setFirstRender={setFirstRender}/>
	  	</div>
	);
}
