import styles from "./TableOptions.module.scss"
import ColumnsIcon from "../../../iconComponents/Columns"
import React, {useContext, useState, useMemo, Fragment} from "react"
import Modal from "../../Util/Modal"
import clsx from "clsx"
import Table from "../Table"
import CloseIcon from "../../../iconComponents/Clear"
import Button from "../Button"
import Item from "./TableOptions/Item"
import Drag from "../../Util/Drag"

function mapItemsToElements(items) {
    return items.filter(item => item.label).map((item,index) => {
        return <Item key={item.id} index={index} count={items.length} {...item} />;
    });
}

export function useElements(items) {
    const elements = useMemo(() => {
        if (items) {
            return mapItemsToElements(items);
        }
        return null;
    }, [items]);
    return elements;
}

export default function TableOptions() {
    const [visible, setVisible] = useState(false) || [];
    const [columns, setColumns] = useContext(Table.Columns) || [];
    const [defaultColumns] = useState(columns && [...columns]);
    const [items, setItems] = useState([]);
    const elements = useElements(items);
   
    const onClose = () => {
        setVisible(false);
    };

    const showOptions = () => {
        setItems([...columns]);
        setVisible(true);
    };

    const saveColumns = () => {
        setColumns([...items]);
        setVisible(false);
    };

    const resetOptions = () => {
        setItems([...defaultColumns]);
    };
    
    return <Fragment>
        <ColumnsIcon onClick={showOptions} className={styles.columnsIcon} />
        <Modal visible={visible}>
            <div className={clsx(styles.container, visible && styles.visible)}>
                <div className={styles.dialog}>
                    <div className={styles.dialogTopBorder} />
                    <div className={styles.header}>
                        <div className={styles.title}>Customize Columns</div>
                        <CloseIcon onClick={onClose} className={styles.close} />
                    </div>
                    <div className={styles.description}>
                        Drag and drop to change columns order
                    </div>
                    <div className={styles.columns}>
                        <TableOptions.Items.Provider value={[items, setItems]}>
                            <Drag enabled={true}>
                                {elements}
                            </Drag>
                        </TableOptions.Items.Provider>
                    </div>
                    <div className={styles.buttons}>
                        <Button onClick={resetOptions} uppercase={true} label="Reset to default settings" />
                        <div style={{ flex: 1 }} />
                        <Button onClick={onClose} uppercase={true} label="Cancel" />
                        <Button onClick={saveColumns} filled={true} uppercase={true} label="Save" />
                    </div>
                </div>
            </div>
        </Modal>
    </Fragment>;
}

TableOptions.Items = React.createContext();
