import React, { useState, useRef, useEffect } from 'react';
import styles from './MultiSelectDropDown.module.scss';
import { Button } from '../button/Button';
import { useWindowSize } from "../../util/size"
import { CheckBox } from '../checkbox/Checkbox';
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { TextField } from '../textField/TextField';
import DropDownIcon from '../../iconComponents/DropDown';
export function MultiSelectDropdown({
    style,
    /**
     * disabled:-
     * 
     * If `true`, dropdown's on-click/on-hover actions will be disabled, 
     * Default - false
    */
    disabled,
    /**
     * relativePos :-
     *
     * The relativePos indicates top, left position of dropdwon modal relative to dropdown button
     * Default - empty
     */
    relativePos,
    /**
    * selectedOptions :-
    *
    * Array of the ids of the options that has to be selected,
    * Default - empty
    */
    selectedOptions = [],
    /**
    * setSelectedOptions :-
    *
    * Function to run on selecting options,
    * Default - --
    */
    setSelectedOptions,
    /**
    * options :-
    *
    * The options to be rendered on dropdown modal for selection,
    * Default - empty
    */
    options = [],
    /**
    * label :-
    *
    * The label of the dropdown button
    * Default - --
    */
    label,
    /**
    * buttonProps :-
    *
    * The props object to be passed to the button
    * Default - --
    */
    buttonProps,
    /**
    * filled :-
    *
    * If true a primary button is rendered
    * Default - false
    */
    filled = false,
    variant = 'button',
    className,
    ...props
}) {
    const dialogRef = useRef();
    const windowSize = useWindowSize();
    const [menuVisible, setMenuVisible] = useState(false);
    var rect = dialogRef.current && dialogRef.current.getBoundingClientRect() || {};
    style = { ...style };
    if (!style.right) {
        style.left = rect.left;
    }
    if (!style.width) {
        style.width = rect.width;
    }
    style.top = rect.bottom;
    if (relativePos) {
        if (relativePos.left) {
            style.left += relativePos.left;
        }
        if (relativePos.top) {
            style.top += relativePos.top;
        }
    }
    const menuHeight = Math.min((options && options.length + 1) * 30, 300);
    if (windowSize && windowSize.height - style.top < menuHeight) {
        style.top = rect.top - menuHeight;
    }
    const closeMenu = () => {
        setMenuVisible(false);
    };
    const showMenu = () => {
        setMenuVisible(true);
    };
    const isSelected = (id) => {
        return selectedOptions.find((item) => { return item === id })
    }
    const setSelected = (id, checked) => {
        checked ? setSelectedOptions([...selectedOptions, id]) : setSelectedOptions(selectedOptions.filter((item) => { return item !== id }))
    }
    return (
        <div { ...props }>
            <div ref={ dialogRef } className={ clsx(styles.views, className) }>
                { variant == 'textfield' ? 
                <div onClick={!disabled && showMenu}>
                <TextField disabled={!!disabled} className={styles.textField} value={selectedOptions} icon={<DropDownIcon width={7} height={5} className={clsx(styles.arrow, disabled && styles.disabled)}/>} label={label}/>
                </div> :
                <Button filled={filled} onClick={showMenu} uppercase={true} dropdown={true} disabled={!!disabled} id='MultiSelectDropdown' {...buttonProps}>
                    {label}
                </Button>
                }
            </div>
            <div className={clsx(styles.mask, menuVisible && styles.visible)} onClick={closeMenu} />
            <div className={clsx(styles.dialog, menuVisible && styles.visible)} style={style}>
                {options.map((item) => {
                    return <div className={ styles.elements } key={ item.id }> <CheckBox id={ item.id + '-checkBox' } disabled={ item.disabled } label={ item.label } checked={ isSelected(item.id) } setChecked={ (checked) => { setSelected(item.id, checked) } } /> </div>
                })}
            </div>
        </div>
    )
}
MultiSelectDropdown.propTypes = {
    setSelectedOptions: PropTypes.func
};