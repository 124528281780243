import React, {useContext, useMemo} from "react"
import {replaceAll} from "../../../util/string"

export function localize(localization, language, text) {
    if(!localization || !language) {
        return text;
    }
    const mapping = localization[language];
    if(!mapping) {
        return text;
    }
    if(mapping[text]){
        return mapping[text];
    }
    for(const key in mapping) {
        text = replaceAll(text, key, mapping[key]);
    }
    return text;
}

export function mergeLocalization(target, source) {
    if(source) {
        for(const lang in source) {
            target[lang] = Object.assign({}, target[lang], source[lang]);
        }
    }
}

export function useLocalization(localization) {
    const [parentLocalization] = useContext(Text.Localization) || [];
    const items = useMemo(() => {
        let result = {};
        if(parentLocalization) {
            mergeLocalization(result, parentLocalization);
        }
        if(localization) {
            mergeLocalization(result, localization);
        }
        return result;
    }, [localization, parentLocalization]);
    return items;
}

export default function Text({type="div", children, ...props}) {
    const [language] = useContext(Text.Language) || [];
    const [localization] = useContext(Text.Localization) || [];
    const elements = React.Children.map(children, item => {
        if(!React.isValidElement(item)) {
            return localize(localization, language, item);
        }
        return React.cloneElement(item);
    }).filter(Boolean);
    return React.createElement(type, props, elements);
}

export function TextValue(value) {
    const [language] = useContext(Text.Language) || [];
    const [localization] = useContext(Text.Localization) || [];
    return localize(localization, language, value);
}

Text.Language = React.createContext();
Text.Localization = React.createContext();
