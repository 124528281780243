import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import { usePanelsStore } from "../../state-management";
import { useSidebarItems } from './items';

const useStyles = makeStyles((theme) => {
	const appBarTop = 76;
	const drawerWidth = 250;
	const mainContentOpenIndentation = 18;
	const mainContentCloseIndentation = 194;

	return {
		root: {
			display: 'flex',
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			zIndex: 0,
			backgroundColor: "#292929",
			height: 'calc(100% - 64px)',
			top: appBarTop,
		},
		drawerOpen: {},
		drawerClose: {
			overflowX: 'hidden',
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			borderRadius: 20
		},
		listItem: {
			color: "#ffffff",
			borderLeft: "5px solid transparent",
			left: 10,
		},
		listItemGutters: {
			paddingLeft: 10,
			paddingRight: 24,
			height: 40
		},
		paper: {
			height: "100%"
		},
		mainContent: {
			height: `calc(100% - ${appBarTop}px)`,
			backgroundColor: "white",
			position: "fixed",
			borderTopLeftRadius: 20,
			top: appBarTop,
			right: 0,
			bottom: 0,
			left: 0,
			transition: 'transform 250ms ease',
			'-webkit-transition': 'transform 250ms ease',
			willChange: 'transform, -webkit-transform, padding-right'
		},
		mainContentOpen: {
			paddingRight: drawerWidth - mainContentOpenIndentation,
			transform: `translateX(${drawerWidth - mainContentOpenIndentation}px)`,
			'-webkit-transform': `translateX(${drawerWidth - mainContentOpenIndentation}px)`,
		},
		mainContentClose: {
			paddingRight: drawerWidth - mainContentCloseIndentation,
			transform: `translateX(${drawerWidth - mainContentCloseIndentation}px)`,
			'-webkit-transform': `translateX(${drawerWidth - mainContentCloseIndentation}px)`,
		},
		selectedLeaf: {
			borderLeft: "5px solid red",
			fontWeight: "bold",
			backgroundColor: "#3381ff"
		},
		sideBarItemTypography: {
			width: "max-content",
			fontSize: 14,
			fontStyle: "normal",
			lineHeight: 1.21,
			marginLeft: 12,
			fontFamily: "'Source Sans Pro', sans-serif"

		},
		hoverStyle: {
			"&:hover": {
				backgroundColor: "hsla(0,0%,100%,.15)"
			}
		},
		parentWithSelectedChild: {
			borderLeft: "5px solid red",
			backgroundColor: "hsla(0,0%,100%,.15)"
		},
		selectedFamily: {
			backgroundColor: "rgba(255, 255, 255, 0.1)"
		}

	};
});

const CustomListItemIcon = styled(ListItemIcon)({
	color: "#ffffff",
	left: 20,
	minWidth: "max-content"
});

const parentWithSelectedChild = (parentId, children = [], sidebarSelectedId) => {
	if (parentId === sidebarSelectedId) {
		return true;
	}
	const hasSelectedChildren = (children || []).some(child => parentWithSelectedChild(child.id, child.children, sidebarSelectedId));

	return hasSelectedChildren;
};

function findItemAncestors(idToFind, parents = [], sidebarItems = []) {
	for (let i = 0; i < sidebarItems.length; i++) {
		const sidebarItem = sidebarItems[i];

		if (sidebarItem.id === idToFind) {
			return [];
		}
		if (sidebarItem.children) {
			const res = findItemAncestors(idToFind, parents, sidebarItem.children);

			if (res) {
				const children = sidebarItem.children.map(obj => obj.id);

				return [...res, sidebarItem.id, ...children];
			}
		}

	}
}

const SideBarItem = ({ id, title, icon, children = [], component, brothers, onClick, depth = 0, fullItems }) => {
	const classes = useStyles();
	const isDrawerOpen = usePanelsStore(state => state.isDrawerOpen);
	const setIsDrawerOpen = usePanelsStore(state => state.setIsDrawerOpen);
	const selectedSidebarItem = usePanelsStore(state => state.selectedSidebarItem);
	const setSelectedSidebarItem = usePanelsStore(state => state.setSelectedSidebarItem);
	const setSelectedPanel = usePanelsStore(state => state.setSelectedPanel);
	const isExpandable = children && children.length;
	const [open, setOpen] = useState(false);
	const isSelected = id === selectedSidebarItem;
	const isParent = !!(children && children.length);
	const isLeafSelected = isSelected && !isParent;
	const isTopMenu = !depth;

	// const topMenuIds = useMemo(() => {
	// 	return new Set(fullItems.map(obj => obj.id));
	// }, [fullItems]);

	const directChildren = useMemo(() => {
		return new Set(children.map(obj => obj.id));
	}, [children]);

	const handleClick = () => {

		if (isParent) {
			if (!isDrawerOpen) {
				setIsDrawerOpen(true);
			}
			if (parentWithSelectedChild(id, children, selectedSidebarItem)) {
				setOpen(!open);
			} else {
				setOpen(!open);
			}

		} else {
			setIsDrawerOpen(true);
			setSelectedSidebarItem(id);
			setSelectedPanel(component);
		}
	};
	const style = {
		marginLeft: (depth * 16)
	};

	const family = new Set(findItemAncestors(selectedSidebarItem, [], fullItems));
	const appliedStyles = [classes.listItem, !isSelected && classes.hoverStyle];

	if (isDrawerOpen) {
		if (directChildren.has(selectedSidebarItem) && !open) {
			appliedStyles.push(classes.parentWithSelectedChild);
		}
		if (isLeafSelected || (isParent && (!children || !children.length))) {
			appliedStyles.push(classes.selectedLeaf);
		} else if (family.has(id)) {
			appliedStyles.push(classes.selectedFamily);
		}

	} else {
		if ((isParent && parentWithSelectedChild(id, children, selectedSidebarItem)) || (isSelected && isTopMenu)) {
			appliedStyles.push(classes.parentWithSelectedChild);
		}
	}
	const MenuItemRoot = (
		<div className={clsx(...appliedStyles)}>
			<div className={classes.listItem}>
				<div style={style}>
					<ListItem button onClick={onClick || handleClick} classes={{ gutters: classes.listItemGutters }}>
						{!!icon && (
							<CustomListItemIcon>
								{icon}
							</CustomListItemIcon>
						)}
						<Typography title={title} className={clsx(classes.sideBarItemTypography)} variant="inherit"
							noWrap>
							{title}
						</Typography>
						<div style={{ flex: 1 }}/>
						{/* Display the expand menu if the item has children */}
						{isDrawerOpen && !!isExpandable && !open && <ExpandMore/>}
						{isDrawerOpen && !!isExpandable && open && <ExpandLess/>}
					</ListItem>
				</div>
			</div>
		</div>
	);
	const parentData = { id, isOpen: open };
	const getBrothers = (selfId, brothers = []) => {
		const res = [];

		brothers.forEach(obj => {
			if (obj.id !== selfId) {
				res.push(obj.id);
			}
		});

		return new Set(res);
	};
	const MenuItemChildren = isExpandable ? (
		<Collapse in={isDrawerOpen && open} timeout="auto" unmountOnExit>
			<List disablePadding className={clsx(classes.list)}>
				{children.map((child, index) => {
					const brothers = getBrothers(child.id, children);

					return <SideBarItem {...child} brothers={brothers} parent={parentData} fullItems={fullItems}
						depth={depth + 1} key={index}/>;
				})}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	);

};

export default function SideBar() {
	const classes = useStyles();
	const isDrawerOpen = usePanelsStore(state => state.isDrawerOpen);
	const sidebarItems = useSidebarItems();

	return (
		<div className={classes.root}>
			<CssBaseline/>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawer, {
						[classes.drawerOpen]: isDrawerOpen,
						[classes.drawerClose]: !isDrawerOpen

					}),
				}}
			>
				<List disablePadding={true} style={{ paddingTop: 20 }}>
					{sidebarItems.map((item, index) => {
						return <SideBarItem {...item} fullItems={sidebarItems} key={index}/>;
					})}
				</List>
			</Drawer>
			<div className={clsx(classes.mainContent, {
				[classes.mainContentOpen]: isDrawerOpen,
				[classes.mainContentClose]: !isDrawerOpen,
			})}>
			</div>
		</div>
	);
}

