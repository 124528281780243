import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getAllProductRoles } from "../../../../external-apis";
import Loader from "../../../Loader";
import ProductsRoles from "./ProductsRoles";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import modifyRoles from "../../../../assets/icons/modify/modifyRoles.svg";

const useStyles = makeStyles(() => ({
	root: {
		margin: '0 40px 16px 32px',
	},
	rolesContainer: {
		marginLeft: 24,
	},
	productsRolesContainer: {
		height: '100%',
		width: '100%',
		borderRadius: 4,
	},
	rolesLabel:{
		fontSize: 16
	},
	rolesSelections: {
		width: '100%'
	},
	iconContainer:{
		marginLeft: 5,
	},
	accordionSummary: {
		display: "flex",
		flexDirection: 'row-reverse',
	},
	rotate:{
		transform: 'rotate(-90deg)'
	},
	rotateSelected:{
		transform: 'rotate(180deg)'
	},
	modify: {
		marginLeft: 8,
	},
	labelContainer: {
		display:"flex",
		flexDirection: "row",
		alignItems: "center"
	}
}));

const mapAccountRoles = (products) => {
	const productsWithAdminRoles = (products ?? []).map(product => {
		const { id, displayName, roles } = product;

		const adminRoles = roles.filter(role => role.isProductAdmin === true);

		if (adminRoles?.length > 0 ) {
			return {
				id,
				displayName,
				roles: adminRoles
			};
		} else {
			return null;
		}
	});

	return productsWithAdminRoles.filter(product => product != null);
};

export default function ProductAdminRoles({ isEdit, firstRender, setFirstRender, changesMadeCmp }) {
	const styles = useStyles();

	const [expended, setExpended] = useState(false);
	const staleTime = 120000;
	const {
		isLoading,
		isError,
		error,
		data: productRoles = []
	} = useQueryWithAuthorization('all-roles', getAllProductRoles(), {
		select: mapAccountRoles,
		staleTime
	});

	if (isLoading) return <Loader/>;
	if (isError) return <span>Error: {error.message}</span>;

	return (
		<div>
			{productRoles?.length > 0 &&
				<div className={styles.root}>
					<Accordion
						onChange = {(e,data) => {
							setExpended(data);
						}}
						expanded={expended}
					>
						<AccordionSummary
							expandIcon={<div className={clsx(styles.iconContainer, expended ? styles.rotateSelected:styles.rotate)}><ExpandMoreIcon/></div>}
							className={styles.accordionSummary}
							sx={{ padding: "0px 0px 0px 3px" }}
							aria-controls="panel1a-content" id="panel1a-header">
							<Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '7px', fontFamily: 'Source Sans Pro' }}>
								<div className={styles.labelContainer}>
									Product Administration{isEdit && !changesMadeCmp && <img alt={''} src={modifyRoles} className={styles.modify}/>}
								</div>
							</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ backgroundColor: '#FAFAFA', minHeight: 100, paddingBottom: 0, paddingTop: 0 }}>
							<div className={styles.rolesContainer}>
								<div className={styles.rolesSelections}>
									<div className={styles.productsRolesContainer}>
										<ProductsRoles productRoles={productRoles} isProductAdmin={true} isEdit={isEdit} parentFirstRender={firstRender} setParentFirstRender={setFirstRender}/>
									</div>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			}
		</div>
	);
}
