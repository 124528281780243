import * as React from 'react'

function SvgTagInfo(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 12 13'
      {...props}
    >
      <path
        d='M6 12.312A5.812 5.812 0 105.999.689 5.812 5.812 0 006 12.312zm0-.75A5.061 5.061 0 116 1.438a5.062 5.062 0 010 10.124zm0-6.937a.75.75 0 100-1.5.75.75 0 000 1.5zM6.844 9.5a.281.281 0 00.281-.281v-.188a.281.281 0 00-.281-.281h-.282V5.469a.28.28 0 00-.28-.281H5.156a.28.28 0 00-.281.28v.188c0 .156.126.282.281.282h.281V8.75h-.28a.281.281 0 00-.282.281v.188c0 .155.126.281.281.281h1.688z'
        fill='#8F8F8F'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgTagInfo
