import * as React from 'react'

function SvgInProgress(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 11 9'
      {...props}
    >
      <path
        d='M1.123 3a.25.25 0 00.165-.082l.292-.365L2.933.866C2.97.82 3 .73 3 .666s-.03-.154-.065-.2L2.616.083A.23.23 0 002.457 0a.238.238 0 00-.16.083l-1.193 1.48-.425-.518A.236.236 0 00.52.963a.245.245 0 00-.16.082l-.294.368A.368.368 0 000 1.61c0 .065.03.154.066.199l.892 1.108a.25.25 0 00.165.082zm9.527-1.031c.193 0 .35-.14.35-.313v-.312c0-.173-.157-.313-.35-.313h-6.3c-.193 0-.35.14-.35.313v.312c0 .173.157.313.35.313h6.3zM1.121 6a.25.25 0 00.165-.082l.294-.365L2.934 3.87A.36.36 0 003 3.673a.368.368 0 00-.064-.195l-.319-.396A.238.238 0 002.457 3a.24.24 0 00-.159.082L1.105 4.565l-.426-.514a.238.238 0 00-.16-.083.242.242 0 00-.159.083l-.294.365C.03 4.461 0 4.55 0 4.614s.03.153.066.198l.89 1.106A.254.254 0 001.121 6zm9.529-1.031c.193 0 .35-.14.35-.313v-.312c0-.173-.157-.313-.35-.313h-6.3c-.193 0-.35.14-.35.313v.312c0 .173.157.313.35.313h6.3zM1.003 9a1 1 0 10-.004-1.998A1 1 0 001.003 9zm9.647-1.031c.193 0 .35-.14.35-.313v-.312c0-.173-.157-.313-.35-.313h-6.3c-.193 0-.35.14-.35.313v.312c0 .173.157.313.35.313h6.3z'
        fill='#3380FF'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgInProgress
