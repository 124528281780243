import { useQueries } from "react-query";
import { fetchFn } from "../../external-apis";
import { useAuth0 } from "@auth0/auth0-react";

const fetchFun = async (getAccessTokenSilently, query) => {
	let accessToken;

	try {
		accessToken = await getAccessTokenSilently();
	} catch (e) {
		// TODO: Error handling
		console.error('An error occurred in useQueryWithAuthorization', e);

		return;
	}

	query.headers = query.headers ?? {};
	query.headers.Authorization = `Bearer ${accessToken}`;

	return fetchFn(query)();

};
const useQueriesWithAuthorization = (queries) => {
	const { getAccessTokenSilently } = useAuth0();

	Object.values(queries).forEach((query) => {
		query.queryFn = () => (fetchFun(getAccessTokenSilently, query.query));
	});

	return useQueries(queries, {  retry: (count, { message }) => {
		try {
			const { status, method } = JSON.parse(message);

			return (status < 500 || method==="POST") ? false : count < 4;
		}catch (err) {
			return false;
		}
	} });
};

export default useQueriesWithAuthorization;
