import { Button } from "@ip-synamedia/syna-ui-library";
import { makeStyles } from '@material-ui/core/styles';
import SelectAccount from "../../../SelectAccount";
import { useUsersStore } from "../../../../state-management";
import InviteUsersDialog from "../InviteUserDialog";
import UsersTabs from "../UsersTabs";
import shallow from "zustand/shallow";
import React, { useState } from "react";
import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getProjectsByAccountId } from "../../../../external-apis";
import Loader from "../../../Loader";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: "flex",
		width: '100%',
		height: '100%',
		background: '#FFFFFF',
		boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
	},
	secondaryContainer: {
		position: 'relative',
		width: '100%',
		height: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 20,
		paddingBottom: 0
	},
	titleContainer: {
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		alignItems: 'center',
		width: 'fit-content'
	},
	mainTitleContainer: {
		display: 'flex',
		width: '100%',
		height: 70,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		minWidth: '30%'
	},
	title: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 20,
		color: '#292929',
	},
	synopsis: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14,
		color: '#8F8F8F',
	},
	separator: {
		border: '1px solid #C2C2C2',
		margin: '0px 12px',
		height: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectAccountDropdown: {
		width: "200px",
		marginLeft: 30,
		marginTop: -10
	},
	tabsContainer: {
	}
}));
const mapProjectsToUiElement = (projects) => {
	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			label: displayName
		};
	});
};

export default function Header() {
	const styles = useStyles();
	const staleTime = 120000;

	const [inviteUserDialogVisible, setInviteUserDialogVisible] = useUsersStore(state => [state.inviteUserDialogVisible, state.setInviteUserDialogVisible], shallow);
	const [accountId, setAccountId] = useUsersStore(state => [state.accountId, state.setAccountId], shallow);
	const [closeEditConfirmationVisible, setCloseEditConfirmationVisible] = useState(false);
	const {
		data:projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const handleInviteUserClick = () => {
		setInviteUserDialogVisible(true);
	};

	const handleAccountChange = (accountId) => {
		setAccountId(accountId);
	};

	if(projectsIsLoading) return <Loader width={50} height={60} type={'Rings'}/>;
	if (projectsIsError) return (projectError.message);

	return (
		<div className={styles.rootContainer}>
			<div className={styles.secondaryContainer}>
				<div className={styles.mainTitleContainer}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>Your Users</div>
						<div className={styles.separator}/>
						<div className={styles.synopsis}>Invite and manage users permissions</div>
					</div>
					<div className={styles.selectAccountDropdown}>
						<SelectAccount val={accountId} onChange={handleAccountChange} isValid={accountId != null}/>
					</div>
					<div style={{ flex: "1 1 0%" }}/>
					<div className={styles.buttonsContainer}>
						<Button filled={true} disabled={!accountId} onClick={handleInviteUserClick} color="primary">Invite User</Button>
					</div>
				</div>
				<div className={styles.tabsContainer}>
					<UsersTabs accountId={accountId}/>
				</div>
			</div>
			<InviteUsersDialog visible={inviteUserDialogVisible} setVisible={setInviteUserDialogVisible} closeEditConfirmationVisible={closeEditConfirmationVisible} setCloseEditConfirmationVisible={setCloseEditConfirmationVisible} projects={projects}/>
		</div>
	);
}
