import * as React from 'react'
import '../../styles.css'
import styles from './textField.module.scss'
import clsx from 'clsx'
import { Tooltip } from '../tooltip/Tooltip'
export const TextField = ({
    /**
     * Place Holder :-
     * 
     * If present,a placeholder is displayed in the text field.
     * Default - empty
     */
    placeholder = "",
    /**
     * Label :-
     * 
     * If present, a label is displayed on top of the text field.
     * Default - empty
     */
    label = "",
    /**
     * Width :-
     * 
     * If present, increases text box width to the value.
     * Default - 110px
     */
    width,
    /**
     * Height :-
     * 
     * If present, increases text box height to the value.
     * Default - 21px
     */
    height,
    /**
     * Disabled :-
     * 
     * If `true`, text field is disabled , not allowing change .
     * Default - false
     */
    disabled = false,
    /**
     * Read Only :-
     * 
     * If `true`, text field is read-only field .
     */
    readOnly,
    /**
     * Error :-
     * 
     * If `true`, Text Field Border changes to red , indicating an error.
     */
    error,
    /**
     * Helper Text :-
     * 
     * If present and if error is `true`, helper text is added below the text field.
     */
    helperText,
    /**
     * On-Change :-
     * 
     * If present, input function gets executed on change of value in text field.
     */
    onChange,
    /**
     * Icons :-
     * 
     * If prsent, Icon suffix is added at the end of text field.
     */
    icon,
    /**
     * Value :-
     * 
     * If present, the value is pre-populated in the text field.
     * Default - empty
     */
    value = "",
    /**
     * Saved Mode :-
     * 
     * If true, the value gets displayed without textbox
     * Default - false
     */
    savedMode = false,
    /**
     * If Empty :-
     * 
     * If present and in savedmode, the ifEmpty value gets displayed when value is empty
     * Default - N/A
     */
    ifEmpty = "N/A",
    /**
     * type :-
     * 
     * Represents the HTML5 input type
     * Default - --
     */
    type,
    /**
     * loader :-
     * 
     * If present, textfield will come with a loader symbol
     * Default - false
     */
    loader,
    /**
     * textArea :-
     * 
     * If true, text field becomes a text area
     * Default - false
     */
    textarea,
    /**
     * rows :-
     * 
     * If textarea, the number of rows are specified
     * Default - 5
     */
    rows = 5,
    /**
     * cols :-
     * 
     * If textarea, the number of cols are specified
     * Default - 40
     */
    cols = 40,
    /**
     * resize :-
     * 
     * If true, resize of a textfield is enabled
     * Default - false
     */
    resize = false,
    className,
    inputContainer,
    onKeyPress,
    ...props
}) => {
    const textElementRef = React.useRef()
    const [showTooltip, setShowTooltip] = React.useState(false)

    const ellipsisCheck = () => {
        let compare = false
        if(textElementRef.current) {
         compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth
         setShowTooltip(compare)
        }
    };

    React.useEffect(() => {
        ellipsisCheck();
    }, []);

    return (
        <div { ...props }>
            {label && <div data-testid='label' className={clsx(styles.label, savedMode && styles.saved)}>{label}</div> }
            {!savedMode  &&
                <div>
                { !textarea && 
                    <div className={ clsx(styles.inputContainer, inputContainer && inputContainer) }>
                        <input
                        className={ clsx(error && styles.error, styles.input, className && className) }
                            value={value}
                            placeholder={placeholder}
                            type={type}
                            margin='normal'
                            style={{ width, height }}
                            disabled={disabled}
                            readOnly={readOnly}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            data-testid='input'
                        />
                        {loader ? <span className={styles.loader} data-testid='loader'/> : icon && <span className={styles.icon} data-testid='icon'> {icon}</span>}
                    </div>
                }
                {
                    !!textarea &&
                    <textarea
                        className={clsx(error && styles.error , styles.textarea, !resize && styles.noResize)}
                        rows={rows}
                        cols={cols}
                        value={value}
                        placeholder={placeholder}
                        type={type}
                        margin='normal'
                        style={{ width, height }}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={onChange}
                        {...props}
                        data-testid='textarea'
                    />
                }
                {error && <div className={styles.helperText} data-testid='helperText'>{helperText}</div>}
                </div>
            }
            {!!savedMode &&
                <Tooltip content={value ? value : ifEmpty} disable={!showTooltip} arrow={false}>
                    <div className={clsx(styles.view, !textarea && styles.overflowView )} style={{ width }} data-testid='saved' ref={textElementRef}>
                        {value ? value : ifEmpty}
                    </div>
                </Tooltip>
            }
        </div>
    )
}
