import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		left: 280,
		top: 220,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		height: 200,
		width: 299,
		// border: "1px solid #C2C2C2",
	},
	iconCircle: {
		position: 'absolute',
		top: 8,
		left: 98,
		height: 100,
		width: 100,
		border: '2px solid #3380FF',
		borderRadius: '50%',
		backgroundColor: '#3381FF',
		opacity: '20%',
	},
	icon: {
		position: 'absolute',
		top: 38,
		left: 125,
		color: '#3380FF',
	},
	label: {
		position: 'absolute',
		display:"flex",
		flexDirection: "column",
		alignItems: "center",
		top: 125,
		color: '#8F8F8F',
		width: 300,
		height: 20,
	},
	labelNoButton:{
		width: 350
	},
	addPackageContainer: {
		position: 'absolute',
		top: 160,
		left: 100,
		display: 'flex',
		alignItems: 'center',
		color: '#3380FF',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	addPackageText: {
		marginLeft: 4,
	}

}));

export default function NoPackages( { handleAddPackage, packagesOptions } ) {
	const styles = useStyles();
	const isExistPackagesOptions = (packagesOptions !== undefined && Object.keys(packagesOptions).length > 0);

	return (<div className={styles.container}>
		<div>
			<div className={styles.iconCircle}/>
			<CreateNewFolderOutlinedIcon className={styles.icon} sx={{ width: '45px', height: '40px' }}/>
		</div>
		<div className={clsx(styles.label, isExistPackagesOptions && styles.labelNoButton)}>
			{isExistPackagesOptions? 'Content will be displayed once packages are added': 'There are no available packages to add'}
		</div>
		{isExistPackagesOptions && <div className={styles.addPackageContainer} onClick={handleAddPackage}>
			<AddCircleOutlineOutlinedIcon sx={{ width: '14px', height: '14px' }}/>
			<div className={styles.addPackageText}>ADD PACKAGE</div>
		</div>}
	</div>);
}