import { PopUp } from "@ip-synamedia/syna-ui-library";
import React from 'react';
import { toast } from "@ip-synamedia/syna-ui-library";
import { ScheduleMeeting } from 'react-schedule-meeting';

// this generates basic available timeslots for the next 6 days
const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
	return {
		id,
		startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)),
		endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(17, 0, 0, 0)),
	};
});

const ScheduleMeetingComponentInYourApp = (props) => {
	const { onConfirm } = props;

	return (
		<ScheduleMeeting
			borderRadius={10}
			primaryColor="#3f5b85"
			eventDurationInMinutes={30}
			availableTimeslots={availableTimeslots}
			onStartTimeSelect={onConfirm}
		/>
	);
};

export default function SchedulerPopup(props) {
	const { visible, setVisible } = props;

	const onConfirm = () => {
		setVisible(false);
		toast({ severity: 'success', message: 'The call was scheduled successfully', placement: "topCenter" });
	};

	return (
		<PopUp
			visible={visible}
			setVisible={setVisible}
			title={'Schedule a call'}
			variant='big'
			width='900px'
			height='600px'
		>
			<ScheduleMeetingComponentInYourApp onConfirm={onConfirm}/>
		</PopUp>
	);
}
