import create from "zustand";

const useStore = create((set) => ({
	sidebarCollapsed: true,
	selectedSidebarItem: null,
	setSelectedSidebarItem: (item) => {
		set(() => ({
			selectedSidebarItem: item
		}));
	},
	collapsed: true,
	selectedState: null,
	isDrawerOpen: true,
	setIsDrawerOpen: (newVal) => {
		set(() => ({
			isDrawerOpen: newVal
		}));
	},
	selectedPanel: null,
	setSelectedPanel: (newPanel) => {
		set(() => ({
			selectedPanel: newPanel
		}));
	},
})
);

export default useStore;
