import * as React from 'react'

function SvgCopyWhite(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 13 15'
      fill='#FFF'
      {...props}
    >
      <path
        d='M7.812 14.25c.725 0 1.313-.588 1.313-1.313v-1.312h2.187c.725 0 1.313-.588 1.313-1.312V2.98c0-.3-.172-.716-.384-.928L10.821.633a1.503 1.503 0 00-.927-.384H5.187c-.724 0-1.312.588-1.312 1.313v1.312H1.687c-.724 0-1.312.588-1.312 1.313v8.75c0 .724.588 1.312 1.312 1.312h6.125zm3.336-3.937H5.352a.165.165 0 01-.165-.165V1.727c0-.091.074-.164.165-.164H8.25v2.406c0 .362.294.656.656.656h2.406v5.523a.164.164 0 01-.164.165zm.164-7h-1.75v-1.75h.264c.043 0 .085.017.116.048l1.322 1.322a.183.183 0 01.042.075l.006.041v.264zm-3.664 9.624H1.852a.164.164 0 01-.165-.164V4.352c0-.091.074-.164.165-.164h2.023v6.125c0 .724.588 1.312 1.312 1.312h2.625v1.148a.163.163 0 01-.164.164z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgCopyWhite
