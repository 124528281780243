import React from 'react';
import styles from '../Features/Features.module.css';
import placeholder from '../../assets/images/placeholder.png';
import components from '../../images/hero-bar.png';
import federations from '../../images/configurations.png';
import BackgroundImage from '../../images/remote-syna.JPG';
import Fade from 'react-reveal/Fade';

const homePageImage = {
	background:
    'linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 43%, rgba(0, 0, 0, 0.2) 72%, rgba(0, 0, 0, 0.8) 99%), url(' +
    BackgroundImage +
    ') no-repeat center',
	backgroundSize: 'cover',
	height: '450px',
	width: '100%'
};

const Features = () => {
	return (
		<>
			<Fade bottom distance='8%' delay={900}>
				<div className={styles.plainContainer}>
					<div style={homePageImage}/>
					<div className={styles.imageOverlay}>
						<div className={styles.overLaytext}>
						</div>
					</div>
				</div>
				<div className={styles.divider}>
					<div className={styles.redBlock}/>
				</div>
			</Fade>

			<div className={styles.lowerSection}>
				{/* ########################### COMPONENTS START ################### */}
				<div className={styles.componentsSection}>
					<Fade left distance='2%'>
						<div className={styles.componentsTextSection}>
							{/*<div className={styles.subHeaderText}>SYNA UI LIBRARY</div>*/}
							<div>
								<span className={styles.sectionTitle}>ACCOUNTS</span>
							</div>
							<div className={styles.sectionDescription}>
                Easily control your account permission and roles.
							</div>
							<div>
								{/*<Link*/}
								{/*  className={styles.btn}*/}
								{/*  to={'/componentsOverView'}*/}
								{/*>*/}
								{/*  LEARN MORE*/}
								{/*</Link>*/}
							</div>
						</div>
					</Fade>
					<Fade right distance='2%'>
						<div className={styles.imgDivs}>
							<div className={styles.imgOrg}>
								<img src={components} width={400} height={350}  alt={''}/>
							</div>
							<div className={styles.compLayeredDiv}/>
						</div>
					</Fade>
				</div>
				{/* ########################### COMPONENTS END ################### */}

				{/* ########################### MICROFRONTENDS START ################### */}
				<div className={styles.microfrontendsSection}>
					<Fade left distance='2%'>
						<div className={styles.imgDivs}>
							<div className={styles.imgOrg}>
								<img src={placeholder} width={400} height={350}  alt={''}/>
							</div>
							<div className={styles.federationLayeredDiv}/>
						</div>
					</Fade>

					<Fade right distance='2%'>
						<div className={styles.microfrontendsTextSection}>
							{/*<div className={styles.subHeaderText}>SYNA UI LIBRARY</div>*/}
							<div>
								<span className={styles.sectionTitle}>PRODUCTS</span>
							</div>
							<div className={styles.sectionDescription}>
                Subscribe and purchase a product with just  a few clicks
							</div>
							<div>
								<div className={styles.btn}>LEARN MORE</div>
							</div>
						</div>
					</Fade>
				</div>
				{/* ########################### MICROFRONTENDS END ################### */}

				{/* ########################### CONFIGURATIONS START ################### */}
				<div className={styles.configurationsSection}>
					<Fade left distance='2%'>
						<div className={styles.configurationstextSection}>
							{/*<div className={styles.subHeaderText}>SYNA UI LIBRARY</div>*/}
							<div>
								<span className={styles.sectionTitle}>CONFIGURATIONS</span>
							</div>
							<div className={styles.sectionDescription}>
                Customize your tenants and using our easy-to-use configurations
							</div>
							<div className={styles.btn}>LEARN MORE</div>
						</div>
					</Fade>
					<Fade right distance='2%'>
						<div className={styles.imgDivs}>
							<div className={styles.imgOrg}>
								<img src={federations} width={350} height={400}  alt={''}/>
							</div>
							<div className={styles.configLayeredDiv}/>
						</div>
					</Fade>
				</div>
				<div style={{ height: '50px' }}/>
			</div>
		</>
	);
};

export default Features;
