import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import useStateWithValidation from "../../../../../custom-hooks/form-hooks/useStateWithValidation";
import SelectProductsPermissions from "../SelectProductsPermissions";
import { useDeepCompareEffect } from "../../../../../custom-hooks";
import { editApplication as apiEditApp } from '../../../../../external-apis';
import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import AppDialog from "../../../../AppDialog";
import AppInput from "../../../../AppInput";
import AppDisplay from "../../../../AppDisplay";
import AppButton from "../../../../AppButton";

const useStyles = makeStyles(() => ({
	appDialog: {
		width: 850,
	},
	inputs: {
		display: 'flex',
		justifyContent: 'space-evenly',
		boxShadow: '2px 2px 5px grey',
		borderRadius: 8,
	},
	display: {
		padding: 20,
		width: 479,
	},
	input: {
		padding: 20,
		width: 479,
	},
	appIntput: {
		borderColor:'#8F8F8F',
		fontSize: 16,
	},
	appDisplay: {
		fontSize: 16,
	},
	products: {
		margin: '20px 0',
		boxShadow: '2px 2px 5px grey',
		borderRadius: 8,
	},
	productsLabel: {
		display: "inline-block",
		margin: 20
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 'auto',
	},
	button: {
		marginLeft: 10
	},
}));

export default function EditApplicationDialog(props) {
	const styles = useStyles();

	const { visible, setEditAppData, accountId,projectId,id,projectName,applicationDisplayName, permissions } = props;
	const { mutateAsync } = useMutationWithAuthorization();
	const [appName, setAppName, isValidAppName] = useStateWithValidation(applicationDisplayName, appName => appName != null && appName.trim() !== '');
	const [selectedPermissions, setSelectedPermissions] = useState(new Set(permissions));

	const queryClient = useQueryClient();

	const handleClose = () => {
		setEditAppData(null);
		setAppName(applicationDisplayName);
		setSelectedPermissions(new Set(permissions));
	};

	useDeepCompareEffect(async () => {
		setAppName(applicationDisplayName);
		setSelectedPermissions(new Set(permissions));
	}, [applicationDisplayName, permissions]);

	const editApplication = async (app) => {

		handleClose();
		const editAppPromise = mutateAsync(apiEditApp(accountId, projectId,id, app));

		toast.promise(editAppPromise,
			{
				pending: "Editing Application...",
				success: {
					render() {
						queryClient.invalidateQueries(['get-application-by-id', id]);

						return 'Edited Successfully';
					}
				},
				error: {
					render({ data: error }) {
						console.error('Error Editing Application', error);
						setEditAppData(null);

						return `Failed to Edit application: ${error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	const handleEdit = async () => {
		const hasError = !accountId || !isValidAppName;

		if (hasError) {
			return;
		}

		if (!selectedPermissions.size) {
			toast.error('Please select at least one permission', { position: toast.POSITION.TOP_CENTER });

			return;
		}

		const appToEdit = {
			name: appName,
			permissions: [...selectedPermissions]
		};

		await editApplication(appToEdit);
	};

	return (
		<AppDialog
			open={visible}
			onClose={handleClose}
			title={'Edit Application'}
			overrideClasses={{ content: styles.appDialog }}
		>
			<div className={styles.inputs}>
				<div className={styles.display}>
					<AppDisplay
						overrideClasses={{ output: styles.appDisplay }}
						label='Project'
						display={projectName}
					/>
				</div>
				<div className={styles.input}>
					  <AppInput
						overrideClasses={{ input: styles.appIntput }}
						value={appName}
						name={'appName'}
						label='Application Name'
						onChange={ setAppName }
						error={!isValidAppName}
						errorMessage={'Please insert a value'}
						required={true}
					/>
				</div>
			</div>
			<div className={styles.products}>
				<SelectProductsPermissions selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions} action={"edit"}/>
			</div>
			<div className={styles.buttonsContainer}>
				<AppButton variant={'outlined'} onClick={handleClose}>CANCEL</AppButton>
				<AppButton variant={'contained'} onClick={handleEdit} overrideClasses={{ button: styles.button }}>save</AppButton>
			</div>
		</AppDialog>
	);
}
