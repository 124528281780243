import { usePanelsStore } from "../../state-management";
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
	const appBarTop = 76;
	const drawerWidth = 250;
	const mainContentOpenIndentation = 18;
	const mainContentCloseIndentation = 194;

	return {
		mainContent: {
			overflow: 'hidden',
			height: `calc(100% - ${appBarTop}px)`,
			backgroundColor: "white",
			position: "fixed",
			borderTopLeftRadius: 20,
			top: appBarTop,
			right: 0,
			bottom: 0,
			left: 0,
			transition: 'transform 250ms ease',
			'-webkit-transition': 'transform 250ms ease',
			willChange: 'transform, -webkit-transform, padding-right'
		},
		mainContentOpen: {
			paddingRight: drawerWidth - mainContentOpenIndentation,
			transform: `translateX(${drawerWidth - mainContentOpenIndentation}px)`,
			'-webkit-transform': `translateX(${drawerWidth - mainContentOpenIndentation}px)`,
		},
		mainContentClose: {
			paddingRight: drawerWidth - mainContentCloseIndentation,
			transform: `translateX(${drawerWidth - mainContentCloseIndentation}px)`,
			'-webkit-transform': `translateX(${drawerWidth - mainContentCloseIndentation}px)`,
		}
	};
});

export default function MainPanel() {
	const classes = useStyles();

	const selectedPanel = usePanelsStore(state => state.selectedPanel);
	const isDrawerOpen = usePanelsStore(state => state.isDrawerOpen);
	const Component = selectedPanel || null;

	return (
		<div className={clsx(classes.mainContent, {
			[classes.mainContentOpen]: isDrawerOpen,
			[classes.mainContentClose]: !isDrawerOpen,
		})}>
			{Component && <Component/>}
		</div>
	);
}
