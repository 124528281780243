import styles from "./Highlight.module.scss"
import {getHighlightedText} from "../../../util/highlight"
import clsx from "clsx"
import React from "react"

export default function Highlight({className,highlight,children,clipped}) {
    if(highlight && typeof children === "string") {
        children = getHighlightedText({styles, text:children, highlight});
    }
    return <div className={clsx(styles.root, clipped && styles.clipped, className)}>{children}</div>;
}
