import styles from "./Tooltip.module.scss"
import React, { useRef, useEffect, useContext, Fragment } from "react"
import { useHover } from "../../../util/hover"
import Modal from "../Util/Modal"
import { useWindowSize } from "../../../util/size"
import clsx from "clsx"
import DoubleChevron from "../../../iconComponents/DoubleChevron"
import ID, {useID} from "../Util/ID"
export default function Tooltip({ children, title = "", message = "", more, style, above = false, center = false, width = 153, height = 135, arrow = false, offset = 0, onClick=null }) {
    const baseID = useID();
    const ref = useRef();
    const tooltipRef = useRef();
    const windowSize = useWindowSize();
    const [hover] = useHover(ref);
    const [tooltipHover] = useHover(tooltipRef);
    const [visible, setVisible] = useContext(Tooltip.Visible) || [];
    var rect = ref.current && ref.current.getBoundingClientRect() || {};
    const tooltipExists = !!title || !!message;

    useEffect(() => {
        let timerHandle = null;
        if (tooltipExists && (hover || tooltipHover)) {
            timerHandle = setTimeout(() => {
                setVisible && setVisible(ref.current);
            }, 1000);
        }
        else {
            timerHandle = setTimeout(() => {
                setVisible && setVisible(null);
            }, 1000);
        }
        return () => {
            clearTimeout(timerHandle);
        }
    }, [hover, tooltipHover]);

    style = { ...style, ...visible === ref.current && { display: "flex" } };
    if (!style.right) {
        style.left = offset + rect.left - width / 2;
    }
    if (above || (windowSize && windowSize.height - rect.top < height)) {
        style.top = rect.top - height;
    }
    else {
        style.top = rect.top + 32;
    }
    style.height = height;
    style.width = width;
    return <Fragment>
        <div ref={ref} className={styles.position}>
            {children}
        </div>
        <Modal visible={visible === ref.current}>
            <div data-cy={baseID + "tooltip"} onClick={e => e.stopPropagation()} ref={tooltipRef} className={clsx(styles.root, arrow && styles.arrow, center && styles.center)} style={style}>
                {!!title && <div data-cy={baseID + "tooltip-title"} className={styles.title}>
                    <ID id="tooltip-title">
                        {title}
                    </ID>
                </div>}
                {!!message && <div data-cy={baseID + "tooltip-message"} className={styles.message}>
                    <ID id="tooltip-message">
                        {message}
                    </ID>
                </div>}
                {!!more && <div data-cy={baseID + "tooltip-more"} className={styles.button}>
                    <ID id="tooltip-more">
                        {more}
                        <DoubleChevron />
                    </ID>
                </div>}
            </div>
        </Modal>
    </Fragment>;
}

Tooltip.Visible = React.createContext();
