import { useMutationWithAuthorization } from "../../../../custom-hooks";
import { deleteUser } from "../../../../external-apis";
import { Delete, Table, Edit } from "@ip-synamedia/syna-ui-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useDecodedJwtStore, useUserManageStore, useUsersStore } from "../../../../state-management";
import { useQueryClient } from "react-query";
import shallow from "zustand/shallow";
import AppDeleteConfirmation from '../../../../components/AppDeleteConfirmation';
import UserRolesDrawer from "./UserRolesDrawer";
import emptyContentBackground from "../../../../assets/icons/emptyContent/emptyContentBackground.png";
import emptyUsersIcon from "../../../../icons/emptyUsersIcon.png";
import AppButton from "../../../AppButton";
import Loader from "../../../Loader";
import EditWrapper from "../EditUserDialog/EditWrapper";

const useTableStyles = makeStyles(() => ({
	rootContainer: {
		// marginLeft: -20, // a hack to "override" syna-ui table margin
		height: 'inherit'
	},
	emptyUsersComponentContainer:{
		// marginLeft: 247,
		marginTop: 160,
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	emptyIconContainer:{
		marginTop: 0,
		display: "grid",
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr',
		gridTemplateAreas: "overlap",

	},
	emptyContentBackgroundIcon:{
		gridArea: 'overlap',
		width:100,
		height: 100,
	},
	emptyContentIcon:{
		gridArea: 'overlap',
		borderRadius: 0,
		margin: '32px 25px 32px 25px'
	},
	emptyUsersText:{
		marginTop: 12,
		fontSize: 17,
		fontWeight: 400,
		color: '#8F8F8F'
	},
	inviteBtnContainer:{
		marginTop: 40
	},
	cancelTextTransform: {
		textTransform: "none"
	}

}));

const useUserNameStyles = makeStyles(() => ({
	role: {
		fontSize: 12,
		color: 'gray'
	}
}));

function UserName({ name, role }) {
	const styles = useUserNameStyles();

	return (
		<div>
			<div>{name}</div>
			<div className={styles.role}>{role?.toUpperCase()}</div>
		</div>
	);
}

const headers = [
	{
		id: "userName",
		label: "User Name",
		widthUnits: 1,
		searchable: true,
		filterable: true,
		elementCb: (userData) => <UserName name={userData.userName} role={userData.userRole}/>
	},
	{
		id: "email",
		label: "Email",
		widthUnits: 1,
		searchable: true,
		filterable: true
	},
	{
		id: "lastLogin",
		label: "Last Login",
		widthUnits: 1,
		searchable: false,
		filterable: true
	},
	{
		id: "joined",
		label: "Joined",
		widthUnits: 1,
		searchable: false,
		filterable: true
	}
];

export default function ActiveUsers() {
	const styles = useTableStyles();
	const accountId = useUsersStore(state => state.accountId, shallow);
	const [accountOwnerEmail] = useUserManageStore(state => [state.accountOwnerEmail], shallow);
	const { mutateAsync } = useMutationWithAuthorization();
	const queryClient = useQueryClient();
	const decodedJwt = useDecodedJwtStore(state => state.decodedJwt, shallow);
	const [users] = useUserManageStore(state => [state.users], shallow);

	const [projects] = useUserManageStore(state => [state.projects], shallow);
	const [usersIsError] = useUserManageStore(state => [state.usersIsError],shallow);
	const [usersError] = useUserManageStore(state => [state.usersError], shallow);
	const [usersIsLoading] = useUserManageStore(state => [state.usersIsLoading],shallow);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [curData, setCurData] = useState(null);

	const allowsUserDeletion = decodedJwt?.scope?.includes("account-admin:user:delete");
	const allowsUserEdit = decodedJwt?.scope?.includes("account-admin:user:update");

	const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
	const [editUserDialogVisible, setEditUserDialogVisible] = useState(false);

	const [curUserDataDelete, setCurUserDataDelete] = useState();
	const [curUserDataUpdate, setCurUserDataUpdate] = useState();

	const [closeEditConfirmationVisible, setCloseEditConfirmationVisible] = useState(false);

	const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
	const ownerDeleteMessage = "You are trying to delete the owner, this action is not permitted.";
	const setInviteUserDialogVisible = useUsersStore(state => state.setInviteUserDialogVisible, shallow);
	const handleInviteUserClick = useCallback(() => {
		setInviteUserDialogVisible(true);
	},[setInviteUserDialogVisible]);
	const EmptyUsersComponent = useCallback(({ drawer = false })  => {
		return <div className={styles.emptyUsersComponentContainer}>
			<div className={styles.emptyIconContainer}>
				<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
				<img alt={''} src={emptyUsersIcon} className={styles.emptyContentIcon}/>
			</div>
			<div className={styles.emptyUsersText}>{'There are no active users yet'}</div>
			{!drawer && <div className={styles.inviteBtnContainer}>
				<AppButton onClick={handleInviteUserClick} overrideClasses={{ button: styles.cancelTextTransform }}>Invite
					User</AppButton>
			</div>}
		</div>;
	}, [styles, handleInviteUserClick,]);

	function openUserRolesDrawer(data) {
		setCurData(data);
		setIsDrawerOpen(true);
	}
	const deleteAction = useMemo(() => {
		return {
			id: "delete",
			label: "Remove User",
			icon: <Delete fill='Black' width='1em' height='1em'/>,
			onClick: (id, data) => {
				setCurUserDataDelete(data);
				setDeleteUserDialogVisible(true);
			}
		};
	}, [setCurUserDataDelete, setDeleteUserDialogVisible]);

	const editAction = useMemo(() => {
		return {
			id: "delete",
			label: "Edit User",
			icon: <Edit fill='Black' width='1em' height='1em'/>,
			onClick: (id, data) => {
				setCurUserDataUpdate(data);
				setEditUserDialogVisible(true);
			}
		};
	}, [setCurUserDataUpdate, setEditUserDialogVisible]);

	const onCloseDrawer = () => {
		setIsDrawerOpen(false);
		setCurData(undefined);
		setCurUserDataDelete(undefined);
	};
	const drawer = useMemo(() =>
		<UserRolesDrawer
			isDrawerOpen={isDrawerOpen}
			onClose={onCloseDrawer}
			projects={projects} curData={curData} accountId={accountId}/>
	, [projects, isDrawerOpen, curData, accountId]);

	const TableComponent = useMemo(() =>
		users.length > 0 ? <Table
			title={'Users'}
			tableData={users}
			tableHeaders={headers}
			sort={'email'}
			searchEnabled={true}
			checkboxSelection={true}
			onClickRow={openUserRolesDrawer}
			tableActions={allowsUserDeletion ? allowsUserEdit? [editAction, deleteAction]:[deleteAction] : allowsUserEdit?[editAction]:false}
			columnsBar={true}
		/>: usersIsLoading? <Loader/> : EmptyUsersComponent

	,[users, usersIsLoading, EmptyUsersComponent, allowsUserDeletion, deleteAction, editAction, allowsUserEdit]);

	function handleDelete() {

		const deleteUserPromise = mutateAsync(deleteUser(accountId, curUserDataDelete.id));

		setDeleteUserDialogVisible(false);
		toast.promise(deleteUserPromise,
			{
				pending: "Deleting user...",
				success: {
					render() {
						queryClient.invalidateQueries(['users-active', accountId]);

						return 'deleted successfully';
					}
				},
				error: {
					render({ data: error='{}' }) {
						const json = JSON.parse(error.message);

						console.error('Error delete user', error);

						return `Failed to delete user: ${json.status === 409 ?"owner user can not be deleted" :error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	}

	useEffect(() => {
		setIsDeleteAllowed(accountOwnerEmail !== curUserDataDelete?.email);
	}, [curUserDataDelete, accountOwnerEmail]);

	if(usersIsError) {
		return <div>{usersError}</div>;
	}

	return (
		<div className={styles.rootContainer} >
			{TableComponent}
			<AppDeleteConfirmation
				visible={deleteUserDialogVisible}
				label={``}
				title={'Remove'}
				value={`${curUserDataDelete?.email}`}
				handleDelete={() => isDeleteAllowed ? handleDelete() : setDeleteUserDialogVisible(false)}
				handleClose={() => setDeleteUserDialogVisible(false)}
				overrideText={!isDeleteAllowed ? ownerDeleteMessage : false}
				overrideActionBtn={!isDeleteAllowed}
			/>
			{curData && drawer}
			{editUserDialogVisible &&
				<EditWrapper visible={editUserDialogVisible}  setVisible={setEditUserDialogVisible} closeEditConfirmationVisible={closeEditConfirmationVisible} setCloseEditConfirmationVisible={setCloseEditConfirmationVisible} userData={curUserDataUpdate} status={'active'}/>}
		</div>
	);
}
