import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Feature from "./Feature";
import clsx from "clsx";
import { getTextWidth } from "../../../../Projects/TenantDrawer/utils";

const useStyles = makeStyles((theme) => ({
	featuresHeader: {
		margin: 12,
		marginBottom: 0,
		fontWeight: 600,
	},
	featuresContainer: {
		display: 'flex',
		flexDirection: 'column',
		'&:focus-within': {
			borderColor: '#3380FF',
		},
		border: "1px solid rgba(41, 41, 41, 0.15)",
		borderRadius: 8,
		boxShadow: '2px 2px 10px rgba(41, 41, 41, 0.15)',
		width: 802,
		marginBottom: 12,
		overflow: 'visible',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
	},
	separator: {
		border: '1px solid #EFEFEF',
	},
	feature: {
		width: 802-24,
		margin: '0 12px',
	},
}));

export default function Features( { featuresOptions, featuresConfigParams, featureIds, addFeatureIds, setFeatureIds, handleFeatureSelectionChange, maxParameterLabelWidth, editTenant, overrideClasses = {} }) {
	const styles = useStyles();
	const length = Object.keys(featuresOptions).length;

	let longestLength = 0;

	Object.entries(featuresOptions).forEach(([featureKey, featureOptions]) => {
		longestLength = Math.max(longestLength, getTextWidth(featureOptions.label ?? featureKey));
	});

	return <div className={clsx(styles.featuresContainer, overrideClasses?.featuresContainer)}>
		<div className={styles.featuresHeader}>
            Features
		</div>
		{ Object.entries(featuresOptions).map(([featureKey, featuresOption], index) => {
			return <div className={clsx(styles.feature, overrideClasses?.feature)} key={featureKey}>
				<Feature featureKey={featureKey} featureOptions={featuresOption} featureConfigParams={featuresConfigParams[featureKey]} featureIds={featureIds} addFeatureIds={addFeatureIds} setFeatureIds={setFeatureIds} handleFeatureSelectionChange={handleFeatureSelectionChange} longestFeatureLength={longestLength} maxParameterLabelWidth={maxParameterLabelWidth} editTenant={editTenant}/>
				{index < length - 1 && <div className={styles.separator}/>}
			</div>;
		})}
	</div>;
}