import { useMutationWithAuthorization } from "../../../../custom-hooks";
import { deleteUserInvitation, resendInvitation } from "../../../../external-apis";
import { useUserManageStore, useUsersStore } from "../../../../state-management";
import { Table } from "@ip-synamedia/syna-ui-library";
import React, { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from "@material-ui/core/styles";
import useCopyToClipboard from "../../../../custom-hooks/useCopyToClipboard";
import { toast } from "react-toastify";
import { Delete } from '@ip-synamedia/syna-ui-library';
import { useQueryClient } from "react-query";
import AppDeleteConfirmation from "../../../AppDeleteConfirmation";
import shallow from "zustand/shallow";
import { useDecodedJwtStore } from "../../../../state-management";
import emptyContentBackground from "../../../../assets/icons/emptyContent/emptyContentBackground.png";
import emptyUsersIcon from "../../../../icons/emptyUsersIcon.png";
import AppButton from "../../../AppButton";
import Loader from "../../../Loader";
import ResendInvitationSVG from "../../../../assets/icons/ResendInvitationSVG";

const useProductRolesStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex'
	},
	rolesContainer: {
		display: 'flex'
	},
	role: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0px 8px',
		background: '#EFEFEF',
		width: 'fit-content',
		marginLeft: 8,
		borderRadius: 4,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	separator: {
		border: '1px solid #C2C2C2',
		flex: 'none',
		margin: '0px 8px'
	},
	product: {
		whiteSpace: 'nowrap',
		textTransform: 'uppercase'
	},
	roleMargin: {
		marginRight: 8
	},

}));

const useProjectsStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		overflowX: 'auto',
		height: '60px',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	project: {
		marginRight: 4,
		textTransform: 'capitalize'

	}
}));

const useTableStyles = makeStyles(() => ({
	rootContainer: {
		// marginLeft: -20, // a hack to "override" syna-ui table margin
		height: 'inherit'
	},
	emptyUsersComponentContainer:{
		// marginLeft: 247,
		marginTop: 160,
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	emptyIconContainer:{
		marginTop: 0,
		display: "grid",
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr',
		gridTemplateAreas: "overlap",

	},
	emptyContentBackgroundIcon:{
		gridArea: 'overlap',
		width:100,
		height: 100,
	},
	emptyContentIcon:{
		gridArea: 'overlap',
		borderRadius: 0,
		margin: '32px 25px 32px 25px'
	},
	emptyUsersText:{
		marginTop: 12,
		fontSize: 17,
		fontWeight: 400,
		color: '#8F8F8F'
	},
	inviteBtnContainer:{
		marginTop: 40
	},
	cancelTextTransform: {
		textTransform: "none"
	}
}));

const headers = [
	{
		id: "email",
		label: "Email",
		widthUnits: 1,
		searchable: true,
		filterable: true
	},
	{
		id: "invitedAt",
		label: "Invited",
		widthUnits: 1,
		searchable: true,
		filterable: true
	},
	{
		id: "projects",
		label: "Projects",
		widthUnits: 1,
		searchable: false,
		filterable: true,
		elementCb: (invitationData) => <Projects projects={invitationData.projects}/>
	},
	{
		id: "productsAndRoles",
		label: "Products and Roles",
		widthUnits: 2,
		searchable: false,
		filterable: true,
		elementCb: (invitationData) => <ProductsRoles projectProductsList={invitationData.products}/>
	},
];

const toolbarActions = [
	// TODO: implement
	// {
	// 	id: "",
	// 	// eslint-disable-next-line react/react-in-jsx-scope
	// 	icon: "",
	// 	onClick: (data) => {
	// 		// eslint-disable-next-line no-console
	// 		console.log("Dlicked", data);
	// 	}
	// }
];

function Projects({ projects }) {
	const styles = useProjectsStyles();

	return (
		<div className={styles.rootContainer}>
			{(projects ?? []).map((project, index) =>
				<div key={project.id} className={styles.project}>
					{project.displayName}
					{index < projects.length - 1 && ','}
				</div>)}
		</div>
	);
}

function ProductsRoles({ projectProductsList = []}) {
	const styles = useProductRolesStyles();

	return projectProductsList.map((projectProducts, projectProductsIndex) => {
		const { projectName, productList: products } = projectProducts;

		return (products ?? []).map((product, productIndex) => {
			// Has more products/projects
			const addSeparator = productIndex < products.length - 1
				|| projectProductsIndex < projectProductsList.length - 1;

			return (
				<div key={product.id} className={styles.rootContainer}>
					<div className={styles.product}>
						{`${product.displayName} - ${projectName}`}
					</div>
					<div className={styles.rolesContainer}>
						<div className={styles.role}>
							{product.role.displayName}
						</div>
					</div>
					{addSeparator && <div className={styles.separator}/>}
				</div>
			);
		});
	});

}
export default function InvitedUsers() {
	const styles = useTableStyles();
	const { mutateAsync } = useMutationWithAuthorization();
	const queryClient = useQueryClient();
	const accountId = useUsersStore(state => state.accountId, shallow);
	const [accountOwnerEmail] = useUserManageStore(state => [state.accountOwnerEmail], shallow);

	const [invitees] = useUserManageStore(state => [state.invitees], shallow);
	const [inviteesIsError] = useUserManageStore(state => [state.inviteesIsError],shallow);
	const [inviteesError] = useUserManageStore(state => [state.inviteesError], shallow);
	const [copyToClipboard, resetCopy, { success: copySuccess }] = useCopyToClipboard();
	const [curUserData, setCurUserData] = useState();
	const [removeUserDialogVisible, setRemoveUserDialogVisible] = useState(false);
	const [decodedJwt] = useDecodedJwtStore(state => [state.decodedJwt, state.setDecodedJwt], shallow);
	const allowsInvitationDeletion = decodedJwt?.scope?.includes("account-admin:user:delete");
	const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
	const ownerDeleteMessage = "You are trying to delete the owner, this action is not permitted.";
	const deleteMessage = "Are you sure you want to revoke the invitation for";
	const setInviteUserDialogVisible = useUsersStore(state => state.setInviteUserDialogVisible, shallow);
	const [usersIsLoading] = useUserManageStore(state => [state.usersIsLoading],shallow);

	const handleInviteUserClick = () => {
		setInviteUserDialogVisible(true);
	};

	const handleResendInvitation = (original) => {

		const reInvitePromise = mutateAsync(resendInvitation(accountId, original.invitationId));

		toast.promise(reInvitePromise,
			{
				pending: "Resending invitation...",
				success: {
					render() {
						queryClient.invalidateQueries(['users-invited', accountId]);

						return <div id={'resendInvitee_toast_text'}>Invitation resent successfully</div>;
					}
				},
				error: {
					render({ data: error }) {
						const json = JSON.parse(error.message);

						return <div
							id={'resendInvitee_toast_textError'}>{`Failed to resend invitation: ${json?.message?.message || json?.message ||  original.invitationId}`}</div>;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	function handleDeleteInvitation() {
		const deleteUserPromise = mutateAsync(deleteUserInvitation(accountId, curUserData.invitationId));

		setRemoveUserDialogVisible(false);
		toast.promise(deleteUserPromise,
			{
				pending: "Revoking invitation...",
				success: {
					render() {
						queryClient.invalidateQueries(['users-invited', accountId]);

						return 'Invitation revoked successfully';
					}
				},
				error: {
					render({ data: error }) {
						const json = JSON.parse(error.message);

						console.error('Error revoke invitation', error);

						return `Failed to revoke invitation: ${json.status === 409 ?"owner invitation can not be revoked" :error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	}

	useEffect(() => {
		setIsDeleteAllowed(accountOwnerEmail !== curUserData?.email);
	}, [curUserData, accountOwnerEmail]);

	if (copySuccess) {
		toast.success('Invitation URL was copied to the clipboard!', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	if (copySuccess != null && !copySuccess) {
		toast.error('Failed to copy invitation URL', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	const deleteAction = [{
		id: "revoke invitation",
		label: "Revoke Invitation",
		icon: <Delete sx={{ width: 14, height: 14 }}/>,
		onClick: (id, invitationData) => {
			setCurUserData(invitationData);
			setRemoveUserDialogVisible(true);
		}
	}];
	const rowActions = [
		{
			id: "copyInvitationUrl",
			label: "Copy invitation URL",
			icon: <ContentCopyIcon sx={{ width: 14, height: 14 }}/>,
			onClick: (id, invitationData) => {
				copyToClipboard(invitationData.invitationUrl);
			},
		},
		{
			id: "resendInvite",
			label: "Resend invite",
			icon: <ResendInvitationSVG/>,
			onClick: (id, invitationData) => {
				handleResendInvitation(invitationData);
			},
		}
		// {
		// 	id: "editUser",
		// 	label: "Edit User",
		// 	icon: <EditIcon sx={{ width: 14, height: 14 }}/>,
		// 	onClick: (id, invitationData) => {
		// 		console.log('edit user', invitationData);
		// 	}
		// },
		// {
		// 	id: "deleteUser",
		// 	label: "Delete User",
		// 	icon: <DeleteIcon sx={{ width: 14, height: 14 }}/>,
		// 	onClick: (id, invitationData) => {
		// 		console.log('delete user', invitationData);
		// 	}
		// }
	];
	const emptyUsersComponent = <div className={styles.emptyUsersComponentContainer}>
		<div className={styles.emptyIconContainer}>
			<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
			<img alt={''} src={emptyUsersIcon} className={styles.emptyContentIcon}/>
		</div>
		<div className={styles.emptyUsersText}>{'There are no pending invitations'}</div>
		<div className={styles.inviteBtnContainer}>
			<AppButton onClick={handleInviteUserClick} overrideClasses={{ button: styles.cancelTextTransform }}>Invite User</AppButton>
		</div>
	</div>;

	if(inviteesIsError) {
		return <div>{inviteesError}</div>;
	}

	return (
		<div className={styles.rootContainer} >
			{invitees.length > 0 ? <Table
				identifier={'email'}
				title={'Users'}
				tableData={invitees}
				tableHeaders={headers}
				sort={'email'}
				searchEnabled={true}
				checkboxSelection={true}
				toolbarActions={toolbarActions}
				tableActions={allowsInvitationDeletion ? rowActions.concat(deleteAction):rowActions}
			/>: usersIsLoading? <Loader/> : emptyUsersComponent}
			<AppDeleteConfirmation
				visible={removeUserDialogVisible}
				label={`invitation`}
				title={'Revoke'}
				value={`${curUserData?.email}`}
				handleDelete={() => handleDeleteInvitation()}
				handleClose={() => setRemoveUserDialogVisible(false)}
				overrideText={!isDeleteAllowed ? ownerDeleteMessage : deleteMessage + ` '${curUserData?.email}'?`}
				overrideActionBtn={!isDeleteAllowed}

			/>
		</div>
	);
}
