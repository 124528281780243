import React, { useEffect, useState } from "react";
import { Listbox, Transition } from '@headlessui/react';
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import clsx from "clsx";
import useQueryWithAuthorization from "../../../../../custom-hooks/external-api-hooks/useQueryWithAuthorization";
import { getProjectsByAccountId } from "../../../../../external-apis";
import Loader from "../../../../Loader";

const useStyles = makeStyles((theme) => {
	return {
		labelContainer: {
			display:"flex",
			flexDirection:"row",
		},
		label: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			display: 'flex',
			justifyContent: 'start',
		},
		requireLabel: {
			color:'red',
			marginLeft: 3,
		},
		contentContainer: {
			position: 'relative',
			marginBottom: 4,
		},
		listboxButtonContainer: {
			width: '100%',
		},
		listboxButton: {
			padding: '8px 0 8px 12px',
			width: '100%',
			textAlign: 'left',
			'&:focus,focus-visible': {
				borderColor: 'hsla(217, 100%, 60%, 1)',
				outline: 'none',
			},
			border: "1px solid #C2C2C2",
			borderRadius: 4,
			height: 30,
			backgroundColor: 'white',
		},
		listboxButtonInvalid: {
			borderColor: 'red',
			'&:focus': {
				borderColor: 'red',
			},
		},
		listboxButtonSelectorContainer:{
			position: 'absolute',
			top: 0,
			bottom: 0,
			right: 0,
			paddingRight: 8,
			display: 'flex',
			alignItems: 'center',
		},
		listboxButtonSelector:{
			width: 20,
			height: 20,
		},
		listboxButtonContent: {
			display: 'block',
			overflow: 'hidden',
			position: 'absolute',
			top: 5,
			textOverflow: 'ellipsis',
			whiteSpace : 'nowrap',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: 14,
		},
		listboxButtonContentUndefined: {
			color: 'gray',
		},
		transitionLeave: {
			transitionDuration: '200ms',
		},
		transitionLeaveFrom: {
			opacity: 1,
		},
		transitionLeaveTo: {
			opacity: 0,
		},
		listboxOptions: {
			position: 'absolute',
			top: 17,
			outline: 'none',
			boxShadow: '0 5px 5px 0 #C2C2C2',
			borderRadius: 4,
			listStyle: 'none',
			padding: 0,
			zIndex: 1,
			backgroundColor: 'white',
			width: '100%',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: 14,
			maxHeight: 420,
			overflowY: 'auto',
		},
		listboxOption: {
			width: '100%',
		},
		listboxOptionItemContainer: {
			cursor: 'default',
			userSelect: 'none',
			paddingTop: 4,
			paddingBottom: 4,
			paddingLeft: 30,
			paddingRight: 16,
			color: 'black',
			backgroundColor: 'white',
			borderRadius: 4,
		},
		listboxOptionItemSelected: {
			backgroundColor: 'rgba(51, 128, 255, 1)',
		},
		listboxOptionItemContainerActive: {
			backgroundColor: 'rgba(51, 128, 255, 0.1)',
		},
		listboxOptionItem: {
			fontWeight: 'normal',
		},
		listboxOptionItemActive: {
			color: 'white',
		},
		inputErrorMessage: {
			color: 'hsla(0, 100%, 66%, 1)',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 12,
			minHeight: 16
		},
	};
});

const mapToLabel = (items) => {
	return (items || []).reduce((itemsByIds, currentItem) => {
		const { id, displayName } = currentItem;

		return {
			...itemsByIds,
			[id]: displayName
		};
	}, {});
};

const label = "Project";
const required = true;
const errorMessage = 'Please select a project';

export default function SelectProject({ accountId, projectId, setProjectId, isValidProjectId, firstRender }) {
	const styles = useStyles();

	const [isMissingParameter, setIsMissingParameter] = useState(false);
	const staleTime = 60000;

	const {
		data: labelsByProjectId = [],
		isError: isErrorProjects,
		isLoading: isLoadingProject,
		error: errorProject
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select : mapToLabel,
		enabled: accountId != null,
		staleTime: staleTime

	});

	useEffect(() => {
		setIsMissingParameter(!isValidProjectId && !firstRender);
	}, [isValidProjectId, firstRender]);

	if (isLoadingProject) return <Loader/>;
	if (isErrorProjects) return <span>Error: {errorProject.message}</span>;

	return (
		<Listbox as="div" value={projectId} onChange={setProjectId}>
			{({ open }) => (
				<>
					<div className={styles.labelContainer}>
						<div className={styles.label}>{ label }</div>
						{ required && <div className={styles.requireLabel}>*</div> }
					</div>
					<div className={styles.contentContainer}>
		  				<span className={styles.listboxButtonContainer}>
							<Listbox.Button className={clsx(styles.listboxButton, isMissingParameter && styles.listboxButtonInvalid)}>
								<span className={clsx(styles.listboxButtonContent, !isValidProjectId && styles.listboxButtonContentUndefined)}>{isValidProjectId ? labelsByProjectId[projectId] : "Select..."}</span>
								<span className={styles.listboxButtonSelectorContainer}>
									<ExpandMoreOutlinedIcon className={styles.listboxButtonSelector}/>
								</span>
							</Listbox.Button>
		  				</span>
						<Transition
							show={open}
							leave={styles.transitionLeave}
							leaveFrom={styles.transitionLeaveFrom}
							leaveTo={styles.transitionLeaveTo}
						>
							<Listbox.Options className={styles.listboxOptions}>
								{labelsByProjectId && Object.entries(labelsByProjectId).map(([projectId, label]) => (
									<Listbox.Option className={styles.listboxOption} key={projectId} value={projectId}>
										{({ selected, active }) => (
											<div className={clsx(styles.listboxOptionItemContainer, selected && styles.listboxOptionItemSelected, active && !selected && styles.listboxOptionItemContainerActive)}>
												<span className={clsx(styles.listboxOptionItem, selected && styles.listboxOptionItemActive)}>
													{label}
												</span>
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
					<div className={styles.inputErrorMessage}>{isMissingParameter && errorMessage}</div>
				</>
			)}
		</Listbox>
	);
}
