import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import AppDialog from "../../../../AppDialog";
import AppButton from "../../../../AppButton";
import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import { regenerateApplicationSecret } from "../../../../../external-apis";
import Loader from "../../../../Loader";
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useCopyToClipboard from "../../../../../custom-hooks/useCopyToClipboard";
import { CopyWhite } from "@ip-synamedia/syna-ui-library";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
	appDialog: {
		width: 850,
		height: 291,
	},
	rootContainer: {
		display: 'flex',
	},
	messageContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		fontFamily: 'Source Sans Pro',
		fontSize: 15,
		fontWeight: 400,
	},
	icon: {
		marginRight: 11,
		color: '#FFC41C',
		width: 20,
		height: 20,
	},
	messageAppContainer: {
		marginTop: 10,
	},
	messageAppName: {
		marginRight: 5,
		fontWeight: 600,
	},
	secretGenerationContainer: {
		height: 100,
	},
	buttonsContainer: {
		position: 'absolute',
		bottom: 24,
		right: 24,
	},
	button: {
		marginTop: 10,
		padding: 0,
	},
	loader: {
		position: 'absolute',
		top: 145,
		left: 390,
	},
	clientSecretContainer: {
		marginTop: 5,
		marginLeft: 20,
	},
	clientSecretLabel: {
		fontWeight: 'bold',
		marginLeft: 310,
		marginBottom: 5,
	},
	clientSecretLine: {
		display: 'flex',
	},
	clientSecret: {
		display: 'inline-block',
		textAlign: 'center',
		width: 690,
		height: 40,
		border: "1px solid #C2C2C2",
		borderRadius: 4,
		marginRight: 10,
	},
	dots: {
		margin: '8px 0',
		paddingBottom: 0,
	},
	dot: {
		height: 7,
		width: 7,
		backgroundColor: 'black',
		borderRadius: '50%',
		margin: '8.5px 1.53px 3px',
		display: 'inline-block',
	},
	clientSecretText: {
		fontFamily: 'monospace',
		fontSize: "16.5px",
		margin: '7px 0',
	},
	eyeIconContainer: {
		marginTop: 7,
	},
	eyeIcon: {
		color: '#3381FF',
		transform: 'scale(.7)',
	},
	copyContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		marginLeft: 310,
		marginTop: 5,
		marginBottom: 20,
		color: '#3381FF',
		"&:hover": {
			cursor: 'pointer',
		}
	},
	copyIcon: {
		marginRight: 5,
	},
	warningMessageContainer: {
		position: "absolute",
		bottom: 30,
		color: '#8F8F8F',
		display: 'flex',
		alignItems: 'center',
	},
	warningMessageIcon: {
		padding: 2,
		marginRight: 2,
	},
	warningMessageText: {
		fontSize: 14,
		fontWeight: 600,
	},
}));

export default function RegenerateSecretDialog({ visible, setVisible, regenerateData }) {
	const { accountId, projectId, id: applicationId, applicationName } = regenerateData || { accountId: null, projectId: null, id: null, applicationName: null };

	const classes = useStyles();

	const [copyToClipboard, resetCopy, { success: copySuccess }] = useCopyToClipboard();
	const [isLoading, setIsLoading] = useState(false);
	const [clientSecret, setClientSecret] = useState(null);
	const [isSecretHidden, setIsSecretHidden] = useState(true);
	const { mutateAsync } = useMutationWithAuthorization();

	const handleRegenerateSecret = async () => {
		setIsLoading(true);
		try {
			const { clientSecret } = await mutateAsync(regenerateApplicationSecret(accountId, projectId, applicationId));

			setClientSecret(clientSecret);
		} catch (e) {
			toast.error('Failed to regenerate secret', { position: toast.POSITION.TOP_CENTER });
			console.error('An error occurred in regenerateApplicationSecret', e);
			handleClose();
		}
		setIsLoading(false);
		setIsSecretHidden(true);
	};

	const handleClose = () => {
		setVisible(false);
		setIsLoading(false);
		setClientSecret(null);
	};

	if (copySuccess) {
		toast.success('The secret was copied to the clipboard!', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	if (copySuccess != null && !copySuccess) {
		toast.error('Failed to copy secret', { position: toast.POSITION.TOP_CENTER });
	}

	return (
		<AppDialog
			variant={'warn'}
			open={visible}
			onClose={handleClose}
			title={'Regenerate Client Secret'}
			overrideClasses={{ content: classes.appDialog }}
		>
			<div className={classes.rootContainer}>
				<ErrorOutlineOutlinedIcon className={classes.icon}/>
				<div className={classes.messageContainer}>
					{!clientSecret && <div>This action will create a new secret. You will no longer be able to use the old secret after performing this action!</div>}
					{clientSecret && <div>A new secret has been created. You will no longer be able to use the old secret!</div>}
					<div className={classes.messageAppContainer}>
						<span className={classes.messageAppName}>
							Application name:
						</span>
						<span>
							{applicationName || ''}
						</span>
					</div>
					<div className={classes.secretGenerationContainer}>
						{!isLoading && !clientSecret && <AppButton variant={'text'} onClick={handleRegenerateSecret} overrideClasses={{ button: classes.button }}>
							REGENERATE CLIENT SECRET
						</AppButton>}
						{isLoading && <div className={classes.loader}><Loader/></div>}
						{clientSecret &&
							<div className={classes.clientSecretContainer}>
								<div className={classes.clientSecretLabel}>Secret:</div>
								<div className={classes.clientSecretLine}>
									<span className={classes.clientSecret}>
										{ isSecretHidden && <div className={classes.dots}>{[...Array(clientSecret.length)].map((e, i) => <div key={i} className={classes.dot}/>)}</div>}
										{ !isSecretHidden && <div className={classes.clientSecretText}>{clientSecret}</div> }
									</span>
									<span className={classes.eyeIconContainer} onClick={() => setIsSecretHidden(prevState => !prevState)}>
										{ isSecretHidden && <VisibilityIcon className={classes.eyeIcon}/> }
										{ !isSecretHidden && <VisibilityOffIcon className={classes.eyeIcon}/> }
									</span>
								</div>
								<div className={classes.copyContainer} onClick={() => copyToClipboard(clientSecret)}>
									<CopyWhite className={classes.copyIcon} fill='#3381FF'/>
									<div>COPY</div>
								</div>
							</div>
						}
					</div>
					{clientSecret && <div className={classes.warningMessageContainer}>
						<InfoOutlinedIcon className={classes.warningMessageIcon}/>
						<span className={classes.warningMessageIcon}>Please save your client secret, you will not be able to view it again.</span>
					</div>}
				</div>
			</div>
			<div className={classes.buttonsContainer}>
				{!clientSecret && <AppButton variant={'contained'} disabled={isLoading} onClick={handleClose}>CANCEL</AppButton>}
				{clientSecret && <AppButton variant={'contained'} onClick={handleClose}>CLOSE</AppButton>}
			</div>
		</AppDialog>
	);
}
