import styles from './Table.module.scss'
import { useSize } from '../../../util/size'
import React, { Fragment, useContext, useRef, useEffect } from 'react'
import Columns from './Table/Columns'
import Row from './Table/Row'
import Pagination, { usePagination } from './Table/Pagination'
// import NumPages from './Table/Pagination/NumPages'
import Contexts from '../Util/Contexts'
import { useID } from '../Util/ID'
import { useAsync, useSearch, useSort } from '../../../util/items'
import Header from './Table/Header'
import { useFilter } from './Filter'
import Mode from '../Util/Mode'
// import useDimensions from 'react-cool-dimensions'
import clsx from 'clsx'
import Tree from './Table/Tree'
import Spinner from './Spinner'
import NoRows from './NoRows'
import NotFound from './NotFound'

export default function Table({
  columnsBar = true,
  pagination = true,
  tableOptions = true,
  itemSize = 0,
  resizeHandle = true,
  tableData = [],
  tableHeaders = [],
  numPages = [],
  tableActions = undefined,
  onAsync = undefined,
  additionalFilters = undefined,
  resetClickedRow = false,
  classes,
  ...props
}) {
  const baseID = useID()
  const ref = useRef()
  const [scroll, setScroll] = useContext(Table.Scroll) || []
  const [, setSmall] = useContext(Mode.Small) || []
  let [items, setItems] = useContext(Table.Items) || []
  const [total, setTotal] = useContext(Table.Total) || []
  const [search] = useContext(Table.Search) || []
  const [, setClickedRow] = useContext(Table.ClickedRow) || []

  const [, setColumns] = useContext(Table.Columns) || []
  let tableLoading = false

  if (onAsync) {
    const [res, isLoading] = useAsync(onAsync)
    tableLoading = isLoading
    items = res
  } else {
    tableData = useSearch(tableData)
    tableData = useFilter(tableData)
    tableData = useSort(tableData)

    items = pagination ? usePagination(items) : items
  }
  const { height, width } = useSize(ref, [])

  useEffect(() => {
    setColumns && setColumns(tableHeaders && tableHeaders)
  }, [tableHeaders])

  useEffect(() => {
    !onAsync && setItems && setItems(tableData && tableData)
  }, [tableData])

  useEffect(() => {
    resetClickedRow && setClickedRow()
  }, [resetClickedRow])
  useEffect(() => {
    setTotal && setTotal(tableData.length)
  }, [tableData.length])

  useEffect(() => {
    setSmall && setSmall(!!scroll)
  }, [scroll])

  const onScroll = (offset) => {
    if (!scroll || !offset) {
      if (items && items.length < 30) {
        offset = 0
      }
      setScroll && setScroll(offset)
    }
  }

  const components = [
    {
      Component: Columns,
      Region: null
    },
    {
      Component: Table,
      Size: { width, height },
      key: width + height,
      Scroll: 0,
      Actions: tableActions
    }
  ]
  let tableContent = (
    <Tree
      onScroll={onScroll}
      items={items}
      itemSize={itemSize}
      height={height}
      width={width}
      {...props}
    >
      {Row}
    </Tree>
  )
  if (tableLoading) {
    tableContent = (
      <div
        style={{
          width: '90px',
          height: '90px',
          position: 'relative',
          top: '42%',
          left: '45%',
          background: 'rgba(51, 128, 255, 0.2)',
          borderRadius: '50%'
        }}
      >
        <div
          style={{
            width: '50px',
            height: '36px',
            position: 'relative',
            top: '32%',
            left: '22%',
            background: 'rgba(255, 255, 255, 0.3)',
            border: '1px solid rgb(51, 128, 255)',
            boxSizing: 'border-box',
            borderRadius: '4px'
          }}
        >
          <Spinner
            style={{
              width: '50px',
              height: '50px',
              bottom: '7px',
              position: 'relative',
              left: '-1px'
            }}
          />
        </div>
        <h3
          style={{
            position: 'relative',
            top: '50%',
            color: '#8F8F8F',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '21px',
            textAlign: 'center'
          }}
        >
          Loading
        </h3>
      </div>
    )
  } else if (!total && search?.length) {
    tableContent = (
      <div
        style={{
          width: '90px',
          height: '90px',
          position: 'relative',
          top: '42%',
          left: '45%',
          background: 'rgba(51, 128, 255, 0.2)',
          borderRadius: '50%'
        }}
      >
        <NotFound
          style={{
            width: '50px',
            height: '50px',
            bottom: '7px',
            position: 'relative',
            top: '32%',
            left: '22%',
            borderBottomStyle: 'inset',
            borderBottomColor: 'rgba(51, 128, 255, 0.2)'
          }}
        />
        <h3
          style={{
            position: 'relative',
            top: '50%',
            color: '#8F8F8F',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '21px',
            left: '-90%',
            width: '250px',
            textAlign: 'center'
          }}
        >
          {`We didn’t find a match for ‘${search}’
Please try another search`}
        </h3>
      </div>
    )
  } else if (!total) {
    tableContent = (
      <div
        style={{
          width: '90px',
          height: '90px',
          position: 'relative',
          top: '42%',
          left: '45%',
          background: 'rgba(51, 128, 255, 0.2)',
          borderRadius: '50%'
        }}
      >
        <div
          style={{
            width: '50px',
            height: '36px',
            position: 'relative',
            top: '32%',
            left: '22%',
            background: 'rgba(255, 255, 255, 0.3)',
            border: '1px solid rgb(51, 128, 255)',
            boxSizing: 'border-box',
            borderRadius: '4px'
          }}
        >
          <NoRows
            style={{
              width: '50px',
              height: '50px',
              bottom: '7px',
              position: 'relative',
              left: '-1px'
            }}
          />
        </div>
        <h3
          style={{
            position: 'relative',
            top: '50%',
            color: '#8F8F8F',
            fontFamily: 'Source Sans Pro',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '21px',
            left: '-100%',
            width: '264px'
          }}
        >
          There are currently no data to show
        </h3>
      </div>
    )
  }
  return (
    <Fragment>
      <Contexts components={components}>
        <Header
          {...props}
          total={total}
          isAutoSearch={false}
          additionalFilters={additionalFilters}
        />
        <div data-cy={baseID + 'table'} className={styles.root}>
          <Columns
            visible={columnsBar}
            tableOptions={tableOptions}
            resizeHandle={resizeHandle}
            height={height}
            width={width}
            {...props}
          />
          <div
            ref={ref}
            className={clsx(styles.table, classes && classes.treeContainer)}
          >
            {tableContent}
          </div>
          {!!pagination && <Pagination total={total} />}
        </div>
      </Contexts>
    </Fragment>
  )
}

Table.Items = React.createContext()
Table.Selected = React.createContext()
Table.SingleSelection = React.createContext()
Table.Sort = React.createContext()
Table.Search = React.createContext()
Table.Columns = React.createContext()
Table.Scroll = React.createContext()
Table.Actions = React.createContext()
Table.Size = React.createContext()
Table.Total = React.createContext()
Table.ClickedRow = React.createContext()
Table.AdditionalFilters = React.createContext()
