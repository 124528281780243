import React, { Fragment, useContext } from 'react'
import styles from './Item.module.scss'
import clsx from 'clsx'
import Checkbox from '../Checkbox'

import Menu from '../Menu'
import Highlight from '../Highlight'
import Handler from '../../Util/Handler'
import { useID } from '../../Util/ID'

export default function Item(props) {
  const baseID = useID()
  const {
    id,
    label,
    icon,
    disabled,
    multiSelect,
    selectable,
    selectedState,
    closeMenu,
    onClick,
    handler,
    highlight = true,
    node = '',
    isAncestorsExist
  } = props
  let { checked } = props
  const [search] = useContext(Menu.Search) || []
  let [selected, setSelected] = selectedState || []
  if (multiSelect && !Array.isArray(selected)) {
    selected = selected ? [selected] : []
  }
  checked = checked || (multiSelect && selected.some((x) => x.label === label))
  const isGreyed = disabled || isAncestorsExist === false
  const onItemClick = (cb) => {
    if (isGreyed) {
      return
    }
    if (cb) {
      cb(id)
    } else if (onClick) {
      onClick(id, node)
    } else if (selectable) {
      if (multiSelect) {
        setChecked(!checked)
        return
      }
      setSelected && setSelected(id)
    }
    closeMenu && closeMenu()
  }
  const setChecked = (value) => {
    if (disabled) {
      return
    }
    if (selectable) {
      selected = selected.filter((item) => item && item !== id)
      if (value) {
        selected.push({ id, label })
      } else {
        selected = selected.filter((x) => x.label !== label)
      }
      setSelected && setSelected(selected)
    }
  }
  const text = <Highlight highlight={highlight && search}>{label}</Highlight>
  return (
    <Fragment>
      <Handler handler={handler}>
        {(cb) => (
          <div
            data-cy={baseID + 'menuItem-' + id}
            id={id}
            onClick={onItemClick.bind(this, cb)}
            className={clsx(
              styles.root,
              id && selected === id && styles.selected,
              isGreyed && styles.disabled
            )}
          >
            {!!multiSelect && (
              <Checkbox checked={checked} icon={icon} label={text} />
            )}
            {!multiSelect && (
              <>
                {!!icon && <div className={styles.icon}>{icon}</div>}
                <div
                  className={clsx(
                    styles.label,
                    id && selected === id && styles.selected,
                    isGreyed && styles.disabled
                  )}
                >
                  {text}
                </div>
              </>
            )}
          </div>
        )}
      </Handler>
    </Fragment>
  )
}
