import * as React from 'react'

function SvgTagActive(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 13 12'
      {...props}
    >
      <path
        d='M6.622 11.203c3.303 0 5.981-2.385 5.981-5.328 0-2.943-2.678-5.328-5.98-5.328C3.319.547.642 2.932.642 5.875c0 2.943 2.677 5.328 5.98 5.328zm0-1.031c-2.665 0-4.823-1.922-4.823-4.297s2.158-4.297 4.823-4.297c2.666 0 4.823 1.922 4.823 4.297s-2.157 4.297-4.823 4.297zM5.84 8.42l4.162-3.679a.238.238 0 00.001-.364l-.543-.488a.316.316 0 00-.41-.002L5.643 6.9 4.2 5.605a.316.316 0 00-.41-.002l-.547.484a.24.24 0 00-.002.365l2.19 1.966a.315.315 0 00.409.002z'
        fill='#2ED15E'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgTagActive
