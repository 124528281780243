import { makeStyles } from "@material-ui/core/styles";
import AppTabs from "../../../../AppTabs";
import React, { useCallback, useMemo, useState } from "react";
import Tenants from "../../Tenants";
import Applications from "../../Applications";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import clsx from "clsx";
import ProjectMenu from "../ProjectMenu";
import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import { getApplicationsByAccountIdAndProjectId, getTenantByAccountIdAndProjectId } from "../../../../../external-apis";
import UsersDrawer from "../UsersDrawer";
import Loader from "../../../../Loader";
import AppTooltip from "../../../../AppTooltip";

const useStyles = makeStyles((theme) => {
	return {

		rootContainer: {
			display: 'flex',
			flexDirection: 'column',
			background: 'hsla(0, 0%, 100%, 1)',
			boxShadow: '0px 0px 10px 0px hsla(0, 0%, 6%, 0.15)',
			borderRadius:4,
			'&:focus-within': {
				borderColor: '#3380FF',
			},
			marginBottom: 12,
			width: '100%',
			overflow: 'hidden',
		},
		rootContainerFocused: {
			borderColor: '#3380FF',
		},
		header: {
			position: 'relative',
			paddingTop: 12,
			display: 'flex',
			justifyContent: 'start',
			height: 86,
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: 14,
		},
		headerCollapsed: {
			height: 49,
		},
		tabsHeader: {
			position: 'absolute',
			left: 28,
			bottom: 0,
		},
		tabContentContainer: {
			width: '100%',
			background: '#FAFAFA',
			borderRadius: 4
		},
		projectName: {
			fontWeight: 600,
			fontSize: 17,
			maxWidth: 600,
			maxHeight: 40,
			overflow: 'hidden',
		},
		projectId: {
			color: '#8F8F8F',
			marginLeft: 5,
			maxWidth: 200,
			minWidth: 50,
			maxHeight: 40,
			overflow: 'hidden',
		},
		iconContainer: {
			margin: '0 7px',
		},
		icon: {
			width: 14,
			height: 6,
		},
		iconUp: {
			transform: 'rotate(180deg)',
		},
		separator: {
			border: '1px solid #C2C2C2',
			margin: '0px 12px',
			height: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		projectMenu: {
			position: 'absolute',
			width: '3px',
			left: 'calc(100% - 50px)',
		},
		projectHover: {
			borderRadius:8,
			"&:hover": {
				border: "1px solid #3380FF",
				backgroundClip: 'border-box',
				cursor: 'pointer'
			}
		}
	};
});

export default function ProjectCard({ projectId, accountId, displayName, description, created, setIsDrawerOpen, isDrawerOpen , customerId, customersData, reseller, errorInUpdate }) {

	const styles = useStyles();
	const [activeTab, setActiveTab] = useState('tenants');
	const staleTime = 60000;

	const mapApplications = useCallback(applications => {
		return applications.map( application => {
			const { applicationId, projectId:applicationProjectId } = application;

			return (applicationProjectId === projectId)?
				{
					applicationId

				} : {};
		}).filter(element => {
			return Object.keys(element).length !== 0;

		});
	}, [projectId]);

	const mapTenants = useCallback(tenants => {
		return tenants.map( tenant => {
			const { tenantId, productId, projectId: tenantProjectId } = tenant;

			return (tenantProjectId === projectId)?{
				tenantId,
				productId,
			} : {};
		}).filter(element => {
			return Object.keys(element).length !== 0;

		});
	}, [projectId]);

	const {
		data: applicationsData = {},
		isError: applicationsIsError,
		isLoading: applicationsIsLoading,
		error: applicationsError
	} = useQueryWithAuthorization(['get-application-by-account-id-and-project-id', accountId, projectId], getApplicationsByAccountIdAndProjectId(accountId, projectId, {}, 'projectId,applicationId'), {
		select: mapApplications,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const {
		data: tenantsData = [],
		isError: tenantsIsError,
		isLoading:tenantsIsLoading,
		error: tenantsError
	} = useQueryWithAuthorization(['get-tenant-by-account-id-and-project-id', accountId, projectId], getTenantByAccountIdAndProjectId(accountId,  projectId, {}, 'tenantId,productId,projectId' ), {
		select : mapTenants,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const handleTabChange = (tabId) => {
		setActiveTab(tabId);
	};

	const [collapsed, setCollapsed] = useState(false);

	const handleCollapse = () => {
		setCollapsed(prevState => !prevState);
	};
	const [showUsers, setShowUsers] = useState(false);
	const [showTenantDrawer, setShowTenantDrawer] = useState(false);

	const handleTabStopPropagation = useCallback((e) => {
		e.stopPropagation();
	}, []);
	const handleShowUsers = () => {
		setShowUsers(true);
		setIsDrawerOpen(true);
	};

	const handleUsersClose = () => {
		setShowUsers(false);
		setIsDrawerOpen(false);

	};

	const tabs = useMemo(() => [
		{
			id: 'tenants',
			label: `Tenants (${tenantsData.length})`,
			component:
				<Tenants
					accountId={accountId}
					projectId={projectId}
					tenantsData={tenantsData}
					setShowDrawer={setShowTenantDrawer}
					handleTabStopPropagation={handleTabStopPropagation}
					setIsDrawerOpen={setIsDrawerOpen}
				/>
		},
		{
			id: 'applications',
			label: `Applications (${applicationsData.length})`,
			component:
				<Applications
					accountId={accountId}
					projectId={projectId}
					projectName={displayName}
					applicationLength={applicationsData.length}
					mapApplications={mapApplications}
					applicationsData={applicationsData}
					handleTabStopPropagation={handleTabStopPropagation}
				/>
		},
	], [applicationsData, tenantsData, accountId, displayName, mapApplications, projectId, handleTabStopPropagation, setIsDrawerOpen]);

	const activeComponent = tabs.find(item => item.id === activeTab).component;

	if (applicationsIsLoading || tenantsIsLoading ) return <Loader/>;
	if (applicationsIsError) return <div>Error fetching data: {applicationsError.message}</div>;
	if (tenantsIsError) return <div>Error fetching data: {tenantsError.message}</div>;

	const projectNameToDisplay = (displayName, id) => {
		const name = customersData.find(customer => customer.id === id)?.name;

		if (name) {
			return (
				<AppTooltip title={'Project Name | Customer Name'} disabled={false}>
					<div> {displayName} | <em style={{ fontSize: "small", color: "#3380FF" }}> {name}</em></div>
				</AppTooltip>);
		}else{
			return displayName;
		}
	};

	return (
		<div className={clsx(styles.rootContainer, showUsers && styles.rootContainerFocused)}>
			<div
				className={!showTenantDrawer && !isDrawerOpen ?styles.projectHover: undefined }
				onClick={handleShowUsers}
			>
				<div className={clsx(styles.header, collapsed && styles.headerCollapsed)}>
					<div className={styles.iconContainer} onClick={handleTabStopPropagation}>
						<ExpandLessIcon className={clsx(styles.icon, !collapsed && styles.iconUp)} onClick={handleCollapse}/>
					</div>
					<div className={styles.projectName}>
						{projectNameToDisplay(displayName, customerId, errorInUpdate) }
					</div>
					<div className={styles.projectMenu} onClick={handleTabStopPropagation}>
						<ProjectMenu accountId={accountId} projectId={projectId} projectName={displayName} projectDescription={description} customerId={customerId} customersData={customersData} reseller={reseller} errorInUpdate={errorInUpdate}/>
					</div>
					{ !collapsed && <div className={styles.tabsHeader} onClick={handleTabStopPropagation}>
						<AppTabs activeTab={activeTab} items={tabs} onChange={handleTabChange} tapProps={{ sx: { fontSize: 14 } }}/>
					</div>}
				</div>
				{ !collapsed && applicationsData !== {} && <div className={styles.tabContentContainer}>
					{activeComponent}
				</div>}
				<div onClick={handleTabStopPropagation}>
					{ showUsers && <UsersDrawer accountId={accountId} projectId={projectId} displayName={displayName} description={description} created={created} handleUsersClose={handleUsersClose} open={showUsers}/>}
				</div>
			</div>
		</div>
	);
}
