import styles from "./Button.module.scss"
import clsx from "clsx"
import DropDownIcon from "../../iconComponents/DropDown"
import Text from "./Text"
import {useID} from "../Util/ID"
import React from "react"

export default function Button({ className, onClick, disabled, style, selectable, selected, dropdown = false, page = true, uppercase, filled = false, border=false, icon, label, children,id="" }) {
    const baseID = useID();
    const classes = clsx(
        styles.root,
        uppercase && styles.uppercase,
        !filled && !border && !selectable && styles.link,
        filled && styles.filled,
        border && styles.border,
        page && styles.page,
        disabled && styles.disabled,
        selectable && styles.selectable,
        selected && styles.selected,
        className);
    return <button data-cy={baseID+'button-'+id} onClick={disabled ? undefined : onClick} className={classes} style={style}>
        {!!icon && <div className={styles.icon}>
            {icon}
        </div>}
        <Text className={clsx(styles.content, border && styles.border, filled && styles.filled, !filled && !border && !selectable && styles.link, page && styles.page, disabled && styles.disabled)}>
            {label}
            {children}
        </Text>
        {!!dropdown && <div className={styles.arrow}>
            <DropDownIcon  width="7" height="5" />
        </div>}
    </button>
}
