import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/projects`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getByAccountId = (accountId, params = {}) => {
	const { headers = {}, includeProducts } = params;

	const urlSearchParams = new URLSearchParams();
	let queryParamsStr = '';

	if (includeProducts) {
		urlSearchParams.set('includeProducts', 'true');
		queryParamsStr = `?${urlSearchParams.toString()}`;
	}

	return {
		method: "GET",
		url: `${getApiEndpoint()}/accounts/${accountId}/projects${queryParamsStr}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const postProject = (accountId, params={}) => {
	const { headers = {}, projectName, description } = params;
	const body = {
		'displayName': projectName,
		'description': description
	};

	return {
		method: "POST",
		url: `${getApiEndpoint()}/accounts/${accountId}/projects`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: JSON.stringify(body)
	};
};

const putProject = (accountId, projectId, params={}) => {
	const { headers = {}, projectName, description,customerId } = params;
	
	const body = {
		'displayName': projectName,
		'description': description,
		'customerId': customerId
	};

	return {
		method: "PUT",
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: JSON.stringify(body)
	};
};

const deleteProject = (accountId, projectId, params={}) => {
	const { headers = {} } = params;

	return {
		method: "DELETE",
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

export {
	getAll,
	getByAccountId,
	postProject,
	putProject,
	deleteProject,
};
