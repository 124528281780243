import styles from './Selector.module.scss'
import ClearIcon from '../../../iconComponents/Clear'
import React, { useEffect, useContext } from 'react'
import Menu from '../Menu'
import ArrowIcon from '../../../iconComponents/SortDesc'
import Data from '../../Util/Data'
import { title } from '../../../../util/string'
import { useContextEx } from '../../Util/Contexts'
import { useCancelAndDebounce } from '../../../../util/performance'

export default function Selector({
  id,
  label,
  placeholder,
  removeFilter,
  width,
  setFilter,
  valuesCb,
  valueCb,
  onOptions,
  isAsyncAutocomplete
}) {
  const [data] = useContext(Data.Items) || []
  const [menuItems, setMenuItems] = useContext(Menu.Items) || []
  const [searchValue] = useContextEx(Menu.Search) || []
  const [selectedMenuItems] = useContext(Menu.Selected) || []

  let values = (menuItems || [])
    .filter((item) =>
      (selectedMenuItems.map((x) => x.id) || []).includes(item.id)
    )
    .map((item) => item.label)
  if (isAsyncAutocomplete) {
    values = selectedMenuItems.map((x) => x.label) || []
  }
  const isEmpty = !(menuItems && menuItems.length)
  useEffect(() => {
    setFilter(selectedMenuItems.map((x) => x.id))
  }, [selectedMenuItems])

  const [res, isLoading] = useCancelAndDebounce(
    async () => {
      let options = menuItems
      if (onOptions && (isAsyncAutocomplete || !options.length)) {
        options = await onOptions({ filterKey: id, filterValue: searchValue })
        options = Array.from(
          new Map(
            [...selectedMenuItems, ...options].map((x) => [x.label, x])
          ).values()
        )
      } else if (onOptions && options.length) {
        return options
      } else {
        const items = data || []
        const callback = valuesCb || valueCb
        const ids = []
        items.forEach((item) => {
          const value = callback ? callback(item) : item[id]
          if (Array.isArray(value)) {
            ids.push(...value)
          } else {
            ids.push(value)
          }
        })
        const set = new Set(ids.filter(Boolean).map((id) => `${id}`))
        const uniqueIds = Array.from(set).sort()
        options = uniqueIds.filter(Boolean).map((id) => ({
          id,
          label: title(id)
        }))
      }
      return options
    },
    [id, searchValue],
    500
  )
  useEffect(() => {
    setMenuItems(res)
  }, [res])

  return (
    <div
      className={styles.root}
      style={{ width }}
      data-testid={'selector-' + id}
    >
      <div onClick={removeFilter} className={styles.cancel}>
        <ClearIcon />
      </div>
      <div className={styles.separator} />
      <Menu
        className={styles.selector}
        selectable
        multiSelect={!isEmpty || isAsyncAutocomplete}
        style={{ marginLeft: '-29px', width }}
        isAsyncAutocomplete={isAsyncAutocomplete}
        isLoading={isLoading}
      >
        <div
          className={styles.select}
          style={{ width: width ? width - 28 : undefined }}
        >
          <div className={styles.label}>{label}</div>
          {!values.length && (
            <div className={styles.placeholder}>{placeholder}</div>
          )}
          {!!values.length && (
            <div className={styles.value}>{values.join(', ')}</div>
          )}
          <div className={styles.arrow}>
            <ArrowIcon />
          </div>
        </div>
      </Menu>
    </div>
  )
}
