import * as React from 'react'

function SvgGroup(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 22 20'
      fill='#FFF'
      {...props}
    >
      <g fillRule='evenodd'>
        <path
          fill='#F1F1F1'
          d='M11 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S1 15.523 1 10 5.477 0 11 0zm0 .588a9.412 9.412 0 100 18.824A9.412 9.412 0 0011 .588z'
        />
        <path
          d='M11 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S1 15.523 1 10 5.477 0 11 0zm0 .588a9.412 9.412 0 100 18.824A9.412 9.412 0 0011 .588z'
        />
        <path
          fillRule='nonzero'
          d='M11 10c.729 0 1.354-.26 1.875-.781.52-.521.781-1.151.781-1.89 0-.74-.26-1.37-.781-1.891A2.558 2.558 0 0011 4.656c-.73 0-1.354.26-1.875.782-.521.52-.781 1.15-.781 1.89s.26 1.37.78 1.89A2.558 2.558 0 0011 10zm5.344 5.344V14c0-.48-.24-.922-.72-1.328-.478-.406-1.13-.73-1.952-.969a9.51 9.51 0 00-2.672-.36 9.5 9.5 0 00-2.672.36c-.823.24-1.474.563-1.953.969-.48.406-.719.848-.719 1.328v1.344h10.688z'
        />
      </g>
    </svg>
  )
}

export default SvgGroup
