import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
	const inputPaddingX = 12;
	const inputBorderWidth = 1;

	return {
		rootContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'start',
		},
		labelContainer: {
			display:"flex",
			flexDirection:"row"
		},
		label: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			display: 'flex',
			justifyContent: 'start',
		},
		output: {
			width: `calc(100% - ${2 * (inputPaddingX + inputBorderWidth)}px)`,
			color: '#C2C2C2',
			height: 40,
			padding: 10,
			border: `${inputBorderWidth}px solid #C2C2C2`,
			boxSizing: 'borderBox',
			borderRadius: 4,
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			paddingLeft: inputPaddingX,
			paddingRight: inputPaddingX,
		}
	};
});

function AppDisplay({ label, display, overrideClasses={} }) {
	const styles = useStyles();

	return (
		<div className={styles.rootContainer}>
			<div className={styles.labelContainer}>
				<div className={styles.label}>{ label }</div>
			</div>
			<output className={ (clsx(overrideClasses.output, styles.output))}>
				{display}
			</output>
		</div>
	);
}

export default AppDisplay;
