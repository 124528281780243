import styles from "./ColumnSelector.module.scss"
import FilterIcon from "../../../iconComponents/TableFilter"
import React, { useEffect, useContext } from "react"
import Menu from "../Menu"
import Table from "../Table"
import Filter from "../Filter"
import Data from "../../Util/Data"
import { title } from "../../../../util/string"
import { useID } from "../../Util/ID"

export default function ColumnSelector({ columnId, callback }) {
    const baseID = useID();
    const [menuItems, setMenuItems] = useContext(Menu.Items) || [];
    const [selectedMenuItems, setSelectedMenuItems] = useContext(Menu.Selected) || [];
    const [filterItems, setFilterItems] = useContext(Filter.Items) || [];
    const [columns] = useContext(Table.Columns) || [];
    const column = columns.find(column => column.id === columnId)
    const menuWidth = column.filterWidth || 218;
    const [data] = useContext(Data.Items) || [];

    const isEmpty = !(menuItems && menuItems.length);

    useEffect(() => {
        const foudFilter = filterItems.find(filterItem => filterItem.id === columnId);
        if (foudFilter && foudFilter.value) {
            setSelectedMenuItems(foudFilter.value)
        }
        const items = data || [];
        const set = new Set(items.map(item => callback ? callback(item) : item[columnId]));
        const uniqueIds = Array.from(set).sort();
        const selectorItems = uniqueIds.filter(Boolean).map(id => ({
            id,
            label: title(id)
        }));
        if (selectorItems && selectorItems.length > 0) {
            setMenuItems([...selectorItems])
        }
    }, [data, filterItems]);

    useEffect(() => {
        selectedMenuItems && setFilterItems(items => {
            const foudFilter = items.find(item => item.id === columnId);
            if (foudFilter) {
                foudFilter.value = selectedMenuItems;
            } else {
                items.push({ ...column, width: menuWidth, value: selectedMenuItems, disabled: false })
            }
            return [...items];
        });
    }, [selectedMenuItems]);

    return (
        <div data-cy={baseID + "columnSelector-" + columnId} className={styles.root}>
            <Menu id={columnId} relativePos={{ left: -(menuWidth / 2) }} selectable={true} multiSelect={!isEmpty} style={{ marginLeft: "-29px", width: menuWidth }}>
                <FilterIcon />
            </Menu>
        </div>
    )
}
