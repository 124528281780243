import Table from '../components/Core/Widgets/Table'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import NumPages from '../components/Core/Widgets/Table/Pagination/NumPages'
import Pagination from '../components/Core/Widgets/Table/Pagination'
import Filter from '../components/Core/Widgets/Filter'
import { useCancelAndDebounce } from './performance'

export function useSearch(items) {
  const [columns] = useContext(Table.Columns) || []
  const [sort] = useContext(Table.Sort) || []
  const [search] = useContext(Table.Search) || []
  return useMemo(() => {
    if (!items) {
      return
    }
    let rows = items
    if (search) {
      rows = rows.filter((row) => {
        let match = false
        columns.forEach((column) => {
          if (column.searchable) {
            const result = column.valueCb ? column.valueCb(row) : row[column.id]
            if (result && result.toLowerCase().includes(search.toLowerCase())) {
              match = true
            }
          }
        })
        return match
      })
    }
    return rows
  }, [items, sort, search, columns])
}

export function useSort(items) {
  const [columns] = useContext(Table.Columns) || []
  const [sort] = useContext(Table.Sort) || []
  return useMemo(() => {
    if (!items) {
      return
    }
    let rows = items
    if (sort) {
      const column = columns.find((item) => item.id === sort)
      if (column) {
        rows = [...rows].sort((a, b) => {
          if (column.sortCb) {
            return column.sortCb(a, b)
          }
          let aResult = column.valueCb ? column.valueCb(a) : a[column.id]
          let bResult = column.valueCb ? column.valueCb(b) : b[column.id]
          if (column.direction === 'asc') {
            const value = aResult
            aResult = bResult
            bResult = value
          }
          if (typeof aResult === 'string' && typeof bResult === 'string') {
            return aResult.localeCompare(bResult)
          }
          return aResult - bResult
        })
      }
    }
    return rows
  }, [items, sort, columns])
}

function mapItemsToElements(items, Item) {
  return items.map((item, index) => {
    const Component = item.Item || Item
    let component = (
      <Component
        key={item.id || index}
        index={index}
        count={items.length}
        {...item}
      />
    )
    if (item?.ancestorsFilters) {
      const existingFilters = items.map(({ label }) => label)

      component = (
        <Component
          key={item.id || index}
          index={index}
          count={items.length}
          isAncestorsExist={item?.ancestorsFilters?.some((id) =>
            existingFilters.includes(id)
          )}
          {...item}
        />
      )
    }
    return component
  })
}

export function useElements(items, Item) {
  const elements = useMemo(() => {
    if (items) {
      return mapItemsToElements(items, Item)
    }
    return null
  }, [items])
  return elements
}

export function useAsync(onAsync) {
  const [count] = useContext(NumPages.Selected) || [0]
  const [filters] = useContext(Filter.Items) || []
  const [additionalFilters] = useContext(Table.AdditionalFilters) || []
  const [columns] = useContext(Table.Columns) || []
  const [sort] = useContext(Table.Sort) || []
  const [offset, setOffset] = useContext(Pagination.Offset) || [0]
  const [tableTotal, setTableTotal] = useContext(Table.Total) || [0]
  const [nextPage, setNextPage] = useState()
  const direction = columns.find((item) => item.id === sort).direction
  const [search] = useContext(Table.Search) || []
  const [, setSelected] = useContext(Table.Selected) || []
  const [, setTableItems] = useContext(Table.Items) || []

  const [prevFilters, setPrevFilters] = useState(false)
  const [prevValues, setPrevValues] = useState({
    search: undefined,
    sort: undefined,
    direction: undefined
  })

  const filterIsValuable = useMemo(
    () =>
      filters?.some(({ value }) => value?.length) ||
      !prevFilters ||
      prevFilters?.some(({ value }) => value?.length) ||
      JSON.stringify(filters) === JSON.stringify(prevFilters),
    [prevFilters, filters]
  )

  const getOffset = () => {
    if (
      JSON.stringify(prevValues) !==
        JSON.stringify({ search, sort, direction }) ||
      JSON.stringify(filters) !== JSON.stringify(prevFilters)
    ) {
      setOffset(0)
      return 0
    }
    return offset
  }
  const [data, isLoading] = useCancelAndDebounce(
    async (fetchOptions) => {
      setPrevValues(JSON.parse(JSON.stringify({ search, sort, direction })))
      const updatedOffset = getOffset()
      if (onAsync && filterIsValuable) {
        setPrevFilters(JSON.parse(JSON.stringify(filters)))
        setSelected && setSelected([])
        return await onAsync(
          {
            limit: count,
            offset: updatedOffset,
            sort,
            direction,
            filters,
            search,
            additionalFilters
          },
          fetchOptions
        )
      }
      return data
    },
    [onAsync, count, offset, sort, direction, filters, search, additionalFilters],
    500
  )
  useEffect(() => {
    if (tableTotal !== data?.total) {
      setTableTotal(data?.total)
    }
    setTableItems(data?.items)
    setNextPage(data?.items)
  }, [data])

  return [nextPage, isLoading]
}
