import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import Loader from "../../../../Loader";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, } from "@material-ui/core";
import {
	getProducts as apiGetProducts,
	getProductsPermissions as apiGetProductsPermissions
} from '../../../../../external-apis';
import { produce } from 'immer';
import AppButton from "../../../../AppButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import clsx from "clsx";
import AppTabs from "../../../../AppTabs";

const useStyles = makeStyles(() => ({
	root: {
		padding: 12,
		paddingLeft:0,
		width:"100%",
	},
	header:{
		width: "100%",
	},
	formGroup: {
		padding: 0,
		alignContent: "flex-start",
	},
	formCheckbox: {
		borderRadius: 4,
		padding: '0.01em 2px',
		marginTop: 10,
		marginLeft: 5,
		width: 170,
		height: 40,
	},
	formCheckboxLabel: {
		fontSize: 14,

	},
	formLabelContainer : {
		display:'flex',
		flexDirection: "row",
		alignItems: "center",
		marginLeft: 12

	},
	formLabelLeft: {
		fontSize: 14,
		color:"#292929"
	},
	formLabelRight: {
		display:'inline',
		whiteSpace: 'pre-wrap',
		color: "#8F8F8F",
		fontSize: 12,
	},
	selectProductContent:{
		marginTop: 16,
		marginLeft: 12,
	},
	selectProductContainer:{
		width: 166
	},
	selectProductButton:{
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		width:166,
		height: 30,
		padding: "5px 10px 5px 16px"

	},
	permissionsContainer:{
		padding:0,
		width: "790px",
		height:201,
		overflowY: "auto",
		marginLeft: 12

	},
	AddCircleOutline:{
		width: 16,
		height: 16,
		marginRight: 8,
	},
	productDisplayContent:{
		minHeight: 240,
	},
	productBtm: {
		width: "100%",
		height: 30,
		'&:hover': {
			backgroundColor: 'rgba(51, 128, 255, 0.1)',
			cursor: 'pointer',
		}
	},
	productBtmDisabled: {
		width: "100%",
		height: 30,
		'&:hover': {
			cursor: 'pointer',
		}
	},
	selectTextDisabled:{
		color: "#C2C2C2",
		marginLeft: 12,
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
	},
	selectText:{
		marginLeft: 12,
		color: "#292929",
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
	},
	selectRoot: {
		position: "absolute",
		backgroundColor: "#FFFFFF",
		zIndex: 2,
		overflowY: "auto",
		width: 166,
		maxHeight: 210,
		borderRadius: 4,
		border: '1px solid #C2C2C2',
		marginLeft: 12

	},
	lineSeparate: {
		position: "absolute",
		width: 783,
		height: 1,
		backgroundColor: "#C2C2C2"
	},
	checkbox:{
		maxWidth: 13,
		maxHeight:14,
		marginRight: 12,
		padding: "7px 6.5px 7px 6.5px",
		'&.Mui-checked': { color: "#3380FF" }
	},
	separator: {
		height:16,
		border: '1px solid #C2C2C2',
		flex: 'none',
		margin: '0px 8px'
	}

}));

const mapProductsPermissionsResult = ({ productsPermissions } = {}) => {
	const allPermissions = [];
	const tabs = [];

	for (const [productId, productInfo] of Object.entries(productsPermissions)) {
		const { name, permissions } = productInfo;

		allPermissions.push(...permissions);
		tabs.push({
			productId,
			name,
			permissions
		});
	}

	return {
		tabs: [
			{ name: 'All', permissions: allPermissions },
			...tabs
		],
		allPermissions
	};
};

const mapProducts = (products) => {

	return products.map(product => {
		const { displayName } = product;

		return {
			id: displayName
		};
	});
};

export default function SelectProductsPermissions(props) {
	const classes = useStyles();
	const staleTime = 60000;
	const [openSelectProduct, setOpenSelectProduct] = useState(false);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [activeTab, setActiveTab] = useState();
	const {	selectedPermissions, setSelectedPermissions } = props;
	const [filteredPermissions, setFilteredPermissions] = useState([]);
	const [tabs, setTabs] = useState([]);
	const [editedPermissions, setEditedPermissions] = useState({});
	const wrapperRef = useRef(null);

	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenSelectProduct(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);

			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	useOutsideAlerter(wrapperRef);

	const selectProductMenu = () => {
		return <div ref={wrapperRef} className={classes.selectProductContainer}>
			{<div className={classes.selectProductContent}>
				<AppButton variant='contained' overrideClasses={{ button: classes.selectProductButton }} onClick={selectOnClick}>
					<div className={classes.AddCircleOutline}>
						<AddCircleOutlineIcon style={{ width: "100%", height: "100%" }}/>
					</div>
					{"SELECT A PRODUCT"}
				</AppButton>
			</div>}
			{openSelectProduct && <SelectDiv/>}
		</div>;

	};

	const { isLoading: isLoadingProducts,
		isError: isErrorProducts,
		data: products = [],
		error: errorProducts } = useQueryWithAuthorization('products', apiGetProducts(), { select: mapProducts, staleTime: staleTime });

	const { isLoading: isLoadingPermissions,
		isError: isErrorPermissions,
		error: errorPermissions,
		data: productsPermissions } = useQueryWithAuthorization('productsPermissions', apiGetProductsPermissions(), {
		select: mapProductsPermissionsResult,
		staleTime: staleTime
	});

	useEffect(() => {
		setTabs(selectedProducts.map((product) => {
			return {
				id: product,
				label: product,
			};
		}));
	}, [selectedProducts]);

	if (isLoadingPermissions|| isLoadingProducts) return <Loader/>;
	if (isErrorProducts) return <div>Error: {errorProducts.message}</div>;
	if (isErrorPermissions) return <div>Error: {errorPermissions.message}</div>;

	const handleChangePermission = event => {
		if (event.target.checked) {
			setSelectedPermissions(produce(selectedPermissions, draft => {
				draft.add(event.target.value);
			}));
		}else {
			setSelectedPermissions(produce(selectedPermissions, draft => {
				draft.delete(event.target.value);
			}));
		}

		const tabEditedPermissions = editedPermissions?.[activeTab]? editedPermissions[activeTab]:[];
		const index = tabEditedPermissions.indexOf(event.target.value);
		const newArray = [...tabEditedPermissions];

		if (index > -1) {
			newArray.splice(index, 1);
		}else {
			newArray.push(event.target.value);
		}
		editedPermissions[activeTab] = (newArray);
		setEditedPermissions(editedPermissions);
	};
	const selectOnClick = () => {
		setOpenSelectProduct(!openSelectProduct);
	};

	const handleTabChange = (tabId) => {
		const permissionsToDisplay = productsPermissions.tabs.find((item) => item.name === tabId).permissions;

		setFilteredPermissions(permissionsToDisplay);
		setActiveTab(tabId);
	};

	const onClickProduct = ( productId ) => {
		setOpenSelectProduct(false);
		if(!(selectedProducts.includes(productId))) {
			setSelectedProducts([...selectedProducts, productId]);
			setActiveTab(productId);
			handleTabChange(productId);
		}

	};

	function SelectDiv() {

		return <div className={classes.selectRoot}>
			{Object.values(products).map((product, index) => {
				const isProductSelected = selectedProducts.includes(product.id);

				return <div key={index} className={clsx(!isProductSelected && classes.productBtm, isProductSelected && classes.productBtmDisabled)} onClick={() => onClickProduct(product.id)}>
					<div className={clsx(!isProductSelected && classes.selectText, isProductSelected && classes.selectTextDisabled)}>
						{product.id}
					</div>
				</div>;
			})}
		</div>;
	}

	const handleCloseTab = (tabId, e) => {
		e.stopPropagation();
		if(editedPermissions?.[tabId]) {
			let newSet = new Set(selectedPermissions);

			editedPermissions[tabId].forEach((permission) => {
				newSet.has(permission) ? newSet.delete(permission):newSet.add(permission);
			});
			setSelectedPermissions(newSet);
			editedPermissions[tabId] = Array.from([]);
			setEditedPermissions(editedPermissions);
		}
		const index = selectedProducts.indexOf(tabId);

		const newProducts = [...selectedProducts];

		newProducts.splice(index, 1);
		setSelectedProducts(newProducts);

		if ( newProducts.length === 0 ) {
			setFilteredPermissions([]);
			setActiveTab(null);
		}
		else if ( tabId === activeTab ) {
			handleTabChange(newProducts[index === newProducts.length ? index - 1 : index]);
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.formLabelContainer}>
					<FormLabel classes={{ root: classes.formLabelLeft }} component="legend" focused={false}>
						Permissions
					</FormLabel>
					<div className={classes.separator}/>
					<FormLabel classes={{ root: classes.formLabelRight }} component="legend" focused={false}>
						{"Please select a product and add the desired permissions "}
					</FormLabel>
				</div>
				{selectProductMenu()}
			</div>
			<div className={classes.productDisplayContent}>
				{tabs.length > 0 && <AppTabs
					activeTab={activeTab}
					items={tabs}
					isClosable={true}
					onClose={handleCloseTab}
					onChange={handleTabChange}
					variant={"scrollable"}
					tapProps={{ sx:{
						fontSize: 14,
						minWidth: 68,
						'&.MuiTab-root': {
							padding: '24px 16px 0px 10px',
						}
					} }}
				/>}
				{selectedProducts.length > 0 && <div className={classes.lineSeparate}/>}
				<form className={ classes.permissionsContainer}>
					<FormControl fullWidth component="fieldset" margin="normal">
						<FormGroup classes={{ root: classes.formGroup }} row={true}>
							{filteredPermissions.map((permission, i) => (
								<FormControlLabel
									classes={{ root: classes.formCheckbox ,label: classes.formCheckboxLabel }}
									key={i}
									value={permission}
									control={<Checkbox className={classes.checkbox} onChange={handleChangePermission}/>}
									label={permission}
									checked={selectedPermissions.has(permission)}
								/>
							))}
						</FormGroup>
					</FormControl>
				</form>
			</div>
		</div>
	);
}
