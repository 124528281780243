import styles from "./DragHandle.module.scss"
import React,{useState, useRef, useContext, Fragment} from "react"
import {useColumnWidth} from "../Columns"
import clsx from "clsx"
import Table from "../../Table"
import ResizeArrow from "../../../../iconComponents/ResizeArrow"

export default function DragHandle({id}) {
    const [{height}] = useContext(Table.Size) || [];
    const ref = useRef();
    const [hover, setHover] = useState(false);
    const [isDragging, setDragging] = useState(false);
    const [, setItems] = useContext(Table.Columns) || [];
    const columnWidth = useColumnWidth(id);

    const onMouseOver = () => setHover(true);
    const onMouseOut = () => setHover(false);

    const onMouseDown = event => {
        event.persist()
        let currentWidth = columnWidth;
        const mouseMove = e => {
            const diff = e.pageX - event.pageX;
            setItems && setItems(items => {
                const item = items.find(item => item.id === id);
                const width = Math.max(columnWidth + diff, 100);
                const threshold = 3;
                if(width < currentWidth - threshold || width > currentWidth + threshold) {
                    setHover(false);
                    item.width = currentWidth = width;
                    return [...items];
                }
            });
        };
        const mouseUp = () => {
            setDragging(false);
            window.document.body.removeEventListener("mouseup", mouseUp);
            window.document.body.removeEventListener("mousemove", mouseMove);
        };
        window.document.body.addEventListener("mouseup", mouseUp);
        window.document.body.addEventListener("mousemove", mouseMove);

        setDragging(true);
    };

    return <Fragment>
        <div style={{flex:1}}/>
        <div ref={ref} className={clsx(styles.root, (hover || isDragging) && styles.hover)} onMouseDown={onMouseDown} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <ResizeArrow className={styles.resize}/>
            <div className={styles.handle}/>
            <div className={styles.columnLine} style={{height:height+6}} />
        </div>
    </Fragment>;
}
