import axios from "axios";

axios.defaults.headers.common = {};
axios.defaults.headers.post = {};
axios.defaults.headers.patch = {};
axios.defaults.headers.put = {};

const fetchFn = (externalApiObj = {}) => {
	return async () => {
		const { url, method, headers, body } = externalApiObj;

		const res = await axios({
			method,
			url,
			data: body,
			headers,
			validateStatus: false
		});

		if(res.status >= 400) {
			throw new Error(JSON.stringify({ status : res.status, message: res.data, method }));
		}

		return res.data;
	};
};

export {
	fetchFn
};
