import * as React from 'react'

function SvgLeftArrow(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 5 8'
      {...props}
    >
      <path
        d='M4.052 7.106l-.18.181a.304.304 0 01-.432 0L.449 4.342a.307.307 0 01-.003-.432L3.437.965c.12-.12.313-.12.432 0l.18.18c.12.12.12.312 0 .432L1.454 4.126l2.598 2.55c.12.119.12.312 0 .43z'
        fill='#8F8F8F'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgLeftArrow
