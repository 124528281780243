import ActiveUsers from "./ActiveUsers";
import InvitedUsers from "./InvitedUsers";

const defaultTabId = 'activeUsers';
const tabToComponentData = {
	'activeUsers': {
		label: 'Active',
		component: <ActiveUsers/>
	},
	'invitedUsers': {
		label: 'Invited',
		component: <InvitedUsers/>
	}
};

export {
	tabToComponentData,
	defaultTabId
};
