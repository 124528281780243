import React, { useState, useRef, useCallback } from "react";

const compose = (contexts, children) =>
    contexts.reduce((acc, [Context, value]) => {
        return <Context.Provider value={value}>{acc}</Context.Provider>;
    }, children);

export default function Contexts({ children, components }) {
    const [, setCounter] = useState(0);
    const state = useRef([]);
    const setState = useCallback((component, id, value) => {
        const item = state.current.find(item => item.component === component && item.id === id) || {};
        if (typeof value === "function") {
            value = value(item.value);
            if(typeof value === "undefined") {
                return;
            }
        }
        if (!Object.is(value, item.value)) {
            if (item) {
                item.value = value;
            }
            else {
                state.current.push({ component, id, value });
            }
            setCounter(counter => counter + 1);
        }
    }, []);
    const contexts = [];
    (components || []).forEach(component => {
        const { Component, key, ...states } = component;
        if (!Component || !Object.keys(states).length) {
            return;
        }
        for (const id in states) {
            if (!Component[id]) {
                continue;
            }
            const defaultState = states[id];
            let item = state.current.find(item => item.component === Component && item.id === id);
            if(typeof defaultState === "undefined") {
                if(item) {
                    state.current = state.current.filter(obj => obj !== item);
                }
            }
            else if (!item) {
                item = { component: Component, id, value: defaultState, defaultState };
                state.current.push(item);
            }
            else if(item && item.key !== key) {
                item.value = defaultState;
                item.key = key;
                item.defaultState = defaultState;
            }
            const value = item ? item.value : undefined;
            const contextValue = defaultState && defaultState.Provider ? defaultState : [value, setState.bind(this, Component, id)];
            contexts.push([Component[id], contextValue]);
        }
    });
    return compose(contexts, children);
}

export function useContextEx(component) {
    const result = React.useContext(component);
    const forward = React.useContext(result && result.Provider ? result : component);
    const value = result && result.Provider ? forward : result;
    return value;
}
