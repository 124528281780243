import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import General from "./Tabs/General";
import Packages from "./Tabs/Packages";
import AppTabs from "../../../../AppTabs";
import moment from "moment";
import clsx from "clsx";
import { useMutationWithAuthorization, useQueryWithAuthorization } from "../../../../../custom-hooks";
import { deleteTenant, getTenantById, getTenantConfiguration, terminateTenant } from "../../../../../external-apis";
import Loader from "../../../../Loader";
import AppButton from "../../../../AppButton";
import AppDeleteConfirmation from "../../../../AppDeleteConfirmation";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import AppTooltip from "../../../../AppTooltip";
import { useDecodedJwtStore } from "../../../../../state-management";
import shallow from "zustand/shallow";
import { ReactComponent as ErrorBackground } from"../../../../../assets/icons/error/oval.svg";
import { ReactComponent as ErrorIcon } from"../../../../../assets/icons/error/error.svg";

const useStyles = makeStyles((theme) => {
	return {
		drawerContainer: {
			height: '100%',
			background: 'white',
			position: 'fixed',
			top: 0,
			right: 233,
			width: 577,
			zIndex: 1,
			boxShadow: '1px 0px 7px grey',
		},
		tenantHeader: {
			maxHeight: 145,
			width: '100%',
			boxShadow: '0px 2px 5px 0px hsla(0, 0%, 0%, 0.15)',
			fontFamily: 'Source Sans Pro',

		},
		tenantHeaderContent:{
			display: 'flex',
		},
		projectId: {
			color: '#8F8F8F',
			marginLeft: 5,
			maxWidth: 200,
			minWidth: 50,
			maxHeight: 40,
			overflow: 'hidden',
		},
		separator: {
			border: '1px solid #C2C2C2',
			margin: '0px 12px',
			height: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		separatorBlack:{
			border: '1px solid #292929',
		},
		displayName: {
			paddingTop: 16,
			marginLeft: 24,
			fontSize: 20,
			fontWeight: 600,
			lineHeight: "25px",
		},
		usersHeaderProjectDescription: {
			display:"flex",
			alignItems: "self-start",
			fontSize: 14,
			margin: '4px 0 4px 24px',
			maxHeight: 20,
			overflow: 'hidden',
			color:'#292929'
		},
		usersHeaderProjectCreated: {
			display: 'flex',
			marginLeft: 24,
			fontSize: 14,
			color: 'gray',
		},
		btnClose: {
			color:"#8F8F8F",
			margin: '18.5px 6px 0px auto',
			padding: '3px',
			width:13,
			height: 20,
			cursor: 'pointer',
			'&:hover': {
				transform: 'scale(1.2)',
			}
		},
		tabContentContainer: {
			width: '100%',
			backgroundColor: '#FFFFFF',
			borderRadius: 4,
			overflowY: 'auto',
			height: 'calc(100% - 200px)',
			marginTop: 5
		},
		description: {
			marginTop:4
		},
		created: {
			marginTop:4
		},
		deleteBtnContainer:{
			position:"absolute",
			top: 95,
			left: 490
		},
		terminateBtnContainer:{
			position:"absolute",
			top: 95,
			left: 468
		},
		loader: {
			marginLeft:50
		},
		appTabs:{
			marginLeft:12,
		},
		invalidPageContainer:{
			display:"flex",
			flexDirection:"column",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			height: 'calc(100% - 100px)'
		},
		invalidIconContainer:{
			display: "grid",
			gridTemplateColumns: '1fr',
			gridTemplateRows: '1fr',
			gridTemplateAreas: "overlap",
		},
		invalidContentIcon:{
			gridArea: 'overlap',
			borderRadius: 0,
		},
		invalidPageText:{
			textAlign: "center",
			color: "#8F8F8F",
			fontSize: 14,
			fontFamily: 'Source Sans Pro',
			margin: '0px 0px',
			fontWeight: 400,
		},

		terminateConfirmationBtb: {
			display: 'flex',
			alignItems: 'center',
			width: 122,
			marginLeft: 10
		},
		deleteConfirmationBtb:{
			display: 'flex',
			alignItems: 'center',
			width: 100,
			marginLeft: 5
		},
		errorComponentContainer: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			marginTop: 250,
			marginLeft: 12

		},
		errorIconContainer: {
			width: 100,
			height: 100,
			display: "grid",
			gridTemplate: '1fr',
			gridTemplateAreas: "overlap",
		},

		errorIconBackground: {
			gridArea: 'overlap',
		},

		errorIcon: {
			gridArea: 'overlap',
			marginLeft: 31,
			marginTop: 31
		},
		errorTextTop: {
			fontSize: 17,
			fontWeight: 400,
			color: '#8F8F8F',
			marginTop: 10,
			marginLeft: 11,
			fontFamily: 'Source Sans Pro',

		},
		errorTextButton: {
			fontSize: 17,
			fontWeight: 400,
			color: '#8F8F8F',
			marginTop: 6,
			fontFamily: 'Source Sans Pro',
			marginRight: 10,

		},

		errorDeleteBtnContainer: {
			marginTop: 12
		},
		contactSupport:{
			fontSize: 14,
			color: '#3380FF'
		}

	};
});

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, handleDrawerClose, deleteTerminateVisible) {
	useEffect(() => {
		/**
         * Alert if clicked on outside of element
         */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target) && !deleteTerminateVisible) {
				handleDrawerClose();
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handleDrawerClose, deleteTerminateVisible]);
}
export default function TenantDrawer({ productId, productDisplayName, handleDrawerClose, tenantId, accountId, projectId, productTenantAutomationEndpoint }) {

	const wrapperRef = useRef(null);
	const styles = useStyles();
	const decodedJwt = useDecodedJwtStore(state => state.decodedJwt, shallow);
	const allowsTenantDeletion = decodedJwt?.scope?.includes("account-admin:tenant:delete");
	const [isDeletable, setIsDeletable] = useState(false);
	const [deleteTerminateVisible, setDeleteTerminateVisible] = useState(false);
	const [deleteUsersRoles, setDeleteUsersRoles] = useState(false);
	const { mutateAsync } = useMutationWithAuthorization();
	const queryClient = useQueryClient();
	const staleTime = 60000;

	const {
		isLoading: tenantIsLoading,
		isError: tenantIsError,
		data: tenant={},
		error: tenantError
	} = useQueryWithAuthorization(['get-tenant-by-id', tenantId], getTenantById(tenantId), {
		enabled: tenantId != null,
		staleTime: staleTime
	});

	const {
		isLoading: configIsLoading,
		isError: configIsError,
		data:  config={}
	} = useQueryWithAuthorization(['get-config-by-tenant-id', productId], getTenantConfiguration(productId,{}), {
		enabled: productId != null,
		staleTime: staleTime
	});

	const [activeTab, setActiveTab] = useState('general');
	const [tenantErrorStatus, setTenantErrorStatus] = useState(null);

	useEffect(() => {
		const isTenantAutomation = productTenantAutomationEndpoint !== undefined;

		setIsDeletable(isTenantAutomation? (tenant.provisioningStatus === 'error' || tenant.provisioningStatus === 'terminated' || tenant.provisioningStatus === 'terminating'):true);
	}, [tenant, productTenantAutomationEndpoint]);

	const tabs = useMemo(() => [
		{
			id: 'general',
			label: 'General',
			component: <General tenant={tenant} productConfiguration={config}/>
		},
		{
			id: 'packages',
			label: `Packages (${tenant.packageIds ? Object.keys(tenant.packageIds).length:0})`,
			component: <Packages tenant={tenant} productConfiguration={config}/>
		},
	], [tenant, config]);

	const handleDeleteUsersRoles = (checked) => {
		console.log("yaniv checked before " + checked);
		setDeleteUsersRoles (checked);
		console.log("yaniv deleteUsersRoles after " + deleteUsersRoles);
	};

	const handleDeleteTerminate = () => {
		handleDrawerClose();
		const deleteTenantRes = mutateAsync(isDeletable ? deleteTenant(accountId, projectId, tenantId):terminateTenant(accountId, projectId, tenantId, deleteUsersRoles));

		toast.promise(deleteTenantRes,
			{
				pending: isDeletable? "Deleting tenant...":"Terminating tenant...",
				success: {
					render() {
						queryClient.invalidateQueries(['get-tenant-by-account-id-and-project-id', accountId, projectId]);
						! isDeletable && queryClient.invalidateQueries(['get-tenant-by-id', tenantId]);

						return isDeletable?'Deleted Successfully':'Terminated Successfully';
					}
				},
				error: {
					render({ data: error }) {
						const json = JSON.parse(error.message);
						const status = json.status;
						const message = json.message.message;

						if(status === 403) {
							return message;
						}

						return isDeletable?`Error Deleting Tenant: ${error.message}`:`Error Terminating Tenant: ${error.message}`;
					}
				}
			}, { position: toast.POSITION.TOP_CENTER }
		);
	};

	const description = <div>
		<div className={styles.usersHeaderProjectDescription}>
			{`Tenant type: ${tenant?.environment || 'unknown'}`}
			<div className={clsx(styles.separatorBlack, styles.separator)}/>
			{`Product: ${productDisplayName}`}
		</div>
	</div>;
	const created = <div className={styles.usersHeaderProjectCreated}>
		{`Created: ${tenant?.created ? moment(new Date(tenant.created)).format("DD MMM YYYY, h:mm A") : 'unknown'}`}
		<div className={styles.separator}/>
		{`Tenant ID: ${tenantId}`}
	</div>;

	const handleTabChange = (tabId) => {
		setActiveTab(tabId);
	};

	useOutsideAlerter(wrapperRef, handleDrawerClose, deleteTerminateVisible);

	const escFunction = useCallback((event) => {
		if (event.key === "Escape" && !deleteTerminateVisible) {
			handleDrawerClose();
		}
	}, [handleDrawerClose, deleteTerminateVisible]);
	const activeComponent = tabs.find(item => item.id === activeTab).component;

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	useEffect(() => {
		if (tenantIsError && tenantError.message) {
			setTenantErrorStatus(JSON.parse(tenantError.message)?.status);
		} else {
			setTenantErrorStatus(null);
		}
	}, [tenantIsError, tenantError]);

	function ErrorComponent() {
		return <div className={styles.errorComponentContainer}>
			<div className={styles.errorIconContainer}>
				<div className={styles.errorIconBackground}><ErrorBackground/></div>
				<div className={styles.errorIcon}><ErrorIcon/></div>
			</div>
			<div className={styles.errorTextTop}>There was a problem retrieving the information</div>
			<div className={styles.errorTextButton}>Please contact customer support</div>
		</div>;
	}

	const errTabs = [
		{
			id: 'general',
			label: 'General',
			component: <div className={styles.errorComponentContainer}>
				<div className={styles.errorIconContainer}>
					<div className={styles.errorIconBackground}><ErrorBackground/></div>
					<div className={styles.errorIcon}><ErrorIcon/></div>
				</div>
				<div className={styles.errorTextTop}>There was a problem retrieving the information</div>
				<div className={styles.errorTextButton}>Please contact customer support</div>
			</div>
		},
		{
			id: 'packages',
			label: "Packages",
			component: <div/>
		},
	];

	if (tenantIsError && tenantErrorStatus === 404) {
		return <div ref={wrapperRef} className={styles.drawerContainer}>
			<div className={styles.tenantHeader}>
				<div className={styles.tenantHeaderContent}>
					<div className={styles.displayName}>{`Tenant ID: ${tenantId}`}</div>
					<CloseIcon className={styles.btnClose} onClick={handleDrawerClose}/>
				</div>
			</div>
			<div className={styles.invalidPageContainer}>
				<div className={styles.errorIconContainer}>
					<div className={styles.errorIconBackground}><ErrorBackground/></div>
					<div className={styles.errorIcon}><ErrorIcon/></div>
				</div>
				<div className={styles.invalidPageText}>
						This tenant does no longer exist. <br/>Please refresh the page or call customer support if the problem persists.
				</div>
			</div>
		</div>;
	}

	return (
		<div ref={wrapperRef} className={styles.drawerContainer}>
			<div className={styles.tenantHeader}>
				<div className={styles.tenantHeaderContent}>
					<div>
						{(tenantIsLoading || configIsLoading) ?
							<div className={styles.loader}><Loader/></div> :
							<div>
								<div className={styles.displayName}>{tenant?.tenantName || "tenant:name:unknown"}</div>
								<div className={styles.description}>{description}</div>
								<div className={styles.created}>{created}</div>
							</div>
						}
					</div>
					<CloseIcon className={styles.btnClose} onClick={handleDrawerClose}/>
				</div>
				{(tenantIsLoading || configIsLoading) ?
					<div className={styles.loader}><Loader/></div> :
					(tenantIsError || configIsError) ? <div>
						<div className={styles.appTabs}>
							<AppTabs
								activeTab={activeTab}
								items={errTabs}
								onChange={handleTabChange}
								tapProps={{ sx:{
									fontSize: 14,
									minWidth: 68,
									'&.MuiTab-root': {
										padding: '30px 12px 8px 12px',
									}
								} }}
							/>
						</div>
						<div className={styles.tabContentContainer}>
							{<ErrorComponent/>}
						</div>
					</div>: <div>
						<div className={styles.appTabs}>
							<AppTabs
								activeTab={activeTab}
								items={tabs}
								onChange={handleTabChange}
								tapProps={{ sx:{
									fontSize: 14,
									minWidth: 68,
									'&.MuiTab-root': {
										padding: '30px 12px 0px 12px',
									}
								} }}
							/>

						</div>
						<div className={styles.tabContentContainer}>
							{Object.keys(tenant).length > 0 && config && activeComponent}
						</div>
					</div>
				}
			</div>
			{!(tenantIsLoading || configIsLoading) && !tenantIsError && !configIsError && <div className={isDeletable ? styles.deleteBtnContainer:styles.terminateBtnContainer}>

				<AppTooltip title={!allowsTenantDeletion ? "You dont have the permission to delete this tenant":tenant.provisioningStatus === 'terminating' && "Deletion will be available once termination is complete"} disabled={!(tenant.provisioningStatus === 'terminating'|| !allowsTenantDeletion)}>
					<div>
						<AppButton onClick={() => setDeleteTerminateVisible(true)} disabled={tenant.provisioningStatus === 'terminating' || !allowsTenantDeletion}>
							{isDeletable ? 'delete' : 'terminate'}
						</AppButton>
					</div>
				</AppTooltip>
			</div>}
			<AppDeleteConfirmation
				visible={deleteTerminateVisible}
				label={isDeletable? 'delete':'terminate'}
				value={'someValue'}
				handleDelete={handleDeleteTerminate}
				handleClose={() => setDeleteTerminateVisible(false)}
				overrideText={`Are you sure you want to ${isDeletable? 'delete':'terminate'} this tenant?`}
				title={isDeletable? 'Delete':'Terminate'}
				includeCheckBox={ isDeletable ? false:true}
				checkBoxText={"Remove user roles for this tenant?"}
				onIncludeCheckBoxChange = {handleDeleteUsersRoles}
				overrideClasses={ { actionButton: isDeletable ? styles.deleteConfirmationBtb:styles.terminateConfirmationBtb }}
			/>
		</div>
	);
}
