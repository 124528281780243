import * as React from 'react'

function SvgCheckboxSelected(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.063em'
      height='1.5em'
      viewBox='0 0 13 17'
      fill='#3381FF'
      {...props}
    >
      <path
        d='M10.937 14.875c.725 0 1.313-.588 1.313-1.312V3.938c0-.725-.588-1.313-1.313-1.313H1.312C.588 2.625 0 3.213 0 3.938v9.625c0 .724.588 1.312 1.312 1.312h9.625zm-6.215-2.681L1.878 9.35a.44.44 0 010-.619l.619-.618a.438.438 0 01.619 0l1.915 1.915 4.103-4.103a.44.44 0 01.62 0l.618.619c.17.17.17.448 0 .618L5.34 12.194a.44.44 0 01-.62 0z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgCheckboxSelected
