import React, { Fragment } from "react";

export default function Handler({handler, children}) {
    if(!handler) {
        return children();
    }
    const Component = handler;
    return <Component>
            {cb => children(cb)}
        </Component>
    }
