import Checkbox from "../../Checkbox"
import {useID} from "../../../Util/ID"
import styles from "./Item.module.scss"
import TableOptions from "../TableOptions"
import React, {useContext, useRef} from "react"
import { useDrag } from "../../../Util/Drag"
import cslx from "clsx"
import {useHover} from "../../../../../util/hover"

export default function Item({index, id, label, fixed, visible=true}) {
    const baseID = useID();
    const ref = useRef();
    const [hover] = useHover(ref);
    const [, setItems] = useContext(TableOptions.Items);
    const {dragId, ...drag} = useDrag({id, setItems});
    const setChecked = checked => {
        setItems(items => {
            const index = items.findIndex(item => item.id === id);
            if(index !== -1) {
                items[index] = {...items[index], visible:checked};
            }
            return [...items];
        });
    };
    return <div data-cy={baseID + "tableOptionsItem-" +id} className={styles.root}>
        <div className={styles.index}>
            {index+1}
        </div>
        <div key={id} ref={ref} {...drag} className={cslx(styles.container, dragId === id && styles.dragging, !dragId && hover && styles.hover)}>
            <Checkbox disabled={fixed} checked={visible} setChecked={setChecked} label={label} />
        </div>
    </div>
}
