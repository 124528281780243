import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useUsersInvitationsStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import produce from "immer";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@mui/icons-material/Close";
import { Table } from "@ip-synamedia/syna-ui-library";

const useStyles = makeStyles(() => {
	const rowHeight = 71;
	const headerHeight = 38;
	const footerHeight = 0;

	return {
		tableContainer: {
			height: props => {
				return `calc(${props.productsSize > 0 ? props.productsSize * rowHeight + headerHeight + footerHeight : 0}px)`;
			}
		},
		tableHeader: {
			fontSize: 14,
			fontWeight: "bold",
		},
		MultiselectContainer:{
			position: "absolute",
			'&:hover': {
				cursor: 'pointer',
			},
		},
		closeIcon: {
			padding: 4,
			color: '#8F8F8F',
			'&:hover': {
				padding: 3,
				color: '#3380FF',
			},
		}
	};
});
const multiselectStyle = {
	chips: {
		background: "#FAFAFA",
		color: 'black',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		borderRadius: 3,
		padding: '3px 0px 0px 0px'
	},
	searchBox: {
		display: 'flex',
		borderRadius: 4,
		padding: '0px 40px 0 15px',
		height: 24,
		width: 500
	},
	inputField: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		width: 40,
		padding: '2px 0px 7px 0px',
	},
	multiselectContainer: {
	},
	optionContainer: {
		position: 'absolute',
		top: -48,
		height: 70,
		width: 500,
		overflowY: "scroll",
		backgroundColor: 'white',
	},
	optionListContainer:{

	},
	option: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'small',
		fontWeight: 400,
		fontSize: 14,
		margin: 0,
		padding: 5,
		borderRadius: 4,
	},

};

function TableHeader({ headerName }) {
	const styles = useStyles();

	return (
		<div className={styles.tableHeader}>{headerName}</div>
	);
}

export default function ProductsRoles({ projectId, productRoles, isProductAdmin =false, isEdit=false, initialState={}, parentFirstRender = false, setParentFirstRender = false }) {
	const styles = useStyles({ productsSize: productRoles.length??0 });

	const [productRolesByProjectId, setProductRolesByProjectId] = useUsersInvitationsStore(state => [state.productRolesByProjectId, state.setProductRolesByProjectId], shallow);
	const [productAdminRolesIds, setProductAdminRolesIds] = useUsersInvitationsStore(state => [state.productAdminRolesIds, state.setProductAdminRolesIds], shallow);
	const [selectedProductsIds, setSelectedProductsIds] = useState(new Set());
	const [firstRender, setFirstRender] = useState(true);
	const [currProductsRoles, setCurrProductsRoles] = useState({});

	useEffect(() => {
		if(!projectId || !productRoles) {
			setCurrProductsRoles(undefined);
			setFirstRender(false);
		}else if(!isProductAdmin) {
			if(parentFirstRender) {
				let _filteredRoles = {};

				productRoles.forEach(product => {
					_filteredRoles[product.id] = isEdit && initialState?.productRolesByProjectId ? initialState.productRolesByProjectId[projectId].filter(role => role.productId === product.id): [];
				});
				setCurrProductsRoles(_filteredRoles);
				setFirstRender(false);
			}else if(firstRender && productRolesByProjectId?.[projectId]) {
				let _filteredRoles = {};

				productRoles.forEach(product => {
					_filteredRoles[product.id] = productRolesByProjectId[projectId].filter(role => role.productId === product.id);
				});
				setCurrProductsRoles(_filteredRoles);
				setFirstRender(false);
			}
		}
	}, [productRoles, projectId, initialState, isEdit, isProductAdmin, parentFirstRender, productRolesByProjectId, firstRender]);

	useEffect(() => {

		projectId && productRolesByProjectId?.[projectId] && setSelectedProductsIds(new Set([...productRolesByProjectId[projectId].map(role => {
			return role?.productId ? role.productId: role;
		})]
		));
	}, [productRolesByProjectId, projectId]);

	const columns = [
		{
			id: 'label',
			label: 'Product',
			searchable: false,
			filterable: false,
			sortable: false,
			widthUnits: 0.25,
			elementCb: (productRole) => <TableHeader headerName={productRole.id}/>,
		},
		{
			id: 'roles',
			label: 'Roles',
			searchable: false,
			filterable: false,
			sortable: false,
			elementCb: (productRole) => {

				return (
					<Roles
						row={productRole}
					/>
				);
			},
		},
	];

	const handleSelectionChange = ({ id, data, productId }) => {
		setParentFirstRender && setParentFirstRender(false);
		if(!isProductAdmin) {

			// update selectedProductsIds
			if (!selectedProductsIds.has(productId)) {
				selectedProductsIds.add(productId);
				setSelectedProductsIds(new Set(selectedProductsIds));
			}
			// update currProductsRoles
			setCurrProductsRoles(produce(currProductsRoles, draft => {
				draft[productId] = [...draft[productId], { ...data, productId: productId }];
			}));

			// update ProductRolesByProjectId
			setProductRolesByProjectId(produce(productRolesByProjectId, draft => {
				draft[projectId] = [].concat.apply([] ,Object.values(produce(currProductsRoles, draft => {
					draft[productId] = [...draft[productId], { ...data, productId: productId }];
				})));

			}));
		}else {
			if(!productAdminRolesIds.has(id)) {
				productAdminRolesIds.add(id);
				setProductAdminRolesIds(new Set(productAdminRolesIds));
			}
		}

	};

	const handleRemoveChange = ({ id, data, productId }) => {
		setParentFirstRender && setParentFirstRender(false);

		// update selectedProductsIds
		if(!isProductAdmin) {
			if (selectedProductsIds.has(productId) && data.length === 0) {
				selectedProductsIds.delete(productId);
				setSelectedProductsIds(new Set(selectedProductsIds));
				setCurrProductsRoles(produce(currProductsRoles, draft => {
					draft[productId] =[...draft[productId]].filter(role => role.id !== data.id);
				}));
			}

			// update currProductsRoles
			setCurrProductsRoles(produce(currProductsRoles, draft => {
				draft[productId] = [...draft[productId]].filter(role => role.id !== data.id);
			}));

			// update ProductRolesByProjectId
			setProductRolesByProjectId(produce(productRolesByProjectId, draft => {
				draft[projectId] = [].concat.apply([], Object.values(produce(currProductsRoles, draft => {
					draft[productId] = [...draft[productId]].filter(role => role.id !== data.id);
				})));
			}));

		}else {
			if(productAdminRolesIds.has(id)) {
				productAdminRolesIds.delete(id);
				setProductAdminRolesIds(new Set(productAdminRolesIds));
			}
		}

	};

	function Roles(props) {
		const { row } = props;

		const options = row.roles.map(role => role.id);

		return (
			<div className={styles.MultiselectContainer}>
				<Multiselect
					options={options}
					selectedValues={(isProductAdmin) ?options.filter(option => productAdminRolesIds.has(option)) :currProductsRoles?.[row.id]? options.filter(opt =>  currProductsRoles[row.id].map(role => role.id).includes(opt)):[]}
					isObject={false}
					avoidHighlightFirstOption={true}
					placeholder='Select'
					hidePlaceholder={true}
					onSelect={(data ,id) => handleSelectionChange({ id, data: row.roles.find(role => role.id === id), productId:row.id })}
					onRemove={(data, id) => handleRemoveChange({ id, data: row.roles.find(role => role.id === id), productId:row.id })}
					displayValue={"displayName"}
					showCheckbox={true}
					showArrow={true}
					customCloseIcon={<CloseIcon fontSize='small' className={styles.closeIcon}/>}
					style={multiselectStyle}
				/>
			</div>
		);
	}

	return (
		<div className={styles.tableContainer}>
			<Table
				checkboxSelection={false}
				tableData={productRoles}
				filterEnabled={false}
				searchEnabled={false}
				tableHeaders={columns}
				tableOptions={false}
				tableActions={false}
				sort={false}
				pagination={false}
				resizeHandle={false}
			/>
		</div>
	);
}
