import { useCallback, useState } from "react";

export default function useStateWithValidation(initialValue, validationFn) {
	const [state, setState] = useState(initialValue);
	const [isValid, setIsValid] = useState(() => validationFn(state));

	const onChange = useCallback(nextState => {
		const value = typeof nextState === "function" ? nextState(state) : nextState;

		setState(value);
		setIsValid(validationFn(value));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validationFn]);

	return [state, onChange, isValid];
}
