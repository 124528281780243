import AppDialog from "../../../../AppDialog";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useStateWithValidation from "../../../../../custom-hooks/form-hooks/useStateWithValidation";
import { createProject, updateProject } from "../../../../../external-apis";
import { makeStyles, MenuItem } from "@material-ui/core";
import AppInput from "../../../../AppInput";
import AppButton from "../../../../AppButton";
import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import { useQueryClient } from "react-query";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => {
	return {
		content: {
			width: 400
		},
		textContainer: {
			width: '352px',
			height: '30px',
			marginBottom: 48,
			marginLeft: 12
		},
		buttonsContainer: {
			display: "flex",
			height: 30,
			margin: '20px 15px 0 auto',
		},
		button:{
			marginLeft: 10,
		},
		selectContainer: {
			marginTop: 12,
			width: 300
		},
		fieldLabel:{
			fontWeight: 400,
			fontSize: 14,
			marginBottom: 0
		}
	};
});

export default function AddEditProjectDialog({ visible, setVisible, accountId, projectId = null, projectName = '', projectDescription = '', customerId, customersData ,reseller, errorInUpdate }) {
	const styles = useStyles();

	const [name, setName, isValidName] = useStateWithValidation(projectName, name => name != null && name.trim() !== '');
	const [description, setDescription, isValidDescription] = useStateWithValidation(projectDescription, description => description != null && description.trim() !== '');
	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const [firstRender, setFirstRender] = useState(true);
	let [customerName, setCustomerName] = useState( (customersData?.find(customer => customer.id === customerId))?.name );
	const orgCustomerName = useRef(customerName);
	const editProject = projectId != null;
	const noCustomerName = "No customer selected";
	const editWarnMsg = "The project`s name has changed. During the update, the project`s tenants will be updated.\n" +
		"Ensure that all tenants are in an active state";
	const editErrorMsg = "The previous update failed. Please contact customer service and update again";

	if (!customerName) {
		setCustomerName (noCustomerName);
	}

	if (customersData && !customersData?.find(p => p.name === noCustomerName)) {
		customersData.push({ "name": noCustomerName, "id": "fakeId" });
	}

	useEffect(() => {
		setCustomerName((customersData?.find(customer => customer.id === customerId))?.name );
	}, [customerId, setCustomerName, customersData] );

	const handleClose = () => {

		setVisible(false);
		projectName === '' ? setName('') : setName(projectName);
		projectDescription === '' ? setDescription('') : setDescription(projectDescription);
		setCustomerName(orgCustomerName.current);
		setFirstRender(true);
	};

	const handleSubmit =  () => {

		let customer = customersData?.find(customer => customer.name === customerName);

		setFirstRender(false);
		if(!isValidName || !isValidDescription) {

			return;
		}
		const params = {
			'projectName': name,
			'description': description,
			...(customer && customer?.id  && customer?.id !=="fakeId" && { 'customerId' :customer.id })
		};

		createReq(params);
	};

	const createReq = async (params) => {
		handleClose();

		let addupdateCreateProject;

		if (projectId == null) {
			addupdateCreateProject = mutateAsync(createProject(accountId, { ...params }));
		} else {
			addupdateCreateProject = mutateAsync(updateProject(accountId, projectId, { ...params }));
		}

		const id = toast.loading(`${ editProject ? 'Updating' : 'Creating' } Project...`,  {
			position: toast.POSITION.TOP_CENTER,
			autoClose: false });

		try {
			await addupdateCreateProject;
			queryClient.invalidateQueries(['projects', accountId]);
			toast.update(id, { render: editProject ? 'Updated' : 'Created', type: "success", isLoading: false,position: "top-center",
				autoClose: 5000 });
			setVisible(false);
		}catch (e) {
			const error = JSON.parse(e.message);

			if (error.status === 422) {
				setVisible(false);
				queryClient.invalidateQueries(['projects', accountId]);
				toast.update(id, { render: error.message, type: "warning", isLoading: false,position: "top-center",
					autoClose: 5000 });
			}
			else {
				setVisible(false);
				queryClient.invalidateQueries(['projects', accountId]);
				toast.update(id, { render: JSON.stringify(error?.message), type: "error", isLoading: false,position: "top-center",
					autoClose: 5000 });
			}
		}
	};

	return(
		<AppDialog
			open={visible}
			onClose={handleClose}
			title={`${ editProject ? 'Edit' : 'Add' } Project`}
			overrideClasses={{ content: styles.content }}
		>
			<div className={styles.textContainer}>
				<AppInput
					autoFocus
					value={name}
					name={'projectName'}
					label='Project Name'
					onChange={ setName }
					placeholder={ name === '' ? 'Enter Your Text...' : name }
					error={!isValidName && !firstRender}
					errorMessage={'Please insert a value'}
					required={true}
					warn={editProject}
					enforceWarn={errorInUpdate}
					warnMessage={errorInUpdate ? editErrorMsg: editWarnMsg }
				/>
			</div>
			<div className={styles.textContainer}>
				<AppInput
					value={description}
					name={'description'}
					label='Description'
					onChange={setDescription}
					placeholder={'Enter Your Text...'}
					error={!isValidDescription && !firstRender}
					errorMessage={'Please insert a value'}
					required={true}
				/>
			</div>

			{reseller && <div className={styles.textContainer}>
				<div className={styles.fieldLabel}>
					Reseller Customer
				</div>

				<TextField
					style={{
						width: "330px",
						borderRadius: "8px",
						marginTop: 2,
					}}
					label={ customerName ? '' : "No customer selected"}
					InputLabelProps={{
						style: { color: 'rgba(0, 0, 0, 0.87)' },
					}}
					id="select-customer"
					select
					margin='dense'
					value={ customerName }
					onChange={event => {setCustomerName(event.target.value);}}
					variant='outlined'
				>
					{customersData?.map((customer, index) => {
						return (
							<MenuItem key={index} value={customer?.name} >
								{customer?.name}
							</MenuItem>
						);
					})}
				</TextField>
			</div> }

			<div className={styles.buttonsContainer}>
				<AppButton variant={'outlined'} onClick={handleClose}>CANCEL</AppButton>
				<AppButton variant={'contained'} onClick={handleSubmit} overrideClasses={{ button: styles.button }}>{ editProject ? 'SAVE' : 'ADD PROJECT' }</AppButton>
			</div>
		</AppDialog>
	);
}
