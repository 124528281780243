import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SchedulerPopup from "./SchedulerPopup";

const useStyles = makeStyles({
	root: {
		backgroundColor: "#ffffff",
		boxShadow: "0 0 6px 0 rgba(41, 41, 41, 0.25)",
		border: "solid 1px transparent",
		borderRadius: 10,
		overflow: "auto",
		width: 300,
		height: 450,
		margin: 20,
		display: "flex",
		flexDirection: "column",
		float: "left",
		textAlign: "center",
		position: "relative",
	},
	media: {
		height: 120,
		borderRadius: 10
	},
	mediaContainer: {
		width: 'calc(100% - 60px)',
		margin: 30,
		borderRadius: 10
	},
	image: {
		margin: 40,
		marginBottom: 0,
		maxWidth: "inherit",
		maxHeight: 150
	},
	name: {
		marginTop: 50,
		fontSize: 20,
		fontWeight: "bold"
	},
	description: {
		marginTop: 10
	},
	button: {
		fontSize: 12,
		textTransform: "none",
		marginTop: 10,
		margin: "0",
		width: 120,
		color: "#3381FF",
		backgroundColor: "white",
		border: "1px solid #3381FF",
		'&:hover': {
			backgroundColor: "#004ecc",
			color: 'white'
		}
	},
	buttonsContainer: {
		position: "absolute",
		bottom: 20,
		width: "100%",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
	}
});

export default function Product(props) {
	const classes = useStyles();
	const { displayName, description, image, informationPortal } = props;

	const [showScheduler, setShowScheduler] = useState(false);

	return (
		<>
			<Card className={classes.root}>
				<CardActionArea>
					<div className={classes.mediaContainer}>
						<CardMedia
							className={classes.media}
							image={image}
						/>
					</div>
					<CardContent>
						<Typography gutterBottom variant="h5" component="h2">
							{displayName}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							{description}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions disableSpacing classes={{ root: classes.buttonsContainer }}>
					<Button classes={{ root: classes.button }} size="small" color="primary" variant={"outlined"}
						onClick={() => informationPortal && window.open(informationPortal)}>Learn More</Button>
					<Button classes={{ root: classes.button }} size="small" color="primary" variant={"outlined"}
						onClick={() => setShowScheduler(true)}>Schedule a Call</Button>
				</CardActions>
			</Card>

			<SchedulerPopup visible={showScheduler} setVisible={setShowScheduler}/>
		</>
	);
}
