import React, { useEffect, useState } from "react";
import AppTooltip from "../../../../../../AppTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import Parameter from "./Parameter";
import { makeStyles } from "@material-ui/core/styles";
import { getShortStr, getTextWidth } from "../../../../Projects/TenantDrawer/utils";

const useStyles = makeStyles((theme) => ({
	featureContainer: {
		display: 'flex',
		flexDirection: 'column',
		margin: '12px 0',
	},
	featureHeader: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: props => `calc(${ props.longestFeatureLength + 55}px)`
	},
	labelContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	featureLabel: {
		fontWeight: 300,
	},
	infoIcon: {
		marginLeft: 3,
		color: '#8F8F8F',
		'&:hover': {
			color: '#3380FF',
		},
	},
	separator: {
		border: '1px solid #EFEFEF',
	},
	switch: {
		position: 'absolute',
		right: 0,
		background: 'rgba(143, 143, 143, 1)',
		borderRadius: '10px 10px',
		border: 0,
		height: 16,
		width: 30,
	},
	switchEnabled: {
		background: 'rgba(51, 128, 255, 1)',
	},
	switchToggle: {
		height: 12,
		width: 12,
		borderRadius: '50%',
		color: 'white',
		backgroundColor: 'white',
		background: 'white',
		zIndex: 2,
		display: 'inline-block',
		transform: 'translateX(-7px)'
	},
	switchToggleEnabled: {
		transform: 'translateX(7px)'
	},
	parametersContainer: {
		margin: '12px 0 0 188px'
	},
	parameterCard: {
		flexDirection: 'row',
		alignItems: 'center',
		border: "none",
		boxShadow: 'none',
		margin: '24px 0 0 0',
		width: props => `calc(${ props.longestParameterLength + 30}px)`
	},
	parameterHeader: {
		margin: 0,
		fontWeight: 400,
	},
	parameterParameter: {
		position: 'absolute',
		left: props => `calc(${ props.longestParameterLength + 230}px)`,
		margin: '0px 12px',
	},
	parameterInput: {
		width: 200,
	}
}));

export default function Feature( { featureKey, featureOptions, featureConfigParams, featureIds, addFeatureIds, setFeatureIds, handleFeatureSelectionChange, longestFeatureLength, maxParameterLabelWidth, editTenant } ) {
	const configOptions = featureOptions.config;
	const isEmptyConfiguration = configOptions == null || Object.keys(configOptions).length === 0;

	const maxLabelWidth = 161;

	longestFeatureLength = Math.min( longestFeatureLength, maxLabelWidth );

	let longestParameterLength = 0;

	configOptions && Object.entries(configOptions).forEach(([parameter, parameterConfig]) => {
		longestParameterLength = Math.max(longestParameterLength, getTextWidth(parameterConfig.label ?? parameter));
	});

	longestParameterLength = Math.min( longestParameterLength, maxParameterLabelWidth );

	const styles = useStyles({ longestFeatureLength: longestFeatureLength, longestParameterLength: longestParameterLength });

	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		if (featureIds && featureIds.includes(featureKey)) {
			setEnabled(true);
		}
	}, [featureIds, featureKey]);

	const handleChange = (enabled) => {
		setEnabled(enabled);

		if ( enabled ) {
			addFeatureIds(featureKey);
		} else {
			const newFeatureIds = [...featureIds];

			newFeatureIds.splice(featureIds.indexOf(featureKey), 1);
			setFeatureIds(newFeatureIds);
		}
	};

	const label = featureOptions.label ?? featureKey;
	const isLabelLong = getTextWidth(label) > longestFeatureLength;

	return <div className={styles.featureContainer}>
		<div className={styles.featureHeader}>
			<div className={styles.labelContainer}>
				<AppTooltip title={label} disabled={!isLabelLong}>
					<div className={styles.featureLabel}>{ isLabelLong ? getShortStr(label, longestFeatureLength) + '...': label }</div>
				</AppTooltip>
				{ featureOptions.description != null && <AppTooltip title={featureOptions.description}>
					<InfoOutlinedIcon sx={{ width: 15, height: 15 }} className={styles.infoIcon}/>
				</AppTooltip>}
			</div>
			<Switch
				checked={enabled}
				onChange={handleChange}
				className={clsx(styles.switch, enabled && styles.switchEnabled)}
			>
				<span
					className={clsx(styles.switchToggle, enabled && styles.switchToggleEnabled)}
				/>
			</Switch>
		</div>
		{ enabled && !isEmptyConfiguration && <div className={styles.parametersContainer}>
			<div className={styles.separator}/>
			{ Object.entries(configOptions).map(([parameter, parameterConfig]) => {
				const { enum: allowedValues, label, description, required, type, modifiable } = parameterConfig;
				const selectedValue = featureConfigParams[parameter]?.value;

				return <Parameter
					key={parameter}
					label={label ?? parameter}
					type={type}
					description={description}
					parameter={parameter}
					selectedValue={selectedValue}
					allowedValues={allowedValues}
					handleSelectionChange={(value, parameter, type) => handleFeatureSelectionChange(featureKey, value, parameter, type)}
					required={required}
					longestLabelLength={longestParameterLength}
					disabled={editTenant && modifiable === false}
					overrideClasses={{
						card: styles.parameterCard,
						header: styles.parameterHeader,
						parameter: styles.parameterParameter,
						input: styles.parameterInput }}
				/>;
			})}
		</div>}
	</div>;
}
