import clsx from "clsx"
import React from 'react'

export function getHighlightedText({styles, text, highlight}) {
    if(!highlight) {
        return text;
    }
    const parts = `${text}`.split(new RegExp(`(${highlight})`, 'gi'));
    if(parts.length <= 1) {
        return text;
    }
    const elements = parts.map((part, i) => {
        const matches = part.toLowerCase() === highlight.toLowerCase();
        return <span key={i} className={clsx(styles.part, matches && styles.match)}>
            { part }
        </span>;
    });
    return elements;
}
