import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import { getQueryParams } from "../../../utils/router-utils";

const InvitationPage = () => {
	const { loginWithRedirect } = useAuth0();
	const { invitation, organization, finish } = getQueryParams();

	const [error, setError] = useState(null);

	const acceptInvitation = useCallback(async () => {

		if (!invitation || !organization) {
			setError(new Error('Missing invitation params...'));

			return;
		}

		loginWithRedirect({
			organization,
			invitation,
			appState: {
				returnTo: `/invite?finish=true`
			}
		});
	}, [invitation, loginWithRedirect, organization]);

	useEffect(() => {
		if (finish !== 'true') acceptInvitation();
	}, [acceptInvitation, finish]);

	if (error) return <div>{error.message}</div>;

	// The invitation process finished -> redirect to the home page
	if (finish === 'true') return <Redirect to={`/home`}/>;

	return null;
};

export default InvitationPage;

