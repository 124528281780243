import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 14 15'
      {...props}
    >
      <path
        d='M13.634 14.654l.27-.27a.328.328 0 000-.465l-3.527-3.528a.328.328 0 00-.232-.095h-.282a5.687 5.687 0 10-.317.319v.28c0 .087.035.17.095.232l3.528 3.527a.328.328 0 00.465 0zM5.687 11.25A4.809 4.809 0 01.875 6.438a4.81 4.81 0 014.812-4.813A4.81 4.81 0 0110.5 6.438a4.81 4.81 0 01-4.813 4.812z'
        fill='#BFBFBF'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgSearch
