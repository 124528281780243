import React, { useState, useEffect } from "react"
import styles from "./Toaster.module.scss"
import Clear from '../../iconComponents/Clear'
import TagActive from '../../iconComponents/TagActive'
import Warning from '../../iconComponents/Warning'
import Error from '../../iconComponents/Error'
import Info from '../../iconComponents/Info'
import clsx from "clsx"
import { render, unmountComponentAtNode } from 'react-dom';

const icons = {
    "error": <Error className={ styles.icon } />,
    "warning": <Warning fill={ '#ffc41c' } className={ styles.icon } />,
    "info": <Info fill={ '#3380ff' } className={ styles.icon } />,
    "success": <TagActive className={ styles.icon } />
}

export const Toaster = ({ severity, message, permanent = false, timer = 5000, show = true, setShow = null, isFunction = false, fullWidth = false, showClose = true }) => {

    const [visible, setVisible] = useState(show)

    const handleClick = () => {
        setVisible(false);
        setShow && setShow(false);
        isFunction && removeBodyClass();
    }

    useEffect(() => {
        if (!permanent) {
            const timeoutId = setTimeout(() => {
                handleClick()
            }, timer);
            return () => {
                clearTimeout(timeoutId);
            }
        }
    }, []);

    return (
        <div data-testid='Toaster-test' className={ clsx(styles.toast, visible && styles.visible, fullWidth && styles.fullWidthStyle) }>
            <div className={ clsx(styles.border, styles[severity]) } />
            <div className={ styles.content }>
                <div className={ styles.iconMessage }>
                    <div data-testid='icon-test' className={ styles.iconContainer }>
                        { icons[severity] }
                    </div>
                    <div className={ styles.message }>{ message }</div>
                </div>
                <div style={ { flex: 1 } } />
                { showClose ?
                    <Clear data-testid={ 'clear' } className={ styles.close } onClick={ () => handleClick() } /> : <div></div> } 
            </div>
        </div>
    )
}

const changePlacement = (placement, target, fullWidth) => {
    switch (placement) {
        case "topLeft":
            target.className = clsx(styles.topLeftStyle, fullWidth && styles.fullWidthStyle)
            break
        case "topRight":
            target.className = clsx(styles.topRightStyle, fullWidth && styles.fullWidthStyle)
            break
        case "bottomLeft":
            target.className = clsx(styles.bottomLeftStyle, fullWidth && styles.fullWidthStyle)
            break
        case "bottomRight":
            target.className = clsx(styles.bottomRightStyle, fullWidth && styles.fullWidthStyle)
            break
        case "center":
            target.className = clsx(styles.centerStyle, fullWidth && styles.fullWidthStyleforCenter)
            break
        case "topCenter":
            target.className = clsx(styles.topCenterStyle, fullWidth && styles.fullWidthStyleforCenter)
            break
        case "bottomCenter":
            target.className = clsx(styles.bottomCenterStyle, fullWidth && styles.fullWidthStyleforCenter)
            break
        default:
            target.className = clsx(styles.bottomRightStyle, fullWidth && styles.fullWidthStyle)
            break
    }
}

const createElementToast = (properties) => {
    let divTarget = document.getElementById("synalibfunctiontoast");
    if (divTarget) {
        changePlacement(properties.placement, divTarget, properties.fullWidth)
        render(<Toaster { ...properties } isFunction={ true } />, divTarget);
    } else {
        divTarget = document.createElement("div");
        divTarget.id = "synalibfunctiontoast";
        document.body.appendChild(divTarget);
        changePlacement(properties.placement, divTarget, properties.fullWidth)
        render(<Toaster { ...properties } isFunction={ true } />, divTarget);
    }
};

const removeBodyClass = () => {
    const target = document.getElementById("synalibfunctiontoast");
    if (target) {
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target);
    }
};

export const toast = (props) => {
    return createElementToast(props);
};