import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useCreateTenantStore, useProjectsStore } from "../../../../../../../state-management";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import productIconImg from '../../../../../../../assets/icons/tenants/PRODUCT.png';
import clsx from "clsx";
import { useQueryWithAuthorization } from "../../../../../../../custom-hooks";
import {
	getProducts as apiGetProducts,
	getProjectsByAccountId, getTenantByAccountIdAndProjectId, getTenantById,
} from "../../../../../../../external-apis";
import AppTooltip from "../../../../../../AppTooltip";
import Loader from "../../../../../../Loader";
import shallow from "zustand/shallow";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 30,
		top: 60,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 14,
		overflowY: 'auto',
		height: 560,
		width: 823,
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		border: "1px solid #C2C2C2",
		borderRadius: 8,
		boxShadow: '2px 2px 10px #C2C2C2',
		width: 802,
		margin: '12px 0',
	},
	header: {
		margin: 12,
		marginBottom: 6,
	},
	labelGroup: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: '0 25px',
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: 100,
		margin: 12.5,
		marginBottom: 12,
	},
	iconContainer: {
		position: 'absolute',
		top: 10,
		left: 35,
		width: 26,
		height: 26,
	},
	iconContainerSelected: {
		color: '#3380FF',
	},
	icon: {
		width: '100%',
		height: '100%',
	},
	iconDisabled: {
		opacity: 0.5
	},
	labelBox: {
		position: 'relative',
		width: 100,
		height: 50,
		border: "1px solid #C2C2C2",
		borderRadius: 4,
		boxShadow: '1px 1px 1px #C2C2C2',
		overflow: 'hidden',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: 'rgba(51, 129, 255, 0.1)',
		},
	},
	labelBoxSelected: {
		borderColor: '#3380FF',
		borderWidth: 2,
	},
	labelBoxDisabled: {
		borderColor: '#C2C2C2',
		'&:hover': {
			cursor: 'default',
			backgroundColor: 'white',
		},
	},
	labelText: {
		lineHeight: '45px',
		textAlign: 'center',
		color: 'black',
		padding: '0 5px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	labelTextSelected: {
		color: '#3380FF',
	},
	labelTextDisabled: {
		color: '#C2C2C2',
	},
	productLabelText: {
		width: 100,
		textAlign: 'center',
		color: 'black',
		padding: '0 5px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	productLabelTextDisabled: {
		color: '#8F8F8F',
	},
	selectedIcon: {
		position: 'absolute',
		transform: 'scale(.6)',
		color: '#3380FF',
		right: -3,
		top: -3,
	},
	selectedIconDisabled: {
		color: '#C2C2C2',
	},
}));

const mapProjectsToUiElement = (projects) => {

	return (Object.values(projects) || []).reduce((projectsByIds, currentProject) => {

		const { id, displayName, description } = currentProject;

		return {
			...projectsByIds,
			[id]: {
				description,
				label: displayName
			}
		};
	}, {});
};

function ProjectElement({ projectId, label, description, selected, editTenant, setProjectId, setProductId, setEnvironment, setCurrentStep, setNextButtonDisabled }) {
	const styles = useStyles();

	const handleOnClick = () => {
		if (!selected && !editTenant) {
			setProjectId(projectId);
			setProductId(null);
			setEnvironment(null);
			setCurrentStep(1);
			setNextButtonDisabled(true);
		}
	};

	return (<div className={styles.labelContainer}>
		<AppTooltip title={description} disabled={description == null}>
			<div className={clsx(styles.labelBox, selected && styles.labelBoxSelected, editTenant && styles.labelBoxDisabled)} onClick={handleOnClick}>
				{ selected && <CheckCircleIcon className={clsx(styles.selectedIcon, editTenant && styles.selectedIconDisabled)}/> }
				<div className={clsx(styles.labelText, selected && styles.labelTextSelected, editTenant && styles.labelTextDisabled)}>
					{label}
				</div>
			</div>
		</AppTooltip>
	</div>);
}

function ProductElement({ productId, label, selected, disabled, setProductId, setEnvironment, setCurrentStep, setNextButtonDisabled }) {
	const styles = useStyles();
	const resetProductConfigParams = useCreateTenantStore(state => state.resetProductConfigParams, shallow);
	const resetPackageConfigParams = useCreateTenantStore(state => state.resetPackageConfigParams, shallow);
	const resetFeatureConfigParams = useCreateTenantStore(state => state.resetFeatureConfigParams, shallow);
	const resetPackagesFeatureIds = useCreateTenantStore(state => state.resetPackagesFeatureIds, shallow);
	const resetFullListOfPackagesFeatureIds = useCreateTenantStore(state => state.resetFullListOfPackagesFeatureIds, shallow);
	const resetFullListOfFeatureIds = useCreateTenantStore(state => state.resetFullListOfFeatureIds, shallow);
	const resetPackagesFeaturesConfigParams = useCreateTenantStore(state => state.resetPackagesFeaturesConfigParams, shallow);
	const initiatePackagesIds = useCreateTenantStore(state => state.initiatePackagesIds, shallow);
	const initiateFeaturesIds = useCreateTenantStore(state => state.initiateFeaturesIds, shallow);

	const handleOnClick = () => {
		if (!selected && !disabled) {
			setProductId(productId);
			setEnvironment(null);
			setCurrentStep(2);
			setNextButtonDisabled(true);
			resetProductConfigParams();
			resetPackageConfigParams();
			resetFeatureConfigParams();
			resetPackagesFeatureIds();
			resetFullListOfPackagesFeatureIds();
			resetFullListOfFeatureIds();
			resetPackagesFeaturesConfigParams();
			initiatePackagesIds();
			initiateFeaturesIds();
		}
	};

	return (<div className={styles.labelContainer}>
		<div className={clsx(styles.labelBox, selected && styles.labelBoxSelected, disabled && styles.labelBoxDisabled)} onClick={handleOnClick}>
			{ selected && <CheckCircleIcon className={clsx(styles.selectedIcon, disabled && styles.selectedIconDisabled)}/> }
			<div className={styles.iconContainer}>
				<img className={clsx(styles.icon, disabled && styles.iconDisabled)} src={productIconImg} alt={''}/>
			</div>
		</div>
		<div className={clsx(styles.productLabelText, disabled && styles.productLabelTextDisabled)}>
			{label}
		</div>
	</div>);
}

function ProductsCard( { accountId, projectId, editTenant, products, selectedProductId, existingProductsIds, setProductId, setEnvironment, setCurrentStep, setNextButtonDisabled } ) {
	const [initiateExistingProductsIds, addExistingProductsIds] = useCreateTenantStore(state => [state.initiateExistingProductsIds, state.addExistingProductsIds], shallow);

	const isProductDisabled = (value) => {
		return existingProductsIds.length? existingProductsIds.includes(value) : false;
	};

	const {
		data: tenantsData = [],
		isError: tenantsIsError,
		isLoading:tenantsIsLoading,
		error: tenantsError
	} = useQueryWithAuthorization(['get-tenant-by-account-id-and-project-id', accountId, projectId], getTenantByAccountIdAndProjectId(accountId,  projectId, {}, 'tenantId,productId,projectId' ), {
		enabled: accountId != null,
		staleTime: staleTimeout
	});

	useEffect( () => {
		initiateExistingProductsIds();
		tenantsData?.forEach(product => {
			addExistingProductsIds(product.productId);
		});
	}, [accountId, projectId, tenantsData, addExistingProductsIds, initiateExistingProductsIds]);

	if ( tenantsIsLoading ) return <Loader/>;
	if ( tenantsIsError ) return <div>Error fetching data: {tenantsError.message}</div>;

	return products && products.map((product) => (
		<ProductElement
			key={product.id}
			productId={product.id}
			label={product.displayName}
			selected={selectedProductId === product.id}
			disabled={isProductDisabled(product.id) || editTenant}
			setProductId={setProductId}
			setEnvironment={setEnvironment}
			setCurrentStep={setCurrentStep}
			setNextButtonDisabled={setNextButtonDisabled}
		/>
	));
}
function TenantTypeElement({ environment, label, icon, selected, setSelected, setSubscriptionStatus, setCurrentStep, setNextButtonDisabled }) {
	const styles = useStyles();

	const handleOnClick = () => {
		if (!selected) {
			setSelected(environment);
			setSubscriptionStatus(null);
			setCurrentStep(3);
			setNextButtonDisabled(true);
		}
	};

	return (<div className={styles.labelContainer}>
		<div className={clsx(styles.labelBox, selected && styles.labelBoxSelected)} onClick={handleOnClick}>
			{ selected && <CheckCircleIcon className={styles.selectedIcon}/> }
			<div className={clsx(styles.iconContainer, selected && styles.iconContainerSelected)}>
				{icon}
			</div>
		</div>
		<div className={styles.productLabelText}>
			{label}
		</div>
	</div>);
}

function SubscriptionElement({ subscription, label, selected, setSelected, setNextButtonDisabled }) {
	const styles = useStyles();

	const handleOnClick = () => {
		setSelected(subscription);
		setNextButtonDisabled(false);
	};

	return (<div className={styles.labelContainer}>
		<div className={clsx(styles.labelBox, selected && styles.labelBoxSelected)} onClick={handleOnClick}>
			{ selected && <CheckCircleIcon className={styles.selectedIcon}/> }
			<div className={clsx(styles.labelText, selected && styles.labelTextSelected)}>
				{label}
			</div>
		</div>
	</div>);
}

const tenantTypeIcons = {
	production: <CampaignOutlinedIcon/>,
	test: <IntegrationInstructionsOutlinedIcon/>
};

const stepsHeaders = [
	'A - Select a Project',
	'B - Select a Product',
	'C - Select Tenant Type',
	'D - Select Subscription Status'];

const staleTimeout = 60000;

export default function GeneralDetails({ tenantId, setTenant, setVisible, setErrorMessage }) {
	const styles = useStyles();

	const editTenant = tenantId != null;
	const accountId = useProjectsStore(state => state.accountId);
	const setNextButtonDisabled = useCreateTenantStore(state => state.setNextButtonDisabled, shallow);
	const [projectId, setProjectId] = useCreateTenantStore(state => [state.projectId, state.setProjectId], shallow);
	const [productId, setProductId] = useCreateTenantStore(state => [state.productId, state.setProductId], shallow);
	const setPackageIds = useCreateTenantStore(state => state.setPackageIds, shallow);
	const [ctsEnvironment, setEnvironment] = useCreateTenantStore(state => [state.environment, state.setEnvironment], shallow);
	const [existingProductsIds, tenantTypeLabels] = useCreateTenantStore(state => [state.existingProductsIds, state.tenantTypeLabels], shallow);
	const [subscriptionStatus, setSubscriptionStatus, subscriptionStatusLabels] = useCreateTenantStore(state => [state.subscriptionStatus, state.setSubscriptionStatus, state.subscriptionStatusLabels], shallow);
	const [generalSettingStep, setGeneralSettingStep] = useCreateTenantStore(state => [state.generalSettingStep, state.setGeneralSettingStep], shallow);
	const resetState = useCreateTenantStore(state => state.reset, shallow);

	const {
		data: projectsByIds,
		isError: isErrorProjects,
		isLoading: isLoadingProject,
		error: errorProject
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null,
		staleTime: staleTimeout
	});

	const {
		isLoading: isLoadingProducts,
		isError:isErrorProducts,
		data: products,
		error:errorProducts } = useQueryWithAuthorization('products', apiGetProducts(), {
		staleTime: staleTimeout,
	});

	const {
		isLoading: isLoadingTenant,
		isError: isErrorTenant,
		data: tenant,
		error: errorTenant
	} = useQueryWithAuthorization(['get-tenant-by-id', tenantId], getTenantById(tenantId), {
		enabled: tenantId != null,
		staleTime: staleTimeout
	});

	useEffect(() => {
		if (!isLoadingTenant && !isErrorTenant && tenantId != null) {
			if (tenant.provisioningStatus !== 'active') {
				resetState();
				setVisible(false);
				let errorMessage = 'An unexpected error occurred. For assistance, please contact customer support.';

				if (tenant.provisioningStatus === 'requested' || tenant.provisioningStatus === 'creating') {
					errorMessage = 'The tenant is not yet active. Please try again later.';
				} else if (tenant.provisioningStatus === 'updating') {
					errorMessage = 'A tenant update is already in progress. Please try again later.';
				} else if (tenant.provisioningStatus === 'terminating' || tenant.provisioningStatus === 'terminated') {
					errorMessage = 'Cannot update the tenant because it is being terminated.';
				}

				console.error(errorMessage);
				setErrorMessage(errorMessage);
			} else {
				setTenant(tenant);
				//FOR DEBUGGING
				// setPackageIds([...tenant?.packageIds, "package_1"]);
				//END OF FOR DEBUGGING
				setPackageIds(tenant?.packageIds);
				setEnvironment(tenant?.environment);
				setSubscriptionStatus(tenant?.subscriptionStatus);
				setNextButtonDisabled(false);
			}
		}
	}, [isLoadingTenant, isErrorTenant, tenantId, tenant, setPackageIds, setEnvironment, setSubscriptionStatus, setNextButtonDisabled, setTenant, resetState, setErrorMessage, setVisible]);

	if (isLoadingProducts || isLoadingProject || isLoadingTenant) return <Loader/>;
	if (isErrorProducts) return <span>Error: {errorProducts.message}</span>;
	if (isErrorProjects) return <span>Error: {errorProject.message}</span>;
	if (isErrorTenant) return <span>Error: {errorTenant.message}</span>;

	const stepsElements = [
		projectsByIds && Object.entries(projectsByIds).map(([id, project]) => (
			<ProjectElement
				key={id}
				projectId={id}
				label={project.label}
				description={project.description}
				selected={projectId === id}
				editTenant={editTenant}
				setProjectId={setProjectId}
				setProductId={setProductId}
				setEnvironment={setEnvironment}
				setCurrentStep={setGeneralSettingStep}
				setNextButtonDisabled={setNextButtonDisabled}
			/>
		)),
		<ProductsCard
			key={'ProductsCard'}
			accountId={accountId}
			projectId={projectId}
			editTenant={editTenant}
			products={products}
			selectedProductId={productId}
			existingProductsIds={existingProductsIds}
			setProductId={setProductId}
			setEnvironment={setEnvironment}
			setCurrentStep={setGeneralSettingStep}
			setNextButtonDisabled={setNextButtonDisabled}
		/>,
		tenantTypeLabels && Object.keys(tenantTypeLabels).map((environment, index) => (
			<TenantTypeElement
				key={index}
				environment={environment}
				label={tenantTypeLabels[environment]}
				icon={tenantTypeIcons[environment]}
				selected={ctsEnvironment === environment}
				setSelected={setEnvironment}
				setSubscriptionStatus={setSubscriptionStatus}
				setCurrentStep={setGeneralSettingStep}
				setNextButtonDisabled={setNextButtonDisabled}
			/>
		)),
		subscriptionStatusLabels && Object.keys(subscriptionStatusLabels).map((subscription, index) => (
			<SubscriptionElement
				key={index}
				subscription={subscription}
				label={subscriptionStatusLabels[subscription]}
				selected={subscriptionStatus === subscription}
				setSelected={setSubscriptionStatus}
				setNextButtonDisabled={setNextButtonDisabled}
			/>
		))
	];

	return (<div className={styles.root}>
		{
			stepsHeaders.map((headerLabel, index) => {
				return generalSettingStep >= index && <div key={index} className={styles.card}>
					<div className={styles.header}>{headerLabel}</div>
					<div className={styles.labelGroup}>
						{stepsElements[index]}
					</div>
				</div>;
			})
		}
	</div>);
}
