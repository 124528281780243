import styles from './Pagination.module.scss'
import React, { useContext, useEffect } from 'react'
import NumPages from './Pagination/NumPages'
import PageSelector from './Pagination/PageSelector'
import Text from '../Text'

export default function Pagination({ total }) {
  let [count] = useContext(NumPages.Selected) || [0]
  const [offset, setOffset] = useContext(Pagination.Offset) || [0]
  if (!count) {
    count = total || 0
  }
  if (count >= total) {
    count = total
  }
  useEffect(() => {
    setOffset && setOffset(0)
  }, [total])

  useEffect(() => {
    if (count) {
      const currentPage = parseInt(offset / count)
      setOffset && setOffset(currentPage * count)
    } else {
      setOffset && setOffset(0)
    }
  }, [count])

  if (!total) {
    return null
  }

  const start = total ? offset + 1 : 0
  const end = Math.min(offset + count, total)

  return (
    <div className={styles.root}>
      <Text className={styles.label}>Rows per page:</Text>
      <NumPages />
      <div className={styles.pagePos}>
        {start}-{end} of {total}{' '}
      </div>
      <PageSelector total={total} />
    </div>
  )
}

export function usePagination(items) {
  let [count] = useContext(NumPages.Selected) || [0]
  const [offset] = useContext(Pagination.Offset) || [0]

  if (items && !count) {
    count = items.length
  }
  return (items || []).slice(offset, offset + parseInt(count))
}

Pagination.Offset = React.createContext()
