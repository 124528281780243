import * as React from 'react'

function SvgSidebarCollapse(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 12 14'
      fill='#FFF'
      {...props}
    >
      <path
        d='M6.922 13.862L.325 7.269a.375.375 0 010-.531L6.922.144c.15-.15.387-.15.534-.003l.222.222a.374.374 0 010 .53L1.568 7l6.107 6.11a.374.374 0 010 .53l-.222.222a.375.375 0 01-.531 0zm4.003-.003L4.328 7.266a.376.376 0 010-.532L10.925.141a.374.374 0 01.528-.003l.222.221a.376.376 0 010 .532L5.569 7l6.109 6.106a.376.376 0 010 .532l-.222.221a.375.375 0 01-.531 0z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgSidebarCollapse
