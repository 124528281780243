import { makeStyles } from "@material-ui/core/styles";
import EnumSelect from "./EnumSelect";
import AppButton from "../../../../../../AppButton";
import clsx from "clsx";
import Multiselect from "multiselect-react-dropdown";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import AppTooltip from "../../../../../../AppTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getShortStr, getTextWidth } from "../../../../Projects/TenantDrawer/utils";

const useStyles = makeStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		'&:focus-within': {
			borderColor: '#3380FF',
		},
		border: '1px solid #C2C2C2',
		borderRadius: 8,
		boxShadow: '2px 2px 10px #C2C2C2',
		width: 802,
		// marginTop: 12,
		marginBottom: 12,
		overflow: 'visible',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		margin: '12px 12px 0 12px',
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 14,
		fontWeight: 600,
		fontStretch: "normal",
		fontStyle: "normal",
	},
	requireLabel: {
		marginLeft: 3,
		color: 'red',
	},
	infoIcon: {
		marginLeft: 3,
		color: '#8F8F8F',
		'&:hover': {
			color: '#3380FF',
		},
	},
	parameter: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '12px 12px',
	},
	booleanButtonsContainer: {
		border: '1px solid #3380FF',
		borderRadius: 5,
	},
	booleanButtonsContainerDisabled: {
		border: '1px solid #C2C2C2',
	},
	booleanButton: {
		margin: 0,
	},
	input: {
		width: 800,
		height: 30,
		border: '1px solid #C2C2C2',
		boxSizing: 'borderBox',
		borderRadius: 4,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14,
		'&:focus,focus-visible': {
			borderColor: 'hsla(217, 100%, 60%, 1)',
			outline: 'none',
		},
		padding: '0 12px',
	},
	inputNumber: {
		width: 200,
	},
	closeIcon: {
		padding: 4,
		color: '#8F8F8F',
		'&:hover': {
			padding: 3,
			color: '#3380FF',
		},
	},
}));

const multiselectStyle = {
	chips: {
		background: "white",
		color: 'black',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		borderRadius: 3,
		padding: '3px 0px 0px 0px',
	},
	searchBox: {
		display: 'flex',
		flexWrap: 'nowrap',
		borderRadius: 4,
		padding: '0px 40px 0 15px',
		height: 30,
		width: 200,
		overflow: 'auto',
	},
	inputField: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		width: 40,
		padding: '2px 0px 7px 0px',
	},
	multiselectContainer: {
	},
	optionContainer: {
		borderRadius: 4,
	},
	option: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		margin: 0,
		padding: 5,
		borderRadius: 4,
	},
};

export default function Parameter( { label, type, description, selectedValue, handleSelectionChange, parameter, required, allowedValues, longestLabelLength, disabled, overrideClasses = {} }) {
	const styles = useStyles();
	
	const isLabelLong = getTextWidth(label) > longestLabelLength;
	
	return <div key={parameter} className={clsx(styles.card, overrideClasses?.card)}>
		<div className={clsx(styles.header, overrideClasses?.header)}>
			<AppTooltip title={label} disabled={!isLabelLong}>
				<span>{ isLabelLong ? getShortStr(label, longestLabelLength) + '...': label }</span>
			</AppTooltip>
			{ required && <div className={styles.requireLabel}>*</div> }
			{ description != null && <AppTooltip title={description}>
				<InfoOutlinedIcon sx={{ width: 15, height: 15 }} className={styles.infoIcon}/>
			</AppTooltip>}
		</div>
		<div className={clsx(styles.parameter, overrideClasses?.parameter)}>
			{ type === 'enum' && <EnumSelect
				value={String(selectedValue != null ? selectedValue : 'None')}
				setValue={value => handleSelectionChange(value === 'None' ? null : value, parameter, type)}
				valueOptions={ required ? allowedValues : ['None', ...allowedValues] }
				disabled={disabled}
			/> }
			{ type === 'boolean' && <div className={clsx(styles.booleanButtonsContainer, disabled && styles.booleanButtonsContainerDisabled)}>
				<AppButton overrideClasses={ { button: styles.booleanButton } } variant={selectedValue === 'true' ? 'contained' : 'outlined'} disabled={disabled} onClick={() => handleSelectionChange('true', parameter, type)}>TRUE</AppButton>
				<AppButton overrideClasses={ { button: styles.booleanButton } } variant={selectedValue === 'false' ? 'contained' : 'outlined'} disabled={disabled} onClick={() => handleSelectionChange('false', parameter, type)}>FALSE</AppButton>
			</div>}
			{  type === 'string' && <input
				type={type}
				className={clsx(styles.input, overrideClasses?.input)}
				value={selectedValue}
				placeholder="Enter your text..."
				onChange={e => handleSelectionChange( e.target.value, parameter, type)}
				disabled={disabled}
			/>}
			{ type === 'number'  && <input
				step=".01"
				type={type}
				className={clsx(styles.input, styles.inputNumber )}
				value={selectedValue}
				placeholder="Enter your number..."
				onChange={e => {
					if(!isNaN(e.target.value)) {
						handleSelectionChange( e.target.value ? parseFloat(e.target.value):e.target.value, parameter, type);
					}else if(e.target.value === '-') {
						handleSelectionChange( '-', parameter, type);
					}
				}}
				disabled={disabled}
			/>}
			{ (type === 'array') && <Multiselect
				options={allowedValues}
				selectedValues={selectedValue}
				isObject={false}
				avoidHighlightFirstOption={true}
				placeholder='Select'
				hidePlaceholder={true}
				onSelect={(selectedList, selectedItem) => handleSelectionChange(selectedList, parameter, type)}
				onRemove={(selectedList, selectedItem) => handleSelectionChange(selectedList, parameter, type)}
				displayValue="name"
				showCheckbox={true}
				// showArrow={true}
				customCloseIcon={<CloseIcon fontSize='small' className={styles.closeIcon}/>}
				disable={disabled}
				style={multiselectStyle}
			/>}
		</div>
	</div>;
}