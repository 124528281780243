import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Product from "./Product";
import Loader from "../../Loader";
import { getProducts as apiGetProducts } from "../../../external-apis";
import { useQueryWithAuthorization } from "../../../custom-hooks/external-api-hooks";

const useStyles = makeStyles(() => ({
	rootContainer: {},
	title: {
		textAlign: "center",
		marginLeft: 40,
		marginTop: 20,
		fontSize: 40,
		// fontWeight: "bold",
		fontStyle: "normal",
		fontFamily: "'Source Sans Pro', sans-serif"
	},
	productsContainer: {
		marginTop: 20,
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		overflowY: "auto",
		height: "80vh"
	}
}));

const productsToUiElements = (products) => {
	return products.map(product => {
		const { id, displayName, description, image, informationPortal, developerPortal } = product;

		return (
			<Product
				key={id}
				displayName={displayName}
				description={description}
				image={image}
				informationPortal={informationPortal}
				developerPortal={developerPortal}
			/>
		);
	});
};

export default function ProductsPanel() {
	const classes = useStyles();

	const { isLoading, isError, data = [], error } = useQueryWithAuthorization('products', apiGetProducts());

	if (isLoading) return <Loader/>;
	if (isError) return <span>Error: {error.message}</span>;

	return (
		<div className={classes.rootContainer}>
			<div className={classes.title}>Synamedia Cloud Services</div>
			<div className={classes.productsContainer}>
				{productsToUiElements(data)}
			</div>
		</div>
	);
}
