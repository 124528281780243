import * as React from 'react'

function SvgClear(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 9 10'
      fill='#C2C2C2'
      {...props}
    >
      <path
        d='M.992 9.06l3.383-3.382 2.804 2.804.579.579a.22.22 0 00.309 0l.619-.62a.218.218 0 000-.308L5.303 4.75l2.804-2.804.579-.579a.22.22 0 000-.309l-.62-.619a.218.218 0 00-.308 0L4.375 3.822.992.439a.22.22 0 00-.309 0l-.619.619a.22.22 0 000 .31L3.447 4.75.064 8.133a.22.22 0 000 .309l.619.619a.22.22 0 00.309 0z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgClear
