import * as React from 'react'

function SvgRefresh(props) {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.207 1.54297L11.7852 2.96484C10.5547 1.73438 8.85938 0.96875 7 0.96875C3.36328 0.96875 0.382812 3.83984 0.21875 7.42188C0.191406 7.61328 0.355469 7.75 0.546875 7.75H1.3125C1.47656 7.75 1.61328 7.64062 1.64062 7.44922C1.80469 4.63281 4.12891 2.39062 7 2.39062C8.47656 2.39062 9.81641 2.99219 10.7734 3.97656L9.29688 5.45312C9.07812 5.67188 9.24219 6 9.51562 6H13.4531C13.6172 6 13.7812 5.86328 13.7812 5.67188V1.76172C13.7812 1.48828 13.4258 1.32422 13.207 1.54297ZM13.4258 7.75H12.6602C12.4961 7.75 12.3594 7.88672 12.332 8.07812C12.168 10.8945 9.84375 13.1094 7 13.1094C5.49609 13.1094 4.15625 12.5352 3.19922 11.5508L4.67578 10.0742C4.89453 9.85547 4.73047 9.5 4.45703 9.5H0.546875C0.355469 9.5 0.21875 9.66406 0.21875 9.82812V13.7656C0.21875 14.0391 0.546875 14.2031 0.765625 13.9844L2.1875 12.5625C3.41797 13.793 5.11328 14.5312 7 14.5312C10.6094 14.5312 13.5898 11.6875 13.7539 8.10547C13.7812 7.91406 13.6172 7.75 13.4258 7.75Z'
        fill={props.fill || '#3380FF'}
      />
    </svg>
  )
}

export default SvgRefresh
