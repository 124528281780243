import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useCreateTenantStore } from "../../../../../../../state-management";
import clsx from "clsx";
import AppTooltip from "../../../../../../AppTooltip";
import AppDeleteConfirmation from "../../../../../../AppDeleteConfirmation";
import shallow from "zustand/shallow";

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		left: 30,
		top: 80,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		height: 530,
		width: 800,
	},
	header: {
		display: 'flex',
	},
	addPackageContainer: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
		color: '#3380FF',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	addPackageContainerDisabled: {
		color: '#8F8F8F',
		'&:hover': {
			cursor: 'default',
		},
	},
	addPackageText: {
		marginLeft: 4,
	},
	packagesContainer: {
		overflowY: 'auto',
		height: 510,
		width: 823,
	},
	packageCard: {
		position: 'relative',
		height: 43,
		width: 802,
		border: "1px solid #C2C2C2",
		borderRadius: 4,
		margin: '12px 0',
		boxShadow: '2px 2px 2px #C2C2C2',
		fontSize: 14,
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
	},
	packagesAmount: {
		fontSize: 17,
		fontWeight: 600,
	},
	packageText: {
		position: 'absolute',
		left: 12,
	},
	icons: {
		position: 'absolute',
		right: 10,
		top: 10,
		color: '#8F8F8F',
	},
	editIcon: {
		marginRight: 12,
		'&:hover': {
			cursor: 'pointer',
			color: '#3380FF',
		},
	},
	deleteIcon: {
		'&:hover': {
			cursor: 'pointer',
			color: '#3380FF',
		},
	}
}));

function AddPackageContainer() {
	const styles = useStyles();

	return <>
		<AddCircleOutlineOutlinedIcon sx={{ width: '14px', height: '14px' }}/>
		<div className={styles.addPackageText}>ADD PACKAGE</div>
	</>;
}
export default function AddedPackages( { packagesOptions, setAddPackageVisible, setEditedPackage } ) {
	const styles = useStyles();
	const [packageIds, setPackagesIds] = useCreateTenantStore(state => [state.packageIds, state.setPackageIds], shallow);
	const [deletePackageIndex, setDeletePackageIndex] = useState();

	const packagesLength = packagesOptions ? Object.keys(packagesOptions).length : 0;
	const addPackagesDisabled = packageIds?.length === packagesLength;

	function handleAddPackage() {
		setEditedPackage(null);
		setAddPackageVisible(true);
	}

	function handleEditPackage(packageId) {
		setEditedPackage(packageId);
		setAddPackageVisible(true);
	}

	function handleDeletePackage(index) {
		const newPackageIds = [...packageIds];

		setDeletePackageIndex(null);

		newPackageIds.splice(index, 1);
		setPackagesIds(newPackageIds);
	}

	return (<div className={styles.container}>
		<header className={styles.header}>
			<div className={styles.packagesAmount}>
				{`${packageIds?.length} Packages`}
			</div>
			{ addPackagesDisabled && <AppTooltip title='all available packages already added'>
				<div className={clsx(styles.addPackageContainer, styles.addPackageContainerDisabled)}>
					<AddPackageContainer/>
				</div>
			</AppTooltip>}
			{ !addPackagesDisabled && <div className={styles.addPackageContainer} onClick={handleAddPackage}>
				<AddPackageContainer/>
			</div>}

		</header>
		<div className={styles.packagesContainer}>
			{ packageIds.map( (packageId, index ) => <div key={index} className={styles.packageCard}>
				<div className={styles.packageText}>
					{packagesOptions?.[packageId]?.label}
				</div>
				<div className={styles.icons}>
					<ModeEditOutlineOutlinedIcon className={styles.editIcon} onClick={ () => handleEditPackage(packageId)}/>
					<DeleteOutlineOutlinedIcon className={styles.deleteIcon} onClick={ () => setDeletePackageIndex(index) }/>
				</div>
			</div>) }
		</div>
		<AppDeleteConfirmation
			visible={deletePackageIndex != null}
			label='package'
			value={packagesOptions?.[packageIds[deletePackageIndex]]?.label}
			handleDelete={() => handleDeletePackage(deletePackageIndex)}
			handleClose={() => setDeletePackageIndex(null)}
			title='Remove'
		/>
	</div>);
}
