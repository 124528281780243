import React from "react";
import CreateTenantSteps from "./steps";
import AppDialog from "../../../../AppDialog";
import { makeStyles } from "@material-ui/core/styles";
import { useCreateTenantStore } from "../../../../../state-management";
import shallow from "zustand/shallow";

const useStyles = makeStyles(() => ({
	appDialogContent: {
		width: 850,
		height: 700,
	},
	appDialogBody: {
		position: 'absolute',
		top: 10,
		left: -30,
	},
	appDialogHeaderWrapper: {
		zIndex: 2,
	},
	appDialogHeader: {
		paddingBottom: 45,
	},
}));

export default function AddEditTenant({ visible, setVisible, setCloseConfirmationVisible, setErrorMessage, tenantId }) {
	const classes = useStyles();
	const generalSettingStep = useCreateTenantStore(state => state.generalSettingStep, shallow);
	const currentStep = useCreateTenantStore(state => state.currentStep, shallow);

	const handleCancel = () => {
		if ( currentStep > 0 || generalSettingStep > 0) {
			setCloseConfirmationVisible(true);
		}
		setVisible(false);
	};

	return <AppDialog
		variant={'info'}
		open={visible}
		onClose={handleCancel}
		title={ tenantId != null ? 'Update Tenant' : 'Add Tenant' }
		overrideClasses={{
			content: classes.appDialogContent,
			body: classes.appDialogBody,
			headerWrapper: classes.appDialogHeaderWrapper,
			header: classes.appDialogHeader }}
	>
		<CreateTenantSteps
			setVisible={setVisible}
			handleCancel={handleCancel}
			setErrorMessage={setErrorMessage}
			tenantId={tenantId}
		/>
	</AppDialog>;
}
