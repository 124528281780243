import Joi from 'joi';
import { schema as auth0Schema } from './auth0Schema';

const schema = Joi.object({
	apiEndpoint: Joi.string()
		.trim()
		.uri()
		.required(),
	groups: Joi.string()
		.trim()
		.required(),
	auth0: auth0Schema
});

const validate = (auth0Config) => schema.validate(auth0Config, { abortEarly: false, stripUnknown: true });

export {
	schema,
	validate
};
