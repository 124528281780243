import React, { useEffect, useMemo, useState, Fragment } from 'react'
import styles from './AdvancedFilter.module.scss'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Popover from '@material-ui/core/Popover'
import clsx from 'clsx'
import FilterChips from './FilterChips'
import { Button } from '../button/Button'
import { TextField } from '../textField/TextField'
import {
  Expand,
  Collapse,
  CheckboxSelected,
  Checkbox,
  Search
} from '../iconComponents'
import { Typography } from '../typography/Typography'
import Tooltip from '@material-ui/core/Tooltip'

// Tooltip on filters
const LightTooltip = withStyles((theme) => ({
  arrow: {
    backgroundColor: '#FFFFFF',
    color: '#FFFFFF'
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: '130px'
  }
}))(Tooltip)

export const AdvancedFilter = ({
  rawData,
  state,
  setState,
  options = {
    enableSearch: true,
    enableFilters: true,
    displayFilterChips: false
  },
  searchFieldProps = {},
  clearButtonProps = {},
  clearButtonEnabled = true,
  searchWidth = '23vw',
  classes
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverId, setPopoverId] = useState(null)

  useEffect(() => {
    onFilterChange(JSON.parse(JSON.stringify(state.filtersJson)))
  }, [rawData])

  const handleClickFilter = (event, id) => {
    setAnchorEl(event.currentTarget)
    setPopoverId(id)
  }

  const handleSearchChange = (event) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    var appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    if (appliedFilters && Array.isArray(appliedFilters)) {
      var current = appliedFilters.find((filter) => {
        for (const key in filter) {
          if (key === 'searchFilterText') {
            return true
          } else {
            return false
          }
        }
      })
      if (current) {
        current['searchFilterText'] = event.target.value
      } else {
        var newFilter = {}
        newFilter['searchFilterText'] = event.target.value
        appliedFilters.push(newFilter)
      }
      onFilterChange(existingFiltersJson)
    }
  }

  const handleCloseFilter = () => {
    setAnchorEl(null)
    setPopoverId(null)
  }

  function containsIgnoreCase(text, other) {
    var contains = false
    if (
      text !== null &&
      text !== undefined &&
      other !== null &&
      other !== undefined
    ) {
      text = text.toString()
      other = other.toString()
      contains = text.toLowerCase().includes(other.toLowerCase())
    }
    return contains
  }

  const handleSelectionChange = (event, checked, item, target) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    var appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    if (appliedFilters && Array.isArray(appliedFilters)) {
      var current = appliedFilters.find((filter) => {
        for (const key in filter) {
          if (key === target) {
            return true
          } else {
            return false
          }
        }
      })
      if (checked) {
        if (current && Array.isArray(current[target])) {
          current[target].push(item)
          onFilterChange(existingFiltersJson, target)
        } else {
          var newFilter = {}
          newFilter[target] = []
          newFilter[target].push(item)
          appliedFilters.push(newFilter)
          onFilterChange(existingFiltersJson, target)
        }
      } else {
        var index = current[target].indexOf(item)
        if (index !== -1) {
          current[target].splice(index, 1)
          onFilterChange(existingFiltersJson, target)
        }
      }
    }
  }

  const handleFlagChange = (event, target) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    var appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    if (appliedFilters && Array.isArray(appliedFilters)) {
      var current = appliedFilters.find((filter) => {
        for (const key in filter) {
          if (key === target) {
            return true
          } else {
            return false
          }
        }
      })
      var checked = event.target.checked
      if (current) {
        current[target] = checked ? true : null
        onFilterChange(existingFiltersJson)
      } else {
        var newFilter = {}
        newFilter[target] = checked ? true : null
        appliedFilters.push(newFilter)
        onFilterChange(existingFiltersJson)
      }
    }
  }

  const getTargetFilterStatus = (target, value = null) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    const appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    var filterStatus = target === 'searchFilterText' ? '' : false
    if (appliedFilters && appliedFilters.length > 0) {
      appliedFilters.forEach((filter) => {
        for (const key in filter) {
          if (key === target) {
            if (Array.isArray(filter[target])) {
              if (value !== null) {
                filterStatus = filter[target].includes(value)
              } else {
                filterStatus = filter[target]
              }
            } else if (typeof filter[target] === 'boolean') {
              filterStatus =
                filter[target] !== null && filter[target] !== undefined
                  ? filter[target]
                  : false
            } else {
              filterStatus = filter[target]
            }
          }
        }
      })
    }
    return filterStatus
  }

  const getFilterLabel = (target, availableFilters) => {
    var filterLabel = []
    availableFilters &&
      availableFilters.forEach((availableFilter) => {
        if (getTargetFilterStatus(target, availableFilter.id)) {
          filterLabel.push(availableFilter.label)
        }
      })
    return filterLabel.join(' , ')
  }

  const getFilterCount = (target, availableFilters) => {
    var filterCount = 0
    availableFilters &&
      availableFilters.forEach((availableFilter) => {
        if (target === availableFilter.id) {
          filterCount = availableFilter.count
        }
      })
    return filterCount
  }

  const getSearchMapping = (mappings, searchText) => {
    var mappedText = searchText
    if (mappings && mappings.length > 0) {
      var mappedIndex = mappings.findIndex((mapping) =>
        containsIgnoreCase(mapping.label, searchText)
      )
      if (mappedIndex !== -1) {
        mappedText = mappings[mappedIndex]['id']
      }
    }
    return mappedText
  }

  const getFilterDetails = (existingFilters, targetFilter, type) => {
    var filterProperties = null
    if (type === 'selection') {
      var selectionFilterObject = existingFilters.find(
        (filterType) => filterType.type === 'selection'
      )
      if (
        selectionFilterObject &&
        selectionFilterObject.filters &&
        selectionFilterObject.filters.length > 0
      ) {
        filterProperties = selectionFilterObject.filters.find(
          (filter) => filter.target === targetFilter
        )
      }
    } else if (type === 'flag') {
      var selectionFilterObject = existingFilters.find(
        (filterType) => filterType.type === 'flag'
      )
      if (
        selectionFilterObject &&
        selectionFilterObject.filters &&
        selectionFilterObject.filters.length > 0
      ) {
        filterProperties = selectionFilterObject.filters.find(
          (filter) => filter.target === targetFilter
        )
      }
    } else if (type === 'text') {
      var selectionFilterObject = existingFilters.find(
        (filterType) => filterType.type === 'text'
      )
      if (
        selectionFilterObject &&
        selectionFilterObject.filters &&
        selectionFilterObject.filters.length > 0
      ) {
        filterProperties = selectionFilterObject.filters
      }
    }
    return filterProperties
  }

  const onFilterChange = (filtersJsonObject, except) => {
    const filteredResults =
      state.filtersJson &&
      rawData.filter(function (channel) {
        var flag = true
        var appliedFilters =
          filtersJsonObject && filtersJsonObject.length > 0
            ? filtersJsonObject[0].appliedFilters
            : null
        appliedFilters.forEach((filter) => {
          for (const target in filter) {
            if (
              Array.isArray(filter[target]) &&
              filter[target].length > 0 &&
              flag
            ) {
              //If filter is of type Selection
              const filterProperties = getFilterDetails(
                filtersJsonObject,
                target,
                'selection'
              )
              if (
                filterProperties.relativePath &&
                filterProperties.relativePath.length > 0
              ) {
                var parentAttribute_1 = filterProperties.relativePath[0]
                if (Array.isArray(channel[parentAttribute_1])) {
                  var parentAttribute_2 = filterProperties.relativePath[1]
                  if (channel[parentAttribute_1].length === 0) {
                    flag = false
                  } else {
                    flag =
                      flag &&
                      channel[parentAttribute_1].some((parentObject) => {
                        let temp = true
                        if (
                          parentAttribute_2 !== null &&
                          parentAttribute_2 !== undefined
                        ) {
                          if (Array.isArray(parentObject[parentAttribute_2])) {
                            if (parentObject[parentAttribute_2].length === 0) {
                              temp = false
                            } else {
                              temp =
                                temp &&
                                parentObject[parentAttribute_2].some(
                                  (targetObject) => {
                                    let temp2 = true
                                    if (!filterProperties.strictMatch) {
                                      temp2 = filter[target].some(
                                        (filterValue) => {
                                          if (
                                            !getFilterCount(
                                              filterValue,
                                              filterProperties.availableFilters
                                            )
                                          ) {
                                            return except
                                              ? except !== target &&
                                                  targetObject[target].includes(
                                                    filterValue
                                                  )
                                              : targetObject[target].includes(
                                                  filterValue
                                                )
                                          } else {
                                            return targetObject[
                                              target
                                            ].includes(filterValue)
                                          }
                                        }
                                      )
                                    } else {
                                      temp2 =
                                        filter[target].findIndex(
                                          (filterValue) => {
                                            if (
                                              !getFilterCount(
                                                filterValue,
                                                filterProperties.availableFilters
                                              )
                                            ) {
                                              return except
                                                ? except !== target &&
                                                    filterValue ===
                                                      targetObject[target]
                                                : filterValue ===
                                                    targetObject[target]
                                            } else {
                                              return (
                                                filterValue ===
                                                targetObject[target]
                                              )
                                            }
                                          }
                                        ) !== -1
                                          ? true
                                          : false
                                    }
                                    return temp2
                                  }
                                )
                            }
                          } else {
                            if (!filterProperties.strictMatch) {
                              temp = filter[target].some((filterValue) => {
                                if (
                                  !getFilterCount(
                                    filterValue,
                                    filterProperties.availableFilters
                                  )
                                ) {
                                  return except
                                    ? except !== target &&
                                        parentObject[parentAttribute_2][
                                          target
                                        ].includes(filterValue)
                                    : parentObject[parentAttribute_2][
                                        target
                                      ].includes(filterValue)
                                } else {
                                  return parentObject[parentAttribute_2][
                                    target
                                  ].includes(filterValue)
                                }
                              })
                            } else {
                              temp =
                                filter[target].findIndex((filterValue) => {
                                  if (
                                    !getFilterCount(
                                      filterValue,
                                      filterProperties.availableFilters
                                    )
                                  ) {
                                    return except
                                      ? except !== target &&
                                          filterValue ===
                                            parentObject[parentAttribute_2][
                                              target
                                            ]
                                      : filterValue ===
                                          parentObject[parentAttribute_2][
                                            target
                                          ]
                                  } else {
                                    return (
                                      filterValue ===
                                      parentObject[parentAttribute_2][target]
                                    )
                                  }
                                }) !== -1
                                  ? true
                                  : false
                            }
                          }
                        } else {
                          //If filter has single parent attribute in realtive path
                          if (!filterProperties.strictMatch) {
                            if (
                              parentObject[target] === null ||
                              parentObject[target] === undefined
                            ) {
                              temp = false
                            }
                            temp =
                              temp &&
                              filter[target].some((filterValue) => {
                                if (
                                  !getFilterCount(
                                    filterValue,
                                    filterProperties.availableFilters
                                  )
                                ) {
                                  return except
                                    ? except !== target &&
                                        parentObject[target].includes(
                                          filterValue
                                        )
                                    : parentObject[target].includes(filterValue)
                                } else {
                                  return parentObject[target].includes(
                                    filterValue
                                  )
                                }
                              })
                          } else {
                            temp =
                              filter[target].findIndex((filterValue) => {
                                if (
                                  !getFilterCount(
                                    filterValue,
                                    filterProperties.availableFilters
                                  )
                                ) {
                                  return except
                                    ? except !== target &&
                                        filterValue === parentObject[target]
                                    : filterValue === parentObject[target]
                                } else {
                                  return filterValue === parentObject[target]
                                }
                              }) !== -1
                                ? true
                                : false
                          }
                        }
                        return temp
                      })
                  }
                } else {
                  if (
                    channel[parentAttribute_1] === null ||
                    channel[parentAttribute_1] === undefined
                  ) {
                    flag = false
                  }
                  if (!filterProperties.strictMatch) {
                    flag =
                      flag &&
                      filter[target].some((filterValue) => {
                        if (
                          !getFilterCount(
                            filterValue,
                            filterProperties.availableFilters
                          )
                        ) {
                          return except
                            ? except !== target &&
                                channel[parentAttribute_1][target].includes(
                                  filterValue
                                )
                            : channel[parentAttribute_1][target].includes(
                                filterValue
                              )
                        } else {
                          return channel[parentAttribute_1][target].includes(
                            filterValue
                          )
                        }
                      })
                  } else {
                    flag =
                      flag &&
                      filter[target].findIndex((filterValue) => {
                        if (
                          !getFilterCount(
                            filterValue,
                            filterProperties.availableFilters
                          )
                        ) {
                          return except
                            ? except !== target &&
                                filterValue ===
                                  channel[parentAttribute_1][target]
                            : filterValue === channel[parentAttribute_1][target]
                        } else {
                          return (
                            filterValue === channel[parentAttribute_1][target]
                          )
                        }
                      }) !== -1
                        ? true
                        : false
                  }
                }
              } else {
                if (channel[target] !== undefined && channel[target] !== null) {
                  if (!filterProperties.strictMatch) {
                    flag = filter[target].some((filterValue) => {
                      if (
                        !getFilterCount(
                          filterValue,
                          filterProperties.availableFilters
                        )
                      ) {
                        return except
                          ? except !== target &&
                              channel[target].includes(filterValue)
                          : channel[target].includes(filterValue)
                      } else {
                        return channel[target].includes(filterValue)
                      }
                    })
                  } else {
                    flag =
                      filter[target].findIndex((filterValue) => {
                        if (
                          !getFilterCount(
                            filterValue,
                            filterProperties.availableFilters
                          )
                        ) {
                          return except
                            ? except !== target &&
                                filterValue === channel[target]
                            : filterValue === channel[target]
                        } else {
                          return filterValue === channel[target]
                        }
                      }) !== -1
                        ? true
                        : false
                  }
                }
              }
            } else if (typeof filter[target] === 'boolean') {
              //If filter is of type flag
              if (flag) {
                flag = filter[target] === channel[target] ? true : false
              }
            } else if (
              filter[target] &&
              filter[target] !== '' &&
              target === 'searchFilterText'
            ) {
              //If filter is of type text
              const textFilters = getFilterDetails(
                filtersJsonObject,
                target,
                'text'
              )
              flag =
                flag &&
                textFilters.some((textFilter) => {
                  let textFilterFlag = true
                  let searchText = getSearchMapping(
                    textFilter.mappings,
                    filter[target]
                  )
                  if (
                    textFilter.relativePath &&
                    textFilter.relativePath.length > 0
                  ) {
                    var parentAttribute_1 = textFilter.relativePath[0]
                    if (Array.isArray(channel[parentAttribute_1])) {
                      var parentAttribute_2 = textFilter.relativePath[1]
                      if (channel[parentAttribute_1].length === 0) {
                        textFilterFlag = false
                      } else {
                        textFilterFlag =
                          textFilterFlag &&
                          channel[parentAttribute_1].some((parentObject) => {
                            let temp = true
                            if (
                              parentAttribute_2 !== null &&
                              parentAttribute_2 !== undefined
                            ) {
                              if (
                                Array.isArray(parentObject[parentAttribute_2])
                              ) {
                                if (
                                  parentObject[parentAttribute_2].length === 0
                                ) {
                                  temp = false
                                } else {
                                  temp =
                                    temp &&
                                    parentObject[parentAttribute_2].some(
                                      (targetObject) => {
                                        let temp2 = true
                                        if (!textFilter.strictMatch) {
                                          temp2 = containsIgnoreCase(
                                            targetObject[textFilter.target],
                                            searchText
                                          )
                                        } else {
                                          temp2 =
                                            searchText ===
                                            targetObject[textFilter.target]
                                        }
                                        return temp2
                                      }
                                    )
                                }
                              } else {
                                if (!textFilter.strictMatch) {
                                  temp = containsIgnoreCase(
                                    parentObject[parentAttribute_2][
                                      textFilter.target
                                    ],
                                    searchText
                                  )
                                } else {
                                  temp =
                                    searchText ===
                                    parentObject[parentAttribute_2][
                                      textFilter.target
                                    ]
                                }
                              }
                            } else {
                              //If relative path contains single parent attribute
                              if (!textFilter.strictMatch) {
                                temp = containsIgnoreCase(
                                  parentObject[textFilter.target],
                                  searchText
                                )
                              } else {
                                temp =
                                  searchText === parentObject[textFilter.target]
                              }
                            }
                            return temp
                          })
                      }
                    } else {
                      if (!textFilter.strictMatch) {
                        textFilterFlag = containsIgnoreCase(
                          channel[parentAttribute_1][textFilter.target],
                          searchText
                        )
                      } else {
                        textFilterFlag =
                          searchText ===
                          channel[parentAttribute_1][textFilter.target]
                      }
                    }
                  } else {
                    if (
                      channel[textFilter.target] !== undefined &&
                      channel[textFilter.target] !== null
                    ) {
                      if (!textFilter.strictMatch) {
                        textFilterFlag = containsIgnoreCase(
                          channel[textFilter.target],
                          searchText
                        )
                      } else {
                        textFilterFlag =
                          searchText === channel[textFilter.target]
                      }
                    }
                  }
                  return textFilterFlag
                })
            }
          }
        })
        return flag
      })
    updateOptionCount(
      filteredResults,
      getCountClearedFilters(filtersJsonObject, except),
      except
    )
  }

  const updateOptionCount = (filteredResults, filtersJsonObject, except) => {
    filteredResults.forEach(function (channel) {
      var selectionFilterObject = filtersJsonObject.find(
        (filterType) => filterType.type === 'selection'
      )
      var selectionFilters =
        selectionFilterObject && selectionFilterObject.filters
          ? selectionFilterObject.filters
          : []
      options &&
        options.enableFilters &&
        selectionFilters.forEach((filter) => {
          //auto populating filter options
          if (
            filter.autoPopulate &&
            filter.strictMatch &&
            filter.availableFilters &&
            Array.isArray(filter.availableFilters)
          ) {
            var createNew = filter.availableFilters.findIndex(
              (availableFilter) => {
                if (availableFilter.id == channel[filter.target]) {
                  return true
                } else {
                  return false
                }
              }
            )
            if (createNew === -1) {
              var newFilter = {
                id: channel[filter.target],
                label: channel[filter.target],
                count: 0
              }
              filter.availableFilters.push(newFilter)
            }
          }
          if (
            filter.availableFilters &&
            filter.availableFilters.length > 0 &&
            except !== filter.target
          ) {
            filter.availableFilters.forEach((filterValue) => {
              if (filter.relativePath && filter.relativePath.length > 0) {
                var parentAttribute_1 = filter.relativePath[0]
                if (Array.isArray(channel[parentAttribute_1])) {
                  var parentAttribute_2 = filter.relativePath[1]
                  let flag = false
                  if (channel[parentAttribute_1].length === 0) {
                    flag = false
                  } else {
                    flag = channel[parentAttribute_1].some((parentObject) => {
                      let temp = true
                      if (
                        parentAttribute_2 !== null &&
                        parentAttribute_2 !== undefined
                      ) {
                        if (Array.isArray(parentObject[parentAttribute_2])) {
                          if (parentObject[parentAttribute_2].length === 0) {
                            temp = false
                          } else {
                            temp =
                              temp &&
                              parentObject[parentAttribute_2].some(
                                (targetObject) => {
                                  let temp2 = true
                                  if (!filter.strictMatch) {
                                    temp2 = targetObject[
                                      filter.target
                                    ].includes(filterValue.id)
                                  } else {
                                    temp2 =
                                      targetObject[filter.target] ===
                                      filterValue.id
                                  }
                                  return temp2
                                }
                              )
                          }
                        } else {
                          if (!filter.strictMatch) {
                            temp = parentObject[parentAttribute_2][
                              filter.target
                            ].includes(filterValue.id)
                          } else {
                            temp =
                              parentObject[parentAttribute_2][filter.target] ===
                              filterValue.id
                          }
                        }
                      } else {
                        //If filter has single parent attribute in relative path
                        if (
                          parentObject[filter.target] === null ||
                          parentObject[filter.target] === undefined
                        ) {
                          temp = false
                        }
                        if (!filter.strictMatch) {
                          temp =
                            temp &&
                            parentObject[filter.target].includes(filterValue.id)
                        } else {
                          temp =
                            temp &&
                            filterValue.id === parentObject[filter.target]
                        }
                      }
                      return temp
                    })
                  }
                  if (flag) {
                    filterValue.count++
                  }
                } else {
                  let flag = true
                  if (
                    channel[parentAttribute_1] === null ||
                    channel[parentAttribute_1] === undefined
                  ) {
                    flag = false
                  }
                  if (!filter.strictMatch) {
                    flag =
                      flag &&
                      channel[parentAttribute_1][filter.target].includes(
                        filterValue.id
                      )
                  } else {
                    flag =
                      flag &&
                      channel[parentAttribute_1][filter.target] ===
                        filterValue.id
                  }
                  if (flag) {
                    filterValue.count++
                  }
                }
              } else {
                if (
                  channel[filter.target] !== undefined &&
                  channel[filter.target] !== null
                ) {
                  let flag = false
                  if (!filter.strictMatch) {
                    flag = channel[filter.target].includes(filterValue.id)
                    if (channel[filter.target]) {
                    }
                  } else {
                    flag = channel[filter.target] === filterValue.id
                  }
                  if (flag) {
                    filterValue.count++
                  }
                }
              }
            })
          }
        })
      var flagFilterObject = filtersJsonObject.find(
        (filterType) => filterType.type === 'flag'
      )
      var flagFilters =
        flagFilterObject && flagFilterObject.filters
          ? flagFilterObject.filters
          : []
      options &&
        options.enableFilters &&
        flagFilters.forEach((filter) => {
          if (filter.target && channel[filter.target]) {
            filter.count++
          }
        })
    })
    setState({
      ...state,
      filtersJson: filtersJsonObject,
      filteredData: filteredResults
    })
  }

  const clearFilters = () => {
    if (state.filtersJson) {
      var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
      if (existingFiltersJson && existingFiltersJson.length > 0) {
        existingFiltersJson[0].appliedFilters = []
      }
      var selectionFilters = existingFiltersJson.find(
        (filter) => filter.type === 'selection'
      )
      selectionFilters?.filters.forEach((filter) => {
        filter.selectAll = false
      })
      updateOptionCount(rawData, getCountClearedFilters(existingFiltersJson))
    }
  }

  const getCountClearedFilters = (existingFilters, except) => {
    var selectionFilterObject = existingFilters.find(
      (filterType) => filterType.type === 'selection'
    )
    if (
      selectionFilterObject &&
      selectionFilterObject.filters &&
      selectionFilterObject.filters.length > 0
    ) {
      selectionFilterObject.filters.forEach((filter) => {
        if (filter.target !== except) {
          filter.availableFilters &&
            filter.availableFilters.forEach((availableFilter) => {
              availableFilter.count = 0
            })
        }
      })
    }
    var flagFilterObject = existingFilters.find(
      (filterType) => filterType.type === 'flag'
    )
    if (
      flagFilterObject &&
      flagFilterObject.filters &&
      flagFilterObject.filters.length > 0
    ) {
      flagFilterObject.filters.forEach((filter) => {
        filter.count = 0
      })
    }
    return existingFilters
  }

  const handleFilterSearchChange = (event, value, availableFilters, target) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    var selectionFilters = existingFiltersJson.find(
      (filter) => filter.type === 'selection'
    )
    var currentSelectionFilter = selectionFilters?.filters?.find(
      (filter) => filter.target === target
    )
    currentSelectionFilter && (currentSelectionFilter.searchValue = value)
    currentSelectionFilter &&
      (currentSelectionFilter.searchedFilters = availableFilters.filter(
        (filter) => {
          return filter.label.includes(value)
        }
      ))
    onFilterChange(existingFiltersJson, target)
  }

  const handleSelectAll = (event, checked, availableFilters, target) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(state.filtersJson))
    var selectionFilters = existingFiltersJson.find(
      (filter) => filter.type === 'selection'
    )
    var currentSelectionFilter = selectionFilters?.filters?.find(
      (filter) => filter.target === target
    )
    currentSelectionFilter && (currentSelectionFilter.selectAll = checked)
    var appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    if (appliedFilters && Array.isArray(appliedFilters)) {
      var current = appliedFilters.find((filter) => {
        for (const key in filter) {
          if (key === target) {
            return true
          } else {
            return false
          }
        }
      })
      if (checked) {
        if (current && Array.isArray(current[target])) {
          current[target] = []
          availableFilters &&
            availableFilters.forEach((filter) => {
              current[target].push(filter.id)
            })
          onFilterChange(existingFiltersJson, target)
        } else {
          var newFilter = {}
          newFilter[target] = []
          availableFilters &&
            availableFilters.forEach((filter) => {
              newFilter[target].push(filter.id)
            })
          appliedFilters.push(newFilter)
          onFilterChange(existingFiltersJson, target)
        }
      } else {
        current && current[target] ? (current[target] = []) : null
        onFilterChange(existingFiltersJson, target)
      }
    }
  }

  const FilterComponent = useMemo(() => {
    if (state && state.filtersJson && state.filtersJson.length > 0) {
      return (
        <>
          {options && options.enableSearch && (
            <div style={{ width: searchWidth }}>
              <TextField
                width={searchWidth}
                id='filter-search'
                type='search'
                icon={<Search />}
                placeholder='what are you looking for today?'
                value={getTargetFilterStatus('searchFilterText')}
                onChange={handleSearchChange}
                className={clsx(
                  styles.searchField,
                  classes && classes.searchField
                )}
                {...searchFieldProps}
              />
            </div>
          )}
          {options && options.enableFilters && (
            <>
              <div className={clsx(styles.root, classes && classes.root)}>
                {clearButtonEnabled && (
                  <>
                    <Button
                      onClick={clearFilters}
                      className={clsx(
                        styles.clearButton,
                        classes && classes.clearButton
                      )}
                      {...clearButtonProps}
                    >
                      Clear
                    </Button>
                  </>
                )}
                {state &&
                  state.filtersJson &&
                  state.filtersJson.map((filterType) => {
                    if (filterType.type === 'selection') {
                      const filters = filterType.filters
                      return (
                        <>
                          {filters.map((filter) => {
                            var target = filter.target
                            var availableFilters = filter.availableFilters
                            var searchedFilters =
                              filter.searchedFilters || availableFilters
                            var label = getFilterLabel(target, availableFilters)
                            var selectAll = filter.selectAll
                            var searchValue = filter.searchValue
                            return (
                              <FormControl
                                key={target}
                                className={clsx(
                                  styles.formControl,
                                  classes && classes.formControl
                                )}
                              >
                                <div
                                  id={target}
                                  className={clsx(
                                    styles.button,
                                    classes && classes.button
                                  )}
                                  onClick={(event) =>
                                    handleClickFilter(event, target)
                                  }
                                >
                                  <label
                                    className={clsx(
                                      styles.buttonLabel,
                                      classes && classes.buttonLabel
                                    )}
                                  >
                                    <span
                                      className={clsx(
                                        styles.filterLabel,
                                        classes && classes.filterLabel
                                      )}
                                    >
                                      {filter.label !== ''
                                        ? `${filter.label}:`
                                        : 'Type:'}
                                    </span>
                                    {label !== '' ? (
                                      <LightTooltip
                                        title={label}
                                        placement='left'
                                        interactive
                                        arrow
                                      >
                                        <span
                                          className={clsx(
                                            styles.filterSelected,
                                            classes && classes.filterSelected
                                          )}
                                        >
                                          {label}
                                        </span>
                                      </LightTooltip>
                                    ) : (
                                      <span
                                        className={clsx(
                                          styles.filterPlaceholder,
                                          classes && classes.filterPlaceholder
                                        )}
                                      >
                                        Select
                                      </span>
                                    )}
                                  </label>
                                  {popoverId === target ? (
                                    <Collapse
                                      className={clsx(
                                        styles.buttonIcon,
                                        classes && classes.buttonIcon
                                      )}
                                    />
                                  ) : (
                                    <Expand
                                      className={clsx(
                                        styles.buttonIcon,
                                        classes && classes.buttonIcon
                                      )}
                                    />
                                  )}
                                </div>
                                <Popover
                                  id={target}
                                  open={popoverId === target}
                                  anchorEl={anchorEl}
                                  onClose={handleCloseFilter}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                  }}
                                  PaperProps={{
                                    component: 'div',
                                    square: true,
                                    elevation: 0,
                                    classes: { root: styles.paperRoot }
                                  }}
                                >
                                  <div
                                    className={clsx(
                                      styles.dropdown,
                                      classes && classes.dropdown
                                    )}
                                  >
                                    <div
                                      className={clsx(
                                        styles.searchBox,
                                        classes && classes.searchBox
                                      )}
                                    >
                                      <TextField
                                        className={clsx(
                                          styles.filterSearchTextField,
                                          classes &&
                                            classes.filterSearchTextField
                                        )}
                                        value={searchValue}
                                        placeholder='Search...'
                                        type='search'
                                        icon={<Search fill='#C2C2C2' />}
                                        onChange={(event) =>
                                          handleFilterSearchChange(
                                            event,
                                            event.target.value,
                                            availableFilters,
                                            target
                                          )
                                        }
                                      />
                                      <div
                                        className={clsx(
                                          styles.selectAllContainer,
                                          classes && classes.selectAllContainer
                                        )}
                                      >
                                        <div
                                          key='select_all'
                                          className={clsx(
                                            styles.selectAll,
                                            classes && classes.selectAll
                                          )}
                                          onClick={(e) => {
                                            handleSelectAll(
                                              e,
                                              !selectAll,
                                              availableFilters,
                                              target
                                            )
                                          }}
                                        >
                                          <div
                                            className={clsx(
                                              styles.itemCheckBox,
                                              styles.itemCheckBoxChecked,
                                              classes && classes.itemCheckBox,
                                              selectAll &&
                                                classes &&
                                                classes.itemCheckBoxChecked
                                            )}
                                          >
                                            {selectAll && <CheckboxSelected />}
                                            {!selectAll && <Checkbox />}
                                          </div>
                                          <Typography
                                            className={clsx(
                                              styles.itemLabel,
                                              classes && classes.itemLabel
                                            )}
                                          >
                                            Select All
                                          </Typography>
                                        </div>
                                        <Button
                                          onClick={(event) => {
                                            handleSelectAll(
                                              event,
                                              false,
                                              availableFilters,
                                              target
                                            )
                                          }}
                                        >
                                          CLEAR
                                        </Button>
                                      </div>
                                    </div>
                                    <div
                                      className={clsx(styles.separator)}
                                    ></div>
                                    <div
                                      className={clsx(
                                        styles.itemList,
                                        classes && classes.itemList
                                      )}
                                    >
                                      {searchedFilters &&
                                        searchedFilters.map((item) => {
                                          const checked = getTargetFilterStatus(
                                            target,
                                            item.id
                                          )
                                          const label =
                                            item.label + ' (' + item.count + ')'
                                          return (
                                            <div
                                              key={item.id}
                                              className={clsx(
                                                styles.item,
                                                classes && classes.item
                                              )}
                                              onClick={(e) => {
                                                handleSelectionChange(
                                                  e,
                                                  !checked,
                                                  item.id,
                                                  target
                                                )
                                              }}
                                            >
                                              <div
                                                className={clsx(
                                                  styles.itemCheckBox,
                                                  styles.itemCheckBoxChecked,
                                                  classes &&
                                                    classes.itemCheckBox,
                                                  checked &&
                                                    classes &&
                                                    classes.itemCheckBoxChecked
                                                )}
                                              >
                                                {checked && (
                                                  <CheckboxSelected />
                                                )}
                                                {!checked && <Checkbox />}
                                              </div>
                                              <Typography
                                                className={clsx(
                                                  styles.itemLabel,
                                                  checked &&
                                                    styles.checkedItemLabel,
                                                  classes && classes.itemLabel
                                                )}
                                              >
                                                {label}
                                              </Typography>
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                </Popover>
                              </FormControl>
                            )
                          })}
                        </>
                      )
                    } else if (filterType.type === 'flag') {
                      const filters = filterType.filters
                      return (
                        <FormControl
                          key='flag'
                          className={classes && classes.formControl}
                        >
                          <div
                            className={classes && classes.button}
                            onClick={(event) =>
                              handleClickFilter(event, 'flag')
                            }
                          >
                            <div className={classes && classes.buttonLabel}>
                              Flags
                            </div>
                            {popoverId === 'flag' ? (
                              <Expand
                                className={classes && classes.buttonIcon}
                              />
                            ) : (
                              <Collapse
                                className={classes && classes.buttonIcon}
                              />
                            )}
                          </div>
                          <Popover
                            id='flag'
                            open={popoverId === 'flag'}
                            anchorEl={anchorEl}
                            onClose={handleCloseFilter}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            PaperProps={{
                              component: 'div',
                              square: true
                            }}
                          >
                            <div className={classes && classes.dropdown}>
                              {filters.map((filter) => {
                                var target = filter.target
                                var appliedFilters =
                                  getTargetFilterStatus(target)
                                let label =
                                  filter.label + ' (' + filter.count + ')'
                                return (
                                  <FormControlLabel
                                    key={target}
                                    className={classes && classes.switchItem}
                                    control={
                                      <Switch
                                        className={
                                          classes && classes.switchComponent
                                        }
                                        id='relatedRecommendation'
                                        checked={appliedFilters}
                                        onChange={(e) =>
                                          handleFlagChange(e, target)
                                        }
                                        value={appliedFilters}
                                        color='primary'
                                        inputProps={{
                                          'aria-label': 'primary checkbox'
                                        }}
                                      />
                                    }
                                    label={label}
                                    labelPlacement='start'
                                  />
                                )
                              })}
                            </div>
                          </Popover>
                        </FormControl>
                      )
                    }
                  })}
              </div>
              {options && options.disaplyFilterChips && (
                <FilterChips
                  filtersJson={state.filtersJson}
                  onFilterChange={onFilterChange}
                />
              )}
            </>
          )}
        </>
      )
    }
  }, [state, popoverId, options])

  return (
    <div
      className={clsx(
        styles.filterContainer,
        classes && classes.filterContainer
      )}
    >
      {FilterComponent}
    </div>
  )
}
