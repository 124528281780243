import styles from './PageSelector.module.scss'
import Item from './PageSelector/Item'
import React, { useContext } from 'react'
import NumPages from './NumPages'
import Pagination from '../Pagination'
import LeftArrow from '../../../../iconComponents/LeftArrow'
import RightArrow from '../../../../iconComponents/RightArrow'

export default function PageSelector({ total }) {
  let [count] = useContext(NumPages.Selected) || [0]
  const [offset] = useContext(Pagination.Offset) || [0]

  if (!count) {
    count = total
  }

  const pageCount = Math.ceil(total / count)

  const currentPage = parseInt(offset / count)
  const middleCount = 5
  let firstPage = 1
  if (currentPage > middleCount - 1) {
    firstPage = Math.min(currentPage - 1, pageCount - middleCount)
  }
  let numItems = Math.min(pageCount, middleCount)
  const showPrevSkipped = firstPage > 1
  const showNextSkipped =
    pageCount > middleCount + 2 && firstPage < pageCount - middleCount

  numItems -= !!showPrevSkipped + !!showNextSkipped

  const elements = new Array(numItems)
    .fill(0)
    .map((_, index) => {
      const pageIndex = firstPage + index
      if (pageIndex >= pageCount - 1) {
        return null
      }
      return <Item key={pageIndex} index={pageIndex} label={pageIndex + 1} />
    })
    .filter(Boolean)

  const isFirstPage = !currentPage
  const isLastPage = currentPage === pageCount - 1

  return (
    <div className={styles.root}>
      <Item
        index={currentPage - 1}
        disabled={isFirstPage}
        selectable={false}
        label={<LeftArrow />}
      />
      <Item index={0} label={1} />
      {!!showPrevSkipped && <div className={styles.skipped}>…</div>}
      {elements}
      {!!showNextSkipped && <div className={styles.skipped}>…</div>}
      {pageCount > 1 && <Item index={pageCount - 1} label={pageCount} />}
      <Item
        index={currentPage + 1}
        disabled={isLastPage}
        selectable={false}
        label={<RightArrow />}
      />
    </div>
  )
}
