import styles from './Header.module.scss'
import Count from '../Count'
import Actions from './Header/Actions'
import Filter from '../Filter'
import React, { useContext } from 'react'
import Mode from '../../Util/Mode'
import clsx from 'clsx'
import Table from '../Table'
import Search from '../Search'
import AdditionalFilters from '../AdditionalFilters'

export default function Header(props) {
  const [small] = useContext(Mode.Small) || []
  const [value, setValue] = useContext(Table.Search) || []
  return (
    <div className={clsx(styles.root, small && styles.small)}>
      {(props?.toolbarActions || props?.title || props?.searchEnabled) && (
        <div className={styles.row}>
          {props.title && <Count label={props.title} total={props.total} />}
          {props.additionalFilters && <div className={styles.separator} />}
          {Array.isArray(props.additionalFilters) &&
            props.additionalFilters.length > 0 &&
            props.additionalFilters.map((additionalFilter, index) => {
              return (
                <React.Fragment>
                  {additionalFilter.separatorNeeded && (
                    <div className={styles.separator} />
                  )}
                  <AdditionalFilters
                    additionalFilter={additionalFilter}
                    index={index}
                  />
                </React.Fragment>
              )
            })}
          {props.searchEnabled && <div className={styles.separator} />}
          {props.searchEnabled && (
            <Search
              value={value}
              setValue={setValue}
              width={props.searchWidth}
              isAutoSearch={props.isAutoSearch}
            />
          )}
          <div style={{ flex: 1 }} />
          <Actions {...props} />
        </div>
      )}
      {props.filterEnabled && <Filter />}
    </div>
  )
}
