import * as React from 'react'

function SvgHelp(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10em'
      height='10em'
      viewBox='0 0 14 15'
      fill='#3381FF'
      {...props}
    >
      <path
        d='M7 14.531A6.782 6.782 0 106.999.971 6.782 6.782 0 007 14.53zm0-1.312A5.466 5.466 0 011.531 7.75 5.468 5.468 0 017 2.281a5.468 5.468 0 015.469 5.469A5.466 5.466 0 017 13.219zm.624-3.938a.328.328 0 00.328-.328V8.78c0-.678 1.98-.706 1.98-2.54 0-1.38-1.432-2.427-2.774-2.427-1.28 0-2.104.532-2.75 1.481a.33.33 0 00.073.448l.76.576a.329.329 0 00.456-.058c.412-.519.704-.82 1.338-.82.487 0 1.088.314 1.088.785 0 .357-.294.54-.774.81-.56.313-1.301.704-1.301 1.681v.237c0 .181.147.328.328.328h1.248zM7 11.961a1.15 1.15 0 000-2.297 1.15 1.15 0 000 2.297z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgHelp
