import { useState, useEffect } from 'react'

export function useCancelAndDebounce(asyncFunction, dependencies, delay) {
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()
    const timer = setTimeout(async () => {
      try {
        await setIsLoading(true)
        const res = await asyncFunction({ signal: abortController.signal })
        await setResponse(res)
        await setIsLoading(false)
      } catch (error) {
        await setIsLoading(false)
      }
    }, delay)
    return function cancel() {
      abortController.abort()
      clearTimeout(timer)
    }
  }, [...dependencies])

  return [response, isLoading]
}
