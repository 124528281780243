import create from "zustand";

const initialState = Object.freeze({
	users: undefined,
	projects: undefined,
	invitees: undefined,
	usersIsError: undefined,
	inviteesIsError: undefined,
	inviteesIsLoading: undefined,

	usersIsLoading: undefined,
	usersError: undefined,
	inviteesError: undefined,
	accountOwnerEmail: undefined

});

const useStore = create((set) => ({
	...initialState,

	setUsers: (val) => {
		set(() => ({
			users: val
		}));
	},
	setProjects: (val) => {
		set(() => ({
			projects: val
		}));
	},
	setUsersError: (val) => {
		set(() => ({
			usersError: val
		}));
	},
	setInvitees: (val) => {
		set(() => ({
			invitees: val
		}));
	},
	setInviteesError: (val) => {
		set(() => ({
			inviteesError: val
		}));
	},
	setUsersIsError: (val) => {
		set(() => ({
			usersIsError: val
		}));
	},
	setAccountOwnerEmail: (val) => {
		set(() => ({
			accountOwnerEmail: val
		}));
	},
	setInviteesIsError: (val) => {
		set(() => ({
			inviteesIsError: val
		}));
	},
	setUsersIsLoading: (val) => {
		set(() => ({
			usersIsLoading: val
		}));
	},
	setInviteesIsLoading: (val) => {
		set(() => ({
			inviteesIsLoading: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
