import React from "react";
import CustomAppBar from "../../AppBar";
import SideBar from "../../SideBar";
import MainPanel from '../../MainPanel';

export default function HomePage() {
	return (
		<div>
			<CustomAppBar/>
			<SideBar/>
			<MainPanel/>
		</div>
	);
}
