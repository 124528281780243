import styles from './Search.module.scss'
import React, { Fragment, useEffect, useState } from 'react'
import SearchIcon from '../../iconComponents/Search'
import ClearIcon from '../../iconComponents/Clear'
import clsx from 'clsx'
import Input from './Input'
import ID, { useID } from '../Util/ID'

export default function Search({
  className,
  value,
  setValue,
  width,
  isAutoSearch = true
}) {
  const baseID = useID()
  const [tempValue, setTempValue] = useState(value || '')
  useEffect(() => {
    if (!tempValue.length) {
      setValue('')
      setTempValue('')
    }
  }, [tempValue])
  return (
    <div style={{ marginTop: '6px', marginLeft: '8px' }}>
      <ID id='search'>
        <Input
          id={baseID + 'search'}
          placeholder='Search...'
          className={clsx(styles.input, className)}
          width={width}
          value={tempValue}
          setValue={isAutoSearch ? setValue : setTempValue}
          onKeyPress={(event) => {
            const inputValue = event.target.value
            if (event.charCode === 13 && inputValue?.length) {
              setValue(inputValue)
            }
          }}
          suffix={
            <>
              {(!tempValue || !isAutoSearch) && (
                <SearchIcon
                  className={clsx(
                    styles.icon,
                    isAutoSearch ? styles.autoSearch : styles.search
                  )}
                  onClick={() => setValue(tempValue)}
                />
              )}
              {!!tempValue && isAutoSearch && (
                <ClearIcon
                  onClick={() => {
                    setValue('')
                    setTempValue('')
                  }}
                  className={clsx(styles.icon, styles.clear)}
                />
              )}
            </>
          }
        />
      </ID>
    </div>
  )
}
