import React from 'react'
import clsx from 'clsx'
import styles from './tooltip.module.scss'
export const Tooltip = ({
    /**
     * content :-
     * 
     * This attribute determines the content of the tooltip,
     * Default - empty
    */
    content, 
    /**
     * arrow :-
     * 
     * If `false`, tooltip renders without an arrow, 
     * Default - true
    */
    arrow=true, 
    /**
     * children :-
     * 
     * This attribute determines tooltip reference element, 
     * Default - empty
    */
    children,
    /**
     * disable :-
     * 
     * If `true`, tooltip will not appear.
     * Default - false
    */
    disable = false
}) => {
    return (
        <React.Fragment>
        {!disable ?
        <div className={ clsx(styles.tooltip) } data-testid="Tooltip"> {children}
            <span className={ clsx(arrow && styles.arrow, styles.tooltiptext) } data-testid="Content">{content}</span>
        </div> : children
        }
        </React.Fragment>
    )
}
