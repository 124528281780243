import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useUsersStore } from "../../../state-management";
import Header from "./Header";
import { Info } from "@ip-synamedia/syna-ui-library";
import { useCleanupEffect } from "../../../custom-hooks";

const useStyles = makeStyles(() => ({
	root: {
		marginTop: 20,
		height: "100%"
	},
	selectAccountContainer: {
		position: "relative",
		width: "100%",
		height: "100%"
	},
	selectAccountInfo: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	selectAccountLabel: {
		fontSize: 25,
		marginTop: 10
	}
}));

export default function UsersPanel() {
	const classes = useStyles();
	const [accountId, resetUserStore, activeTabComponent] = useUsersStore(state => [state.accountId, state.reset, state.activeTabComponent]);

	useCleanupEffect(resetUserStore);

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<div style={{ height: 100 }}>
				<Header/>
			</div>
			<div className={classes.root}>
				{!accountId && <div className={classes.selectAccountContainer}>
					<div className={classes.selectAccountInfo}>
						<Info style={{ display: "block", margin: "auto" }} fill='#3381FF' width='4em' height='4em'/>
						<div className={classes.selectAccountLabel}>
						Select account to manage users
						</div>
					</div>
				</div>}
				{accountId && activeTabComponent}
			</div>
		</div>
	);
}
