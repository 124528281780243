import React, { Fragment, useContext } from "react"
import Button from "../../Button"
import Menu from "../..//Menu"
import Contexts from "../../../Util/Contexts"

export default function NumPages() {
    const [items] = useContext(NumPages.Items) || [];
    const [selected] = useContext(NumPages.Selected) || [];
    const components = [
        {
            Component: Menu,
            Items: NumPages.Items,
            Selected: NumPages.Selected,
            Search:""
        }
    ];
    const selectedItems = (items || []).find(item => item.id === selected) || {};
    return <Fragment>
        <Contexts components={components}>
            <Menu selectable={true} style={{width:50}}>
                <Button uppercase={true} dropdown={true}>
                    {selectedItems.label}
                </Button>
            </Menu>
        </Contexts>        
    </Fragment>;
}

NumPages.Items = React.createContext();
NumPages.Selected = React.createContext();
