import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	btn: {
		fontSize: '1.3em',
		width: 150,
		padding: '9px 15px',
		background: '#ffc41c',
		textAlign: 'center',
		'&:hover': {
			cursor: 'pointer',
			background: '#eeb311'
		},
		'&:active': {
			cursor: 'pointer',
			background: '#dab74e'
		}
	}
}));

const LoginButton = () => {
	const classes = useStyles();

	const { loginWithRedirect } = useAuth0();

	return (
		<button
			className={classes.btn}
			onClick={() => {
				loginWithRedirect({
					appState: {
						returnTo: '/home'
					}
				});
			}}
		>
            Log In
		</button>
	);
};

export default LoginButton;
