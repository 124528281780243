import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as SynamediaLogo } from '../../icons/synamedia-logo-white.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { Group } from  '@ip-synamedia/syna-ui-library';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
	appBar:{
		display:"flex",
		flexDirection:"row",
		height:85,
		zIndex:0
	},
	logoAppBar:{
		display:"flex",
		paddingTop:18,
		paddingLeft:25,
		paddingRight:85,
		minWidth:280,
		backgroundColor:"#292929"
	},
	titleAppBar: {
		width:"100%",
		backgroundColor: '#3381ff',

	},
	title:{
		color:"#ffffff",
		fontFamily: "'Source Sans Pro', sans-serif",
		fontSize:28,
		lineHeight:1.29,
		letterSpacing: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		width:'max-content',
		height:36,
		position:"absolute",
		top:20,
		left:22,
	},
	userInfo:{
		backgroundColor: '#3381ff',
		display:"flex",
		flexDirection:"row",
		paddingTop:23,
		paddingRight:68
	},
	userInfoTitle:{
		color:"#ffffff",
		fontFamily: "'Source Sans Pro', sans-serif",
		fontSize:14,
		lineHeight:1.21,
		letterSpacing: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		width:'max-content',
		marginLeft:4,
		paddingTop:5
	},
	dropDownContainer:{
		position:"relative",
		// top:5,
		left:5
	},
	dropDownItemText:{
		fontFamily: "'Source Sans Pro', sans-serif",
		color: "#292929",
		fontSize:14,
		lineHeight:1.21,
		letterSpacing: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		marginLeft:5

	},
	dropDownItemIcon:{
		minWidth:"max-content"
	},
	menuItem:{
		"&:hover": {
			backgroundColor: "rgba(51, 129, 255, 0.1)"
		}
	},
	dropDownIcon: {
		padding: 2,
		color: "white"
	}

}));

function ElevationScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
	window: PropTypes.func,
};

export default function CustomAppBar(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const { logout, isAuthenticated, user } = useAuth0();

	const dropDownItems = [
		{
			id: 'logout',
			label: 'Logout',
			icon:<ExitToAppIcon fontSize={"small"}/>,
			cb:() => {
				logout({
					returnTo: window.location.origin
				});
			}
		}
	];

	const handleDropDownClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<ElevationScroll {...props}>
				<div>
					<div className={classes.appBar}>
						<div className={classes.logoAppBar}>
							<SynamediaLogo/>
						</div>
						<Toolbar className={classes.titleAppBar}>
							<Typography className={classes.title}>Synamedia Cloud Services</Typography>
						</Toolbar>
						{isAuthenticated && <div className={classes.userInfo}>
							<Group width={"2em"} height={"2em"}/>
							<Typography className={classes.userInfoTitle}>{user.nickname}</Typography>
							<div className={classes.dropDownContainer}>
								<IconButton aria-label="delete" classes={{ root: classes.dropDownIcon }} onClick={handleDropDownClick}>
									<ExpandMoreIcon  />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									getContentAnchorEl={null}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									{dropDownItems.map(item => {
										return <MenuItem className={classes.menuItem} key={item.id}  onClick={item.cb}>
											<ListItemIcon classes={{ root:classes.dropDownItemIcon }}>
												{item.icon}
											</ListItemIcon>
											<ListItemText primary={item.label} classes={{ primary:classes.dropDownItemText }} />
										</MenuItem>;
									})}
								</Menu>
							</div>
						</div>}
					</div>
				</div>
			</ElevationScroll>
			<Toolbar />
		</React.Fragment>
	);
}
