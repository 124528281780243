import React, {useContext} from "react";

export default function ID({id="", children}) {
    let baseId = useID();
    return <ID.Context.Provider value={baseId+id}>
        {children}
    </ID.Context.Provider>;
}

export function useID() {
    // let baseId = useContext(ID.Context) || "";
    let baseId = ""
    if(baseId) {
        baseId += "-";
    }
    return baseId;
}

ID.Context = React.createContext();
