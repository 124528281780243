import { makeStyles } from "@material-ui/core/styles";
import AppButton from "../../../../../../AppButton";
import React, { useEffect, useState } from "react";
import PackageElement from "./PackageElement";
import Configurations from "../Configurations";
import { useCreateTenantStore } from "../../../../../../../state-management";
import shallow from "zustand/shallow";
import AppTooltip from "../../../../../../AppTooltip";
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 30,
		top: 80,
		height: 579,
		width: 802,
		background: '#EFEFEF',
		borderRadius: 4,
		zIndex: 20,
	},
	header: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontSize: 17,
		fontWeight: 600,
		margin: 12,
		display: 'flex',
		alignItems: 'center',
	},
	separator: {
		border: '1px solid #8F8F8F',
		margin: '0px 12px',
		height: 20,
	},
	headerDescription: {
		fontSize: 12,
		fontWeight: 400,
		color: '#8F8F8F',
	},
	packagesContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '0px 6px',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 14,
	},
	packageDescription: {
		fontWeight: 400,
		fontSize: 12,
		margin: 12,
		marginTop: 0,
	},
	configurations: {
		position: 'relative',
		left: 12,
		top: 0,
		height: 350,
		width: 781,
	},
	configContainer: {
		width: 760,
		background: 'white',
	},
	featuresContainer: {
		width: 760,
		background: 'white',
	},
	feature: {
		width: 760 - 24,
	},
	buttonsContainer: {
		position: 'absolute',
		display: 'flex',
		right: 4,
		bottom: 4,
	},
	button: {
		margin: theme.spacing(1)
	},
	disabledFinishButton: {
		height: 30,
		padding: '5px 11.5px',
		margin: 8,
		borderRadius: 4,
		cursor: 'default',
		backgroundColor: '#C2C2C2',
		color: 'white',
	},
}));

export default function AddEditPackage({ packagesOptions, setAddPackageVisible, editTenant, editedPackage = null } ) {
	const styles = useStyles();

	const [packagesConfigParams, updatePackageConfigParams] = useCreateTenantStore(state => [state.packagesConfigParams, state.updatePackageConfigParams], shallow);
	const [tempPackageConfigParams, setTempPackageConfigParams] = useState(cloneDeep(packagesConfigParams[editedPackage]));
	const [packagesFeaturesConfigParams, updatePackagesFeaturesConfigParams] = useCreateTenantStore(state => [state.packagesFeaturesConfigParams, state.updatePackagesFeaturesConfigParams], shallow);
	const [tempPackagesFeaturesConfigParams, setTempPackagesFeaturesConfigParams] = useState(cloneDeep(packagesFeaturesConfigParams[editedPackage]));
	const [packagesFeatureIds, updatePackagesFeatureIds] = useCreateTenantStore(state => [state.packagesFeatureIds, state.updatePackagesFeatureIds], shallow);
	const [tempPackagesFeatureIds, setTempPackagesFeatureIds] = useState([...packagesFeatureIds[editedPackage] ?? []]);
	const [packageIds, addPackageIds] = useCreateTenantStore(state => [state.packageIds, state.addPackageIds], shallow);

	const [selectedPackageId, setSelectedPackageId] = useState(editedPackage);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		let _isValid = true;

		if (selectedPackageId != null) {
			const config = packagesOptions?.[selectedPackageId]?.config;

			config && Object.entries(config).forEach(([parameter, parameterConfig]) => {
				const required = parameterConfig?.required || false;
				const configParam = packagesConfigParams?.[selectedPackageId]?.[parameter];

				if (required && !(configParam && (configParam.value || configParam.value === 0) && !((typeof configParam.value === 'string' || Array.isArray(configParam.value)) && configParam.value.length === 0 ))) {
					_isValid = false;
				}
			});

			packagesFeatureIds?.[selectedPackageId]?.forEach( featureId => {
				const configOptions = packagesOptions[selectedPackageId]?.features?.[featureId]?.config;

				configOptions && Object.entries(configOptions).forEach(([parameter, parameterConfig]) => {
					const required = parameterConfig?.required || false;
					const configParam = packagesFeaturesConfigParams[selectedPackageId]?.[featureId]?.[parameter];

					if (required && !(configParam &&  (configParam.value || configParam.value === 0) && !((typeof configParam.value === 'string' || Array.isArray(configParam.value)) && configParam.value.length === 0 ))) {
						_isValid = false;
					}
				});
			});

		} else {
			_isValid = false;
		}
		setIsValid(_isValid);
	}, [selectedPackageId, packagesConfigParams, packagesOptions, packagesFeatureIds, packagesFeaturesConfigParams, setIsValid]);

	const handleCancel = () => {
		if (selectedPackageId != null) {
			updatePackageConfigParams(selectedPackageId, tempPackageConfigParams);
			updatePackagesFeaturesConfigParams(selectedPackageId, tempPackagesFeaturesConfigParams);
			updatePackagesFeatureIds(selectedPackageId, tempPackagesFeatureIds);
		}
		setAddPackageVisible(false);
		setIsValid(false);
	};

	const handleSelectionChange = (value, parameter, type) => {
		const packageConfigParam = { ...packagesConfigParams[selectedPackageId] };

		delete packageConfigParam[parameter];
		if (value != null) {
			packageConfigParam[parameter] = {
				value,
				type
			};
		}
		updatePackageConfigParams(selectedPackageId, packageConfigParam);
	};

	function addFeatureIds(featureId) {
		const featureIds = [...packagesFeatureIds[selectedPackageId] ?? [], featureId];

		updatePackagesFeatureIds(selectedPackageId, featureIds);
	}

	function setFeatureIds(featureIds) {
		updatePackagesFeatureIds(selectedPackageId, featureIds);
	}

	const handleFeatureSelectionChange = (featureId, value, parameter, type) => {
		const featureConfigParamPackage = { ...packagesFeaturesConfigParams[selectedPackageId] };

		delete featureConfigParamPackage[featureId][parameter];
		if (value != null) {
			featureConfigParamPackage[featureId][parameter] = {
				value,
				type
			};
		}
		updatePackagesFeaturesConfigParams(selectedPackageId, featureConfigParamPackage);
	};

	const handleAddPackage = () => {
		editedPackage == null && addPackageIds(selectedPackageId);
		setAddPackageVisible(false);
	};

	function handlePackageChange(packageId) {
		if (!packageIds?.includes(packageId)) {
			if (selectedPackageId != null) {
				updatePackageConfigParams(selectedPackageId, tempPackageConfigParams);
				updatePackagesFeaturesConfigParams(selectedPackageId, tempPackagesFeaturesConfigParams);
				updatePackagesFeatureIds(selectedPackageId, tempPackagesFeatureIds);
			}
			setTempPackageConfigParams({ ...packagesConfigParams[packageId] });
			setTempPackagesFeaturesConfigParams({ ...packagesFeaturesConfigParams[packageId] });
			setTempPackagesFeatureIds([...packagesFeatureIds[packageId] ?? []]);
			setSelectedPackageId(packageId);
		}
	}

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				{ (editedPackage ? 'Edit' : 'Add') + ' Package'}
				<div className={styles.separator}/>
				<div className={styles.headerDescription}>{ editedPackage ? 'Please make the desired changes to the package' : 'Please select one of the packages below' }</div>
			</div>
			<div className={styles.packagesContainer}>
				{ !editedPackage && packagesOptions && Object.entries(packagesOptions).map( ( [packageId, packageOptions], index) =>
					<PackageElement key={index} packageId={packageId} item={packageOptions} selected={selectedPackageId === packageId} handleClick={() => handlePackageChange(packageId)}/>) }
				{ editedPackage && packagesOptions &&
					<PackageElement packageId={editedPackage} item={packagesOptions[editedPackage]} selected={true} edited={true}/> }
			</div>
			{ selectedPackageId != null && packagesOptions && <div>
				<div className={styles.packageDescription}>
					{ packagesOptions?.[selectedPackageId]?.description }
				</div>
				<Configurations
					configOptions={packagesOptions[selectedPackageId]?.config}
					configParams={packagesConfigParams[selectedPackageId]}
					handleSelectionChange={handleSelectionChange}
					featuresOptions={packagesOptions[selectedPackageId]?.features}
					featuresConfigParams={packagesFeaturesConfigParams[selectedPackageId]}
					featureIds={packagesFeatureIds[selectedPackageId] ?? []}
					addFeatureIds={addFeatureIds}
					setFeatureIds={setFeatureIds}
					handleFeatureSelectionChange={handleFeatureSelectionChange}
					maxParameterLabelWidth={250}
					editTenant={ editTenant && selectedPackageId && packageIds.includes(selectedPackageId)}
					overrideClasses={{
						configurations: styles.configurations,
						card: styles.configContainer,
						featuresContainer: styles.featuresContainer,
						feature: styles.feature
					}}/>
			</div>}
			<div className={styles.buttonsContainer}>
				<AppButton overrideClasses={ { button: styles.button } } variant={'outlined'} onClick={handleCancel}>CANCEL</AppButton>
				{!isValid && <AppTooltip title={'please set required configurations'}>
					<div className={styles.disabledFinishButton}>{ editedPackage ? 'SAVE' : 'ADD PACKAGE' }</div>
				</AppTooltip>}
				{isValid && <AppButton overrideClasses={ { button: styles.button } } variant={'contained'} onClick={handleAddPackage}>{ editedPackage ? 'SAVE' : 'ADD PACKAGE' }</AppButton>}
			</div>
		</div>
	);
}