import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))

export default function FilterChips({ filtersJson, onFilterChange }) {
  const classes = useStyles()

  const closeFilterChip = (target, value) => {
    var existingFiltersJson = JSON.parse(JSON.stringify(filtersJson))
    const appliedFilters =
      existingFiltersJson && existingFiltersJson.length > 0
        ? existingFiltersJson[0].appliedFilters
        : null
    if (appliedFilters && appliedFilters.length > 0) {
      appliedFilters.forEach((filter, filterIndex) => {
        for (const key in filter) {
          if (key === target) {
            if (Array.isArray(filter[target]) && filter[target].length > 0) {
              var index = filter[target].indexOf(value)
              if (index !== -1) {
                filter[target].splice(index, 1)
                onFilterChange(existingFiltersJson)
              }
            } else {
              appliedFilters.splice(filterIndex, 1)
              onFilterChange(existingFiltersJson)
            }
          }
        }
      })
    }
  }

  const FilterChipSet = useMemo(() => {
    const appliedFilters =
      filtersJson && filtersJson.length > 0
        ? filtersJson[0].appliedFilters
        : null
    if (appliedFilters) {
      return (
        <div>
          <Box className={classes.root}>
            <Typography variant='body1'>Filters Applied :</Typography>
            {appliedFilters &&
              appliedFilters.map((appliedFilter) => {
                const target =
                  Object.keys(appliedFilter) &&
                  Object.keys(appliedFilter).length > 0
                    ? Object.keys(appliedFilter)[0]
                    : null
                if (target) {
                  const filterValues = appliedFilter[target]
                  if (Array.isArray(filterValues)) {
                    return (
                      <>
                        {filterValues &&
                          filterValues.map((filterValue) => {
                            if (
                              filterValue !== null &&
                              filterValue !== undefined
                            ) {
                              return (
                                <Chip
                                  key={filterValue}
                                  label={filterValue}
                                  onDelete={(e) =>
                                    closeFilterChip(target, filterValue)
                                  }
                                  className={classes.chip}
                                />
                              )
                            }
                          })}
                      </>
                    )
                  } else {
                    if (
                      filterValues !== null &&
                      filterValues !== undefined &&
                      filterValues !== ''
                    ) {
                      return (
                        <Chip
                          key={target + ' - ' + filterValues}
                          label={target + ' - ' + filterValues}
                          onDelete={(e) =>
                            closeFilterChip(target, filterValues)
                          }
                          className={classes.chip}
                        />
                      )
                    }
                  }
                }
              })}
          </Box>
        </div>
      )
    }
  }, [filtersJson])

  return <div>{FilterChipSet}</div>
}
