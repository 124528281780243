import * as React from 'react'

function SvgSortDescActive(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 12 11'
      {...props}
    >
      <path
        d='M9.166 3c.585 0 .878.708.462 1.121L6.374 7.375a.654.654 0 01-.927 0L2.193 4.121a.656.656 0 01.465-1.12h6.508z'
        fill='#003b99'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgSortDescActive
