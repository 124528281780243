import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

const useInputStyles = makeStyles(() => {
	const inputPaddingX = 12;
	const inputPaddingY = 5;
	const borderWidth = 1;

	return {
		rootContainer: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'start',
		},
		labelContainer: {
			display:"flex",
			flexDirection:"row"
		},
		label: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			display: 'flex',
			justifyContent: 'start',
			width: '100%',
			height: '100%'
		},
		requireLabel: {
			color:'red',
			marginLeft: 3,
		},
		textArea: {
			width: `calc(100% - ${2 * (inputPaddingX + borderWidth)}px)`,
			height: '100%',
			border: `${borderWidth}px solid #C2C2C2`,
			boxSizing: 'borderBox',
			borderRadius: 4,
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			resize : 'none',
			padding: `${inputPaddingY}px ${inputPaddingX}px`,
			'&:focus,focus-visible': {
				borderColor: 'hsla(217, 100%, 60%, 1)',
				outline: 'none',
			},
		},
		inputError: {
			borderColor: 'hsla(0, 100%, 66%, 1)',
			'&:focus,focus-visible': {
				borderColor: 'hsla(0, 100%, 66%, 1)',
			}
		},
		inputErrorMessage: {
			color: 'hsla(0, 100%, 66%, 1)',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 12,
			minHeight: 16
		}
	};
});

function AppTextArea( { name, id, label, ref, onChange, error, errorMessage, placeholder, value, onEnterPress, autoFocus, overrideClasses={}, required= false } ) {
	const styles = useInputStyles();
	const [firstRender, setFirstRender] = useState();

	const handleChange = (value) => {
		firstRender && setFirstRender(false);
		onChange?.(value);
	};

	const handleKeyPress = (key) => {
		if (key === 'Enter') {
			onEnterPress?.();
		}
	};

	useEffect(() => {
		setFirstRender(true);
	}, []);
	const showError = !firstRender && error;

	return (
		<div className={ styles.rootContainer }  id={id}>
			<div className={styles.labelContainer}>
				<div className={styles.label}>{ label }</div>
				{ required && <div className={styles.requireLabel}>*</div> }
			</div>
			<textarea ref={ref} placeholder={placeholder} autoFocus={autoFocus} onKeyPress={e => handleKeyPress(e.key)} value={value} name={name} className={ (clsx( styles.textArea, overrideClasses?.textArea, showError &&  styles.inputError)) } onChange={e => handleChange(e.target.value)}/>
			<div className={styles.inputErrorMessage}>{showError && errorMessage}</div>
		</div>
	);
}

export default AppTextArea;
