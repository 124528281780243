import React from 'react'
import { FixedSizeTree } from 'react-vtree'

// This helper function constructs the object that will be sent back at the step
// [2] during the treeWalker function work. Except for the mandatory `data`
// field you can put any additional data here.
const getNodeData = (node, nestingLevel,props) => ({
    data: {
        id: props && props.identifier ? node[props.identifier].toString() : node.id.toString(), // mandatory
        isLeaf: !node.children || node.children.length === 0,
        isOpenByDefault: true, // mandatory
        nestingLevel,
        node,
        identifier: props.identifier || "id",
        props: props
    },
    nestingLevel,
    node
})

// The `treeWalker` function runs only on tree re-build which is performed
// whenever the `treeWalker` prop is changed.
function * treeWalker (items,props) {
    // Step [1]: Define the root node of our tree. There can be one or
    // multiple nodes.
    for (let i = 0; i < items.length; i++) {
        yield getNodeData(items[i], 0,props)
    }

    while (true) {
    // Step [2]: Get the parent component back. It will be the object
    // the `getNodeData` function constructed, so you can read any data from it.
        const parent = yield

        if (!parent.node.children) {
            continue
        }

        if(parent.node.children) {
            for (let i = 0; i < parent.node.children.length; i++) {
                // Step [3]: Yielding all the children of the provided component. Then we
                // will return for the step [2] with the first children.
                yield getNodeData(parent.node.children[i], parent.nestingLevel + 1,props)
            }
        }
    }
}

export default function Tree ({ onScroll, items, children, ...props }) {
    if(!items || !items.length) {
        return null;
    }
    const onTreeScroll = ({scrollOffset}) => {
        onScroll && onScroll(scrollOffset);
    };
    return <FixedSizeTree onScroll={onTreeScroll} treeWalker={treeWalker.bind(null, items,props)} {...props}>
        {children}
    </FixedSizeTree>
}
