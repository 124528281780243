import styles from './Dropdown.module.scss'
import React, { useState, useRef, Fragment } from 'react'
import clsx from 'clsx'
import Modal from '../Core/Util/Modal'
import { useWindowSize } from '../../util/size'
import ID, { useID } from '../Core/Util/ID'
import { Button } from '../button/Button'
import { TextField } from '../textField/TextField'
import {
  Options as OptionsIcon,
  DropDown as DropDownIcon
} from '../iconComponents'

export function Dropdown({
  className,
  style,
  /**
   * relativePos :-
   *
   * The relativePos indicates top, left position of dropdown modal relative to dropdown button
   * Default - empty
   */
  relativePos,
  /**
   * options :-
   *
   * The options to be rendered on dropdown modal for selection,
   * Default - empty
   */
  options,
  /**
   * selected :-
   *
   * The id of the option that has to be selected,
   * Default - empty
   */
  selected,
  /**
   * disabled:-
   *
   * If `true`, dropdown's on-click/on-hover actions will be disabled,
   * Default - false
   */
  disabled = false,
  /**
   * onSelectionChange :-
   *
   * The method will be triggered on selection change of options,
   * Default - empty
   */
  onSelectionChange,
  /**
   * variant:-
   *
   * Supports - "dropdown" | "menu",
   * Default - dropdown
   */
  variant = 'dropdown',
  /**
   * component:-
   *
   * Supports - "button" | "textField" | "icon",
   * Default - button
   */
  component = 'button',
  /**
   * componentProps:-
   *
   * Props of corresponding component(button, textField, icon) which needs to be overridden,
   * Default - empty
   */
  componentProps,
  /**
   * placeholder:-
   *
   * default label if nothing is selected,
   * Default - Select
   */
  placeholder = 'Select',
  classes,
  children
}) {
  const baseID = useID()
  const windowSize = useWindowSize()
  const ref = useRef()

  const [selectedOption, setSelectedOption] = useState(
    options && options.find((option) => option.id === selected)
  ) || { label: '', id: '' }
  const [visible, setVisible] = useState(false)

  const closeMenu = (event) => {
    event.stopPropagation()
    setVisible(false)
  }

  const handleClick = (e, value) => {
    onSelectionChange && onSelectionChange(value.id, e)
    setSelectedOption(value)
    setVisible(false)
  }

  const onClick = (event) => {
    event.stopPropagation()
    setVisible(true)
  }

  var rect = (ref.current && ref.current.getBoundingClientRect()) || {}

  style = { ...style }
  if (!style.right) {
    style.left = rect.left
  }
  if (!style.width) {
    style.width = Math.max(rect.width, 150)
  }
  style.top = rect.bottom
  if (relativePos) {
    if (relativePos.left) {
      style.left += relativePos.left
    }
    if (relativePos.top) {
      style.top += relativePos.top
    }
  }
  const menuHeight = Math.min((options && options.length + 1) * 30, 300)
  if (windowSize && windowSize.height - style.top < menuHeight) {
    style.top = rect.top - menuHeight
  }
  if (windowSize && windowSize.width - style.left < style.width) {
    style.left = rect.left - style.width
  }

  const selectedLabel =
    selectedOption && selectedOption.label ? selectedOption.label : placeholder
  const buttonComponent = (
    <Button id='Dropdown' uppercase dropdown disabled={!!disabled}>
      {selectedLabel}
    </Button>
  )

  const InputComponent =
    children ||
    {
      button: buttonComponent,
      textField: (
        <TextField
          value={selectedLabel}
          placeholder={placeholder}
          icon={<DropDownIcon fill='#8F8F8F' width='8px' height='10px' />}
        />
      ),
      icon: <OptionsIcon data-testid='Dropdown' width='3px' height='18px' />
    }[component]

  const isMenu = variant === 'menu'

  return (
    <Fragment>
      <div
        ref={ref}
        className={clsx(styles.component, classes && classes.component)}
        onClick={disabled ? undefined : onClick}
      >
        {React.cloneElement(InputComponent, {
          ...componentProps
        })}
      </div>
      <Modal visible={visible}>
        <div
          data-cy={baseID + 'menu-clickable'}
          className={clsx(
            styles.mask,
            visible && styles.visible,
            classes && classes.mask
          )}
          onClick={closeMenu}
        />
        <div
          data-cy={baseID + 'menu'}
          className={clsx(
            styles.menu,
            visible && styles.visible,
            classes && classes.menu
          )}
          style={style}
        >
          <ID Id='Menu'>
            {options.map((option) => {
              return (
                <div
                  data-cy={baseID + 'menuItem-' + option.id}
                  key={baseID + 'menuItem-' + option.id}
                  data-testid={option.id}
                  onClick={(e) => !option.disabled && handleClick(e, option)}
                  className={clsx(
                    styles.root,
                    selectedOption &&
                      selectedOption.id === option.id &&
                      !isMenu &&
                      styles.selected,
                    option.disabled && styles.disabled,
                    classes && classes.root
                  )}
                >
                  {!!option.icon && (
                    <div
                      data-testid='menuItem-icon'
                      className={clsx(
                        styles.menuIcon,
                        selectedOption &&
                          selectedOption.id === option.id &&
                          !isMenu &&
                          styles.selected,
                        option.disabled && styles.disabled,
                        classes && classes.menuIcon
                      )}
                    >
                      {option.icon}
                    </div>
                  )}
                  <div
                    className={clsx(
                      styles.label,
                      selectedOption &&
                        selectedOption.id === option.id &&
                        !isMenu &&
                        styles.selected,
                      option.disabled && styles.disabled,
                      classes && classes.label
                    )}
                  >
                    {option.label}
                  </div>
                </div>
              )
            })}
          </ID>
        </div>
      </Modal>
    </Fragment>
  )
}
