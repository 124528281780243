import React, { useMemo, useState } from "react";
import Permissions from "./Permissions";
import { Table } from "@ip-synamedia/syna-ui-library";
import { makeStyles } from "@material-ui/core/styles";
import RegenerateSecretDialog from "./RegenerateSecretDialog";
import EditApplicationDialog from "./EditApplicationDialog";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteApplication, getApplicationById } from "../../../../external-apis";
import Loader from "../../../Loader";
import { useQueryClient } from "react-query";
import { useMutationWithAuthorization, useQueriesWithAuthorization } from "../../../../custom-hooks";
import AppDeleteConfirmation from "../../../AppDeleteConfirmation";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => {
	const rowHeight = 71;
	const headerHeight = 38;
	const footerHeight = 49;
	const separatorHeight = 1; //Is needed to remove the last row separator from the bottom

	return {
		rootContainer: {
			overflow: 'hidden',
			height: props => `calc(${ props.applicationSize > 0 ? props.applicationSize * rowHeight + headerHeight - separatorHeight : 20}px)`
		},
		tableContainer: {
			height: props => `calc(${ props.applicationSize > 0 ? props.applicationSize * rowHeight + headerHeight + footerHeight : 0}px)`
		},
		loader: {
			height : 100
		}
	};
});

const tableHeaders = [
	{
		id: "name",
		label: "Application Name",
		placeholder: "",
		widthUnits: 1,
		searchable: false,
		filterable: false
	},
	{
		id: "clientId",
		label: "Client ID",
		placeholder: "",
		widthUnits: 2,
		searchable: false,
		filterable: false
	},
	{
		id: "permissions",
		label: "Permissions",
		placeholder: "",
		widthUnits: 6,
		searchable: false,
		filterable: false,
		elementCb: (node) => {
			return <Permissions permissions={node.permissions}/>;
		}
	},
];
const getQueries = (applicationsData, projectId, accountId, staleTime) => {
	return Object.values(applicationsData).map((app) => {
		const applicationId = app.applicationId;

		return { queryKey:['get-application-by-id', applicationId], query: getApplicationById(applicationId, projectId, accountId), staleTime: staleTime };
	});
};

export default function Applications({ accountId, projectId, projectName, handleTabStopPropagation }) {

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const applicationsData = queryClient.getQueryData(['get-application-by-account-id-and-project-id', accountId, projectId]);
	const staleTime = 120000;
	const styles = useStyles({ applicationSize: applicationsData.length??0 });
	const [regenerateData, setRegenerateData] = useState(null);
	const [deleteAppData, setDeleteAppData] = useState(null);
	const [editAppData, setEditAppData] = useState(null);
	const [regeneratePopupVisible, setRegeneratePopupVisible] = useState(false);
	const queries = getQueries(applicationsData, projectId, accountId, staleTime);

	const applicationsTable = useQueriesWithAuthorization(queries);

	const rowActions = useMemo(() => [
		{
			id: "editApplication",
			label: "Edit Application",
			icon: <ModeEditOutlineOutlinedIcon sx={{ width: '16px', height: '16px' }}/>,
			onClick: (id, data) => {
				setEditAppData(data);
			}
		},
		{
			id: "deleteApplication",
			label: "Delete Application",
			icon:<DeleteOutlineIcon sx={{ width: '16px', height: '16px' }}/>,
			onClick: (id, data) => {
				setDeleteAppData(data);
			}
		},
		{
			id: "regenerateSecret",
			label: "Regenerate Client Secret",
			icon: <ReplayOutlinedIcon sx={{ width: '16px', height: '16px' }}/>,
			onClick: (id, data) => {
				setRegenerateData(data);
				setRegeneratePopupVisible(true);
			}
		}
	], []);

	if (!applicationsTable.every(key => !key.isLoading)) return <div className={styles.loader}><Loader/></div>;
	if (applicationsTable.length>0 && applicationsTable.every(key => key.isError)) {
		const error = applicationsTable.map(key => key.error).join(',');

		return <div>Error fetching data: {error}</div>;
	}

	const handleDeleteApplication = (params) => {

		const deleteApplicationRes = mutateAsync(deleteApplication(accountId, projectId, deleteAppData?.id,{ ...params }));

		setDeleteAppData(null);

		toast.promise(deleteApplicationRes,
			{
				pending: "Deleting Application...",
				success: {
					render() {
						queryClient.invalidateQueries(['get-application-by-account-id-and-project-id', accountId, projectId]);

						return 'Deleted Successfully';
					}
				},
				error: {
					render({ data: error }) {

						return `Error Deleting Application: ${error.message}`;
					}
				}
			}, { position: toast.POSITION.TOP_CENTER }
		);
	};

	return (
		<div className={styles.rootContainer}>
			<div className={styles.tableContainer} onClick={handleTabStopPropagation}>
				<Table
					sort={"title"}
					checkboxSelection={false}
					tableData={applicationsTable.map(key => key.data)}
					filterEnabled={false}
					searchEnabled={false}
					tableHeaders={tableHeaders}
					tableActions={rowActions}
				/>
				<RegenerateSecretDialog
					visible={regeneratePopupVisible}
					setVisible={setRegeneratePopupVisible}
					regenerateData={{
						accountId: accountId,
						projectId: projectId,
						applicationName: regenerateData?.name, ...regenerateData
					}}
				/>
				<AppDeleteConfirmation
					visible={deleteAppData != null}
					label='application'
					value={deleteAppData?.name}
					handleDelete={() => handleDeleteApplication()}
					handleClose={() => setDeleteAppData(null)}
				/>
				<EditApplicationDialog
					visible={editAppData != null}
					setEditAppData={setEditAppData}
					id={editAppData?.id} accountId={accountId} projectId={projectId}
					projectName={projectName} applicationId={editAppData?.id}
					applicationDisplayName={editAppData?.name}
					permissions={editAppData?.permissions}/>
			</div>
		</div>
	);
}
