import React, { useEffect, useState } from "react";
import { Tab, Tabs } from '@mui/material';
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@material-ui/core";
import TabScrollButton from '@material-ui/core/TabScrollButton';
import AppTooltip from "../AppTooltip";

const StyledTab = styled((props) => <Tab disableRipple {...props}/>)(
	({ theme }) => ({
		textTransform: 'capitalize',
		fontWeight: 'normal',
		fontSize: theme.typography.pxToRem(14),
		color: 'hsla(0, 0%, 56%, 1)',
		margin: 0,
		minWidth: 150,
		'&.MuiTab-root': {
			padding: '15px 30px 0px 30px',
			'&:hover': {
				color:  'hsla(217, 100%, 60%, 1)',
			},
		},
		'&.Mui-selected': {
			color: 'hsla(217, 100%, 60%, 1)'
		},
	}),
);

const useStyles = makeStyles(() => ({
	label: {
		display: 'flex',
		width: 'fit-content',
	},
	closeButtonContainer:{
		marginLeft: 10,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	tabContainer:{
		display:"flex",
		flexDirection: "row",
		alignItems: "center"
	},
}));

export default function AppTabs({ activeTab, items = [], onChange, tapProps = {}, isClosable=false, onClose=null, variant='standard' }) {
	const styles = useStyles();
	const [selectedTabId, setSelectedTabId] = useState(activeTab);

	useEffect(() => {
		setSelectedTabId(activeTab);
	}, [activeTab]);

	const handleTabChange = (tabId) => {
		setSelectedTabId(tabId);
		onChange(tabId);
	};
	const tabScrollButton = withStyles(theme => ({
		root: {
			'&.Mui-disabled': {
				width: 0,
			},
		},
	}))(TabScrollButton);

	return (
		<Tabs
			ScrollButtonComponent={tabScrollButton}
			variant={variant}
			scrollButtons={"auto"}
			TabIndicatorProps={{ sx: { borderRadius: 3, backgroundColor: 'hsla(217, 100%, 60%, 1)', width: 0 }, }}
			value={selectedTabId}
			onChange={(_, value) => handleTabChange(value)}
		>

			{items.map(item =>
				<StyledTab
					key={item.id}
					value={item.id}
					label={
						<div className={styles.tabContainer}>
							<div className={styles.label}>{item.label}</div>
							{isClosable && <div onClick={(e) => onClose(item.id, e)} className={styles.closeButtonContainer}>
								<AppTooltip title={"Closing this tab will discard all changes"}>
									<CloseIcon sx={{ width: 14, height: 22 }}/>
								</AppTooltip>
							</div>}
						</div>
					}
					{...tapProps}
				/>

			)}

		</Tabs>
	);
}
