import { useAuth0 } from "@auth0/auth0-react";
import { decode } from "jsonwebtoken";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

const JWTKeys = {
	'https://account.synamedia.com/id': 'accountId',
	'https://account.synamedia.com/alias': 'accountAlias',
	'https://user.synamedia.com/id': 'userId',
	org_id: 'orgId',
	scope: 'scope',
};

const parseJWT = (decodedJWT = {}) => {
	return Object.keys(JWTKeys).reduce((userInfo, claimKey) => {
		const userInfoKey = JWTKeys[claimKey];

		if (claimKey in decodedJWT) userInfo[userInfoKey] = decodedJWT[claimKey];

		return userInfo;
	}, {});
};

const UserInfoContext = createContext({});

const UserInfoProvider = ({ children }) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const [userClaims, setUserClaims] = useState({});

	const getUserClaims = useCallback(async () => {
		try {
			const accessToken = await getAccessTokenSilently();
			const userClaims = parseJWT(decode(accessToken));

			setUserClaims(userClaims);
		}
		catch (e) {
			// TODO: Error handling
			console.error('An error occurred in UserInfoProvider', e);
		}
	}, [getAccessTokenSilently, setUserClaims]);

	useEffect(() => {
		if (!isLoading && isAuthenticated) {
			getUserClaims();
		}
	}, [getUserClaims, isAuthenticated, isLoading]);

	return (
		<UserInfoContext.Provider value={userClaims}>
			{children}
		</UserInfoContext.Provider>
	);
};

// A custom hook to get access to the provided data
export const useUserInfo = () => useContext(UserInfoContext);

// The provider
export default UserInfoProvider;
