const getQueryParams = () => {
	const urlSearchParams = new URLSearchParams(
		window?.location?.search || {}
	);

	const params = {};

	for (const [key, value] of urlSearchParams) {
		params[key] = value;
	}

	return params;
};

export {
	getQueryParams
};
