import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ProductsPerProjectRoles from "./ProductsPerProjectRoles";
import { useUsersInvitationsStore, useUsersStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import AccountRoles from "./AccountRoles";
import { inviteUser } from "../../../../external-apis";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useMutationWithAuthorization } from "../../../../custom-hooks";
import AppTextArea from "../../../AppTextArea";
import AppInput from "../../../AppInput";
import AppDialog from "../../../AppDialog";
import AppTooltip from "../../../AppTooltip";
import AppButton from "../../../AppButton";
import ProductAdminRoles from "./ProductAdminRoles";
import AppCloseConfirmation from "../../../AppCloseConfirmation";
import useFocus from "../../../../custom-hooks/useFocus";

const useStyles = makeStyles(() => ({
	dialogContainer: {
		width: 850,
		position: 'absolute'
	},
	dialogBody: {
		paddingBottom: 60,
		overflowY: "scroll",
		height: 621,
	},
	content: {
		width: 850
	},
	inviteBtmContainer: {
		marginLeft: 16,
		marginRight: 38,
	},
	userInputContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: "space-between",
		height: 166,
		padding: 12,
		borderRadius: 10,
		boxShadow: '1px 1px 10px rgba(41, 41, 41, 0.15)',
		marginBottom: 12
	},
	selectRolesContainer: {
		borderRadius: 10,
		boxShadow: '1px 1px 10px rgba(41, 41, 41, 0.15)',
	},
	actionsContainer: {
		position: "absolute",
		bottom: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		height: 50,
		width: "100%",
		backgroundColor: "white",
		zIndex: 100
	},
	cancelBtm: {
		marginLeft: "auto",
	},
	emailInput: {
		height: 60,
		width: 761
	},
	messageInput: {
		height: 30,
		width: 761
	},
	required: {
		color: 'hsla(0, 100%, 66%, 1)',
		marginLeft: 5
	},
	assignRoles: {
		display: "flex",
		flexDirection: "row",
		margin: "14px 0px 13px 32px",
		fontWeight: 600,
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		width: '100%'
	}
}));

const validateEmail = (inputText) => {
	const mailFormat = /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/;

	return inputText && !!inputText.match(mailFormat);
};

export default function InviteUsersDialog({
	visible,
	setVisible,
	closeEditConfirmationVisible,
	setCloseEditConfirmationVisible,
	projects
}) {
	const styles = useStyles();
	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const [resetState, setEmail, setMessage, setProductRolesByProjectId] = useUsersInvitationsStore(state => [state.reset, state.setEmail, state.setMessage, state.setProductRolesByProjectId], shallow);
	const [hasPermission, setHasPermission] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const accountId = useUsersStore(state => state.accountId, shallow);
	const [focusedProjects, setFocusedProjects] = useState(new Set());
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [inputRef, setInputFocus] = useFocus();

	const userInvitationData = useUsersInvitationsStore(state => ({
		email: state.email,
		message: state.message,
		accountRolesIds: state.accountRolesIds,
		productAdminRolesIds: state.productAdminRolesIds,
		productRolesByProjectId: state.productRolesByProjectId
	}), shallow);

	useEffect(() => {
		if (firstRender && projects) {
			let _productRolesByProjectId = {};

			projects.forEach((project, index) => {
				_productRolesByProjectId[project.id] = [];
			});
			setProductRolesByProjectId(_productRolesByProjectId);
		}

	}, [projects, firstRender, setProductRolesByProjectId]);

	useEffect(() => {
		const hasProductRoleSelected = () => {
			if (!userInvitationData?.productRolesByProjectId) {

				return false;
			}
			for (const projectRoles of Object.values(userInvitationData.productRolesByProjectId)) {
				if (projectRoles.length > 0) {
					return true;
				}
			}

			return false;
		};

		setHasPermission(hasProductRoleSelected() || userInvitationData.accountRolesIds?.size > 0 || userInvitationData.productAdminRolesIds?.size > 0);
	}, [userInvitationData]);

	const handleEmailChange = (email) => {
		setFirstRender(false);
		setEmail(email);
		setIsValidEmail(true);

	};

	const handleMessageChange = (message) => {
		setMessage(message);
		setFirstRender(false);
	};

	const createInvitationObj = () => {
		const {
			email,
			message,
			accountRolesIds,
			productAdminRolesIds,
			productRolesByProjectId
		} = userInvitationData;

		const membership = Object.keys(productRolesByProjectId).map(projectId => {
			return {
				projectId,
				productRoles: [].concat.apply([], productRolesByProjectId[projectId].map(role => role.id))
			};
		});

		return {
			email,
			message,
			accountRoles: [...accountRolesIds, ...productAdminRolesIds],
			membership
		};
	};

	const handleInvite = async () => {
		const _isValidEmail = validateEmail(userInvitationData.email);

		setIsValidEmail(_isValidEmail);
		if(!_isValidEmail) {
			document.getElementById('email-input').scrollIntoView();
			setInputFocus();
			inputRef.current?.scrollIntoView({ behavior: 'smooth' });

			return;
		}
		handleCancel(false);

		const invitationObj = createInvitationObj();

		const inviteUserPromise = mutateAsync(inviteUser(accountId, invitationObj));

		try {
			await toast.promise(inviteUserPromise,
				{
					pending: "Sending invitation...",
					success: {
						render({ data } = {}) {
							resetState();

							return "Invitation sent!";
						}
					},
					error: {
						render({ data: error }) {
							setVisible(true);
							const errorData = error?.message && JSON.parse(error.message);

							return errorData?.status === 409 ? "user already exists" : `Failed to send invitation ${error.message || ''}`;
						}
					}
				}, {
					position: toast.POSITION.TOP_CENTER
				}
			);
		} catch (e) {
			console.log(e.message);
		}
		queryClient.invalidateQueries(['users-invited', accountId]);
	};

	const handleCancel = (isConfirmationNeeded = true) => {
		isConfirmationNeeded = isConfirmationNeeded && (hasPermission || (userInvitationData.email !== undefined && userInvitationData.email !== ''));
		isConfirmationNeeded && setCloseEditConfirmationVisible(isConfirmationNeeded);
		if (!isConfirmationNeeded) {
			setFocusedProjects(new Set());
			setFirstRender(true);
		}
		setVisible(false);
	};

	return (
		<div className={styles.dialogContainer}>
			<AppDialog open={visible} title={'Invite user'} onClose={handleCancel}
				overrideClasses={{ body: styles.dialogBody, content: styles.content }}>
				<div className={styles.userInputContainer}>
					<AppTextArea
						value={userInvitationData.email}
						name={'email'}
						ref={inputRef}
						label='Insert an email address to invite a user'
						onChange={handleEmailChange}
						overrideClasses={{ textArea: styles.emailInput }}
						placeholder={'Enter Your Text...'}
						error={!firstRender && (!userInvitationData.email || !isValidEmail)}
						errorMessage={'Please insert a valid email'}
						required={true}
						id={'email-input'}
					/>
					<AppInput
						initVal={userInvitationData.message}
						label={'Add a message (optional)'}
						onChange={handleMessageChange}
						overrideClasses={{ input: styles.messageInput }}
						placeholder={'Enter Your Text...'}

					/>
				</div>
				<div className={styles.selectRolesContainer}>
					<div className={styles.assignRoles}>
						{'Assign Roles'}
						<div className={styles.required}>
                            *
						</div>
					</div>
					<ProductsPerProjectRoles projects={projects} focusedProjects={focusedProjects}
						setFocusedProjects={setFocusedProjects} firstRender={firstRender}
						setFirstRender={setFirstRender}/>
					<ProductAdminRoles projects={projects} isEdit={false} setFirstRender={setFirstRender}
						firstRender={firstRender}/>
					<AccountRoles setFirstRender={setFirstRender}/>
				</div>
				<div className={styles.actionsContainer}>
					<div className={styles.cancelBtm}>
						<Button onClick={handleCancel}>cancel</Button>
					</div>
					<AppTooltip
						disabled={userInvitationData.email !== '' && hasPermission && userInvitationData.accountRolesIds.size}
						title={userInvitationData.email !== undefined && userInvitationData.email !== '' ? (!hasPermission ? 'Please select at least one role' : !userInvitationData.accountRolesIds.size ? 'Please select at least one account role' : '') :"Please add at least one email address"}>
						<div className={styles.inviteBtmContainer}>
							<AppButton
								disabled={!hasPermission || !userInvitationData.email || !userInvitationData.accountRolesIds.size}
								variant={'contained'} onClick={handleInvite}>Invite</AppButton>
						</div>
					</AppTooltip>
				</div>
			</AppDialog>
			{closeEditConfirmationVisible !== undefined && <AppCloseConfirmation visible={closeEditConfirmationVisible}
				setVisible={setCloseEditConfirmationVisible}
				setParentDialogVisible={setVisible}
				text={'Are you sure you want to cancel inviting the user?'}
				resetState={() => {
					resetState();
					setFocusedProjects(new Set());
					setFirstRender(true);
				}}/>
			}
		</div>
	);
}
