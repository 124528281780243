import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getAllCustomersByAccountId, getProjectsByAccountId } from "../../../../external-apis";
import { useProjectsStore } from "../../../../state-management";
import React, { useState } from "react";
import Loader from "../../../Loader";
import ProjectCard from "./ProjectCard";
import { makeStyles } from "@material-ui/core/styles";
import shallow from "zustand/shallow";
import moment from "moment";

const useStyles = makeStyles((theme) => {
	return {
		rootContainer: {
			padding: '0 20px'
		},
	};
});

const mapProjects = (projects) => {
	return projects.reduce((projectDataByProjectId, project) => {
		const { id, displayName, description, created , customerId, errorInUpdate } = project;

		return {
			...projectDataByProjectId,
			[id]: {
				displayName,
				description,
				customerId,
				errorInUpdate,
				created: created && moment(new Date(created)).format("DD MMM YYYY, h:mm A")
			}
		};
	}, {});
};

const mapCustomers = (customers) => {
	return  customers;
};

export default function Projects() {
	const styles = useStyles();
	const accountId = useProjectsStore(state => state.accountId, shallow);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [groups] = useProjectsStore(state => [state.groups]);
	let reseller =  groups?.includes("reseller");

	const staleTime = 60000;
	const {
		data: projectsData = {},
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjects,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const {
		data: customersData = [],
		isError: customersIsError,
		isLoading: customersIsLoading,
		error: customersError
	} = useQueryWithAuthorization(['customers', accountId], getAllCustomersByAccountId(accountId, reseller), {
		select: mapCustomers,
		enabled: accountId != null,
		staleTime: staleTime
	});

	if (projectsIsLoading ||  customersIsLoading) return <Loader/>;
	if (projectsIsError) return <div>Error fetching data: {projectsError.message}</div>;
	if (customersIsError) return <div>Error fetching data: {customersError.message}</div>;

	return (
		<div className={styles.rootContainer}>
			{projectsData && Object.entries(projectsData).map(([id, project]) => {
				const { displayName, description, created, customerId, errorInUpdate } = project;

				return (
					<ProjectCard
						key={id}
						projectId={id}
						accountId={accountId}
						displayName={displayName}
						description={description}
						created={created}
						setIsDrawerOpen={setIsDrawerOpen}
						isDrawerOpen={isDrawerOpen}
						customerId={customerId}
						customersData={customersData}
						reseller={reseller}
						errorInUpdate={errorInUpdate}
					/>
				);
			})}
		</div>
	);
}
