import create from "zustand";

const initialState = Object.freeze({
	accountId: undefined,
	activeTabComponent: undefined,
	inviteUserDialogVisible: false
});

const useStore = create((set) => ({
	...initialState,

	setAccountId: (val) => {
		set(() => ({
			accountId: val
		}));
	},
	setActiveTabComponent: (val) => {
		set(() => ({
			activeTabComponent: val
		}));
	},
	setInviteUserDialogVisible: (val) => {
		set(() => ({
			inviteUserDialogVisible: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
