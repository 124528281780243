import ReactLoader from "react-loader-spinner";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	container: {
		position: "relative",
		height: "inherit"
	},
	loader: {
		/* Center vertically and horizontally */
		position: "absolute",
		top: "50%",
		left: "50%",
		margin: "-25px 0 0 -25px", /* apply negative top and left margins to truly center the element */
	}
}));

export default function Loader(props) {
	const { type = "ThreeDots", width = 100, height = 100, color = '#3381ff' } = props;
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.loader}>
				<ReactLoader
					type={type}
					color={color}
					height={height}
					width={width}
				/>
			</div>
		</div>
	);
}
