import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/Loader";

const ProtectedRoute = ({ component: Component, ...args }) => {
	const { path } = args;

	return (
		<Route
			component={withAuthenticationRequired(Component, {
				onRedirecting: () => <Loader/>,
				returnTo: path
			})}
			{...args}
		/>
	);
};

export default ProtectedRoute;
