import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCreateTenantStore, useProjectsStore } from "../../../../../../state-management";
import clsx from "clsx";
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useMutationWithAuthorization } from "../../../../../../custom-hooks";
import { createTenant as createTenantApi, updateTenant as updateTenantApi } from "../../../../../../external-apis";
import AppButton from "../../../../../AppButton";
import GeneralDetails from "./GeneralDetails";
import TenantConfiguration from "./TenantConfiguration";
import AppTooltip from "../../../../../AppTooltip";
import PackageConfiguration from "./PackageConfiguration";
import shallow from "zustand/shallow";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		height: 650,
		width: 840,
	},
	steps: {
		width: 820,
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
		marginTop: 20
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	stepLabel: {
		width: 100,
		height: 14,
		margin: "5px 6px 6px",
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 14,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1,
		letterSpacing: "normal",
		color: "#8f8f8f"
	},
	activeStepLabel: {
		width: 100,
		height: 14,
		margin: "5px 6px 6px",
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 14,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1,
		letterSpacing: "normal",
		color: "#3381ff"
	},
	completedStepLabel: {
		width: 100,
		height: 14,
		margin: "5px 6px 6px",
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 14,
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1,
		letterSpacing: "normal",
		color: "yellow"
	},
	iconContainer: {
		completed: {
			color: "yellow"
		}
	},
	buttonsContainer: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		height: 30,
		display: 'flex',
	},
	disabledFinishButton: {
		height: 30,
		padding: '5px 11.5px',
		margin: 8,
		borderRadius: 4,
		cursor: 'default',
		backgroundColor: '#C2C2C2',
		color: 'hsla(0, 0%, 100%, 1)',
	},
	button: {
		margin: theme.spacing(1)
	},
}));

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: 'transparent',
		zIndex: 1,
		color: '#7f7f7f',
		width: 25,
		height: 25,
		display: 'flex',
		borderRadius: '50%',
		border: "solid 1px #979797",
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 14,
		position: 'relative',
	},
	active: {
		color: 'white',
		backgroundColor: '#3381ff',
		border: "solid 1px transparent",
	},
	completed: {
		backgroundColor: 'transparent',
		border: "solid 1px #979797"
	},
	completedIconContainer: {
		position: 'absolute',
		top: -2,
		right: -5,
		backgroundColor: 'white',
		borderRadius: '50%',
		height: 14,
		width: 14,
	},
	completedIcon: {
		position: 'absolute',
		top: -4,
		right: -4,
		borderRadius: '50%',
		color: '#2ED15E',
	},
});

function ColorlibStepIcon(props) {
	const styles = useColorlibStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(styles.root, {
				[styles.active]: active,
				[styles.completed]: completed,
			})}
		>
			{ completed &&
				<div className={styles.completedIconContainer}>
					<CheckCircleIcon className={styles.completedIcon} sx={{ width: '18px', height: '18px' }}/>
				</div>
			}
			{String(props.icon)}
		</div>
	);
}

export default function CreateTenantSteps({ setVisible, handleCancel, setErrorMessage, tenantId }) {
	const styles = useStyles();
	const queryClient = useQueryClient();
	const [packagesOptions, setPackagesOptions] = useState({});
	const [tenant, setTenant] = useState();

	const resetState = useCreateTenantStore(state => state.reset, shallow);
	const [currentStep, setCurrentStep] = useCreateTenantStore(state => [state.currentStep, state.setCurrentStep], shallow);
	const nextButtonDisabled = useCreateTenantStore(state => state.nextButtonDisabled, shallow);
	const finishButtonDisabled = useCreateTenantStore(state => state.finishButtonDisabled, shallow);
	const productConfigParams = useCreateTenantStore(state => state.productConfigParams, shallow);
	const packageIds = useCreateTenantStore(state => state.packageIds, shallow);
	const packagesConfigParams = useCreateTenantStore(state => state.packagesConfigParams, shallow);
	const [featureIds, fullListOfFeatureIds, fullListOfPackagesFeatureIds] = useCreateTenantStore(state => [state.featureIds, state.fullListOfFeatureIds, state.fullListOfPackagesFeatureIds], shallow);
	const featuresConfigParams = useCreateTenantStore(state => state.featuresConfigParams, shallow);
	const packagesFeatureIds = useCreateTenantStore(state => state.packagesFeatureIds, shallow);
	const packagesFeaturesConfigParams = useCreateTenantStore(state => state.packagesFeaturesConfigParams, shallow);
	const accountId = useProjectsStore(state => state.accountId, shallow);
	const { projectId, environment, productId, subscriptionStatus } = useCreateTenantStore(state => ({
		projectId: state.projectId,
		environment: state.environment,
		productId: state.productId,
		subscriptionStatus: state.subscriptionStatus
	}), shallow);

	const { mutateAsync } = useMutationWithAuthorization();

	const useSteps = [
		{
			id: "generalDetails",
			label: "GENERAL DETAILS",
			content: <GeneralDetails tenantId={tenantId} setTenant={setTenant} setVisible={setVisible} setErrorMessage={setErrorMessage}/>,
		},
		{
			id: "tenantConfiguration",
			label: "TENANT CONFIGURATION",
			content: <TenantConfiguration setVisible={setVisible} setErrorMessage={setErrorMessage} setPackagesOptions={setPackagesOptions} tenant={tenant}/>,
		},
		{
			id: "packageConfiguration",
			label: "PACKAGE CONFIGURATION",
			content: <PackageConfiguration packagesOptions={packagesOptions} tenant={tenant}/>,
		},
	];

	const handleNext = async () => {
		setCurrentStep(currentStep + 1);
	};

	function parameterToJson(parameter) {
		return parameter?.type === 'boolean' ? parameter?.value === 'true' : parameter?.type === 'number' ? parseFloat(parameter?.value) : parameter?.value;
	}

	function configToJson(config) {
		const json = {};

		config && Object.entries(config).forEach(([paramName, paramValue]) => {
			json[paramName] = parameterToJson(paramValue);
		});

		return json;
	}

	function addFeatures(fullListOfFeatureIds, featureIds, featuresConfigParams) {
		const featuresConfigParamsJson = {};

		Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

		fullListOfFeatureIds?.forEach( featureId => {
			const enabled = featureIds.includes(featureId);

			featuresConfigParamsJson[featureId] = {
				enabled: enabled,
			};
			const featureConfigJson = configToJson(Object.filter(featuresConfigParams?.[featureId], ([paramName, paramObj]) => paramObj.value != null &&
				(paramObj.value.length > 0 || Number.isInteger(paramObj.value) )));

			if (enabled && Object.keys(featureConfigJson).length > 0) {
				featuresConfigParamsJson[featureId].config = featureConfigJson;
			}
		});

		return featuresConfigParamsJson;
	}

	const addUpdateTenant = async () => {
		setVisible(false);
		Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

		const productConfigParamsJson = configToJson(Object.filter(productConfigParams, ([paramName, paramObj]) => paramObj.value != null && paramObj.value.length > 0));

		if (fullListOfFeatureIds.length) {
			productConfigParamsJson.features = addFeatures(fullListOfFeatureIds, featureIds, featuresConfigParams);
		}

		if (packageIds?.length) {
			const packagesConfigParamsJson = {};

			packageIds?.forEach(packageId => {
				packagesConfigParamsJson[packageId] = {};
				const packageConfigJson = configToJson(Object.filter(packagesConfigParams?.[packageId], ([paramName, paramObj]) => paramObj.value != null && paramObj.value.toString().length > 0));

				if (Object.keys(packageConfigJson).length > 0) {
					packagesConfigParamsJson[packageId].config = packageConfigJson;
				}
				if (fullListOfPackagesFeatureIds[packageId]?.length) {
					packagesConfigParamsJson[packageId].features = addFeatures(fullListOfPackagesFeatureIds[packageId], packagesFeatureIds[packageId], packagesFeaturesConfigParams[packageId]);
				}
			});
			productConfigParamsJson.packages = packagesConfigParamsJson;
		}

		let addUpdateTenantPromise;

		if (tenantId == null) {
			addUpdateTenantPromise = mutateAsync(createTenantApi({
				projectId,
				accountId,
				productId,
				environment,
				subscriptionStatus,
				packageIds,
				tenantConfig: productConfigParamsJson
			}));
		} else {
			addUpdateTenantPromise = mutateAsync(updateTenantApi(accountId, projectId, tenantId, {
				environment,
				subscriptionStatus,
				packageIds,
				tenantConfig: productConfigParamsJson
			}));
		}

		resetState();

		const prefix = tenantId == null ? "Cre" : "Upd";

		try {
			await toast.promise(addUpdateTenantPromise,
				{
					pending: prefix + "ating Tenant...",
					success: {
						render() {
							queryClient.invalidateQueries(['get-tenant-by-account-id-and-project-id', accountId, projectId]);
							queryClient.invalidateQueries(['get-tenant-by-id', tenantId]);

							return prefix + "ated";
						},
					},
					error: {
						render({ data: error }) {
							queryClient.invalidateQueries(['get-tenant-by-account-id-and-project-id', accountId, projectId]);
							queryClient.invalidateQueries(['get-tenant-by-id', tenantId]);
							const errorMessage = JSON.parse(error?.message);

							if (errorMessage?.status === 400) {
								return `An unexpected error occurred  ${errorMessage.message || ''}`;
							}

							return 'An unexpected error occurred. Please try again later or contact customer support if the error persists.';
						},
					}
				}, { position: toast.POSITION.TOP_CENTER }
			);
		} catch (e) {
			console.error(e);
		}

	};

	const handleBack = () => {
		setCurrentStep(currentStep - 1);
	};

	const isLastStep = currentStep === useSteps.length - 1;
	const isPackageSelected = packageIds!== undefined && packageIds.length > 0;
	const isPackageOptionExist = packagesOptions !== undefined && Object.keys(packagesOptions).length > 0;

	return (
		<div className={styles.root}>
			<div className={styles.steps}>
				<Stepper activeStep={currentStep} orientation="horizontal">
					{useSteps.map((obj, index) => (
						<Step key={obj.id}>
							<StepLabel StepIconComponent={ColorlibStepIcon}>{obj.label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{ useSteps[currentStep].content }
			</div>
			<div className={styles.buttonsContainer}>
				{currentStep === 0 && <AppButton overrideClasses={ { button: styles.button } } variant={'outlined'} onClick={handleCancel}>CANCEL</AppButton>}
				{currentStep > 0 && <AppButton overrideClasses={ { button: styles.button } } variant={'outlined'} onClick={handleBack}>BACK</AppButton>}
				{!isLastStep && !(currentStep === 1 && finishButtonDisabled) && <AppButton
					overrideClasses={ { button: styles.button } }
					variant={'contained'}
					onClick={handleNext}
					disabled={nextButtonDisabled}>NEXT</AppButton>}
				{currentStep === 1 && finishButtonDisabled && <AppTooltip title={'Please set required configurations'}>
					<div className={styles.disabledFinishButton}>NEXT</div>
				</AppTooltip>}
				{isLastStep && <AppTooltip title={'Please add at least one package'} disabled={(isPackageOptionExist && isPackageSelected) || !isPackageOptionExist}>
					<div>
						<AppButton overrideClasses={{ button: styles.button }} variant={'contained'} onClick={addUpdateTenant} disabled={isPackageOptionExist && !isPackageSelected}>{ tenantId != null ? 'UPDATE TENANT' : 'ADD TENANT' }</AppButton>
					</div>
				</AppTooltip>}
			</div>
		</div>
	);
}
