import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAllCustomersByAccountId = (accountId, reseller, params = {}) => {
	const { headers = {} } = params;

	if (!reseller) {
		return null;
	}

	return {
		method: "GET",
		url: `${getApiEndpoint()}/accounts/${accountId}/customers`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

export {
	getAllCustomersByAccountId,
};
