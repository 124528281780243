import { Button, CopyWhite, PopUp, Warning } from "@ip-synamedia/syna-ui-library";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, Tooltip } from "@mui/material";
import React from "react";
import Typography from "@material-ui/core/Typography";
import useCopyToClipboard from "../../../../../custom-hooks/useCopyToClipboard";
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
	messageContainer: {
		width: 650,
		height: "100%",
		position: "relative",
	},
	messageAppContainer: {
		display: "flex",
		flexDirection: "row"
	},
	messageSecretContainer: {
		marginTop: 10,
		display: "flex",
		flexDirection: "row"
	},
	messageSecretKey: {
		fontSize: 18,
		fontWeight: "bold",
		width: "fit-content",
	},
	messageAppKey: {
		fontSize: 18,
		fontWeight: "bold",
		width: "fit-content",
	},
	messageAppValue: {
		marginLeft: 10,
		width: "fit-content",
	},
	messageSecretValue: {
		marginLeft: 10,
		backgroundColor: "lightgray",
		width: "fit-content",
	},
	messageNoteContainer: {
		display: 'flex',
		bottom: 0,
		position: "absolute",
		left: "50%",
		transform: "translate(-50%, 0)",
		width: "fit-content"
	},
	messageNoteText: {
		marginLeft: 10,
		fontSize: 18,
	},
	copyIcon: {
		marginLeft: 10
	}
}));

export default function SecretDialog(props) {
	const { visible, onClose, secret, appName, clientId } = props;
	const classes = useStyles();

	const [copyToClipboard, resetCopy, { success: copySuccess }] = useCopyToClipboard();

	const customActions = () => {
		return (
			<center style={{ margin: '10px' }} onClick={onClose}>
				<Button filled={true}>Close</Button>
			</center>
		);
	};

	if (copySuccess) {
		toast.success('The secret was copied to the clipboard!', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	if (copySuccess != null && !copySuccess) {
		toast.error('Failed to copy secret', { position: toast.POSITION.TOP_CENTER });
	}

	return (
		<Dialog open={visible} onClose={onClose}>
			<PopUp
				visible={visible}
				setVisible={onClose}
				title={`Application Secret`}
				customActions={customActions()}
				width='800px'
				height='330px'
			>
				<div className={classes.messageContainer}>
					<div className={classes.messageAppContainer}>
						<div className={classes.messageAppKey}>
								Application Name:
						</div>
						<div className={classes.messageAppValue}>
							<Typography variant={'subtitle1'}>{appName}</Typography>
						</div>
					</div>
					<div className={classes.messageAppContainer}>
						<div className={classes.messageAppKey}>
							Client ID:
						</div>
						<div className={classes.messageAppValue}>
							<Typography variant={'subtitle1'}>{clientId}</Typography>
						</div>
					</div>
					<div className={classes.messageSecretContainer}>
						<div className={classes.messageSecretKey}>
								Secret:
						</div>
						<div className={classes.messageSecretValue}>
							<Typography variant={'subtitle2'}>{secret}</Typography>
						</div>
						<Tooltip title={'Copy'}>
							<span className={classes.copyIcon} onClick={() => copyToClipboard(secret)}>
								<CopyWhite fill='#3381FF' width='1em' height='1em'/>
							</span>
						</Tooltip>
					</div>
					<div className={classes.messageNoteContainer}>
						<Warning style={{ display: "block", margin: "auto" }} fill='Red' width='3em' height='3em' />
						<div className={classes.messageNoteText}>
								The secret cannot be viewed after closing this window.
							<br/>
								Make sure you keep it in a safe place before you close this window!
						</div>
					</div>
				</div>
			</PopUp>
		</Dialog>
	);
}
