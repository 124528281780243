import styles from './popUp.module.css'
import React from 'react'
import clsx from 'clsx'
import { Button } from '../button/Button'
import Clear from '../../iconComponents/Clear'
export const PopUp = ({
  /**
   * Title :-
   *
   * If `present`, accordion comes with a title.
   * Default - empty
   */
  title,
  /**
   * Visible :-
   *
   * If true, Pop-Up is visible by default.
   * Default - true
   */
  visible,
  /**
   * SetVisible :-
   *
   * The method handler to trigger to show/disable pop-up .
   * Default -  empty
   */
  setVisible,
  children,
  /**
   * OnCancel :-
   *
   * If present, onCancel gets triggered on click of cancel button, .
   * Default -  empty
   */
  onCancel,
  /**
   * OnAccept :-
   *
   * If present, onAccept gets triggered on click of accept button, .
   * Default -  empty
   */
  onAccept,
  /**
   * cancel :-
   *
   * If present, the 'cancel' button is renamed .
   * Default - cencel
   */
  cancel = 'cancel',
  /**
   * accept :-
   *
   * If present, the 'accept' button is renamed .
   * Default - accept
   */
  accept = 'accept',
  /**
   * width :-
   *
   * Width determines the width of the pop-up .
   * Default -  empty
   */
  width,
  /**
   * height :-
   *
   * Height determines the height of the pop-up .
   * Default -  empty
   */
  height,
  /**
   * Max height :-
   *
   * MaxHeight determines the max height of the pop-up .
   * Default -  empty
   */
  maxHeight,
  /**
   * Actions :-
   *
   * If true , the action buttons become visible .
   * Default - false
   */
  actions,
  /**
   * Custom Actions :-
   *
   * If present , custome Actions can be used instead of default buttons .
   * Default - false
   */
  customActions,
  /**
   * Secondary Action Label :-
   *
   * If present , and secondaryAction is `true`, a secondary action button is visible on the left with passed label.
   * Default - empty
   */
  secondaryActionLabel,
  /**
   * Secondary Action  :-
   *
   * If present , a secondary action button is visible on the left.
   * Default - false
   */
  secondaryAction
}) => {
  const cancelDialog = () => {
    setVisible(false)
  }
  return (
    <div
      data-testid='PopUp'
      className={clsx(styles.dialog, visible && styles.visible)}
    >
      <div
        className={clsx(styles.dialogContent)}
        style={{ width: width, height: height, maxHeight: maxHeight }}
      >
        <div className={styles.border} style={{ width: width }} />
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div style={{ flex: 1 }} />
          <Clear
            data-testid={'clear'}
            className={styles.close}
            onClick={cancelDialog}
          />
        </div>
        <div className={styles.separator} style={{ width: width }} />
        <div
          className={styles.container}
          style={{
            width: width - '52px',
            height: height,
            maxHeight: maxHeight
          }}
        >
          {children}
        </div>
        {actions && (
          <div className={styles.buttons}>
            {secondaryAction && (
              <a>
                <Button
                  uppercase={true}
                  id={'secondaryAction'}
                  label={secondaryActionLabel}
                  onClick={secondaryAction}
                />
              </a>
            )}
            <div style={{ flex: 1 }} />
            <a>
              <Button
                uppercase={true}
                id='cancelAction'
                onClick={onCancel || cancelDialog}
              >
                {cancel}
              </Button>
            </a>
            <a>
              <Button
                id='acceptAction'
                uppercase={true}
                filled={true}
                onClick={onAccept ||cancelDialog}
              >
                {accept}
              </Button>
            </a>
          </div>
        )}
        {customActions}
      </div>
    </div>
  )
}
