import * as React from 'react'

function SvgError(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        d='M12 23.625c6.42 0 11.625-5.203 11.625-11.625C23.625 5.582 18.42.375 12 .375S.375 5.582.375 12C.375 18.422 5.58 23.625 12 23.625zm0-1.5C6.41 22.125 1.875 17.599 1.875 12 1.875 6.412 6.403 1.875 12 1.875c5.56 0 10.125 4.504 10.125 10.125A10.122 10.122 0 0112 22.125zM12.21 15c.302 0 .55-.238.562-.539l.329-7.875A.563.563 0 0012.539 6H11.46a.562.562 0 00-.562.586l.328 7.875a.563.563 0 00.562.539h.421zM12 18.563a1.312 1.312 0 100-2.625 1.312 1.312 0 000 2.625z'
        fill='#F33'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgError
