import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/tenancyManager/productConfiguration`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getByProductId = (productId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/tenancyManager/productConfiguration/${productId}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const postProductConfig = (productConfig, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/tenancyManager/productConfiguration`,
		headers: {
			...headers,
			...baseHeaders,
		},
		body: JSON.stringify(productConfig)
	};
};

export {
	getAll,
	getByProductId,
	postProductConfig
};
