import React from "react";
import { Button, Help } from "@ip-synamedia/syna-ui-library";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import AddAccountDialog from "./AddAccountDialog";
import EditAccountDialog from "./EditAccountDialog";

const useStyles = makeStyles(() => ({
	separator: {
		width: 1,
		height: 20,
		marginLeft: 10,
		marginRight: 10,
		borderLeft: "solid 1px #bfbfbf",
		position:"relative",
		top:5
	},
	titleToolbar:{
		display:"flex",
		marginRight:22,
		marginBottom:27
	},
	title:{
		width: 92,
		height: 28,
		margin: "6px 0px 27px 0x",
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 20,
		fontWeight: 400,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.4,
		letterSpacing: "normal",
		color: "#000000"
	},
	toolbarButtons:{
		display:"flex"
	}
}));

export default function Breadcrumbs({ editOpen, setEditOpen, editAccountId }) {
	const classes = useStyles();

	return (
		<div className={classes.titleToolbar}>
			<Typography className={classes.title}>Accounts </Typography>
			<div className={classes.separator}/>
			<Typography className={classes.title}>Overview </Typography>
			<div style={{ flex: "1 1 0%" }}/>
			<div className={classes.toolbarButtons}>
				<Button icon={<Help fill='#3381FF' width='1em' height='1em' />}>HELP</Button>
				<div className={classes.separator}/>
				{/*<Button filled={true} style={{ marginRight:12 }} onClick={() => console.log('add account')}>ADD ACCOUNT</Button>*/}
				<AddAccountDialog/>
				{editOpen && <EditAccountDialog editOpen={editOpen} setEditOpen={setEditOpen} data={editAccountId}/>}
			</div>
		</div>
	);
}
