import React, {useContext, useEffect, useRef} from "react";
import {useResize} from "../../../util/size"

export default function Region({children, context, id="default", depends=[]}) {
    const ref = useRef();
    const counter = useResize([]);
    const [, setRegions] = useContext(context);
    useEffect(() => {
        let timeoutHandle = null;
        let intervalHandle = null;
        if(ref.current) {
            intervalHandle = setInterval(() => {
                const rect = ref.current.getBoundingClientRect();
                setRegions(regions => {
                    regions = regions || [];
                    regions[id] = {
                        left: ref.current.offsetLeft,
                        top: ref.current.offsetTop,
                        width: rect.width,
                        height: rect.height
                    };
                    return {...regions};
                });
            }, 50);
            timeoutHandle = setTimeout(() => {
                clearInterval(intervalHandle);
                intervalHandle = null;
            }, 500);
        }
        return () => {
            clearTimeout(timeoutHandle);
            clearInterval(intervalHandle);
        }
    }, [ref.current, counter, ...depends]);

    children = React.Children.map(children, item => {
        return React.cloneElement(item, {ref});
    });

    return children;
}

export function useRegion(context, id="default") {
    const [regions] = useContext(context) || [];
    const region = (regions || [])[id];
    return region;
}
