import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useVariants = makeStyles((theme) => {
	return {
		outlined: {
			backgroundColor: 'hsla(0, 0%, 100%, 1)',
			color: 'hsla(217, 100%, 60%, 1)',
			borderColor: 'hsla(217, 100%, 60%, 1)',
			'&:hover': {
				color: 'hsla(217, 100%, 60%, 1)',
				borderColor: 'hsla(217, 100%, 60%, 1)',
			},
			'&:disabled': {
				color: '#C2C2C2',
			}
		},
		contained: {
			backgroundColor: 'hsla(217, 100%, 60%, 1)',
			color: 'hsla(0, 0%, 100%, 1)',
			'&:hover': {
				backgroundColor: '#1E4D99',
			},
			'&:disabled': {
				backgroundColor: '#C2C2C2',
				color: 'hsla(0, 0%, 100%, 1)',
			}
		},
		text: {
			color: '#3380FF',
			backgroundColor: 'transparent',
			border: 'none',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			textTransform: 'uppercase',
			"&:hover": {
				textDecorationLine: 'underline',
				backgroundColor: 'transparent'
			}
		}
	};
});

const useStyles = makeStyles((theme) => {
	return {
		button: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			textTransform: 'uppercase',
			height: 30,
			paddingLeft: '12px',
			paddingRight: '12px',
			borderRadius: 4,
			border: 0,
			cursor: 'pointer',
			transition: 'transform 150ms ease',
			'&:hover': {
				// transform: 'scale(1.01)'
			},
			'&:disabled': {
				pointerEvents: 'none',
				cursor: 'default',
			}
		},
	};
});

export default function AppButton({ variant = 'contained', children, overrideClasses = {}, onClick, ...props }) {
	const styles = useStyles();
	const variants = useVariants();

	const className = clsx(styles.button,
		variants[variant] ?? variants.contained,
		overrideClasses?.button
	);

	const handleClick = () => {
		onClick?.();
	};

	return (
		<button
			className={className}
			onClick={handleClick}
			{...props}
		>
			{children}
		</button>
	);
}
