import React from 'react';
import styles from './HeroBar.module.css';
import Fade from 'react-reveal/Fade';
import vodmonitor from '../../images/vodmonitor.JPG';
import home from '../../images/baseuihome.JPG';
import AuthenticationButton from "../../buttons/AuthenticationButton";

const HeroBar = () => {
	return (
		<>
			<div className={styles.root}>
				<div className={styles.textContent}>
					<Fade bottom distance='15%' delay={200}>
						<h1 className={styles.heroTitle}>Welcome to Synamedia Cloud Services</h1>
					</Fade>
					<Fade bottom distance='15%' delay={400}>
						<p
							className={`reveal-from-bottom ${styles.description}`}
							data-reveal-delay='400'
						>
						</p>
						<Fade bottom distance='6%' delay={600}>
							<div className='reveal-from-bottom' data-reveal-delay='600'>
								<div className={styles.btnDiv}>
									<AuthenticationButton/>
								</div>
							</div>
						</Fade>
					</Fade>
				</div>
				<div className={styles.rightDiv}>
					<div
						className={`hero-figure reveal-from-bottom illustration-element-01`}
						data-reveal-value='20px'
						data-reveal-delay='800'
					>
						<Fade top distance='8%' delay={800}>
							<img
								className={styles.img}
								src={vodmonitor}
								width={500}
								height={250}
								alt={''}/>
						</Fade>
					</div>
				</div>

				<div
					className={`hero-figure reveal-from-bottom illustration-element-01 ${styles.img1}`}
					data-reveal-value='20px'
					data-reveal-delay='800'
				>
					<Fade bottom distance='8%' delay={800}>
						<img className={styles.img} src={home} width={500} height={250}  alt={''}/>
					</Fade>
				</div>
			</div>
		</>
	);
};

export default HeroBar;
