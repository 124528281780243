import React from "react";
import { getAccounts as getAllAccounts } from "../../external-apis";
import { useQueryWithAuthorization } from "../../custom-hooks";
import Loader from "../Loader";
import { MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	root: {
		"& label": {
			width: "100%",
			textAlign: "left",
			transformOrigin: "left",
			"&.Mui-focused": {
				transformOrigin: "left"
			}
		}
	}
}));

const mapAccountsToUiElement = (accounts) => {
	return (accounts || [])
		.map(account => {
			const { id, alias, groups } = account;

			return {
				id,
				label: alias,
				groups: groups ?? []
			};
		})
		.sort((accountA, accountB) => accountA.label?.localeCompare(accountB.label));
};

const staleTimeout = 60000;

export default function SelectAccount(props) {
	const { val, onChange, isValid } = props;
	const classes = useStyles();

	const { isLoading, isError, data: accounts, error } = useQueryWithAuthorization('accounts', getAllAccounts(), {
		select: mapAccountsToUiElement,
		staleTime: staleTimeout,
	});

	// if (isLoading) return <Loader width={50} height={60} type={'Rings'}/>;
	if (isError) return <span>Error: {error.message}</span>;

	return (
		<TextField classes={{ root: classes.root }} style={{ margin: 0 }}
			label="Account"
			error={!isValid}
			id="select-account-id"
			select
			margin='dense'
			value={val ?? ''}
			onChange={event => onChange(event.target.value, accounts)}
			fullWidth
			variant='outlined'
			inputProps={{ style: { minHeight: 30 } }}
		>
			{isLoading && <div style={{ minHeight: 20 }}>
				<Loader width={50} height={60} type={'Rings'}/>
			</div>}
			{!isLoading && accounts.map(account => {
				const { id, label } = account;

				return (
					<MenuItem key={id} value={id}>
						{label}
					</MenuItem>
				);
			})}
		</TextField>
	);
}
