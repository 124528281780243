import React, { useContext } from 'react'
import { Typography } from '../../typography/Typography'
import { Dropdown } from '../../dropdown/Dropdown'
import { default as Refresh } from '../../../iconComponents/Refresh'
import Table from './Table'
import Button from './Button'
import { formatDate } from '../../../util/date'
import styles from './AdditionalFilters.module.scss'

export default function AdditionalFilters(props) {
  const [additionalFilters, setAdditionalFilters] =
    useContext(Table.AdditionalFilters) || []
  const value = additionalFilters[props.index][props.additionalFilter.id]
  const handleChange = (value) => {
    if (additionalFilters[props.index][props.additionalFilter.id] !== value) {
      let tempAdditionalFilters = JSON.parse(JSON.stringify(additionalFilters))
      tempAdditionalFilters[props.index][props.additionalFilter.id] = value
      setAdditionalFilters(tempAdditionalFilters)
    }
  }

  const DropDown = () => {
    return (
      <Dropdown
        style={props.additionalFilter.style && props.additionalFilter.style}
        options={props.additionalFilter.options}
        selected={value}
        onSelectionChange={handleChange}
      />
    )
  }

  const RefreshData = () => {
    return (
      <React.Fragment>
        <Typography color='secondary' className={styles.refreshDataTitle}>
          Updated: {formatDate(value)}
        </Typography>
        <Button
          className={styles.refreshButton}
          icon={
            <Refresh
              fill='#3381FF'
              width='1em'
              height='1em'
              onClick={() => handleChange(new Date())}
            />
          }
        />
      </React.Fragment>
    )
  }

  switch (props.additionalFilter.type) {
    case 'dropdown':
      return <DropDown />
    case 'refreshData':
      return <RefreshData />
  }
}
