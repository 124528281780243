import * as React from 'react'

function SvgWarning(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 16 15'
      fill='#F33'
      {...props}
    >
      <path
        d='M14.436 14.75a1.313 1.313 0 001.137-1.968L9.012 1.406a1.313 1.313 0 00-2.274 0L.178 12.782a1.312 1.312 0 001.136 1.968h13.122zm-.283-1.313H1.597a.164.164 0 01-.143-.246L7.733 2.31c.063-.11.22-.11.284 0l6.279 10.882a.164.164 0 01-.143.246zM8.441 9.72c.17 0 .317-.139.327-.31l.18-3.062A.328.328 0 008.621 6H7.129a.328.328 0 00-.327.347l.18 3.063c.01.173.154.309.327.309h1.132zm-.566 2.68a1.149 1.149 0 10-.002-2.296 1.149 1.149 0 00.002 2.295z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgWarning
