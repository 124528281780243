import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import emptyContentBackground from "../../../../../../../assets/icons/emptyContent/emptyContentBackground.png";
import emptyContent from "../../../../../../../assets/icons/emptyContent/emptyContent.png";
import Features from "./Features";
import Parameter from "./Parameter";

const useStyles = makeStyles((theme) => ({
	configurations: {
		position: 'absolute',
		left: 0,
		top: 50,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 14,
		overflowY: 'auto',
		height: 510,
		width: 823,
	},
	emptyPageContainer:{
		display:"flex",
		flexDirection:"column",
		alignItems: "center",
		width: "100%",
		height: 503
	},
	emptyIconContainer:{
		marginTop: 118,
		marginRight: 25,
		display: "grid",
		gridTemplateColumns: '1fr',
		gridTemplateRows: '1fr',
		gridTemplateAreas: "overlap",

	},
	emptyContentBackgroundIcon:{
		gridArea: 'overlap',
		width:100,
		height: 100,
	},
	emptyContentIcon:{
		gridArea: 'overlap',
		borderRadius: 0,
		margin: '32px 25px 32px 25px'
	},
	emptyPageText:{
		color: "#8F8F8F",
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		marginTop: 12,
		fontWeight: 400,
		marginRight: 25,
	},
}));

export default function Configurations( { configOptions, configParams, handleSelectionChange, featuresOptions, featuresConfigParams, featureIds, addFeatureIds, setFeatureIds, handleFeatureSelectionChange, maxParameterLabelWidth, editTenant, overrideClasses = {} } ) {
	const styles = useStyles();

	const isEmptyConfiguration = configOptions == null || Object.keys(configOptions).length === 0;
	const isEmptyFeatures = featuresOptions == null || Object.keys(featuresOptions).length === 0;

	const emptyStep = (<div className={styles.emptyPageContainer}>
		<div className={styles.emptyIconContainer}>
			<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
			<img alt={''} src={emptyContent} className={styles.emptyContentIcon}/>
		</div>
		<div className={styles.emptyPageText}>
			There are no available configurations to add
		</div>
	</div>);

	return (<div className={clsx(styles.configurations, overrideClasses?.configurations)}>
		{ isEmptyConfiguration && isEmptyFeatures ? emptyStep :
			<div>
				{ !isEmptyConfiguration && Object.entries(configOptions).map(([parameter, parameterConfig]) => {
					const { enum: allowedValues, label, description, required, type, modifiable } = parameterConfig;
					const selectedValue = configParams?.[parameter]?.value;

					return <Parameter
						key={parameter}
						parameter={parameter}
						allowedValues={allowedValues}
						handleSelectionChange={handleSelectionChange}
						required={required}
						selectedValue={selectedValue}
						type={type}
						label={label}
						description={description}
						disabled={editTenant && modifiable === false}
						overrideClasses={overrideClasses}>
					</Parameter>;
				})}
				{!isEmptyFeatures && <Features
					featuresOptions={featuresOptions}
					featuresConfigParams={featuresConfigParams}
					featureIds={featureIds}
					addFeatureIds={addFeatureIds}
					setFeatureIds={setFeatureIds}
					handleFeatureSelectionChange={handleFeatureSelectionChange}
					maxParameterLabelWidth={maxParameterLabelWidth}
					editTenant={editTenant}
					overrideClasses={overrideClasses}/>}
			</div>
		}
	</div>);
}