import clsx from 'clsx';
import React, { Fragment } from 'react'
import styles from './typography.module.css'

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p'
};


export const Typography = (props) => {
  const { className, transform = false, fontSize = '16px', fontColor = 'black', type = 'normal', color = 'primary', variant = 'body1', customize = false, paragraph = false, variantMapping = defaultVariantMapping, children, ...other } = props;
  const Component = (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) || 'span';
  let custom = {};
  if (customize) {
    custom.color = fontColor;
    custom.fontSize = fontSize;
  }
  return (
    <Component data-testid='typography' className={ clsx(
      styles['typography'],
      transform && styles[`typography--transform-${transform}`],
      styles[`typography--variant-${variant}`],
      styles[`typography--color-${color}`],
      styles[`typography--type-${type}`],
      className
    ) }
      style={ custom }
    >
      { children }
    </Component>
  );
}

export default Typography
