import styles from './Input.module.scss'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useID } from '../Util/ID'

export default function Input({
  id,
  className,
  value,
  setValue,
  validator,
  width = 200,
  prefix,
  suffix,
  type = '',
  placeholder = '',
  required,
  readonly,
  onKeyPress
}) {
  const baseID = useID()
  const [text, setText] = useState(value)
  const [error, setError] = useState('')

  const onChange = (event, value) => {
    setText(value || event.target.value)
    const hasError = validator && validator(value || event.target.value)
    !hasError && setValue(value || event.target.value, id)
    setError(hasError)
  }
  useEffect(() => {
    setText(value)
  }, [value])
  useEffect(() => {
    if (text) {
      const timerHandle = setTimeout(() => {
        !error && setValue && setValue(text, id)
      }, 250)
      return () => {
        clearTimeout(timerHandle)
      }
    } else {
      setValue && setValue(text, id)
    }
  }, [text])
  return (
    <div>
      <div className={styles.root} style={{ width }}>
        {prefix}
        <input
          onKeyPress={onKeyPress}
          data-cy={baseID + 'input'}
          id={id}
          className={clsx(styles.input, error && styles.error, className)}
          style={{ width }}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={text}
          readOnly={readonly}
          required={required}
        />
        {suffix}
      </div>
      <div className={styles.errorMessage}>{error}</div>
    </div>
  )
}
