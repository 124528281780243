import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AppTooltip from "../../../../AppTooltip";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import activeStatus from '../../../../../assets/icons/status/active.svg';
import creatingStatus from '../../../../../assets/icons/status/creating.svg';
import updatingStatus from '../../../../../assets/icons/status/updating.svg';
import errorStatus from '../../../../../assets/icons/status/error.svg';
import requestedStatus from '../../../../../assets/icons/status/requested.svg';
import terminatedStatus from '../../../../../assets/icons/status/terminated.svg';
import terminatingStatus from '../../../../../assets/icons/status/terminating.svg';
import errorActive from '../../../../../assets/icons/status/errorActive.svg';
import infoIcon from '../../../../../assets/icons/infoIcon.svg';

const useStyles = makeStyles((theme) => {

	const provisioningStatusColorMapping = {
		active: {
			container: 'rgba(46, 209, 94, 0.1)',
			label: 'rgba(0, 158, 47, 1)',
		},
		errorActive: {
			container: 'rgba(255, 196, 28, 0.1)',
			label: 'rgba(210, 156, 0, 1)',
			marginBottom: 1
		},
		creating: {
			container: 'rgba(51, 128, 255, 0.1)',
			label: 'rgba(51, 128, 255, 1)',
			marginBottom: 1
		},
		updating: {
			container: 'rgba(51, 128, 255, 0.1)',
			label: 'rgba(51, 128, 255, 1)'
		},
		requested: {
			container: 'rgba(51, 128, 255, 0.1)',
			label: 'rgba(51, 128, 255, 1)',
			marginBottom: 1
		},
		error: {
			container: 'rgba(255, 51, 51, 0.1)',
			label: 'rgba(255, 51, 51, 1)'
		},
		terminated: {
			container: 'rgba(41, 41, 41, 0.05)',
			label: 'rgba(41, 41, 41, 1)'
		},
		terminating: {
			container: 'rgba(250, 250, 250, 1)',
			label: 'rgba(143, 143, 143, 1)'
		},
	};

	return {
		lineDetailsContainer:{
			display:"flex",
			flexDirection:"row",
			alignItems:"self-start",
			minHeight:20,
			marginBottom:12,
		},
		provisioningStatus: {
			height: 20,
			width: 97,
			backgroundColor: props => provisioningStatusColorMapping[props.provisioningStatus]?.container,
			borderRadius: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		},
		provisioningStatusIcon: {
			borderRadius: 0,
			marginBottom: props => provisioningStatusColorMapping[props.provisioningStatus]?.marginBottom ?? 0,
		},
		provisioningStatusLabel: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 12,
			marginLeft: 4,
			color: props => provisioningStatusColorMapping[props.provisioningStatus]?.label,
		},
		tooltipContainer: {
			marginTop: 2,
			marginLeft: 12,
		},
		keyContainer:{
			display:"flex",
			flexDirection:"row",
			alignItems:"self-start",
			minHeight:20,
			marginBottom:12,
		},
		label:{
			color:"#8F8F8F",
			width:145,
			fontFamily:"Source Sans Pro",
			fontSize: 14,
			display:"flex",
			alignItems:"center",
			whiteSpace: 'nowrap',
			marginRight: 20
		},
		variable:{
			fontFamily: 'Source Sans Pro',
			fontSize: 14,
			height: 20,
			color: '#292929',
		},
		featureContainer:{
			backgroundColor: "#FAFAFA",
			padding: '12px 0 11px 0'

		},
		icon: {
			transform: 'rotate(90deg)',
			color: "#8F8F8F"
		},
		iconUp: {
			transform: 'rotate(180deg)',
		},
		iconContainer: {
			margin: '0 7px 0 12px',
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			'&:hover': {
				cursor: 'pointer',
			},

		},
		lineDetailsFeaturesContainer:{
			display:"flex",
			flexDirection:"row",
			alignItems:"self-start",
			minHeight:20,
			marginBottom:8,
		},
		keyFeatures:{
			color: '#8F8F8F',
			fontFamily:"Source Sans Pro",
			fontSize: 14,
			marginLeft: 177,
			marginRight: 20,
			whiteSpace: 'nowrap'
		},
		variableFeatures:{
			fontFamily: 'Source Sans Pro',
			fontSize: 14,
			height: 20,
			color: '#292929',
		},
		iconDisabled:{
			marginLeft: 26
		},
		keyNotExistEnabledFeature:{
			marginLeft: 12
		},
		keyExistEnabledFeature:{
			marginLeft: 32,
			width: 125

		},
		separator: {
			border: '0.5px solid #EFEFEF',
			flex: 'none',
		},
		separatorContainer:{
			width: "100%",
			padding: '0 12px 0 147px'
		},
		featureContentContainer: {
			marginLeft: 147,
			marginTop: 12

		},
		featureContentKey: {
			color: "#8F8F8F",
			fontSize: 14,
			marginRight: 20,
			fontFamily: 'Source Sans Pro',
			whiteSpace: 'nowrap'
		},
		featureLineContainer:{
			marginBottom: 8,
			display: "flex",
			flexDirection: "row",
			alignItems: "center"
		},
		lastFeatureLineContainer:{
			marginBottom: 12,
		},
		keyEnabledExistFeatures:{
			width: 157
		},
		packageLabel:{
			marginRight: 0
		}

	};
});

export function getTextWidth(text, font) {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	context.font = font || getComputedStyle(document.body).font;

	return context.measureText(text).width;
}

export const getShortStr = (str, maxLen) => {
	if(getTextWidth(str) <= maxLen) {
		return str;
	}
	if(str.length <=1 ) {
		return getTextWidth(str) > maxLen ? '':str;
	}

	const startStr = str.substring(0, str.length/2);
	const endStr = str.substring(str.length/2, str.length);

	return getTextWidth(startStr) > maxLen ?
		getShortStr(startStr, maxLen):
		startStr + getShortStr(endStr, maxLen - getTextWidth(startStr));

};

export const unassignedVariable = 'Unavailable';
const dotsWidth = getTextWidth('...');

export function LineDetails({ label, value, isLinkify = false, overrideClasses={} }) {
	const styles = useStyles();

	const maxLabelWidth = 155;
	const maxValueWidth = 343;
	const isLabelLong = getTextWidth(label) > maxLabelWidth;
	const isValueLong = getTextWidth(value.toString()) > maxValueWidth;

	return(	<div className={clsx(styles.lineDetailsContainer, overrideClasses?.container)}>
		<AppTooltip title={label} disabled={!isLabelLong}><div className={styles.label}>
			{isLabelLong? getShortStr(label, maxLabelWidth -dotsWidth) + '...': label}
		</div >
		</AppTooltip>
		{isLinkify ? <a href={value} target="_blank" rel="noreferrer">{value}</a> :
			<AppTooltip title={value} disabled={!isValueLong}><div className={styles.variable}>{isValueLong?getShortStr(value, maxValueWidth -dotsWidth) + '...': value}</div></AppTooltip>}
	</div>);
}

export function ProvisioningStatus({ value, error, overrideClasses={} }) {
	const isErrorActive = (value === 'active' || value === 'error') && error != null && error.length > 0;
	const styles = useStyles({ provisioningStatus: isErrorActive ? 'errorActive' : value });

	return(	<div className={clsx(styles.lineDetailsContainer, overrideClasses?.container)}>
		<div className={styles.label}>
			Provisioning Status
		</div>
		<div className={styles.provisioningStatus}>
			{ value === 'active' && error == null && <img className={styles.provisioningStatusIcon} src={activeStatus} width={11} height={11} alt={''}/>}
			{ isErrorActive &&<img className={styles.provisioningStatusIcon} src={errorActive} width={15} height={15} alt={''}/>}
			{ value === 'creating' && <img className={styles.provisioningStatusIcon} src={creatingStatus} width={9} height={11} alt={''}/>}
			{ value === 'updating' && <img className={styles.provisioningStatusIcon} src={updatingStatus} width={11} height={11} alt={''}/>}
			{ value === 'error' && !isErrorActive &&  <img className={styles.provisioningStatusIcon} src={errorStatus} width={11} height={11} alt={''}/>}
			{ value === 'requested' && <img className={styles.provisioningStatusIcon} src={requestedStatus} width={11} height={11} alt={''}/>}
			{ value === 'terminated' && <img className={styles.provisioningStatusIcon} src={terminatedStatus} width={15} height={15} alt={''}/>}
			{ value === 'terminating' && <img className={styles.provisioningStatusIcon} src={terminatingStatus} width={15} height={15} alt={''}/>}
			<div className={styles.provisioningStatusLabel}>
				{value.charAt(0).toUpperCase() + value.slice(1)}
			</div>
		</div>
		{ isErrorActive && <div className={styles.tooltipContainer}>
			<AppTooltip title={"There was an error updating the tenant. Please call customer support for further details."}>
				<img src={infoIcon} width={15} height={15} alt={''}/>
			</AppTooltip>
		</div>}
	</div>);
}

export function isValidHttpUrl(string) {

	try {
		const url = new URL(string);

		return url.protocol === "http:" || url.protocol === "https:";
	} catch (_) {
		return false;
	}
}

export function FeaturesLineDetails({ isExistEnabledFeature, label, dataToDisplay, enabled, longestKeyLength }) {
	const styles = useStyles();
	const [collapsed, setCollapsed] = useState(false);
	const isExistData = dataToDisplay.length > 0;
	const handleCollapse = () => {
		setCollapsed(prevState => !prevState);
	};
	const maxFeatureLabelWidth = 135;
	const isFeatureLabelLong = getTextWidth(label) > maxFeatureLabelWidth;

	return(	<div>
		<div className={styles.keyContainer}>
			<div
				className={clsx(styles.label,
					!enabled && (isExistEnabledFeature  ? styles.keyExistEnabledFeature: styles.keyNotExistEnabledFeature),
					enabled && !isExistEnabledFeature && styles.keyNotExistEnabledFeature,
					enabled && isExistEnabledFeature && styles.keyEnabledExistFeatures)}
			>
				{enabled && isExistEnabledFeature && <div className={clsx(styles.iconContainer, (!isExistData) && styles.iconDisabled)}>
					{isExistData &&
						<ExpandLessIcon sx={{ fontSize: "small" }} className={clsx(styles.icon, collapsed && styles.iconUp)} onClick={handleCollapse}/>
					}
				</div>}
				<AppTooltip title={label} disabled={!isFeatureLabelLong}>
					<div>
						{isFeatureLabelLong? getShortStr(label, maxFeatureLabelWidth - dotsWidth) + '...':label}
					</div>
				</AppTooltip>
			</div>
			<div className={styles.variable}>{enabled ? "On": "Off"}</div>
		</div>
		{isExistData && collapsed && <div className={styles.featureContainer}>
			{dataToDisplay.map((feature, index) => {
				const maxLabelWidth = 165;

				if(longestKeyLength > maxLabelWidth) {
					longestKeyLength = maxLabelWidth;
				}
				const maxValueWidth = 345 - longestKeyLength;

				let value = Object.values(feature)[1];
				let label = Object.values(feature)[0];

				if( Array.isArray(value)) {
					value = value.join(', ').toString();

				}
				if( typeof (value) === 'object') {
					value = JSON.stringify(value);

				}
				const isLabelLong = getTextWidth(label) > maxLabelWidth;
				const isValueLong = getTextWidth(value.toString()) > maxValueWidth;

				return <div key={"feature" + index} className={styles.lineDetailsFeaturesContainer}>
					<AppTooltip title={label} disabled={!isLabelLong}>
						<div className={styles.keyFeatures} style={{ width: longestKeyLength }}>
							{isLabelLong? getShortStr(label, maxLabelWidth -dotsWidth) + '...': label+ ':'}
						</div >
					</AppTooltip>
					<AppTooltip title={value.toString()} disabled={!isValueLong}>
						<div className={styles.variableFeatures}>{isValueLong? getShortStr(value, maxValueWidth -dotsWidth) + '...': value.toString()}</div>
					</AppTooltip>
				</div>;
			})}
		</div>}
	</div>);
}

export function PackageFeaturesLineDetails({ label, enabled, dataToDisplay, longestKeyLength }) {

	const styles = useStyles();
	const maxKeyLength = 165;
	const maxLabelLength = 145;
	const isLabelLong = getTextWidth(label) > maxLabelLength;
	const isExistConfig = dataToDisplay.length >0;

	if (longestKeyLength > maxKeyLength) {
		longestKeyLength = maxKeyLength;
	}
	const maxValueWidth = 310 - longestKeyLength;

	return (<div>
		<div className={styles.lineDetailsContainer}>
			<AppTooltip title={label} disabled={!isLabelLong}>
				<div className={clsx(styles.label, styles.packageLabel)}>
					{isLabelLong? getShortStr(label, maxLabelLength-dotsWidth) + '...':label}
				</div>
			</AppTooltip>
			<div className={styles.variable}>{enabled ? "On" : "Off"}</div>
		</div>
		{enabled && isExistConfig && <div className={styles.separatorContainer}>
			<div className={styles.separator}/>
		</div>}
		<div className={styles.featureContentContainer}>
			{ isExistConfig && enabled && dataToDisplay.map((line, index) => {
				const key = line.label;
				let value = line.value;

				if (Array.isArray(value)) {
					value = value.join(', ');

				}
				if (typeof (value) === 'object') {
					value = JSON.stringify(value).replaceAll(',', ', ').replaceAll(':', ' : ');

				}
				value = value.toString();
				const isValueLong = getTextWidth(value.toString()) > maxValueWidth;
				const isKeyLong = getTextWidth(key) > maxKeyLength;

				return <div key={'lineFeature' + index}  className={clsx(styles.featureLineContainer, index + 1 === dataToDisplay.length && styles.lastFeatureLineContainer)}>
				 	<AppTooltip title={key} disabled={!isKeyLong}><div className={styles.featureContentKey} style={{ width: longestKeyLength }}>{isKeyLong ? getShortStr(key, maxKeyLength -dotsWidth) + '...' : key + ":"}</div></AppTooltip>
					<AppTooltip title={value} disabled={!isValueLong}>
						<div>{isValueLong ? getShortStr(value, maxValueWidth -dotsWidth) + '...' : value.toString()}</div>
					</AppTooltip>
				</div>;
			})}
		</div>
	</div>);
}
