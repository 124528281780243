import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useProjectsStore } from "../../../state-management";
import Header from "./Header";
import { Info } from "@ip-synamedia/syna-ui-library";
import { useCleanupEffect } from "../../../custom-hooks";
import Projects from "./Projects";

const useStyles = makeStyles(() => ({
	mainContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	headerContainer: {
		height: 100
	},
	root: {
		height: "100%",
		overflow: 'auto',
	},
	projectsContainer: {
		paddingTop: 10
	},
	selectAccountContainer: {
		position: "relative",
		width: "100%",
		height: "100%"
	},
	selectAccountInfo: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	selectAccountLabel: {
		fontSize: 25,
		marginTop: 10
	}
}));

export default function ProjectsPanel() {
	const classes = useStyles();
	const [accountId, resetProjectsStore] = useProjectsStore(state => [state.accountId ,  state.reset]);

	useCleanupEffect(resetProjectsStore);

	return (
		<div className={classes.mainContainer}>
			<div className={classes.headerContainer}>
				<Header/>
			</div>
			<div className={classes.root}>
				{!accountId && <div className={classes.selectAccountContainer}>
					<div className={classes.selectAccountInfo}>
						<Info style={{ display: "block", margin: "auto" }} fill='#3381FF' width='4em' height='4em'/>
						<div className={classes.selectAccountLabel}>
						Select account to manage projects
						</div>
					</div>
				</div>}
				{accountId && <div className={classes.projectsContainer}>
					<Projects/>
				</div>}
			</div>
		</div>
	);
}
