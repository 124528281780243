import styles from './Item.module.scss'
import clsx from 'clsx'
import Table from '../../Table'
import React, { useContext } from 'react'
import Highlight from '../../Highlight'
import Button from '../../Button'
import Tooltip from '../../Tooltip'
import { useID } from '../../../Util/ID'
import { useColumnWidth } from '../Columns'

export default function Item({
  id,
  node,
  widthPx,
  valueCb,
  elementCb,
  tooltipCb,
  linkCb,
  onClickRow,
  includeSearch = true
}) {
  const baseID = useID()
  const [search] = useContext(Table.Search) || []
  widthPx = useColumnWidth(id)
  let result = node[id]
  let tooltip = result
  if (elementCb) {
    result = elementCb(node, includeSearch && search)
  } else {
    if (valueCb) {
      tooltip = result = valueCb(node)
    }
    if (search && includeSearch && result) {
      result = (
        <Highlight highlight={search} clipped={true}>
          {result}
        </Highlight>
      )
    }
  }
  const onClick = () => {
    let result = true
    if (linkCb) {
      result = linkCb(node)
    }
    if (result && onClickRow) {
      onClickRow()
    }
  }
  const isString =
    (result && typeof result === 'string') ||
    typeof result === 'number' ||
    Array.isArray(result)
  const isLink = !!linkCb
  const tooltipParams = (tooltipCb && tooltipCb(node)) || {}
  return (
    <div
      data-cy={baseID + 'rowItem-' + node[id]}
      onClick={onClick}
      title={isString && !isLink ? tooltip : undefined}
      className={styles.root}
      style={{ width: widthPx }}
    >
      {!isLink && (
        <div className={clsx(styles.content, isString && styles.string)}>
          {result}
        </div>
      )}
      {!!isLink && (
        <Tooltip {...tooltipParams}>
          <Button className={clsx(styles.link, styles.string)}>{result}</Button>
        </Tooltip>
      )}
    </div>
  )
}
