import * as React from 'react'

function SvgEdit(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 14 15'
      fill='#8F8F8F'
      {...props}
    >
      <path
        d='M.655 14.75a.595.595 0 00.073-.004l3.121-.345 9.638-9.638a1.75 1.75 0 000-2.475l-1.025-1.025A1.745 1.745 0 0011.224.75c-.448 0-.895.17-1.237.512L7.95 3.299l-.95.95-6.649 6.649-.347 3.123a.656.656 0 00.65.729zm10.693-9.704L9.704 3.401l1.211-1.21a.437.437 0 01.619-.001l1.025 1.026a.44.44 0 010 .619l-1.21 1.21zm-9.949 8.305l.206-1.85 7.171-7.172 1.645 1.645-.849.848-6.325 6.325-1.848.204z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgEdit
