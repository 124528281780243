import * as React from 'react'

function SvgTagCompletedWithWarning(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 12 12'
      {...props}
    >
      <path
        d='M6 11.203A5.327 5.327 0 106 .547a5.327 5.327 0 100 10.656zm0-1.031c-2.375 0-4.297-1.922-4.297-4.297S3.625 1.578 6 1.578s4.297 1.922 4.297 4.297S8.375 10.172 6 10.172zM5.303 8.42L9.01 4.74c.1-.1.101-.263.001-.364l-.484-.488a.259.259 0 00-.365-.002L5.127 6.9 3.842 5.605a.259.259 0 00-.365-.002l-.488.484a.26.26 0 00-.001.365l1.95 1.966c.1.101.264.102.365.002z'
        fill='#FFC41C'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgTagCompletedWithWarning
