import { Table } from "@ip-synamedia/syna-ui-library";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppTooltip from "../../../../AppTooltip";

const useTableStyles = makeStyles(() => ({
	rootContainer: {
		height: 764,
		width: '100%',
		boxShadow: '2px 2px 5px grey',
		marginTop: 10,
		borderRadius: 4,
		overflow: 'hidden',
	},
	//TODO: the calculation below are done to hide unnecessary elements from the table
	// We have to use another table in future.
	tableContainer: {
		height: 'calc(100% + 50px)',
	},
}));

const useUserStyles = makeStyles(() => ({
	email: {
		fontSize: 10,
		color: 'gray',
		textTransform: 'lowercase',
	},
	role: {
		fontSize: 10,
		color: 'gray',
		textTransform: 'uppercase',
	},
}));

function UserName({ name, email, role }) {
	const styles = useUserStyles();

	return (
		<div>
			<div>{name}</div>
			<div className={styles.email}>{email}</div>
			<div className={styles.role}>{role}</div>
		</div>
	);
}

const useUsersRolesStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	rolesContainer: {
		display: 'flex',
		margin: 2,
		height: 'fit-content',
	},
	roleProduct: {
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		fontSize: 14,
		marginRight: 10,
	},
	roleDisplayName: {
		padding: '0px 2px',
		background: '#EFEFEF',
		width: 'fit-content',
		marginLeft: 0,
		borderRadius: 4,
		textTransform: 'uppercase',
		fontSize: 12,
		whiteSpace: 'nowrap',
	},
	separator: {
		border: '1px solid #C2C2C2',
		flex: 'none',
		margin: '0px 8px'
	},
	extenderContainer: {
		width: 'fit-content',
		border: '1px solid #8F8F8F',
		borderRadius: '4px',
		color: '#8F8F8F',
		margin: '2px',
	},
	extender: {
		margin: '0 4px',
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 12,
		fontWeight: 400,
		fontStretch: "normal",
		fontStyle: "normal",
	},
	role: {
		padding: '0px 8px',
		color: 'black',
		fontFamily: "Source Sans Pro,sans-serif",
		fontSize: 12,
		fontWeight: 400,
		fontStretch: "normal",
		fontStyle: "normal",
	},
}));

const rolesMaxSize = 2;

function UserRoles({ rolesList = []}) {
	const styles = useUsersRolesStyles();

	return (
		<div className={styles.rootContainer}>
			{ (rolesList).slice(0, rolesMaxSize).map((role, roleIndex) => {
				const addSeparator = roleIndex < rolesList.length - 1;

				return (
					<div key={role.id} className={styles.rolesContainer}>
						<div className={styles.roleProduct}>
							{role.name?.split(':')[0]}
						</div>
						<div className={styles.roleDisplayName}>
							{role.name}
						</div>
						{addSeparator && <div className={styles.separator}/>}
					</div>
				);
			})}
			{rolesList.length > rolesMaxSize &&
				<AppTooltip arrow title={
					rolesList.slice(rolesMaxSize, rolesList.length).map((role) => (role?.name ).toUpperCase()).map((role, index) => {
						return (
							<div key={index} className={styles.role}>
								{role}
							</div>
						);
					})}
				>
					<div className={styles.extenderContainer}>
						<div className={styles.extender}>
							+{rolesList.length - rolesMaxSize}
						</div>
					</div>
				</AppTooltip>}
		</div>
	);
}

export default function UsersTable({ users }) {
	const styles = useTableStyles();
	const headers = [
		{
			id: "userName",
			label: "User Name",
			widthUnits: 1,
			searchable: false,
			filterable: false,
			elementCb: (userData) => {
				return <UserName name={userData.userName} email={userData.email}/>;
			},
		},
		{
			id: "roles",
			label: "Roles",
			widthUnits: 2,
			searchable: false,
			filterable: false,
			sortable: false,
			elementCb: (userData) => <UserRoles rolesList={userData.roles}/>
		},
	];

	return (
		<div className={styles.rootContainer} >
			<div className={styles.tableContainer} >
				<Table
					tableData={users}
					tableHeaders={headers}
					tableOptions={false}
					sort={'userName'}
				/>
			</div>
		</div>
	);
}
