import React, {useContext} from "react"
import Contexts from "./Contexts"

export function useDrag({id, setItems}) {
    const [enabled] = useContext(Drag.Enabled) || [];
    const [dragId, setDragId] = useContext(Drag.Id) || [];

    const onDrag = event => {
        event.preventDefault();
    };
    const onDragOver = event => {
        event.preventDefault();
        setItems(items => {
            const fromIndex = items.findIndex(item => item.id === dragId);
            const toIndex = items.findIndex(item => item.id === id);
            if(fromIndex === toIndex) {
                return items;
            }
            items = [...items];
            items.splice(toIndex, 0, items.splice(fromIndex, 1)[0]);
            return items;
        });
    };
    const onDragStart = () => {
        if(enabled) {
            setDragId(id);
        }
    };
    const onDragEnd = event => {
        setDragId(null);
        event.preventDefault();
    };

    return {dragId, draggable:enabled, onDrag, onDragOver, onDragStart, onDragEnd};
}

export default function Drag({enabled, children}) {
    const components = [
        {
            Component: Drag,
            Id: null,
            Enabled: enabled
        }
    ];

    return <Contexts components={components}>
        {children}
    </Contexts>;
}

Drag.Id = React.createContext();
Drag.Enabled = React.createContext();
