import * as React from 'react'

function SvgFilter(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 14 15'
      {...props}
    >
      <path
        d='M9.187 13.456V7.419l4.427-4.428c.825-.825.24-2.241-.928-2.241H1.314C.148.75-.44 2.165.386 2.99l4.426 4.429v4.706c0 .413.195.802.525 1.094l1.75 1.287c.856.597 2.1.042 2.1-1.05zm-1.312-.019l-1.75-1.312v-5.25L1.312 2.063h11.375L7.875 6.875v6.562z'
        fill='#FFF'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgFilter
