import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		position: 'relative',
		marginBottom: 4,
	},
	listboxButtonContainer: {
		// width: 200,
	},
	listboxButton: {
		paddingLeft: 12,
		width: 200,
		textAlign: 'left',
		'&:focus,focus-visible': {
			borderColor: 'hsla(217, 100%, 60%, 1)',
			outline: 'none',
		},
		border: "1px solid #C2C2C2",
		borderRadius: 4,
		height: 30,
		backgroundColor: 'white',
	},
	listboxButtonInvalid: {
		borderColor: 'red',
		'&:focus': {
			borderColor: 'red',
		},
	},
	listboxButtonSelectorContainer:{
		position: 'absolute',
		color: 'black',
		top: 0,
		bottom: 0,
		right: 0,
		paddingRight: 2,
		display: 'flex',
		alignItems: 'center',
	},
	arrowDown: {
		padding: 2,
	},
	listboxButtonContent: {
		display: 'block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace : 'nowrap',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
	},
	listboxButtonContentUndefined: {
		color: 'gray',
	},
	transitionLeave: {
		transitionDuration: '200ms',
	},
	transitionLeaveFrom: {
		opacity: 1,
	},
	transitionLeaveTo: {
		opacity: 0,
	},
	listboxOptions: {
		position: 'absolute',
		top: 17,
		outline: 'none',
		border: '1px solid #C2C2C2',
		boxShadow: '0 5px 5px 0 #C2C2C2',
		borderRadius: 4,
		listStyle: 'none',
		padding: 0,
		zIndex: 1,
		backgroundColor: 'white',
		width: '100%',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
	},
	listboxOption: {
		width: '100%',
	},
	listboxOptionItemContainer: {
		cursor: 'default',
		userSelect: 'none',
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 30,
		paddingRight: 16,
		color: 'black',
		backgroundColor: 'white',
		borderRadius: 4,
	},
	listboxOptionItemContainerActive: {
		backgroundColor: '#0096fb',
	},
	listboxOptionItemContainerSelected: {
		// backgroundColor: '#3380FF',
	},
	listboxOptionItem: {
		fontWeight: 'normal',
	},
	listboxOptionItemActive: {
		fontWeight: 'bold',
	},
}));

export default function EnumSelect({ value, setValue, valueOptions, disabled=false }) {
	const styles = useStyles();

	return (
		<Listbox as="div" value={value} onChange={setValue} disabled={disabled}>
			{({ open }) => (
				<>
					<div className={styles.contentContainer}>
		  				<span className={styles.listboxButtonContainer}>
							<Listbox.Button className={styles.listboxButton}>
								<span className={clsx(styles.listboxButtonContent, !value && styles.listboxButtonContentUndefined)}>{value || "Select"}</span>
								<span className={styles.listboxButtonSelectorContainer}>
									<ArrowDropDownOutlinedIcon fontSize="large" className={styles.arrowDown}/>
								</span>
							</Listbox.Button>
		  				</span>
						<Transition
							show={open}
							leave={styles.transitionLeave}
							leaveFrom={styles.transitionLeaveFrom}
							leaveTo={styles.transitionLeaveTo}
						>
							<Listbox.Options className={styles.listboxOptions}>
								{valueOptions && valueOptions.map((valueOption) => (
									<Listbox.Option className={styles.listboxOption} key={valueOption} value={valueOption}>
										{({ selected, active }) => (
											<div className={clsx(styles.listboxOptionItemContainer, active && styles.listboxOptionItemContainerActive, selected && styles.listboxOptionItemContainerSelected)}>
												<span className={clsx(styles.listboxOptionItem, selected && styles.listboxOptionItemActive)}>
													{valueOption}
												</span>
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
}
