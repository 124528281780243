import React, { useContext, useMemo } from "react"
import styles from "./Columns.module.scss"
import Checkbox from "../Checkbox"
import Item from "./Columns/Item"
import Table from '../Table'
import clsx from "clsx"
import TableOptions from "./TableOptions"
import Region, {useRegion} from "../../Util/Region"
import {useID} from "../../Util/ID"

function mapItemsToElements(items, options) {
    return items.filter(item => typeof item.visible === "undefined" || item.visible).map((item, index, list) => {
        return <Item key={item.id} index={index} count={list.length} {...options} {...item} />;
    });
}

export function useElements(options) {
    const [items] = useContext(Table.Columns) || [];
    const elements = useMemo(() => {
        if (items) {
            return mapItemsToElements(items, options);
        }
        return null;
    }, [items]);
    return elements;
}

export default function Columns({visible, tableOptions, resizeHandle, ...props}) {
    const baseID = useID();
    const [collapse] = [];
    const [scroll] = useContext(Table.Scroll) || [];
    const [items] = useContext(Table.Items) || [];
    const [selected, setSelected] = useContext(Table.Selected) || [];
    const [singleSelection,] = useContext(Table.SingleSelection) || [];
    const elements = useElements({resizeHandle});
    const allChecked = selected && items && !!items.length && selected.length === items.length;
    const identifier = props.identifier ? props.identifier : "id";
    const setChecked = () => {
        if(allChecked) {
            setSelected && setSelected([]);
        }
        else {
            setSelected && setSelected((items || []).map(item => item[identifier]));
        }
    };
    return <div className={clsx(styles.root, visible && styles.visible)}>
        <div data-cy={baseID + "columns"} className={styles.row}>
            { props.checkboxSelection && <Checkbox visible={selected && !singleSelection} className={clsx(styles.checkbox, items && items.length && styles.visible)} checked={allChecked} setChecked={setChecked} />}
            <Region context={Columns.Region} depends={[collapse]}>
                <div className={styles.items}>
                    {elements}
                </div>
            </Region>
            {!!tableOptions && <TableOptions />}
        </div>
        <div className={clsx(styles.separator, scroll && styles.scroll)} />
    </div>;
}

export function useColumnWidth(columnId) {
    const region = useRegion(Columns.Region);
    const [items] = useContext(Table.Columns) || [];
    const item = items.find(item => item.id === columnId);
    return useMemo(() => {
        if(!item || !region) {
            return 0;        
        }
        if(item.width) {
            return item.width;
        }
        const visibleColumns = items.filter(item => typeof item.visible === "undefined" || item.visible);
        const availableWidth = visibleColumns.reduce((totalWidth, item) => totalWidth - (item.width || 0), region.width);
        const columnsCount = visibleColumns.reduce((count, item) => count + (item.width ? 0 : (item.widthUnits || 1)), 0);
        const unitWidth = availableWidth / columnsCount;
        const {widthUnits = 1} = item;
        return unitWidth * widthUnits;
    }, [region && region.width, items, columnId]);
}

Columns.Region = React.createContext();
