import moment from 'moment'

const fallbackValue = ""
export function formatDate(string, format = 'DD MMM yyyy, h:mm A') {
    if (!string) {
      return fallbackValue
    }
    const date = new Date(string)
    return moment(date).format(format)
}

export function formatDuration(duration, includeHours = false) {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursString = (hours || includeHours) ? ((hours < 10) ? "0" + hours : hours) + ":" : "";
    const minutesString = (minutes < 10) ? "0" + minutes : minutes;
    const secondsString = (seconds < 10) ? "0" + seconds : seconds;

    return hoursString + minutesString + ":" + secondsString;
}
