import './app.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HomePage, InvitationPage, LandingPage } from "./components/pages";
import ProtectedRoute from "./auth/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import React, { useState, useEffect } from "react";
import { enableMapSet } from "immer";
import { setConfig } from "./config";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import { QueryClient, QueryClientProvider } from "react-query";
import UserInfoProvider from "./auth/UserInfoProvider";

enableMapSet();

const queryClient = new QueryClient();

function App() {
	// Ensure initialisation of the config via dynamic loading of the config.json
	const [configLoaded, setConfigLoaded] = useState();

	useEffect(() => {
		fetch("/config.json")
			.then(response => response.json())
			.then(configValue => {
				const isConfigUpdated = setConfig(configValue);

				setConfigLoaded(isConfigUpdated);
			})
			.catch(console.error);
	}, []);

	return configLoaded ? (
		<Router>
			<Auth0ProviderWithHistory>
				<QueryClientProvider client={queryClient}>
					<UserInfoProvider>
						<div className="App">
							<Switch>
								<Route exact path="/" component={LandingPage}/>
								<Route exact path="/invite" component={InvitationPage}/>
								<ProtectedRoute exact path="/home" component={HomePage}/>
							</Switch>
							<ToastContainer pauseOnFocusLoss={false}/>
						</div>
					</UserInfoProvider>
				</QueryClientProvider>
			</Auth0ProviderWithHistory>
		</Router>
	) : null;
}

export default App;
