import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { makeStyles } from "@material-ui/core/styles";
import { useCreateTenantStore, useProjectsStore } from "../../../../../../../state-management";
import { useQueryWithAuthorization } from "../../../../../../../custom-hooks";
import {
	getProductConfigByProductId as apiGetProductConfigByProductId, getProducts as apiGetProducts,
	getProjectsByAccountId
} from "../../../../../../../external-apis";
import Loader from "../../../../../../Loader";
import Configurations from "../Configurations";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		left: 30,
		top: 60,
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 14,
		height: 550,
		width: 823,
	},
	generalInfoContainer: {
		display: 'flex',
		margin: '15px 0',
	},

}));

const mapToLabel = (items) => {
	return (items || []).reduce((itemsByIds, currentItem) => {
		const { id, displayName } = currentItem;

		return {
			...itemsByIds,
			[id]: displayName
		};
	}, {});
};

const useElementStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
	},
	label: {
		marginRight: 5,
		color: '#8F8F8F',
	},
	separator: {
		border: '1px solid #C2C2C2',
		flex: 'none',
		margin: '0px 8px'
	}
}));

function Element({ label, value, isLast }) {
	const styles = useElementStyles();

	return (<div className={styles.root}>
		<div className={styles.label}>
			{`${label}:`}
		</div>
		<div className={styles.value}>
			{value}
		</div>
		{!isLast && <div className={styles.separator}/>}
	</div>);
}

const staleTimeout = 60000;

export default function TenantConfiguration({ setVisible, setErrorMessage, setPackagesOptions, tenant }) {
	const styles = useStyles();

	const [productConfigOptions, setProductConfigOptions] = useState({});
	const [productFeaturesOptions, setProductFeaturesOptions] = useState({});
	const resetState = useCreateTenantStore(state => state.reset, shallow);
	const accountId = useProjectsStore(state => state.accountId, shallow);
	const productId = useCreateTenantStore(state => state.productId, shallow);
	const projectId = useCreateTenantStore(state => state.projectId, shallow);
	const environment = useCreateTenantStore(state => state.environment, shallow);
	const tenantTypeLabels = useCreateTenantStore(state => state.tenantTypeLabels, shallow);
	const [subscriptionStatus, subscriptionStatusLabels] = useCreateTenantStore(state => [state.subscriptionStatus, state.subscriptionStatusLabels], shallow);
	const setFinishButtonDisabled = useCreateTenantStore(state => state.setFinishButtonDisabled, shallow);
	const [productConfigParams = {}, updateProductConfigParams, removeFromProductConfigParams] = useCreateTenantStore(state => [state.productConfigParams, state.updateProductConfigParams, state.removeFromProductConfigParams], shallow);
	const [featuresConfigParams = {}, updateFeatureConfigParams] = useCreateTenantStore(state => [state.featuresConfigParams, state.updateFeatureConfigParams], shallow);
	const [featureIds, addFeatureIds, setFeatureIds] = useCreateTenantStore(state => [state.featureIds, state.addFeatureIds, state.setFeatureIds], shallow);
	const setFullListOfFeatureIds = useCreateTenantStore(state => state.setFullListOfFeatureIds, shallow);

	const {
		isLoading: isProjectsLoading,
		isError: isProjectsError,
		data: labelsByProjectId,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapToLabel,
		enabled: accountId != null,
		staleTime: staleTimeout,
	});

	const {
		isLoading: isProductsLoading,
		isError: isProductsError,
		data: labelsByProductId,
		error: productsError
	} = useQueryWithAuthorization('products', apiGetProducts(), {
		select: mapToLabel,
		staleTime: staleTimeout,
	});

	const {
		isLoading: isProductInventoryLoading,
		isError: isProductInventoryError,
		data: productInventory,
		error: productsInventoryError
	} = useQueryWithAuthorization(`productConfig-${productId}`, apiGetProductConfigByProductId(productId), {
		enabled: productId != null,
		staleTime: staleTimeout,
	});

	useEffect(() => {
		function updateConfigParameter(parameterConfig, name, value) {
			updateProductConfigParams({
				[name]: {
					value: parameterConfig.type === 'array' ? value : String(value),
					type: parameterConfig.type
				}
			});
		}

		function updateFeatureParameter(featureConfig, parameterConfig, name, value) {
			featureConfig[name] = {
				value: parameterConfig.type === 'array' ? value : String(value),
				type: parameterConfig.type
			};
		}

		if (!(isProductInventoryLoading || isProductInventoryError || productInventory == null)) {
			const { config = {}, packages = {} } = productInventory;

			setProductConfigOptions(config);
			setPackagesOptions(packages);
			if (Object.keys(productConfigParams).length === 0) { //Initiate productConfigParams only when it's empty (upon the first time)
				config && Object.entries(config).forEach(([parameter, parameterConfig]) => {
					const tenantParamValue = tenant?.tenantConfig?.[parameter];

					if (tenant != null && tenantParamValue != null) {
						updateConfigParameter(parameterConfig, parameter, tenantParamValue);
					} else if (tenant == null && parameterConfig?.defaultValue != null) {
						updateConfigParameter(parameterConfig, parameter, parameterConfig.defaultValue);
					}
				});
			}
			//FOR DEBUGGING
			//TODO: delete it once we have feature definition on some tenant
			// const features = {
			// 	"cclReporting_synamedia": {
			// 		"label": "CCL Reporting Long Long...",
			// 		"description": "Enable Clarissa Client Library",
			// 		"availability": "synamedia",
			// 		"config": {
			// 			"configNumber": {
			// 				"label": "Configuration Number",
			// 				"type": "number",
			// 				"required": "true",
			// 				"defaultValue": 300,
			// 				modifiable: false,
			// 				"description": "Interval in seconds for reporting"
			// 			},
			// 			"configBoolean": {
			// 				"label": "Configuration Boolean",
			// 				"type": "boolean",
			// 				"defaultValue": "true",
			// 				modifiable: false,
			// 				"description": "Test boolean parameter"
			// 			},
			// 			"configString": {
			// 				"label": "Configuration String Long Long Long Long",
			// 				"type": "string",
			// 				"defaultValue": "true",
			// 				modifiable: false,
			// 				"description": "Test string parameter"
			// 			},
			// 			"configEnum": {
			// 				"label": "Configuration Enum String",
			// 				"required": false,
			// 				"type": "enum",
			// 				"enum": ["one", "two", "three"],
			// 				"defaultValue": "three",
			// 				modifiable: false,
			// 				"description": "Test string parameter"
			// 			},
			// 			"configEnumNumber": {
			// 				"label": "Configuration Enum Number",
			// 				"required": true,
			// 				"type": "enum",
			// 				"enum": [1, 2, 3],
			// 				"defaultValue": 1,
			// 				modifiable: true,
			// 				"description": "Test number parameter"
			// 			},
			// 			"configArray": {
			// 				"label": "Configuration Array",
			// 				"required": true,
			// 				"type": "array",
			// 				"enum": ["Yaniv", "Guy", "Avital", "Racheli", "Moti", "Chaim"],
			// 				"defaultValue": ["Yaniv", "Guy", "Avital"],
			// 				modifiable: false,
			// 				"description": "Test string parameter"
			// 			}
			// 		}
			// 	},
			// 	"synamedia_feature_2": {
			// 		"label": "Synamedia Feature 2",
			// 		"description": "Synamedia Feature 2 Description",
			// 		"availability": "synamedia",
			// 	},
			// 	"synamedia_feature_3": {
			// 		"label": "Synamedia Feature 3",
			// 		"description": "Synamedia Feature 3 Description",
			// 		"availability": "synamedia",
			// 		"config": {
			// 			"configParam1": {
			// 				"label": "Configuration Parameter 1",
			// 				"type": "number",
			// 				"defaultValue": 300,
			// 				"description": "Interval in seconds for reporting"
			// 			},
			// 			"configParam2": {
			// 				"label": "Configuration Parameter 2",
			// 				"type": "boolean",
			// 				"required": "true",
			// 				"defaultValue": "true",
			// 				"description": "Test boolean parameter"
			// 			}
			// 		}
			// 	}
			// };
			//
			// if (tenant != null) {
			// 	tenant.tenantConfig.features = {
			// 		"cclReporting_synamedia": {
			// 			"enabled": true,
			// 			"config": {
			// 				"configNumber": 303,
			// 				"configBoolean": true,
			// 				"configString": "true",
			// 				"configEnum": "three",
			// 				"configArray": [
			// 					"Yaniv",
			// 					"Guy",
			// 					"Avital"
			// 				]
			// 			}
			// 		},
			// 		"synamedia_feature_2": {
			// 			"enabled": true
			// 		},
			// 		"synamedia_feature_3": {
			// 			"enabled": false,
			// 			// "config": {
			// 			// 	"configParam1": 305,
			// 			// 	"configParam2": true
			// 			// }
			// 		}
			// 	};
			// }
			//END OF FOR DEBUGGING

			const { features = {} } = productInventory;

			setProductFeaturesOptions(features);
			const fullListOfFeatureIds = Object.keys(featuresConfigParams);

			setFullListOfFeatureIds(fullListOfFeatureIds);

			if (Object.keys(featuresConfigParams).length === 0) { //Initiate featuresConfigParams only when it's empty (upon the first time)

				features && Object.entries(features).forEach(([featureId, featureOptions]) => {
					const featureConfig = {};
					const isTenantFeatureEnabled = tenant?.tenantConfig?.features?.[featureId]?.enabled === true;

					if ( isTenantFeatureEnabled ) {
						addFeatureIds(featureId);
					}

					featureOptions.config && Object.entries(featureOptions.config).forEach(([parameter, parameterConfig]) => {
						const tenantFeatureParamValue = tenant?.tenantConfig?.features?.[featureId]?.config?.[parameter];

						if (tenantFeatureParamValue != null) {
							updateFeatureParameter(featureConfig, parameterConfig, parameter, tenantFeatureParamValue);
						} else if (!isTenantFeatureEnabled && parameterConfig?.defaultValue != null) {
							updateFeatureParameter(featureConfig, parameterConfig, parameter, parameterConfig.defaultValue);
						}
					});
					updateFeatureConfigParams(featureId, featureConfig);
				});
			}
		}
	}, [productConfigParams, featuresConfigParams, productInventory, isProductInventoryLoading, isProductInventoryError, updateProductConfigParams, setFullListOfFeatureIds, setPackagesOptions, updateFeatureConfigParams, addFeatureIds, tenant]);

	useEffect(() => {
		let isValid = true;

		if (isProductInventoryError) {
			resetState();
			setVisible(false);
			console.error('Error getting product inventory', productsInventoryError?.message);
			setErrorMessage('An unexpected error occurred, we apologise for the inconvenience,\n' +
				'please contact customer support.');
		}
		else if(isProductInventoryLoading) {
			isValid = false;
		} else {
			productConfigOptions && Object.entries(productConfigOptions).forEach(([parameter, parameterConfig]) => {
				const required = parameterConfig?.required || false;
				const configParam = productConfigParams?.[parameter];

				if (required && !(configParam && configParam.value && !((typeof configParam.value === 'string' || Array.isArray(configParam.value)) && configParam.value.length === 0 ))) {
					isValid = false;
				}
			});
		}

		featureIds && featureIds.forEach( featureId => {
			const configOptions = productFeaturesOptions?.[featureId]?.config;

			configOptions && Object.entries(configOptions).forEach(([parameter, parameterConfig]) => {
				const required = parameterConfig?.required || false;
				const configParam = featuresConfigParams?.[featureId]?.[parameter];

				if (required && !(configParam && configParam.value && !((typeof configParam.value === 'string' || Array.isArray(configParam.value)) && configParam.value.length === 0 ))) {
					isValid = false;
				}
			});
		});

		setFinishButtonDisabled(!isValid);
	}, [featureIds, productFeaturesOptions, featuresConfigParams, productConfigParams, productConfigOptions, isProductInventoryLoading, isProductInventoryError, productsInventoryError, setFinishButtonDisabled, setErrorMessage, resetState, setVisible]);

	if (isProductInventoryLoading || isProjectsLoading || isProductsLoading) return <Loader/>;
	if (isProjectsError) return <span>Error: {projectsError.message}</span>;
	if (isProductsError) return <span>Error: {productsError.message}</span>;

	const handleSelectionChange = (value, parameter, type) => {
		removeFromProductConfigParams(parameter);
		if (value != null) {
			updateProductConfigParams({
				[parameter]: {
					value,
					type
				},
			});
		}
	};

	const handleFeatureSelectionChange = (featureId, value, parameter, type) => {
		const featureConfigParam = { ...featuresConfigParams[featureId] };

		delete featureConfigParam[parameter];
		if (value != null) {
			featureConfigParam[parameter] = {
				value,
				type
			};
		}
		updateFeatureConfigParams(featureId, featureConfigParam);
	};

	return (<div className={styles.root}>
		<div className={styles.generalInfoContainer}>
			<Element label='Project' value={labelsByProjectId != null && labelsByProjectId[projectId]} isLast={false}/>
			<Element label='Product' value={labelsByProductId != null && labelsByProductId[productId]} isLast={false}/>
			<Element label='Tenant type' value={tenantTypeLabels != null && tenantTypeLabels[environment]} isLast={false}/>
			<Element label='Subscription' value={subscriptionStatusLabels != null && subscriptionStatusLabels[subscriptionStatus]} isLast={true}/>
		</div>
		<Configurations
			configOptions={productConfigOptions}
			configParams={productConfigParams}
			handleSelectionChange={handleSelectionChange}
			featuresOptions={productFeaturesOptions}
			featuresConfigParams={featuresConfigParams}
			featureIds={featureIds}
			addFeatureIds={addFeatureIds}
			setFeatureIds={setFeatureIds}
			handleFeatureSelectionChange={handleFeatureSelectionChange}
			maxParameterLabelWidth={300}
			editTenant={tenant != null}
		/>
	</div>);

}
