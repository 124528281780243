import { makeStyles } from "@material-ui/core/styles";
import TenantCard from "./TenantCard";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: 20
	}

}));

export default function Tenants({ accountId, projectId, tenantsData, setShowDrawer, handleTabStopPropagation, setIsDrawerOpen } ) {
	const styles = useStyles();

	return (
		tenantsData && <div className={styles.rootContainer}>
			{Object.values(tenantsData).map(tenant => {
				const { tenantId, productId } = tenant;

				return (
					<TenantCard
						accountId={accountId}
						projectId={projectId}
						key={tenantId}
						tenantId={tenantId}
						productId={productId}
						setShowDrawer={setShowDrawer}
						handleTabStopPropagation={handleTabStopPropagation}
						setIsDrawerOpen={setIsDrawerOpen}
					/>
				);
			})}
		</div>
	);
}
