import styles from './Item.module.scss'
import clsx from 'clsx'
import SortDescIcon from '../../../../iconComponents/SortDesc'
import SortAscIcon from '../../../../iconComponents/SortAsc'
import SortAscSelectedIcon from '../../../../iconComponents/SortAscSelected'
import SortDescSelectedIcon from '../../../../iconComponents/SortDescSelected'
import SortAscHoverIcon from '../../../../iconComponents/SortAscHover'
import SortDescHoverIcon from '../../../../iconComponents/SortDescHover'
import SortAscActiveIcon from '../../../../iconComponents/SortAscActive'
import SortDescActiveIcon from '../../../../iconComponents/SortDescActive'
import React, { useContext, useRef, useEffect } from 'react'
import Table from '../../Table'
import { useColumnWidth } from '../Columns'
import ColumnSelector from '../../Filter/ColumnSelector'
import Contexts from '../../../Util/Contexts'
import Menu from '../../Menu'
import { useID } from '../../../Util/ID'
import { useHover } from '../../../../../util/hover'
import DragHandle from './DragHandle'

export default function Item({
  id,
  index,
  count,
  label,
  direction = 'desc',
  filterColumn = false,
  valuesCb,
  valueCb,
  resizeHandle,
  sortable = true
}) {
  const baseID = useID()
  const ref = useRef()
  const [hover, active] = useHover(ref)
  const width = useColumnWidth(id)
  const [, setColumnsItems] = useContext(Table.Columns) || []
  const [sort, setSort] = useContext(Table.Sort) || []

  const components = [
    {
      Component: Menu,
      Items: [{ id: 'none', label: 'No Items', disabled: true }],
      Selected: '',
      Search: ''
    }
  ]

  useEffect(() => {
    setColumnsItems &&
      setColumnsItems((items) => {
        const item = items.find((item) => item.id === id)
        item.widthPx = width
        return [...items]
      })
  }, [width])

  const onClick = () => {
    if (sort === id) {
      setColumnsItems &&
        setColumnsItems((items) => {
          const item = items.find((item) => item.id === id)
          item.direction = item.direction === 'asc' ? 'desc' : 'asc'
          return [...items]
        })
    }
    setSort && setSort(id)
  }
  const callback = valuesCb || valueCb
  const sortSelected = sort === id
  const sortAsc = direction === 'asc'
  let AscIcon = SortAscIcon
  let DescIcon = SortDescIcon
  if (sortSelected) {
    AscIcon = SortAscSelectedIcon
    DescIcon = SortDescSelectedIcon
  }
  if (hover) {
    AscIcon = SortAscHoverIcon
    DescIcon = SortDescHoverIcon
  }
  if (active) {
    AscIcon = SortAscActiveIcon
    DescIcon = SortDescActiveIcon
  }
  return (
    <div
      data-cy={baseID + 'columnsItem-' + id}
      style={{ width }}
      className={styles.header}
    >
      <div
        ref={ref}
        onClick={sortable ? onClick : () => {}}
        className={clsx(styles.root, sort === id && styles.selected)}
      >
        <div className={clsx(styles.label, sort === id && styles.selected)}>
          {label}
        </div>
        {!!label && !!sortAsc && sortable && (
          <AscIcon className={styles.sortAsc} />
        )}
        {!!label && !sortAsc && sortable && (
          <DescIcon className={styles.sortDesc} />
        )}
      </div>
      {!!filterColumn && (
        <Contexts components={components}>
          <ColumnSelector columnId={id} callback={callback} />
        </Contexts>
      )}
      {!!label && !!resizeHandle && index < count - 1 && <DragHandle id={id} />}
    </div>
  )
}
