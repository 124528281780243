import * as React from 'react'

function SvgNotFound(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 45 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M41.337 34.307l-2.952-2.53-2.813 3.796 2.813 2.25c.421.609 1.602 1.462 2.952 0 1.35-1.463.563-2.953 0-3.516z'
        fill='#3380FF'
        fillOpacity={0.2}
      />
      <path
        d='M19.812 21.494l2.853-3.274L41.28 34.436a2.172 2.172 0 01-2.852 3.274L19.812 21.494zM35.07 35.45l3.51-4.029'
        stroke='#3380FF'
      />
      <rect
        x={0.5}
        y={0.5}
        width={23.593}
        height={23.593}
        rx={11.797}
        fill='#fff'
        fillOpacity={0.7}
      />
      <circle
        cx={12.25}
        cy={12.25}
        r={9}
        stroke='#3380FF'
        strokeOpacity={0.2}
        strokeWidth={2.5}
      />
      <path
        stroke='#3380FF'
        strokeLinecap='round'
        d='M22.704 21.936l11.232 9.36'
      />
      <path
        d='M11.355 13.775c-.05-.36-.04-.69.03-.99.08-.3.19-.575.33-.825.15-.25.315-.48.495-.69.19-.22.365-.43.525-.63.17-.21.31-.415.42-.615.11-.2.165-.415.165-.645 0-.36-.11-.65-.33-.87-.21-.22-.505-.33-.885-.33-.31 0-.59.07-.84.21-.25.14-.485.33-.705.57l-.96-.885c.33-.38.72-.69 1.17-.93.46-.25.975-.375 1.545-.375.39 0 .75.055 1.08.165.33.1.61.255.84.465.24.21.425.47.555.78.14.31.21.67.21 1.08 0 .32-.06.61-.18.87-.11.25-.25.49-.42.72-.17.23-.355.45-.555.66-.19.21-.365.43-.525.66-.15.23-.275.475-.375.735-.09.26-.115.55-.075.87h-1.515zm.765 3.405c-.32 0-.585-.11-.795-.33-.21-.22-.315-.5-.315-.84 0-.34.105-.62.315-.84.21-.22.475-.33.795-.33.32 0 .585.11.795.33.22.22.33.5.33.84 0 .34-.11.62-.33.84-.21.22-.475.33-.795.33z'
        fill='#3380FF'
      />
      <rect
        x={0.5}
        y={0.5}
        width={23.593}
        height={23.593}
        rx={11.797}
        stroke='#3380FF'
      />
    </svg>
  )
}

export default SvgNotFound
