import create from "zustand";
import merge from 'deepmerge';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

const initialState = Object.freeze({
	currentStep: 0,
	generalSettingStep: 0,
	nextButtonDisabled: true,
	finishButtonDisabled: true,
	showAddTenantDialog: false,
	projectId: undefined,
	packagesConfigParams: {},
	packagesFeaturesConfigParams: {},
	packagesFeatureIds: {},
	fullListOfPackagesFeatureIds: {},
	packageIds: [],
	featuresConfigParams: {},
	featureIds: [],
	fullListOfFeatureIds: [],
	productId: undefined,
	environment: undefined,
	subscriptionStatus: undefined,
	existingProductsIds: [],
	productConfigParams: {},
	tenantTypeLabels: { production: 'Production', test: 'Test' },
	subscriptionStatusLabels: { trial: 'Trial', active: 'Active' }
});

function replaceParam(object, param, value) {
	const _object = cloneDeep(object);

	_object[param] = value;

	return _object;
}

const useStore = create((set) => ({
	...initialState,

	setShowAddTenantDialog: (val) => {
		set(() => ({
			showAddTenantDialog: val
		}));
	},
	resetProductConfigParams: () => {
		set(() => ({
			productConfigParams: {}
		}));
	},
	updateProductConfigParams: prop => {
		set(state => ({
			productConfigParams: merge(state.productConfigParams, prop)
		}));
	},
	removeFromProductConfigParams: (param) => {
		set((state) => ({
			productConfigParams: omit(state.productConfigParams, param)
		}));
	},
	resetPackageConfigParams: () => {
		set(() => ({
			packagesConfigParams: {}
		}));
	},
	updatePackageConfigParams: (packageId, value) => {
		set((state) => ({
			packagesConfigParams: replaceParam(state.packagesConfigParams, packageId, value)
		}));
	},
	resetPackagesFeaturesConfigParams: () => {
		set(() => ({
			packagesFeaturesConfigParams: {}
		}));
	},
	updatePackagesFeaturesConfigParams: (packageId, value) => {
		set((state) => ({
			packagesFeaturesConfigParams: replaceParam(state.packagesFeaturesConfigParams, packageId, value)
		}));
	},
	resetFeatureConfigParams: () => {
		set(() => ({
			featuresConfigParams: {}
		}));
	},
	updateFeatureConfigParams: (param, value) => {
		set((state) => ({
			featuresConfigParams: replaceParam(state.featuresConfigParams, param, value)
		}));
	},
	resetFullListOfFeatureIds: () => {
		set(() => ({
			fullListOfFeatureIds: {}
		}));
	},
	setFullListOfFeatureIds: (val) => {
		set(() => ({
			fullListOfFeatureIds: val
		}));
	},
	resetFullListOfPackagesFeatureIds: () => {
		set(() => ({
			fullListOfPackagesFeatureIds: {}
		}));
	},
	setFullListOfPackagesFeatureIds: (param, val) => {
		set((state) => ({
			fullListOfPackagesFeatureIds: replaceParam(state.fullListOfPackagesFeatureIds, param, val)
		}));
	},
	resetPackagesFeatureIds: () => {
		set(() => ({
			packagesFeatureIds: {}
		}));
	},
	updatePackagesFeatureIds: (param, value) => {
		set((state) => ({
			packagesFeatureIds: replaceParam(state.packagesFeatureIds, param, value)
		}));
	},
	setProjectId: (val) => {
		set(() => ({
			projectId: val
		}));
	},
	initiatePackagesIds: () => {
		set(() => ({
			packageIds: []
		}));
	},
	setPackageIds: (val) => {
		set(() => ({
			packageIds: val
		}));
	},
	addPackageIds: (val) => {
		set((prevState) => ({
			packageIds: [...prevState?.packageIds, val]
		}));
	},
	initiateFeaturesIds: () => {
		set(() => ({
			featureIds: []
		}));
	},
	setFeatureIds: (val) => {
		set(() => ({
			featureIds: val
		}));
	},
	addFeatureIds: (val) => {
		set((prevState) => ({
			featureIds: [...prevState?.featureIds, val]
		}));
	},
	initiateExistingProductsIds: () => {
		set(() => ({
			existingProductsIds: []
		}));
	},
	addExistingProductsIds: (val) => {
		set((prevState) => ({
			existingProductsIds: [...prevState?.existingProductsIds, val]
		}));
	},
	setProductId: (val) => {
		set(() => ({
			productId: val
		}));
	},
	setEnvironment: (val) => {
		set(() => ({
			environment:val
		}));
	},
	setSubscriptionStatus: (val) => {
		set(() => ({
			subscriptionStatus:val
		}));
	},
	setCurrentStep: (val) => {
		set(() => ({
			currentStep: val,
		}));
	},
	setGeneralSettingStep: (val) => {
		set(() => ({
			generalSettingStep: val,
		}));
	},
	setNextButtonDisabled: (val) => {
		set(() => ({
			nextButtonDisabled: val,
		}));
	},
	setFinishButtonDisabled: (val) => {
		set(() => ({
			finishButtonDisabled: val,
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
