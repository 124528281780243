import { makeStyles } from "@material-ui/core/styles";
import AppButton from "../AppButton";
import React from "react";
import AppDialog from "../AppDialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		width: 500,
		height: 151
	},
	dialogBody: {
		paddingTop: 8,
	},
	warningContainer: {
		display: 'flex',
		alignItems: 'left',
		flexDirection: "column"
	},
	warningFirstRow: {
		display: 'flex',
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '10px 0 0',
		gap: '8px', // Adds space between checkbox and text
		fontSize: '14px',
		cursor: 'pointer', // Makes it clear the area is interactive
		'& input': {
			margin: 0,
			color: '#FF3333',// Removes default input margins for better alignment
		},
		'&:hover': {
			color: '#FF3333', // Subtle hover effect
		}
	},
	reportIcon: {
		color: '#FF3333'
	},
	textContainer: {
		height: 20,
		marginLeft: 10,
	},
	buttonsContainer: {
		position: 'absolute',
		bottom: 24,
		right: 24,
		display: 'flex',
		marginLeft: 'auto',
		height: 30
	},
	deleteButton: {
		display: 'flex',
		alignItems: 'center',
		width: 96,
		marginLeft: 10
	},
	deleteButtonIcon: {
		marginRight: 9,
	},
}));

export default function AppDeleteConfirmation({ visible, onIncludeCheckBoxChange, includeCheckBox = false, checkBoxText, label, value, handleDelete, overrideClasses ={}, handleClose, title = 'Delete' , overrideText= false, overrideActionBtn =false }) {
	const styles = useStyles();

	return <AppDialog
		variant={'error'}
		open={visible}
		onClose={handleClose}
		title={title}
		overrideClasses={{ content: styles.dialogContent, body: styles.dialogBody }}
	>
		<div className={styles.warningContainer}>
			<div className={styles.warningFirstRow}>
				<ErrorOutlineIcon className={styles.reportIcon} sx={{ width: '14px', height: '14px' }}/>
				<div className={styles.textContainer}>
					{overrideText? overrideText: `Are you sure you want to ${title.toLowerCase()} ‘${value}’ ${label}?`}
				</div>
			</div>
			{includeCheckBox && <div className={styles.checkboxContainer}>
				<label>{checkBoxText}</label>
				<input
					type="checkbox"
					onChange={(e) => onIncludeCheckBoxChange(e.target.checked)}
				/>
			</div>}
		</div>
		<div className={styles.buttonsContainer}>
			<AppButton variant={'outlined'} onClick={handleClose}>{overrideActionBtn? "OK":"CANCEL"}</AppButton>
			{!overrideActionBtn &&
				<AppButton
					variant={'contained'}
					onClick={(e) => {
						handleDelete();
					}}
					overrideClasses={overrideClasses?.actionButton ? { button: overrideClasses.actionButton }: { button: styles.deleteButton }}>
					<DeleteOutlineOutlinedIcon className={styles.deleteButtonIcon} sx={{ width: '16px', height: '16px' }}/>
					{title.toUpperCase()}
				</AppButton>}
		</div>
		<div className={styles.buttonsContainer}>
			<AppButton variant={'outlined'} onClick={handleClose}>{overrideActionBtn? "OK1":"CANCEL"}</AppButton>
			{!overrideActionBtn &&
				<AppButton
					variant={'contained'}
					onClick={(e) => {
						handleDelete();
					}}
					overrideClasses={overrideClasses?.actionButton ? { button: overrideClasses.actionButton }: { button: styles.deleteButton }}>
					<DeleteOutlineOutlinedIcon className={styles.deleteButtonIcon} sx={{ width: '16px', height: '16px' }}/>
					{title.toUpperCase()}
				</AppButton>}
		</div>
	</AppDialog>;
}
