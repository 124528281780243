import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const postUserInvitation = (accountId, invitationObj, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/accounts/${accountId}/users/invite`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: JSON.stringify(invitationObj)
	};
};

const getInvitedUsers = (accountId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/invitees`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const getUsers = (accountId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/users`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const getUserRoles = (userId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/users/${userId}/roles`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const getProjectUsers = (accountId, projectId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/users`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const deleteUserInvitation = (accountId, invitationId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'DELETE',
		url: `${getApiEndpoint()}/accounts/${accountId}/invitees/${invitationId}`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const deleteUser = (accountId,userId,  params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'DELETE',
		url: `${getApiEndpoint()}/accounts/${accountId}/users/${userId}`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const reInviteUser = (accountId, invitationId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/accounts/${accountId}/invitees/${invitationId}/reInvite`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const getUserRolesByProject = (accountId, projectId,userId,  params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/members/${userId}/roles`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const getUserRolesByAccount = (accountId, userId,  params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/users/${userId}/roles`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const editUserDetails = (accountId, userId, key, value, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'PUT',
		url: `${getApiEndpoint()}/accounts/${accountId}/users/${userId}/${key}`,
		body: { [key]: value },
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const editProjectRoles = (accountId, projectId, userId, value, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'PUT',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/members/${userId}/roles`,
		body:{ projectRoles:value },
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

const editAccountRoles = (accountId, userId, value, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'PUT',
		url: `${getApiEndpoint()}/accounts/${accountId}/users/${userId}/roles`,
		body:{ accountRoles:value },
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

export {
	postUserInvitation,
	getInvitedUsers,
	getUsers,
	getUserRoles,
	getProjectUsers,
	deleteUser,
	reInviteUser,
	deleteUserInvitation,
	getUserRolesByAccount,
	getUserRolesByProject,
	editUserDetails,
	editProjectRoles,
	editAccountRoles

};
