import Selector from './Selector'
import Contexts from '../../Util/Contexts'
import Filter from '../Filter'
import React, { useContext, useEffect } from 'react'
import Menu from '../Menu'

export default function Item({
  id,
  value,
  width,
  label,
  placeholder,
  valuesCb,
  valueCb,
  onOptions,
  isAsyncAutocomplete,
  isAncestorsExist
}) {
  const [filterItems, setFilterItems] = useContext(Filter.Items) || []

  const removeFilter = () => {
    setFilterItems((items) => items.filter((item) => item.id !== id))
  }
  const setFilter = (value) => {
    setFilterItems((items) => {
      const item = items.find((item) => item.id === id)
      if (item) {
        item.value = value
      }
      return [...items]
    })
  }

  const components = [
    {
      Component: Menu,
      Items: [],
      Selected: value || [],
      Search: ''
    }
  ]

  useEffect(() => {
    isAncestorsExist === false && removeFilter()
  }, [isAncestorsExist])
  return (
    <Contexts components={components}>
      <Selector
        id={id}
        removeFilter={removeFilter}
        label={label + ':'}
        placeholder={placeholder}
        width={width}
        setFilter={setFilter}
        filterItems={filterItems}
        valuesCb={valuesCb}
        valueCb={valueCb}
        onOptions={onOptions}
        isAsyncAutocomplete={isAsyncAutocomplete}
      />
    </Contexts>
  )
}
