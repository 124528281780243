import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useRef } from "react";
import AppTooltip from "../AppTooltip";

const useStyles = makeStyles((theme) => {
	const inputPaddingX = 12;
	const inputBorderWidth = 1;

	return {
		rootContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'start',
		},
		labelContainer: {
			display:"flex",
			flexDirection:"row"
		},
		label: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			display: 'flex',
			justifyContent: 'start',
		},
		requireLabel: {
			color:'red',
			marginLeft: 3,
		},
		changeLabel: {
			color: "#483d8b",
			fontSize: 14,
			display: 'flex',
			marginLeft: 5,
			marginUp:12,
			paddingLeft: 8
		},
		input: {
			width: `calc(100% - ${2 * (inputPaddingX + inputBorderWidth)}px)`,
			height: 40,
			border: `${inputBorderWidth}px solid #C2C2C2`,
			boxSizing: 'borderBox',
			borderRadius: 4,
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			paddingLeft: inputPaddingX,
			paddingRight: inputPaddingX,
			'&:focus,focus-visible': {
				borderColor: 'hsla(217, 100%, 60%, 1)',
				outline: 'none',
			},
		},
		inputError: {
			borderColor: 'hsla(0, 100%, 66%, 1)',
			'&:focus,focus-visible': {
				borderColor: 'hsla(0, 100%, 66%, 1)',
			}
		},
		inputErrorMessage: {
			color: 'hsla(0, 100%, 66%, 1)',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 12,
			minHeight: 16
		},
	};
});

function AppInput({ name, label, onChange, placeholder, error, errorMessage, warn, enforceWarn, warnMessage, value, onEnterPress, autoFocus,overrideClasses={}, required= false }) {
	const styles = useStyles();
	const orgName = useRef(value);

	const handleChange = (value) => {
		onChange?.(value);
	};

	const handleKeyPress = (key) => {
		if (key === 'Enter') {
			onEnterPress?.();
		}
	};

	function IconAttention(prop) {
		return (
			<svg
				viewBox="0 0 961.539 1000"
				fill="currentColor"
				height="1em"
				width="1em"
				{...prop}
			>
				<path d="M956.54 874c6.666 10.667 6.666 22 0 34-6.668 10.667-16.668 16-30 16h-892c-12 0-21.334-5.333-28-16-8-12-8.668-23.333-2-34l446-782c5.332-12 15.332-18 30-18 14.666 0 24.666 6 30 18l446 782m-420-50V724h-110v100h110m0-174V350h-110v300h110" />
			</svg>
		);
	}

	return (
		<div className={styles.rootContainer}>
			<div className={styles.labelContainer}>
				<div className={clsx(styles.label, overrideClasses?.label)}>{ label }</div>
				{ required && <div className={styles.requireLabel}>*</div> }
				{ (enforceWarn || (warn && (value !== orgName.current))) && <div className={styles.changeLabel}>
					<AppTooltip title={warnMessage}>
						<div>{IconAttention ( { color:'grey' } )} </div>
					</AppTooltip>
				</div> }
			</div>
			<input
				className={ (clsx( styles.input, overrideClasses?.input, error &&  styles.inputError))}
				placeholder={placeholder ?? ''}
				autoFocus={autoFocus}
				onKeyPress={e => handleKeyPress(e.key)}
				value={value}
				name={name}
				label="Outlined"
				onChange={e => handleChange(e.target.value)}
			/>
			<div className={styles.inputErrorMessage}>{error && errorMessage}</div>
		</div>
	);
}

export default AppInput;
