import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import AppTooltip from "../../../AppTooltip";

const useStyles = makeStyles((theme) => {
	return {
		permissions: {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
		permissionContainer: {
			background: '#EFEFEF',
			borderRadius: 4,
			margin: '8px'
		},
		permission: {
			margin: '0 8px',
			color: 'black',
			fontFamily: "Source Sans Pro,sans-serif",
			fontSize: 12,
			fontWeight: 400,
			fontStretch: "normal",
			fontStyle: "normal",
		},
		extenderContainer: {
			width: 'fit-content',
			height: 'fit-content',
			border: '1px solid #8F8F8F',
			borderRadius: '4px',
			color: '#8F8F8F',
			margin: '2px',
		},
		extender: {
			margin: '0 4px',
			fontFamily: "Source Sans Pro,sans-serif",
			fontSize: 12,
			fontWeight: 400,
			fontStretch: "normal",
			fontStyle: "normal",
		},
	};
});

const permissionsMaxSize = 4;

export default function Permissions({ permissions = []}) {
	const styles = useStyles();

	return (
		<div className={styles.permissions}>
			{permissions.slice(0, permissionsMaxSize).map((permission, index) => {
				return (
					<div className={styles.permissionContainer} key={index}>
						<div className={styles.permission}>
							{permission}
						</div>
					</div>
				);
			})}
			{permissions.length > permissionsMaxSize &&
			<AppTooltip title={
				permissions.slice(permissionsMaxSize, permissions.length).map((permission, index) => {
					return (
						<div key={index} className={styles.permission}>
							{permission}
						</div>
					);
				})}
			>
				<div className={styles.extenderContainer}>
					<div className={styles.extender}>
						+{permissions.length - permissionsMaxSize}
					</div>
				</div>
			</AppTooltip>}
		</div>
	);
}
