import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import clsx from "clsx";
import { useCreateTenantStore } from "../../../../../../../state-management";
import AppTooltip from "../../../../../../AppTooltip";
import shallow from "zustand/shallow";

const useStyles = makeStyles((theme) => ({
	packageContainer: {
		position: 'relative',
		backgroundColor: 'white',
		margin: 6,
		width: 130,
		height: 60,
		border: "1px solid #C2C2C2",
		borderRadius: 4,
		'&:hover': {
			cursor: 'pointer',
			borderColor: '#3380FF',
			borderWidth: 1,
		},
	},
	packageContainerSelected: {
		borderColor: '#3380FF',
		borderWidth: 2,
		'&:hover': {
			cursor: 'default',
			borderWidth: 2,
		},
	},
	packageContainerDisabled: {
		background: '#EFEFEF',
		'&:hover': {
			cursor: 'default',
			background: '#EFEFEF',
			borderColor: '#C2C2C2',
		},
	},
	packageText: {
		position: 'absolute',
		left: 10,
		top: 10,
		width: 106,
		height: 36,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		color: '#8F8F8F',
	},
	packageTextSelected: {
		color: '#3380FF',
	},
	packageTextDisabled: {
		color: 'rgba(41, 41, 41, 0.2)',
	},
	selectedIcon: {
		position: 'absolute',
		transform: 'scale(.6)',
		color: '#3380FF',
		right: -3,
		top: -3,
	},
}));

export default function PackageElement( { packageId, item, selected, handleClick, edited = false } ) {
	const styles = useStyles();

	const packageIds = useCreateTenantStore(state => state.packageIds, shallow);
	const maxLength = 21;

	return (
		<div className={clsx(styles.packageContainer, (selected || edited) && styles.packageContainerSelected, !edited && packageIds?.includes(packageId) && styles.packageContainerDisabled)} onClick={handleClick}>
			{ (selected || edited) && <CheckCircleIcon className={styles.selectedIcon}/> }
			<AppTooltip title={item.label}>
				<div className={clsx(styles.packageText, (selected || edited) && styles.packageTextSelected, !edited && packageIds?.includes(packageId) && styles.packageTextDisabled)}>
					{ item?.label &&`${item.label?.substring(0, maxLength)}${item.label.length > maxLength + 1 ? ' ...' : ''}` }
				</div>
			</AppTooltip>
		</div>
	);
}
