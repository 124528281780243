import React, { useCallback, useEffect, useState } from "react";
import UsersTable from "../UsersDrawer/UsersTable";
import { makeStyles } from "@material-ui/core/styles";
import AppTooltip from "../../../../AppTooltip";
import { Drawer } from "@ip-synamedia/syna-ui-library";
import { getShortStr, getTextWidth } from "../TenantDrawer/utils";
import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import { getProjectUsers } from "../../../../../external-apis";
import Loader from "../../../../Loader";
import emptyUsersIcon from '../../../../../icons/emptyUsersIcon.png';
import emptyContentBackground from "../../../../../assets/icons/emptyContent/emptyContentBackground.png";
import AppButton from "../../../../AppButton";
import InviteUsersDialog from "../../../UsersPanel/InviteUserDialog";
import { useUsersStore } from "../../../../../state-management";
import shallow from "zustand/shallow";

const useStyles = makeStyles((theme) => {
	return {
		separator: {
			border: '1px solid #C2C2C2',
			margin: '0px 12px',
			height: 20,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		usersHeaderProjectDescription: {
			fontSize: 14,
			margin: '4px 0 4px 0px',
			maxHeight: 20,
			overflow: 'hidden',
		},
		usersHeaderProjectCreated: {
			display: 'flex',
			fontSize: 14,
			color: '#8F8F8F',
		},
		title:{
			color:"#292929",
			fontSize: 17,
			fontFamily: "Source Sans Pro",
			fontWeight: "bold"
		},
		tableContainer:{
			width: 542
		},
		emptyUsersComponentContainer:{
			// marginLeft: 247,
			marginTop: 160,
			display: "flex",
			flexDirection: "column",
			alignItems: "center"
		},
		emptyIconContainer:{
			marginTop: 0,
			display: "grid",
			gridTemplateColumns: '1fr',
			gridTemplateRows: '1fr',
			gridTemplateAreas: "overlap",

		},
		emptyContentBackgroundIcon:{
			gridArea: 'overlap',
			width:100,
			height: 100,
		},
		emptyContentIcon:{
			gridArea: 'overlap',
			borderRadius: 0,
			margin: '32px 25px 32px 25px'
		},
		emptyUsersText:{
			marginTop: 12,
			fontSize: 17,
			fontWeight: 400,
			color: '#8F8F8F'
		},
		inviteBtnContainer:{
			marginTop: 40
		},
		cancelTextTransform: {
			textTransform: "none"
		}
	};
});
const mapProjectUsers = (projectUsers) => {

	return projectUsers.map(userData => {
		const { userId, name, email, roles } = userData;

		return {
			id: userId,
			userName: name,
			email,
			roles
		};
	});
};

export default function UsersDrawer({ accountId, projectId, displayName, description, created, handleUsersClose, open }) {
	const styles = useStyles();
	const maxDescriptionLen = 504;
	const [inviteUserDialogVisible, setInviteUserDialogVisible] = useState(false);
	const setAccountId = useUsersStore(state => state.setAccountId, shallow);

	const handleInviteUserClick = () => {
		setInviteUserDialogVisible(true);
		setAccountId(accountId);
	};
	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			handleUsersClose();
		}
	}, [handleUsersClose]);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);

		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);
	const isLongDescription = getTextWidth(description) > maxDescriptionLen;

	const staleTime = 60000;
	const {
		data: usersData = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	} = useQueryWithAuthorization(['project-users', accountId, projectId], getProjectUsers(accountId, projectId), {
		select: mapProjectUsers,
		enabled: accountId != null && projectId != null,
		staleTime: staleTime

	});

	const descriptionComponent =  <div>
		<AppTooltip disabled={!isLongDescription} title={description}>
			<div className={styles.usersHeaderProjectDescription}>{isLongDescription? getShortStr(description, maxDescriptionLen) + '...': description}</div>
		</AppTooltip>
		<div className={styles.usersHeaderProjectCreated}>
			{`Created: ${created}`}
			<div className={styles.separator}/>
			{`Project ID: ${projectId}`}
		</div>
	</div>;
	const emptyUsersComponent = <div className={styles.emptyUsersComponentContainer}>
		<div className={styles.emptyIconContainer}>
			<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
			<img alt={''} src={emptyUsersIcon} className={styles.emptyContentIcon}/>
		</div>
		<div className={styles.emptyUsersText}>{'There are no users with a role associated to this project'}</div>
		<div className={styles.inviteBtnContainer}>
			<AppButton onClick={handleInviteUserClick} overrideClasses={{ button: styles.cancelTextTransform }}>Invite User</AppButton>
		</div>
		 </div>;

	return (
		<div>
			<Drawer
				isOpen={open}
				onClose={handleUsersClose}
				title={displayName}
				anchor={'right'}
				description={descriptionComponent}
				customStyle={{
					width: 590,
					top: 0,
					right: 234,
					height: '100%',
				}}
				headerCustomStyle={{
					height: 105,
					width: '100%',
					boxShadow: '1px 0px 7px grey',
					fontFamily: 'Source Sans Pro',
					marginBottom: 44,
				}}
			>
				{usersIsLoading? <div><Loader/></div>:
					usersIsError? <div>Error fetching data: {usersError.message}</div>:
						<div>
							{usersData.length > 0 && <div className={styles.title}>
								{`${usersData.length} users`}
							</div>}
							{usersData.length > 0 ?<div className={styles.tableContainer}>
								<UsersTable users={usersData}/>
							</div>:emptyUsersComponent}
						</div>}
			</Drawer>
			<InviteUsersDialog visible={inviteUserDialogVisible} setVisible={setInviteUserDialogVisible}/>

		</div>

	);
}
