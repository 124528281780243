import styles from "./Checkbox.module.scss"
import clsx from "clsx"
import CheckBoxIcon from "../../iconComponents/Checkbox"
import CheckBoxSelectedIcon from "../../iconComponents/CheckboxSelected"
import React from "react"

export default function Checkbox({ className, children, disabled, checked, setChecked, label, visible=true }) {
    const onClick = () => {
        !disabled && setChecked && setChecked(!checked);
    };
    return <button onClick={onClick} className={clsx(styles.root, className)}>
        <div className={clsx(styles.checkbox, disabled && styles.disabled)}>
            {!checked && visible && <CheckBoxIcon  width="13" height="18" />}
            {checked &&  visible && <CheckBoxSelectedIcon  width="13" height="18"/>}
        </div>
        {children}
        {!!label && <div className={styles.label}>
            {label}    
        </div>}
    </button>
}
