import AppButton from "../AppButton";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppDialog from "../AppDialog";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles(() => ({
	content: {
		width: 500,
		height: 151
	},
	textContainer: {
		height: 20,
		marginLeft: 10,
		paddingBottom: 40
	},
	reportIcon: {
		fontSize: "small",
		color: '#FF3333'
	},
	warningContainer: {
		display: 'flex'
	},
	buttonsContainer: {
		display: 'flex',
		marginLeft: 'auto',
		height: 30
	},
	deleteButton: {
		display: 'flex',
		alignItems: 'center',
		width: 96,
		marginLeft: 10
	},
	deleteButtonIcon: {
		padding: '3px 0',
	},
}));

export default function AppErrorNotification({ errorMessage, setErrorMessage }) {
	const styles = useStyles();
	const handleClose = () => {
		setErrorMessage(null);
	};

	return <AppDialog
		variant={'error'}
		open={errorMessage != null}
		onClose={handleClose}
		title={'Unexpected Error'}
		overrideClasses={{ content: styles.content }}
	>
		<div className={styles.warningContainer}>
			<ErrorOutlineIcon className={styles.reportIcon}/>
			<div className={styles.textContainer}>
				{errorMessage}
			</div>
		</div>
		<div className={styles.buttonsContainer}>
			<AppButton onClick={handleClose}>CLOSE</AppButton>
		</div>
	</AppDialog>;
}