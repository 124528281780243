import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LineDetails, isValidHttpUrl, PackageFeaturesLineDetails, getTextWidth } from '../utils';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {

	return {
		packagesContainer: {
			background: 'hsla(0, 0%, 100%, 1)',
			padding:24,
		},
		title:{
			fontFamily: "Source Sans Pro",
			color:"#292929",
			fontSize: 17,
			fontWeight: 'bold',
			marginBottom:27,

		},
		pkgContainer:{
			width: '100%',
			paddingTop: 12,
			borderRadius: 8,
			boxShadow: '0px 0px 10px hsla(0, 0%, 16%, 0.15)',
			marginBottom: 12

		},
		packageTitle:{
			marginLeft: 12,
			fontSize: 14,
			fontWeight:"bold",
			color: "#292929",
			fontFamily: "Source Sans Pro",

		},
		lineContainer:{
			marginTop: 12,
			marginLeft: 12
		},
		separator: {
			border: '0.5px solid #EFEFEF',
			flex: 'none',
		},
		separatorContainer:{
			width: "100%",
			padding: '0 12px 0 12px'
		},
		featuresContent:{
			marginLeft: 18,
		},
		featuresContentContainer:{
			background: "#FAFAFA",
			padding: 12
		},
		icon: {
			transform: 'rotate(90deg)',
			color: "#8F8F8F"
		},
		iconUp: {
			transform: 'rotate(180deg)',
		},
		iconContainer: {
			margin: '0 7px 0 12px',
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			'&:hover': {
				cursor: 'pointer',
			},

		},
		featuresTitleContainer:{
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginTop: 12
		},
		featuresTitle:{
			color: "#292929",
			fontSize: 14,
			fontFamily: 'Source Sans Pro',

		},
		closePaddingFeatures:{
			paddingBottom: 12
		},
		openMarginFeatures:{
			marginBottom: 12
		},
		featureLine:{
			marginTop: 12
		},
		cancelMarginButton:{
			marginBottom: 0
		},
		cancelMarginTop:{
			marginTop: 0
		}

	};
});

export default function Packages({ tenant, productConfiguration = {} }) {
	const styles = useStyles();

	// **** For Implement  *****//
	// if(!tenant.packageIds.includes('consumption')) {
	// 	tenant.packageIds.push('consumption');
	//
	// }
	// if(!tenant.packageIds.includes('enhanced-viewing')) {
	// 	tenant.packageIds.push('enhanced-viewing');
	//
	// }
	// tenant.tenantConfig.packages = { ...tenant.tenantConfig.packages,
	// 	"enhanced-viewing": {
	// 		config: {
	// 			"some package config1": [true, false],
	// 			"some package config2": 'some value',
	// 		},
	// 		features: {
	// 			someFeature1: {
	// 				enabled : false,
	// 				config: {
	// 					"profiles" : 'Enabled',
	// 					"catchup" : 'Enabled',
	// 					"session ssssssstimeout": '30 min',
	// 				}
	// 			},
	// 			someFeature2: {
	// 				enabled : false,
	// 				"config" : {
	// 					"number" : 555,
	// 					"json" : { a: false, b: 2 },
	// 					"boolean": false,
	// 					"array": ["a", "b", "c"],
	// 					"str": "ELYASHIV MORGENSTERN"
	// 				}
	// 			},
	// 			someFeature3: {
	// 				enabled : true,
	// 				"config" : {
	// 					"number" : 555,
	// 					"json" : { a: false,b: 2,c: false,d: 2 ,e: false,f: 2 },
	// 					"boolean": false,
	// 					"array": ["a", "b", "c"],
	// 					"str": " some string",
	// 				}
	// 			}
	// 		}
	// 	},
	// 	"consumption":{
	// 		config: {
	// 			"Hold data for": "30 Days",
	// 			"Anonymize data": 'SHA-1 | Pre-fix: Secret',
	// 			"Sent report": "10 sec"
	// 		},
	// 		features: {}
	// 	}
	// };
	// productConfiguration.packages ={
	// 	"consumption":{
	// 		label: "Consumption",
	// 		config:{
	// 			someConfig1: {
	// 				label: "Some Config 1"
	// 			},
	// 			someConfig2: {
	// 				label: "Some Config 2"
	// 			},
	// 			someConfig3: {
	// 				label: "Some Config 3"
	// 			},
	// 		},
	// 		features: {}
	// 	},
	// 	"enhanced-viewing":{
	// 		label: "Enhanced Viewing",
	// 		config:{
	// 			'some package config1': {
	// 				label: "Some Package Config 1 LONG LONGLONGLONGLONG"
	// 			},
	// 			someConfig2: {
	// 				label: "Some Config 2"
	// 			},
	// 			someConfig3: {
	// 				label: "Some Config 3"
	// 			},
	// 		},
	// 		features: {
	// 			"someFeature1": {
	// 				"description": "some description",
	// 				label: "Some Feature 1 Long Long Long Long Long",
	// 				"availability" : "all",
	// 				config:{
	// 					"profiles" : {
	// 						label: "Profiles"
	// 					},
	// 					"catchup" :{
	// 						label: "Cat Chup"
	// 					},
	// 					"session ssssssstimeout": {
	// 						label: "Session Timeout"
	// 					},
	// 				}
	// 			},
	// 			"someFeature2": {
	// 				"description": "some description",
	// 				label: "Some Feature 2",
	// 				"availability" : "synamedia",
	// 				config:{
	// 					"number" : { label: 'Number ' },
	// 					"json" : { label: 'Json' },
	// 					"boolean": { label: 'Boolean' },
	// 					"array": { label: 'Array' },
	// 					"str": { label: "String" }
	// 				}
	// 			},
	// 			"someFeature3": {
	// 				"description": "some description",
	// 				label: "Some Feature 3",
	// 				"availability" : "synamedia",
	// 				config:{
	// 					"number" : { label: 'Number' },
	// 					"json" : { label: 'Json' },
	// 					"boolean": { label: 'Boolean' },
	// 					"array": { label: 'Array' },
	// 					"str": { label: "String" }
	// 				}
	//
	// 			},
	// 		},
	//
	// 	},
	//
	// };
	// **** For Implement  *****//

	function HandlePackage({ packageId }) {
		const dataToDisplayPkg = tenant?.tenantConfig?.packages?.[packageId]?.config ?? {};
		const [collapsed, setCollapsed] = useState(false);
		const isFeaturesExists = tenant?.tenantConfig.packages?.[packageId]?.features
			&& Object.keys(tenant.tenantConfig.packages[packageId]?.features).length >0
			&& productConfiguration.packages?.[packageId]?.features &&
			Object.keys(productConfiguration.packages[packageId].features).length >0;

		if(!productConfiguration.packages?.[packageId]) {
			return <div/>;
		}
		const handleCollapse = () => {
			setCollapsed(prevState => !prevState);
		};
		let longestKeyLength = 0;

		return (
			<div className={clsx(styles.pkgContainer, !collapsed && styles.closePaddingFeatures)}>
				<div className={styles.packageTitle}>
					{productConfiguration?.packages?.[packageId]?.label ?? packageId}
				</div>
				<div>
					{dataToDisplayPkg && Object.entries(dataToDisplayPkg).map((line, index) => {
						const key = line[0];
						let value = line[1];
						const label =  productConfiguration?.packages?.[packageId]?.config?.[key]?.label ?? key;

						if(Array.isArray(value)) {
							value = value.join(', ');
						}

						return <div key={key}>{ dataToDisplayPkg &&
							<div className={styles.lineContainer}><LineDetails label={label}
								value={value.toString()}
								overrideClasses={{ container: clsx(index + 1 === Object.entries(dataToDisplayPkg).length && !isFeaturesExists && styles.cancelMarginButton) }}
								isLinkify={isValidHttpUrl(value)}
							/>
							</div>}
						</div>;
					})}
				</div>
				{isFeaturesExists && <div className={styles.separatorContainer}> <div className={styles.separator}/></div>}
				{isFeaturesExists && <div className={clsx(styles.featuresTitleContainer, collapsed && styles.openMarginFeatures)}>
					<div className={styles.iconContainer}>
						<ExpandLessIcon sx={{ fontSize: "small" }}
							className={clsx(styles.icon, collapsed && styles.iconUp)}
							onClick={handleCollapse}/>
					</div>
					<div className={styles.featuresTitle}>
						Features
					</div>
				</div>}
				{collapsed && isFeaturesExists && <div className={styles.featuresContentContainer}>
					{Object.entries(productConfiguration.packages[packageId].features).map((obj, index) => {
						const featuresKey = obj[0];
						const label = obj[1]?.label ?? featuresKey;

						const enabled = tenant.tenantConfig.packages?.[packageId]?.features?.[featuresKey]?.enabled ?? false;
						const configToDisplay = tenant.tenantConfig.packages?.[packageId]?.features?.[featuresKey]?.config ?? {};

						const dataToDisplay = Object.entries(configToDisplay).map((config, index) => {
							const key = config[0];
							const value = config[1];
							const label = productConfiguration.packages?.[packageId]?.features?.[featuresKey]?.config?.[key]?.label ?? key;

							if(enabled) {
								longestKeyLength = Math.max(longestKeyLength, getTextWidth(label));
							}

							return  { label, value };
						});

						return <div key={"package features" + index} className={clsx(styles.featureLine, index === 0 && styles.cancelMarginTop)}>
							<div className={styles.featuresContent}>
								<PackageFeaturesLineDetails dataToDisplay={dataToDisplay} enabled={enabled} label={label ?? featuresKey} longestKeyLength={longestKeyLength}/>
							</div>
							{index !== (Object.entries(productConfiguration.packages[packageId].features).length - 1) && <div className={styles.separatorContainer}>
								<div className={styles.separator}/>
							</div>}
						</div>;
					})}
				</div>
				}
			</div>);
	}

	return (
		<div className={styles.packagesContainer}>
			<div className={styles.title}>
				{`Packages (${tenant?.packageIds ? tenant.packageIds.length : 0})`}
			</div>
			{tenant?.packageIds && tenant.packageIds.length > 0 && tenant.packageIds.map((packageId, index) => <HandlePackage key={index} packageId={packageId}/>)}
		</div>
	);
}
