import React, { useEffect, useState } from "react";
import { Tab, Tabs } from '@mui/material';
import { defaultTabId, tabToComponentData } from "./tabsData";
import { useDecodedJwtStore, useUserManageStore, useUsersStore } from "../../../../state-management";
import shallow from "zustand/shallow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getAccountById, getInvitedUsers, getProjectsByAccountId, getUsers } from "../../../../external-apis";
import moment from "moment";
import Loader from "../../../Loader";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
	({ theme }) => ({
		textTransform: 'capitalize',
		fontWeight: 'normal',
		fontSize: theme.typography.pxToRem(14),
		margin: 0,
		display: 'flex',
		flexDirection: "row",

		'&.MuiTab-root': {
			paddingBottom: 7,
		},
	}),
);

const useStyles = makeStyles(() => ({
	labelWrapper: {
		// position: 'relative',
		height: '100%',
		display: 'flex',
		flexDirection: "row",
		justifyContent: 'center',
	},
	label: {
		position: 'absolute',
		bottom: 0,
		marginBottom: 7

	}
}));

function Label({ value }) {
	const styles = useStyles();

	return (
		<span className={styles.labelWrapper}>
			<span className={styles.label}>{value}</span>
		</span>
	);
}
const mapInvitedUsers = (apiResponse) => {
	return apiResponse.map(invitedUserData => {
		const { email, invitedAt, projects: assignedProjects = [], invitationUrl, invitationId } = invitedUserData;

		const { projects, products } = assignedProjects.reduce((mapped, project) => {
			const { id, displayName, products: projectProducts = []} = project;

			mapped.projects.push({
				id,
				displayName
			});

			mapped.products.push({
				projectName: displayName,
				productList: [...projectProducts]
			});

			return mapped;

		}, { projects: [], products: []});

		return {
			email,
			invitedAt: invitedAt && moment(new Date(invitedAt)).format("DD MMM YYYY, h:mm A"),
			invitationUrl,
			projects,
			invitationId,
			products
		};
	});
};

const mapActiveUsers = (apiResponse) => {
	return apiResponse.map(userData => {
		const { userId, name, email, lastLogin, joined, role } = userData;

		return {
			id: userId,
			userName: name,
			userRole: role,
			email,
			lastLogin: lastLogin && moment(new Date(lastLogin)).format("DD MMM YYYY, h:mm A"),
			joined: joined && moment(new Date(joined)).format("DD MMM YYYY, h:mm A")
		};
	});
};

const mapAccount = (apiResponse) => {
	const { owner } = apiResponse;

	return {
		owner
	};
};

const mapToProjects = (projects) => {
	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			displayName
		};
	});
};

export default function UsersTabs({ accountId }) {
	const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
	const setActiveTabComponent = useUsersStore(state => state.setActiveTabComponent, shallow);
	const staleTime = 12000;
	const setDecodedJwt = useDecodedJwtStore(state => state.setDecodedJwt, shallow);
	const setAccountOwnerEmail = useUserManageStore(state => state.setAccountOwnerEmail, shallow);
	const setUsers = useUserManageStore(state => state.setUsers, shallow);
	const setProjects = useUserManageStore(state => state.setProjects, shallow);

	const setUsersError = useUserManageStore(state => state.setUsersError, shallow);
	const setUsersIsError = useUserManageStore(state => state.setUsersIsError, shallow);
	const setUsersIsLoading = useUserManageStore(state => state.setUsersIsLoading, shallow);

	const setInvitees = useUserManageStore(state => state.setInvitees, shallow);
	const setInviteesError = useUserManageStore(state => state.setInviteesError, shallow);
	const setInviteesIsError = useUserManageStore(state => state.setInviteesIsError, shallow);
	const setInviteesIsLoading = useUserManageStore(state => state.setInviteesIsLoading, shallow);

	const handleTabChange = (tabId) => {
		setSelectedTabId(tabId);
	};

	useEffect(() => {
		setActiveTabComponent(tabToComponentData[selectedTabId]?.component);
	}, [selectedTabId, setActiveTabComponent]);
	const {
		data: inviteesRes = [],
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
		error: inviteesError
	} = useQueryWithAuthorization(['users-invited', accountId], getInvitedUsers(accountId), {
		select: mapInvitedUsers,
		enabled: accountId != null,
		staleTime: staleTime
	}, setDecodedJwt);

	const {
		data: usersRes = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	} = useQueryWithAuthorization(['users-active', accountId], getUsers(accountId), {
		select: mapActiveUsers,
		enabled: accountId != null,
		staleTime: staleTime
	});
	const {
		data: account = [],
		isError: accountIsError,
		isLoading:accountIsLoading,
		error: accountError
	} = useQueryWithAuthorization(['get-account-by-id', accountId], getAccountById(accountId), {
		select : mapAccount,
		enabled: accountId != null,
		staleTime: staleTime
	});
	const {
		data: projects = [],
		isLoading: isProjectsLoading,
		isError: isProjectsError,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapToProjects,
		enabled: accountId != null,
		staleTime: staleTime,
	});

	useEffect(() => {
		setInviteesIsLoading(inviteesIsLoading);
		setUsersIsLoading(usersIsLoading);
		!(usersIsError || usersIsLoading) && setUsers(usersRes);
	}, [setUsers, usersRes, inviteesIsLoading, usersIsLoading, setUsersIsLoading, setInviteesIsLoading, usersIsError]);

	if (usersIsLoading|| inviteesIsLoading || accountIsLoading) return <Loader/>;
	if (inviteesIsError) {
		setInviteesIsError(true);
		setInviteesError(inviteesError.message);
	}else {
		setInviteesIsError(false);
	}
	if (usersIsError) {
		setUsersIsError(true);
		setUsersError(usersError.message);
	}else{
		setUsersIsError(false);
	}

	if (usersIsLoading|| inviteesIsLoading || accountIsLoading || isProjectsLoading) return <Loader/>;
	if(accountIsError) return <div>Error fetching data: {accountError.message}</div>;
	if(isProjectsError) return <div>Error fetching data: {projectsError.message}</div>;

	setAccountOwnerEmail(account?.owner?.email);
	setInvitees(inviteesIsError? []:inviteesRes);
	setProjects(isProjectsError? []:projects);

	// TODO: Add num of users per tab dynamically (Tab's label) - count API
	return (
		<Tabs
			TabIndicatorProps={{ sx: { borderRadius: 3 }, }}
			value={selectedTabId}
			onChange={(_, value) => handleTabChange(value)}>
			{Object.keys(tabToComponentData).map(tabId => {
				const label = `${tabToComponentData[tabId].label} `;
				const labelAdding = tabId === "activeUsers" ?(usersIsError || !accountId? '':`(${usersRes.length})`):(inviteesIsError || !accountId? '':`(${inviteesRes.length})`);

				return <StyledTab key = {tabId} value = {tabId} label = {<Label value={label + labelAdding}/>}
				/>;
			})}
		</Tabs>
	);
}
