import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const getById = (id, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${id}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const postAccount = (account, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/accounts`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: account
	};
};

const putAccount = (accountId, account, params = {} ) => {
	const { headers = {} } = params;

	return {
		method: 'PUT',
		url: `${getApiEndpoint()}/accounts/${accountId}`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: account
	};
};

export {
	getAll,
	getById,
	postAccount,
	putAccount
};
