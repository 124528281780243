import React, { useEffect, useState } from "react";
import AddEditPackage from "./AddEditPackage";
import { useCreateTenantStore } from "../../../../../../../state-management";
import AddedPackages from "./AddedPackages";
import NoPackages from "./NoPackages";
import shallow from "zustand/shallow";

export default function PackageConfiguration({ packagesOptions, tenant }) {
	const [addPackageVisible, setAddPackageVisible] = useState(false);
	const [editedPackage, setEditedPackage] = useState();

	const packageIds = useCreateTenantStore(state => state.packageIds, shallow);
	const updatePackagesFeatureIds = useCreateTenantStore(state => state.updatePackagesFeatureIds, shallow);
	const [packagesConfigParams, updatePackageConfigParams] = useCreateTenantStore(state => [state.packagesConfigParams, state.updatePackageConfigParams], shallow);
	const [packagesFeaturesConfigParams, updatePackagesFeaturesConfigParams] = useCreateTenantStore(state => [state.packagesFeaturesConfigParams, state.updatePackagesFeaturesConfigParams], shallow);
	const setFullListOfPackagesFeatureIds = useCreateTenantStore(state => state.setFullListOfPackagesFeatureIds, shallow);

	useEffect(() => {
		function updateParameter(config, parameterConfig, name, value) {
			config[name] = {
				value: parameterConfig.type === 'array' ? value : String(value),
				type: parameterConfig.type
			};
		}

		//FOR DEBUGGING
		//TODO: delete it once we have feature definition on some tenant
		// const packagesOptions = {
		// 	"package_1": {
		// 		"label": "CCL Reporting Long Long...",
		// 		"description": "Enable Clarissa Client Library",
		// 		"availability": "synamedia",
		// 		"config": {
		// 			"configNumber": {
		// 				"label": "Configuration Number",
		// 				"type": "number",
		// 				"required": "true",
		// 				"defaultValue": 300,
		// 				"description": "Interval in seconds for reporting"
		// 			},
		// 			"configBoolean": {
		// 				"label": "Configuration Boolean",
		// 				"type": "boolean",
		// 				"defaultValue": "true",
		// 				"description": "Test boolean parameter"
		// 			},
		// 			"configString": {
		// 				"label": "Configuration String Long Long Long Long",
		// 				"type": "string",
		// 				"defaultValue": "true",
		// 				"description": "Test string parameter"
		// 			},
		// 			"configEnum": {
		// 				"label": "Configuration Enum",
		// 				"required": true,
		// 				"type": "enum",
		// 				"enum": ["one", "two", "three"],
		// 				"defaultValue": "three",
		// 				"description": "Test string parameter"
		// 			},
		// 			"configArray": {
		// 				"label": "Configuration Array",
		// 				"required": true,
		// 				"type": "array",
		// 				"enum": ["Yaniv", "Guy", "Avital", "Racheli", "Moti", "Chaim"],
		// 				"defaultValue": ["Yaniv", "Guy", "Avital"],
		// 				"description": "Test string parameter"
		// 			}
		// 		},
		// 		"features": {
		// 			"Package_cclReporting_synamedia": {
		// 				"label": "Package CCL Reporting Long Long...",
		// 				"description": "Enable Clarissa Client Library",
		// 				"availability": "synamedia",
		// 				"config": {
		// 					"configNumber": {
		// 						"label": "Configuration Number",
		// 						"type": "number",
		// 						"required": "true",
		// 						"defaultValue": 300,
		// 						"description": "Interval in seconds for reporting"
		// 					},
		// 					"configBoolean": {
		// 						"label": "Configuration Boolean",
		// 						"type": "boolean",
		// 						"defaultValue": "true",
		// 						"description": "Test boolean parameter"
		// 					},
		// 					"configString": {
		// 						"label": "Configuration String Long Long Long Long",
		// 						"type": "string",
		// 						"defaultValue": "true",
		// 						"description": "Test string parameter"
		// 					},
		// 					"configEnum": {
		// 						"label": "Configuration Enum",
		// 						"required": true,
		// 						"type": "enum",
		// 						"enum": ["one", "two", "three"],
		// 						"defaultValue": "three",
		// 						"description": "Test string parameter"
		// 					},
		// 					"configArray": {
		// 						"label": "Configuration Array",
		// 						"required": true,
		// 						"type": "array",
		// 						"enum": ["Yaniv", "Guy", "Avital", "Racheli", "Moti", "Chaim"],
		// 						"defaultValue": ["Yaniv", "Guy", "Avital"],
		// 						"description": "Test string parameter"
		// 					}
		// 				},
		// 			},
		// 			"Package_synamedia_feature_2": {
		// 				"label": "Synamedia Feature 2",
		// 				"description": "Synamedia Feature 2 Description",
		// 				"availability": "synamedia",
		// 			},
		// 			"Package_synamedia_feature_3": {
		// 				"label": "Synamedia Feature 3",
		// 				"description": "Synamedia Feature 3 Description",
		// 				"availability": "synamedia",
		// 				"config": {
		// 					"configParam1": {
		// 						"label": "Configuration Parameter 1",
		// 						"type": "number",
		// 						"defaultValue": 300,
		// 						"description": "Interval in seconds for reporting"
		// 					},
		// 					"configParam2": {
		// 						"label": "Configuration Parameter 2",
		// 						"type": "boolean",
		// 						"required": "true",
		// 						"defaultValue": "true",
		// 						"description": "Test boolean parameter"
		// 					}
		// 				}
		// 			}
		// 		}
		// 	},
		// 	"package_2": {
		// 		"label": "Synamedia Feature 2",
		// 		"description": "Synamedia Feature 2 Description",
		// 		"availability": "synamedia",
		// 	},
		// 	"package_3": {
		// 		"label": "Synamedia Package 3",
		// 		"description": "Synamedia Package 3 Description",
		// 		"availability": "synamedia",
		// 		"config": {
		// 			"configParam1": {
		// 				"label": "Configuration Parameter 1",
		// 				"type": "number",
		// 				"defaultValue": 300,
		// 				"description": "Interval in seconds for reporting"
		// 			},
		// 			"configParam2": {
		// 				"label": "Configuration Parameter 2",
		// 				"type": "boolean",
		// 				"required": "true",
		// 				"defaultValue": "true",
		// 				"description": "Test boolean parameter"
		// 			}
		// 		}
		// 	},
		// 	"enhanced-viewing": {
		// 		label: "Enhanced Viewing",
		// 		description: "Providing an OTT grade customer experience and options for the SP to monetize on the service and manage it more efficiently",
		// 		config: {}
		// 	}
		// };
		//
		// if (tenant != null) {
		// 	tenant.tenantConfig.packages =
		// 		{
		// 			"enhanced-viewing": {},
		// 			"package_1": {
		// 				"config": {
		// 					"configNumber": 300,
		// 					"configBoolean": true,
		// 					"configString": "true",
		// 					"configEnum": "three",
		// 					"configArray": [
		// 						"Yaniv",
		// 						"Guy",
		// 						"Avital"
		// 					]
		// 				},
		// 				"features": {
		// 					"Package_cclReporting_synamedia": {
		// 						"enabled": true,
		// 						"config": {

		// 							"configNumber": 343,
		// 							"configBoolean": false,
		// 							"configString": "string example",
		// 							"configEnum": "three",
		// 							"configArray": [
		// 								"Yaniv",
		// 								"Guy",
		// 								"Avital"
		// 							]
		// 						}
		// 					},
		// 					"Package_synamedia_feature_2": {
		// 						"enabled": true
		// 					},
		// 					"Package_synamedia_feature_3": {
		// 						"enabled": false,
		// 						"config": {
		// 							"configParam1": 306,
		// 							"configParam2": true
		// 						}
		// 					}
		// 				}
		// 			},
		// 		};
		// }
		//END OF FOR DEBUGGING

		if (Object.keys(packagesConfigParams).length === 0) { //Initiate packagesConfigParams only when it's empty (upon the first time)
			packagesOptions && Object.entries(packagesOptions).forEach(([packageId, packageOptions], index) => {
				const packageConfig = {};

				packageOptions.config && Object.entries(packageOptions.config).forEach(([parameter, parameterConfig]) => {
					const tenantPackageParamValue = tenant?.tenantConfig?.packages?.[packageId]?.config?.[parameter];

					if (tenantPackageParamValue != null) {
						updateParameter(packageConfig, parameterConfig, parameter, tenantPackageParamValue);
					} else if (!packageIds?.includes(packageId) && parameterConfig?.defaultValue != null) {
						updateParameter(packageConfig, parameterConfig, parameter, parameterConfig.defaultValue);
					}
				});
				updatePackageConfigParams(packageId, packageConfig);
			});
		}
		if (Object.keys(packagesFeaturesConfigParams).length === 0) { //Initiate packagesFeatureParams only when it's empty (upon the first time)
			packagesOptions && Object.entries(packagesOptions).forEach(([packageId, packageOptions]) => {
				const featureConfigParamPackage = {};
				const featureIds = [];

				setFullListOfPackagesFeatureIds(packageId, Object.keys(packageOptions.features ?? {}));
				packageOptions.features && Object.entries(packageOptions.features).forEach(([featureId, featureOptions]) => {
					const featureConfig = {};
					const isPackageFeatureEnabled = tenant?.tenantConfig?.packages?.[packageId]?.features?.[featureId]?.enabled === true;

					if ( isPackageFeatureEnabled ) {
						featureIds.push(featureId);
					}

					featureOptions.config && Object.entries(featureOptions.config).forEach(([parameter, parameterConfig]) => {
						const tenantPackageFeatureParamValue = tenant?.tenantConfig?.packages?.[packageId]?.features?.[featureId]?.config?.[parameter];

						if (tenantPackageFeatureParamValue != null) {
							updateParameter(featureConfig, parameterConfig, parameter, tenantPackageFeatureParamValue);
						} else if (!packageIds?.includes(packageId) && !isPackageFeatureEnabled && parameterConfig?.defaultValue != null) {
							updateParameter(featureConfig, parameterConfig, parameter, parameterConfig.defaultValue);
						}
					});
					featureConfigParamPackage[featureId] = featureConfig;
				});
				updatePackagesFeaturesConfigParams(packageId, featureConfigParamPackage);
				updatePackagesFeatureIds(packageId, featureIds);
			});
		}
	}, [packagesOptions, packagesConfigParams, packagesFeaturesConfigParams, packageIds, updatePackageConfigParams, updatePackagesFeaturesConfigParams, updatePackagesFeatureIds, setFullListOfPackagesFeatureIds, tenant]);

	const handleAddPackage = () => {
		setEditedPackage(null);
		setAddPackageVisible(true);
	};

	return (<div>
		{!addPackageVisible && (packageIds?.length == null || packageIds?.length === 0) && <NoPackages handleAddPackage={handleAddPackage} packagesOptions={packagesOptions}/>}
		{!addPackageVisible && packageIds?.length > 0 && <AddedPackages packagesOptions={packagesOptions} setAddPackageVisible={setAddPackageVisible} setEditedPackage={setEditedPackage}/>}
		{addPackageVisible && <AddEditPackage packagesOptions={packagesOptions} setAddPackageVisible={setAddPackageVisible} editTenant={tenant != null} editedPackage={editedPackage}/>}
	</div>);
}
