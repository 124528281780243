import * as React from 'react'

function SvgSortAscHover(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 8 5'
      {...props}
    >
      <path
        d='M7.166 4.568a.656.656 0 00.462-1.121L4.374.193a.654.654 0 00-.927 0L.193 3.447a.656.656 0 00.465 1.12h6.508z'
        fill='#004ecc'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgSortAscHover
