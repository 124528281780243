import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import useStateWithValidation from "../../../../../custom-hooks/form-hooks/useStateWithValidation";
import SelectProductsPermissions from "../SelectProductsPermissions";
import SelectProject from "../SelectProject";
import { createApplication as apiCreateApp } from '../../../../../external-apis';
import SecretDialog from "../SecretDialog";
import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import AppDialog from "../../../../AppDialog";
import AppInput from "../../../../AppInput";
import AppButton from "../../../../AppButton";
import AppTooltip from "../../../../AppTooltip";

const useStyles = makeStyles(() => ({
	appDialog: {
		width: 850,
		maxHeight: 567
	},
	inputs: {
		display: 'flex',
		justifyContent: 'space-evenly',
		boxShadow: '2px 2px 5px grey',
		borderRadius: 8,
		height: 74
	},
	input: {
		padding: 12,
		paddingBottom: 0,
		width: 479,
	},
	appInput: {
		height: 30
	},
	products: {
		margin: '11px 0 23px',
		boxShadow: '2px 2px 5px grey',
		borderRadius: 8,
	},
	productsLabel: {
		display: "inline-block",
		margin: 20
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 'auto',
	},
	button: {
		marginLeft: 10
	},
}));

export default function CreateApplicationDialog({ visible, setVisible, accountId }) {
	const styles = useStyles();

	const { mutateAsync } = useMutationWithAuthorization();

	const [appName, setAppName, isValidAppName] = useStateWithValidation(null, appName => appName != null && appName.trim() !== '');
	const [projectId, setProjectId, isValidProjectId] = useStateWithValidation(null, projectId => projectId != null);
	const [selectedPermissions, setSelectedPermissions] = useState(new Set());
	const [isRequiredFieldsSelected, setIsRequiredFieldsSelected] = useState(false);

	const [secretDialogVisible, setSecretDialogVisible] = useState(false);
	const [secretData, setSecretData] = useState(null);

	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		setIsRequiredFieldsSelected(appName && projectId);
	}, [projectId, appName]);

	const queryClient = useQueryClient();

	const handleClose = () => {
		setVisible(false);
		setProjectId(null);
		setAppName('');
		setSecretDialogVisible(false);
		setSecretData(null);
		setSelectedPermissions(new Set());
		setFirstRender(true);
	};

	const createApplication = async (app) => {
		handleClose();

		const createAppPromise = mutateAsync(apiCreateApp(accountId, projectId, app));

		toast.promise(createAppPromise,
			{
				pending: "Creating Application...",
				success: {
					render({ data: { clientSecret, application: { name: appName, clientId } } } = {}) {
						setSecretData({ secret: clientSecret, appName, clientId });
						setSecretDialogVisible(true);
						queryClient.invalidateQueries(['get-application-by-account-id-and-project-id', accountId, projectId]);

						return "Created";
					}
				},
				error: {
					render({ data: error }) {
						console.error('Error creating Application', error);
						setVisible(true);

						return `Failed to create application: ${error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	const handleCreate = async () => {
		setFirstRender(false);

		const hasError = !accountId || !isValidAppName || !isValidProjectId;

		if (hasError) {
			return;
		}

		if (!selectedPermissions.size) {
			toast.error('Please select at least one permission', { position: toast.POSITION.TOP_CENTER });

			return;
		}

		const appToCreate = {
			name: appName,
			permissionIds: [...selectedPermissions]
		};

		await createApplication(appToCreate);
	};

	const handleSecretDialogClose = () => {
		setSecretData(null);
		setSecretDialogVisible(false);
		setVisible(false);
	};

	if (secretDialogVisible) {
		return <SecretDialog {...secretData} visible={secretDialogVisible} onClose={handleSecretDialogClose} />;
	}

	return (
		<AppDialog
			open={visible}
			onClose={handleClose}
			title={'Add Application'}
			overrideClasses={{ content: styles.appDialog }}
		>
			<div className={styles.inputs}>
				<div className={styles.input}>
					<SelectProject autoFocus accountId={accountId} projectId={projectId} setProjectId={setProjectId} isValidProjectId={isValidProjectId} firstRender={firstRender}/>
				</div>
				<div className={styles.input}>
					<AppInput
						overrideClasses={{ input: styles.appInput }}
						value={appName}
						name={'appName'}
						label='Application Name'
						onChange={ setAppName }
						placeholder={'Enter Your Text...'}
						error={!isValidAppName && !firstRender}
						errorMessage={'Please insert a value'}
						required={true}
					/>
				</div>
			</div>
			<div className={styles.products}>
				<SelectProductsPermissions selectedPermissions={selectedPermissions} setSelectedPermissions={setSelectedPermissions}/>
			</div>
			<div className={styles.buttonsContainer}>
				<AppButton variant={'outlined'} onClick={handleClose}>CANCEL</AppButton>
				<AppTooltip title={!isRequiredFieldsSelected? 'Please set required fields': 'Please select at least one permission'} disabled={isRequiredFieldsSelected && selectedPermissions.size}>
					<div>
						<AppButton disabled={!isValidAppName || !projectId || !selectedPermissions.size} variant={'contained'} onClick={handleCreate} overrideClasses={{ button: styles.button }}>
							ADD APPLICATION
						</AppButton>
					</div>
				</AppTooltip>
			</div>
		</AppDialog>
	);
}
