import styles from "./Actions.module.scss"
import Button from "../../Button"
import DeleteIcon from "../../../../iconComponents/actions/Delete"
import OfferIcon from "../../../../iconComponents/actions/UpdateOffer"
import clsx from "clsx"
import Table from "../../Table"
import React,{useContext,useMemo} from "react"
import Item from "./Item"


function mapItemsToElements(items, selected, params) {
    return items.filter(item => typeof item.visible === "undefined" || item.visible).map((item, index) => {
        return <Item key={item.id} index={index} count={items.length} selected={selected} {...item} {...params} />;
    });
}

export function useElements({ toolbarActions, selected, ...params }) {
    const [items] = [toolbarActions] || [];
    const elements = useMemo(() => {
        if (items) {
            return mapItemsToElements(items, selected, params);
        }
        return null;
    }, [items, selected]);
    return elements;
}

export default function Actions(props) {
    const [selected] = useContext(Table.Selected) || [];
    const visible = selected && selected.length;
    const element = useElements({toolbarActions: props.toolbarActions, selected})
    return <div className={clsx(styles.root, visible && styles.visible)}>
        {element}
    </div>
}
