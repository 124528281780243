import * as React from 'react'

function SvgDoubleChevron(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 9 9'
      fill='#3381FF'
      {...props}
    >
      <path
        d='M5.086 8.168l3.44-3.469a.281.281 0 000-.398L5.086.832a.282.282 0 00-.399 0l-.464.464a.282.282 0 000 .399L7.001 4.5 4.223 7.305a.282.282 0 000 .399l.464.464c.11.11.289.11.399 0zm-3.75 0l3.44-3.469a.281.281 0 000-.398L1.336.832a.282.282 0 00-.399 0l-.464.464a.282.282 0 000 .399L3.251 4.5.473 7.305a.282.282 0 000 .399l.464.464c.11.11.289.11.399 0z'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgDoubleChevron
