import {useState, useEffect} from "react";

export function useHover(ref) {
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);
  
    const handleMouseOver = () => setHover(true);
    const handleMouseOut = () => setHover(false);
    const handleMouseDown = () => setActive(true);
    const handleMouseUp = () => setActive(false);
  
    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mousedown', handleMouseDown);
            document.body.addEventListener('mouseup', handleMouseUp);
            node.addEventListener('mouseover', handleMouseOver);
            node.addEventListener('mouseout', handleMouseOut);
  
            return () => {
                node.removeEventListener('mousedown', handleMouseDown);
                document.body.removeEventListener('mouseup', handleMouseUp);
                node.removeEventListener('mouseover', handleMouseOver);
                node.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, [ref.current]);
  
    return [hover, hover && active];
}
