import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getProductRoles = (productId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/roles/product/${productId}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getAllProductRoles = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/roles/products`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

export {
	getProductRoles,
	getAllProductRoles
};
