import * as React from 'react'

function SvgRightArrow(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 5 8'
      {...props}
    >
      <path
        d='M.448 7.106l.18.181c.12.12.313.12.432 0l2.991-2.945c.12-.12.12-.312.003-.432L1.063.965a.304.304 0 00-.432 0l-.18.18a.304.304 0 000 .432l2.595 2.549-2.598 2.55a.302.302 0 000 .43z'
        fill='#8F8F8F'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgRightArrow
