import * as React from 'react'

function SvgTableFilter(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 13 11'
      {...props}
    >
      <path
        d='M8.25 9.53V4.414L11.862.8a.469.469 0 00-.331-.8H2.469a.469.469 0 00-.331.8L5.75 4.413v4.024c0 .153.075.297.2.385l1.562 1.093a.47.47 0 00.738-.384z'
        fill='#7F7F7F'
        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgTableFilter
