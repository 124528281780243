import * as React from 'react'

function SvgLoading(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 50 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M26.25 8.75c0-.664-.586-1.25-1.25-1.25-.703 0-1.25.586-1.25 1.25 0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25zM25 25c-.703 0-1.25.586-1.25 1.25 0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25 0-.664-.586-1.25-1.25-1.25zm10-7.5c0-.664-.586-1.25-1.25-1.25-.703 0-1.25.586-1.25 1.25 0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25zm-17.5 0c0-.664-.586-1.25-1.25-1.25-.703 0-1.25.586-1.25 1.25 0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25zm1.29 4.96c-.704 0-1.25.548-1.25 1.25 0 .704.546 1.25 1.25 1.25.702 0 1.25-.546 1.25-1.25 0-.702-.548-1.25-1.25-1.25zm12.382 0c-.703 0-1.25.548-1.25 1.25 0 .704.547 1.25 1.25 1.25s1.25-.546 1.25-1.25c0-.702-.547-1.25-1.25-1.25zM18.789 10.079c-.703 0-1.25.547-1.25 1.25s.547 1.25 1.25 1.25 1.25-.547 1.25-1.25-.547-1.25-1.25-1.25z'
        fill='#3380FF'
      />
    </svg>
  )
}

export default SvgLoading
