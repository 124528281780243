import styles from "./Item.module.scss"
import React, {useContext} from "react"
import NumPages from "../NumPages"
import Pagination from "../../Pagination"
import clsx from "clsx"
import {useID} from "../../../../Util/ID"

export default function Item({index, label, selectable=true, disabled=false}) {
    const baseID = useID();
    let [count] = useContext(NumPages.Selected) || [0];
    const [offset, setOffset] = useContext(Pagination.Offset) || [0];

    const currentPage = count ? parseInt(offset / count) : 0;
    const selected = index === currentPage && selectable;

    const onClick = () => {
        if(!disabled) {
            setOffset && setOffset(index * count);
        }
    };

    return <div data-cy={baseID + index} onClick={onClick} className={clsx(styles.root, selected && styles.selected, disabled && styles.disabled)}>
        <div className={styles.label}>{label}</div>
    </div>;
}
