import * as React from 'react'

function SvgDelete(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 13 14'
      {...props}
    >
      <path
        d='M9.486 14a1.33 1.33 0 001.31-1.23l.579-9.27h.437a.438.438 0 00.438-.438v-.437a.438.438 0 00-.438-.438H9.56L8.63.637C8.42.285 7.915 0 7.505 0H4.747c-.41 0-.915.285-1.126.637l-.93 1.55H.438a.438.438 0 00-.437.438v.437c0 .242.196.438.437.438h.438l.58 9.27A1.33 1.33 0 002.766 14h6.72zM8.028 2.187H4.222l.525-.875h2.756l.525.875zm1.458 10.5H2.765L2.19 3.5h7.869l-.574 9.187z'

        fillRule='nonzero'
      />
    </svg>
  )
}

export default SvgDelete
