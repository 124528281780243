import styles from './OptionsButton.module.scss'
import OptionsIcon from '../../iconComponents/Options'
import OptionsIconSelected from '../../iconComponents/OptionsSelected'
import Contexts from '../Util/Contexts'
import Menu from './Menu'
import React, { useRef } from 'react'
import { useHover } from '../../../util/hover'
import clsx from 'clsx'

export default function OptionsButton({
  className,
  actions,
  style,
  relativePos,
  ...props
}) {
  const ref = useRef()
  const [hover] = useHover(ref)
  const components = [
    {
      Component: Menu,
      Items: actions,
      Selected: '',
      Search: ''
    }
  ]
  const Icon = hover ? OptionsIconSelected : OptionsIcon
  return (
    <Contexts components={components}>
      <div
        className={clsx(
          styles.root,
          actions && actions.length && styles.visible
        )}
      >
        <Menu style={style} relativePos={relativePos} {...props}>
          <div
            ref={ref}
            className={clsx(className, styles.icon)}
            data-cy={`rowOptionButton-${props.node.id}`}
            data-testid={`rowOptionButton-${props.node.id}`}
          >
            <Icon />
          </div>
        </Menu>
      </div>
    </Contexts>
  )
}
