import create from "zustand";

const initialState = Object.freeze({
	accountId: undefined,
	group: ["all"]
});

const useStore = create((set) => ({
	...initialState,

	setAccountId: (val) => {
		set(() => ({
			accountId: val
		}));
	},
	setGroups: (val) => {
		set(() => ({
			groups: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
