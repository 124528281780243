import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from "./Breadcurmbs";
import { Table, Tag, Delete, Edit } from "@ip-synamedia/syna-ui-library";
import Loader from "../../Loader";
import { getAccounts as apiGetAccounts } from "../../../external-apis";
import {  useQueryWithAuthorization } from "../../../custom-hooks/external-api-hooks";

const useStyles = makeStyles(() => ({
	root: {
		margin: 24
	}
}));

const accountsResponseToTableData = (accounts) => {
	return (accounts || []).map(account => {
		const { id, alias, owner: { email } = {}, status, crmAccountId, snowCompanySysID, groups } = account;

		return {
			id,
			alias,
			ownerEmail: email,
			status: status,
			groups,
			crmAccountId,
			snowCompanySysID
		};
	});
};

const defaultTag = <Tag variant={'default'} rounded={true}>Unknown</Tag>;
const statusToTag = {
	creating: <Tag variant={'info'} rounded={true}> Creating </Tag>,
	active: <Tag variant={'success'} rounded> Active </Tag>,
	suspended: <Tag variant={'warning'} rounded> Suspended </Tag>,
	terminated: <Tag variant={'error'} rounded> Terminated </Tag>,
	pending: <Tag variant={'warning'} rounded> Pending </Tag>
};

const toolbarActions = [
	{
		id: "deleteTree",
		// eslint-disable-next-line react/react-in-jsx-scope
		icon: <Delete/>,
		onClick: (data) => {
			// eslint-disable-next-line no-console
			console.log("id", data);
		}
	}
];

const tableHeaders = [
	{
		id: "id",
		label: "Account ID",
		placeholder: "Select Portal",
		widthUnits: 1,
		searchable: false,
		filterable: true
	},
	{
		id: "alias",
		label: "Account Name",
		widthUnits: 1,
		searchable: false,
		filterable: true
	},
	{
		id: "groups",
		label: "Groups",
		widthUnits: 1,
		searchable: false,
		filterable: true,
	},
	{
		id: "ownerEmail",
		label: "Owner Email",
		widthUnits: 1,
		searchable: false,
		filterable: true
	},
	{
		id: "crmAccountId",
		label: "CRM Account ID",
		widthUnits: 1,
		searchable: true,
		filterable: true
	},
	{
		id: "snowCompanySysID",
		label: "SNOW CompanySysID",
		widthUnits: 1,
		searchable: true,
		filterable: true
	},
	{
		id: "status",
		label: "Status",
		widthUnits: 1,
		searchable: true,
		filterable: true,
		elementCb: (node) => {
			// eslint-disable-next-line react/react-in-jsx-scope
			return statusToTag[node.status] || defaultTag;

		}
	}
];

export default function AccountsPanel() {
	const classes = useStyles();
	const [editOpen, setEditOpen] = useState(false);
	const [editAccountId, setEditAccountId] = useState();

	const { isLoading, isError, data = [], error } = useQueryWithAuthorization('accounts', apiGetAccounts());

	const rowActions = [
		{
			id: "editAccount",
			label: "Edit Account",
			icon: <Edit/>,
			onClick: (id, data) => {
				setEditAccountId(data);
				setEditOpen(true);
			}
		}
	];

	if (isLoading) return <Loader/>;
	if (isError) return <span>Error: {error.message}</span>;
	const tableData = accountsResponseToTableData(data);

	return (
		<div className={classes.root}>
			<Breadcrumbs editOpen={editOpen} setEditOpen={setEditOpen} editAccountId={editAccountId}/>
			<div style={{ height: "80vh" }}>
				<Table
					sort={"title"}
					checkboxSelection={true}
					tableData={tableData}
					filterEnabled
					title={'Accounts'}
					searchEnabled={true}
					tableHeaders={tableHeaders}
					toolbarActions={toolbarActions}
					tableActions={rowActions}
				/>
			</div>
		</div>
	);
}
