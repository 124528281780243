import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
	LineDetails,
	isValidHttpUrl,
	unassignedVariable,
	FeaturesLineDetails,
	getTextWidth,
	ProvisioningStatus
} from '../utils';
import clsx from "clsx";

const useStyles = makeStyles((theme) => {

	return {
		generalContainer: {
			backgroundColor: 'hsla(0, 0%, 100%, 1)',

		},
		contentContainer:{
			marginTop:24,
			paddingTop:12,
			paddingLeft:12,
			width:542,
			minHeight:138,
			marginLeft:24,
			marginBottom:12,
			backgroundColor: 'hsla(0, 0%, 100%, 1)',
			boxShadow: '0px 0px 10px hsla(0, 0%, 16%, 0.15)',
			borderRadius: 8,
			display:"flex",
			flexDirection:"column"
		},
		featureContentContainer:{
			padding: 0
		},
		title:{
			fontFamily: "Source Sans Pro",
			color:'#292929',
			fontSize:14,
			fontWeight: 'bold',
			marginBottom:12,

		},
		featureTitle:{
			marginLeft: 12,
			marginTop: 12
		},
		featureLineDetailsContainer:{
			marginLeft: 32
		}

	};
});

export default function General({ tenant, productConfiguration }) {
	const styles = useStyles();

	const getFeaturesLineDetails = (feature, index) => {
		const featureKey = Object.values(feature)[0];
		const enabled = Object.values(feature)[1].enabled;
		const label = productConfiguration.features?.[featureKey]?.label ?? featureKey;
		const configToDisplay = tenant.tenantConfig.features?.[featureKey]?.config ?? {};
		const isExistEnabledFeature = Object.values(tenant.tenantConfig.features).find(feature => {
			return feature?.enabled && Object.keys(feature?.config || {}).length > 0;
		});
		let longestKeyLength = 0;

		const dataToDisplay = Object.entries(configToDisplay).map((config, index) => {
			const key = config[0];
			const value = config[1];
			const label = productConfiguration.features?.[featureKey]?.config?.[key]?.label ?? key;

			return  { label, value };
		});

		dataToDisplay.forEach(line => {
			longestKeyLength = Math.max(longestKeyLength, getTextWidth(line.label.toString()));
		});

		return <div key={"feature" + index}>
			<FeaturesLineDetails isExistEnabledFeature={isExistEnabledFeature} label={label} dataToDisplay={dataToDisplay} enabled={enabled} longestKeyLength={longestKeyLength}/>
		</div>;
	};

	const configurationToDisplay = tenant?.tenantConfig && Object.entries(tenant.tenantConfig).map(configuration => {
		const key = Object.values(configuration)[0];
		const variable = Object.values(configuration)[1];

		if(key === 'features' || key === 'packages') {
			return {};
		}
		const label = ((productConfiguration.config && key in productConfiguration.config)
			&& ( productConfiguration.config[key]?.label))? productConfiguration.config[key]?.label:key;

		return {
			label,
			variable
		};
	});

	let isExistConfig = false;

	configurationToDisplay && configurationToDisplay.forEach(config => {
		isExistConfig = isExistConfig|| Object.keys(config).length > 0;
	});
	const provisioningStatus = tenant?.provisioningStatus;
	const errorMessage = tenant?.errorMessage;
	const detailsComponent =
		<div>
			<LineDetails key={'st'} label ={'Subscription Type'} value={tenant.subscriptionStatus??unassignedVariable} />
			<LineDetails key={'env'} label ={'Environment'} value={tenant?.environment??unassignedVariable} />
			{ provisioningStatus == null ?
				<LineDetails key={'ps'} label ={'Provisioning Status'} value={unassignedVariable} /> :
				<ProvisioningStatus key={'ps'} value={provisioningStatus} error={errorMessage}/>
			}
		</div>;

	const configComponent =
		<div>
			{configurationToDisplay && configurationToDisplay.map((configuration, index) => {
				if(Object.keys(configuration).length === 0) {
					return <div key={'config' + index}/>;
				}
				let value = Object.values(configuration)[1];
				const label = Object.values(configuration)[0];

				if( Array.isArray(value)) {
					value = value.join(', ');

				}
				else if( typeof value === 'object') {
					return <div key={'config' + index}/>;
				}

				return <LineDetails
					key={'config' + index} label ={label}
					value={value? value.toString():(value?.toString()??unassignedVariable)} />;}
			)}
		</div>;

	const propertiesComponent =
		<div>
			{tenant?.tenantProperties && Object.entries(tenant.tenantProperties).map((property, index) => {
				const value = Object.values(property)[1];

				if( typeof value === 'object') {
					return <div key={'tenant property' + index}/>;
				}

				return <LineDetails
					key={'properties' + index}
					label ={Object.values(property)[0]}
					isLinkify={isValidHttpUrl(value) && (provisioningStatus === 'active' || provisioningStatus === 'updating')}
					value={value??unassignedVariable}
				/>;
			})}
		</div>;

	const featuresComponent =
		<div>
			{tenant.tenantConfig?.features && productConfiguration?.features && Object.entries(tenant.tenantConfig.features).map((feature, index) => {
				return getFeaturesLineDetails(feature, index); })}
		</div>;

	const sections = [
		{
			title: 'Details',
			component: detailsComponent,
			isActive: true

		},
		{
			title: 'Configuration',
			component: configComponent,
			isActive: isExistConfig

		},
		{
			title: 'Features',
			component: featuresComponent,
			isActive: tenant?.tenantConfig?.features && productConfiguration?.features && Object.keys(tenant.tenantConfig.features).length > 0
		},
		{
			title: 'Properties',
			component: propertiesComponent,
			isActive: tenant?.tenantProperties && Object.keys(tenant.tenantProperties).length > 0
		},
	];

	return (
		<div className={styles.generalContainer}>
			{
				sections.map((section, index) => {
					return <div  key={index}>
						{section.isActive ? <div className={clsx(styles.contentContainer, section.title === "Features" && styles.featureContentContainer)} key={index}>
							<div className={clsx(styles.title, section.title === "Features" && styles.featureTitle)}>
								{section.title}
							</div>
							<div className={section?.style}>
								{section.component}
							</div>
						</div>: <div/>}
					</div>;
				})
			}
		</div>
	);
}
