import styles from './Menu.module.scss'
import React, { useState, useMemo, useRef, Fragment } from 'react'
import { useContextEx } from '../Util/Contexts'
import Item from './Menu/Item'
import clsx from 'clsx'
import Modal from '../Util/Modal'
import { useWindowSize } from '../../../util/size'
import MultiSelect from './Menu/MultiSelect'
import ID, { useID } from '../Util/ID'
import Tooltip from './Tooltip'
import Spinner from './Spinner'

function mapItemsToElements(items, props) {
  return items.map((item, index) => {
    if (!(typeof item.hiddenCb === 'function' && item.hiddenCb(props?.node))) {
      const Component = item.Item || Item
      let component = (
        <Component
          key={item.id}
          index={index}
          count={items.length}
          disabled={item.disabled}
          {...props}
          {...item}
        />
      )
      if (item?.ancestorsFilters) {
        const existingFilters = new Set(
          items.filter(({ disabled }) => disabled).map(({ label }) => label)
        )
        component = (
          <Tooltip
            offset={50}
            width={150}
            height={50}
            center
            key={item.id}
            message={`This filter is depends on ${item?.ancestorsFilters.join(
              ' / '
            )}`}
          >
            <Component
              key={item.id}
              index={index}
              count={items.length}
              isAncestorsExist={item?.ancestorsFilters?.some((id) =>
                existingFilters.has(id)
              )}
              {...props}
              {...item}
            />
          </Tooltip>
        )
      }
      return component
    }
  })
}

export function useElements(props) {
  const [search] = useContextEx(Menu.Search) || []
  let [items] = useContextEx(Menu.Items) || []
  const selectedState = useContextEx(Menu.Selected)
  const elements = useMemo(() => {
    if (items) {
      if (search) {
        items = items.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase())
        )
      }
      return mapItemsToElements(items, { selectedState, ...props })
    }
    return null
  }, [items, selectedState, search])
  return elements
}

export default function Menu({
  className,
  children,
  style,
  relativePos,
  multiSelect,
  isAsyncAutocomplete,
  isLoading,
  ...props
}) {
  const baseID = useID()
  const windowSize = useWindowSize()
  const ref = useRef()
  const closeMenu = () => {
    setVisible(false)
  }
  const elements = useElements({ closeMenu, multiSelect, ...props })
  const [visible, setVisible] = useState(false)
  const onClick = () => {
    setVisible(true)
  }

  if (typeof children === 'function') {
    children = children(onClick)
  } else {
    children = React.Children.map(children, (element) => {
      return React.cloneElement(element, { onClick })
    })
  }

  var rect = (ref.current && ref.current.getBoundingClientRect()) || {}

  style = { ...style }
  if (!style.right) {
    style.left = rect.left
  }
  if (!style.width) {
    style.width = rect.width
  }
  style.top = rect.bottom
  if (relativePos) {
    if (relativePos.left) {
      style.left += relativePos.left
    }
    if (relativePos.top) {
      style.top += relativePos.top
    }
  }
  const menuHeight = Math.min((React.Children.count(elements) + 1) * 30, 300)
  if (windowSize && windowSize.height - style.top < menuHeight) {
    style.top = rect.top - menuHeight
  }

  return (
    <Fragment>
      <div ref={ref} className={className}>
        {children}
      </div>
      <Modal visible={visible}>
        <div
          data-cy={baseID + 'menu-clickable'}
          className={clsx(styles.mask, visible && styles.visible)}
          onClick={closeMenu}
        />
        <div
          data-testid={'menu'}
          data-cy={baseID + 'menu'}
          className={clsx(styles.menu, visible && styles.visible)}
          style={style}
        >
          <ID id='menu'>
            {multiSelect && (
              <MultiSelect
                isAsyncAutocomplete={isAsyncAutocomplete}
                width={style.width}
              />
            )}
            {!isLoading ? (
              <div className={styles.items}>{elements}</div>
            ) : (
              <Spinner
                style={{
                  width: '45px',
                  height: '45px',
                  position: 'relative',
                  marginBottom: '5px',
                  left: '40%'
                }}
              />
            )}
          </ID>
        </div>
      </Modal>
    </Fragment>
  )
}

Menu.Items = React.createContext()
Menu.Selected = React.createContext()
Menu.Search = React.createContext()
