import '../../styles.css'
import styles from './tag.module.css'
import React from 'react'
import clsx from 'clsx'
import TagIconInfo from '../iconComponents/TagInfo'
import TagIconInProgress from '../iconComponents/cards/InProgress'
import TagIconActive from '../iconComponents/TagActive'
import TagIconPaused from '../iconComponents/TagPaused'
import TagIconDraft from '../iconComponents/TagDraft'
import TagIconError from '../iconComponents/Error'
import TagIconCompletedWithWarning from '../iconComponents/TagCompletedWithWarning'

export const Tag = ({
  /**
     * className :-
     * 
     * If `present`, button overrides the default styles, 
     * Default - empty
    */
  className,  
  /**
     * Styles :-
     * 
     * If `present`, button overrides the default styles, 
     * Default - empty
    */
  style,
  /**
     * variant :-
     * 
     * If `present`, button overrides the default styles, 
     * Default - empty
    */
  variant,
  /**
     * Icon :-
     * 
     * If present, the respective icon is embedded inside the tag , 
     * Default - false
    */
  icon,
  /**
     * Rounded :-
     * 
     * If present, the respective icon is embedded inside the tag , 
     * Default - false
    */
  rounded,
  label,
  /**
     * showIcon :-
     * 
     * If false, the tag without icon can be used, 
     * Default - true
    */
  showIcon = true,
  /**
     * showBackground :-
     * 
     * If false, the tag without background can be used, 
     * Default - true
    */
  showBackground = true,
  center = true,
  children,
  /**
     * width :-
     * 
     * If present, the width of the tag is as the value mentioned, 
     * Default - '100px'
    */
  width = '100px',
  /**
   * noHover :-
   *
   * If true, the tag will not have default hover styles applied to it,
   * Default - false
   */
  noHover = false
}) => {
  const classes = clsx(
    styles.root,
    showBackground && styles.background,
    center && styles.center,
    label && styles.label,
    center && styles.center,
    showIcon && icon && styles.icon,
    rounded && styles.rounded,
    variant && styles[variant],
    noHover && styles.noHover,
    className
  )
  const icons = {
    default: <TagIconInfo />,
    success: <TagIconActive />,
    info: <TagIconInProgress />,
    warning: <TagIconCompletedWithWarning />,
    error: <TagIconError />,
    disabled:  <TagIconInfo />,
  }
  icon = icon || icons[variant]
  return (
    <div className={classes} data-testid="Tag" style={style}>
      <div 
        style={{ width }}
        className={clsx(
          styles.root,
          styles[variant],
          showBackground && styles.background,
          center && styles.center,
          rounded && styles.rounded,
          noHover && styles.noHover
        )}
      >
        {!!showIcon && <div data-testid = "icon" className={styles.icon}>{icon}</div>}
        <div
          className={clsx(
            styles.label,
            center && styles.center,
            rounded && styles.rounded,
            showIcon && icon && styles.icon,
            styles[variant],
            classes && classes[variant],
            noHover && styles.noHover
          )}
        >
          {label}
          {children}
        </div>
      </div>
    </div>
  )
}

