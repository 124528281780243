import styles from "./CheckboxSingle.module.scss"
import clsx from "clsx"
import CheckBoxSelectedIcon from "../../iconComponents/CheckboxSelected"
import React from 'react'

export default function CheckboxSingle({ className, checked, setChecked }) {
    const onClick = () => {
        setChecked && setChecked(!checked);
    };
    return <button onClick={onClick} className={clsx(styles.root, className)}>
        <div className={clsx(styles.checkbox)}>
            {checked && <CheckBoxSelectedIcon />}
        </div>
    </button>
}
