import styles from './Filter.module.scss'
import Button from './Button'
import FilterIcon from '../../iconComponents/Filter'
import Menu from './Menu'
import Contexts from '../Util/Contexts'
import React, { useContext, useMemo } from 'react'
import Item from './Filter/Item'
import Table from './Table'
import Mode from '../Util/Mode'
import clsx from 'clsx'
import { useElements } from '../../../util/items'

export default function Filter() {
  const [small] = useContext(Mode.Small) || []
  const [items, setItems] = useContext(Filter.Items) || []
  const [columns] = useContext(Table.Columns) || []
  const elements = useElements(items, Item)
  const filterTypes = useMemo(
    () =>
      (columns || [])
        .filter((item) => item.filterable)
        .map((item) => {
          const id = item.id
          const disabled = !!(items || []).find((item) => item.id === id)
          return { ...item, width: item.filterWidth || 218, disabled }
        }),
    [items]
  )
  const components = [
    {
      Component: Menu,
      Items: filterTypes,
      key: items
    }
  ]

  const addFilter = (type) => {
    setItems((items) => {
      return [
        ...items,
        {
          ...filterTypes.find((item) => item.id === type)
        }
      ]
    })
  }

  const clearItems = () => {
    setItems([])
  }

  return (
    <div className={clsx(styles.root, small && styles.small)}>
      <Contexts components={components}>
        <Menu onClick={addFilter} style={{ width: 150 }}>
          <Button uppercase filled icon={<FilterIcon />}>
            Add Filter
          </Button>
        </Menu>
        <div className={styles.items}>{elements}</div>
        {!!(items && items.length) && (
          <Button
            onClick={clearItems}
            className={styles.clearFilters}
            uppercase
          >
            Clear filters
          </Button>
        )}
      </Contexts>
    </div>
  )
}

Filter.Items = React.createContext()

export function useFilter(items) {
  const [filters] = useContext(Filter.Items) || []
  const [columns] = useContext(Table.Columns) || []
  return useMemo(() => {
    if (!items) {
      return
    }
    let rows = items
    if (filters) {
      rows = rows.filter((row) => {
        return filters
          .filter((filter) => filter.value)
          .every((filter) => {
            let match = false
            const column = columns.find((column) => column.id === filter.id)

            if (column.isAsyncAutocomplete) {
              return true
            } else {
              const callback = column.valuesCb || column.valueCb
              const result = callback ? callback(row) : row[column.id]
              if (Array.isArray(filter.value)) {
                if (filter.value.length) {
                  if (Array.isArray(result)) {
                    match = filter.value.some(
                      (value) =>
                        result &&
                        result.some(
                          (el) =>
                            el &&
                            `${el}`.toLowerCase().includes(value.toLowerCase())
                        )
                    )
                  } else {
                    match = filter.value.some(
                      (value) =>
                        result &&
                        `${result}`.toLowerCase().includes(value.toLowerCase())
                    )
                  }
                } else {
                  match = true
                }
              } else if (Array.isArray(result)) {
                match = result.some(
                  (el) =>
                    el &&
                    `${el}`.toLowerCase().includes(filter.value.toLowerCase())
                )
              } else if (result) {
                match = `${result}`
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
            }
            return match
          })
      })
    }
    return rows
  }, [items, filters, columns])
}
