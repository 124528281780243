import React from 'react'
import '../../styles.css'
import styles from './checkbox.module.css'
import clsx from 'clsx'
import CheckBoxIcon from '../../iconComponents/Checkbox'
import CheckBoxSelected from '../../iconComponents/CheckboxSelected'

export const CheckBox = ({
  /**
   * className :-
   *
   * If `present`, button overrides the default styles,
   * Default - empty
   */
  className,
  children,
  /**
   * Disbaled:-
   *
   * If `true`, button's on-click/on-hover actions are disabled,
   * Default - empty
   */
  disabled,
  /**
   * Checked :-
   *
   * If `present`, checkbox will be shown as selected,
   * Default - empty
   */
  checked,
  setChecked,
  label,
  id
}) => {
  const onClick = () => {
    !disabled && setChecked && setChecked(!checked)
  }
  const classes = clsx(styles.root, disabled && styles.disabled, className)
  return (
    <button data-testid={id ? id : 'CheckBox'} onClick={() => onClick()} className={clsx(styles.root, className)}>
      <div data-testid='CheckDisabled' className={ clsx(styles.checkbox, disabled && styles.disabled) } disabled={ disabled }>
        {checked ? <CheckBoxSelected /> : <CheckBoxIcon />}
      </div>
      {children}
      {!!label && <div data-testid='Label' className={styles.label}>{label}</div>}
    </button>
  )
}
