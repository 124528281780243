import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductsRoles from "./ProductsRoles";
import { useQueryWithAuthorization } from "../../../../custom-hooks";
import { getAllProductRoles } from "../../../../external-apis";
import Loader from "../../../Loader";
import modifyRoles from '../../../../assets/icons/modify/modifyRoles.svg';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() => ({
	root: {
		// boxShadow: '0px 0px 10px rgba(41, 41, 41, 0.15)',
		padding: '0 16px'
	},
	productsRolesContainer: {
		height: '100%',
		width: '100%',
		borderRadius: 4,
	},
	projectLabel:{
		fontSize: '14px',
		fontWeight: 600,
		fontFamily: 'Source Sans Pro',
		marginLeft: 32,
		marginTop: 20
	},
	cancelMarginTop:{
		marginTop: 0
	},
	projectContainer:{
		margin: '0px 24px 16px 16px',
		minHeight: 44,
		color: "white",

	},
	accordionSummary: {
		display: "flex",
		flexDirection: 'row-reverse',

	},
	rotate:{
		transform: 'rotate(-90deg)'
	},
	rotateSelected:{
		transform: 'rotate(180deg)'

	},
	modify: {
		marginLeft: 8,
	},
	projectLabelContainer: {
		display:"flex",
		flexDirection: "row",
		alignItems: "center"
	}
}));

const mapAccountRoles = (products) => {
	const productsWithAdminRoles = (products ?? []).map(product => {
		const { id, displayName, roles } = product;

		const adminRoles = roles.filter(role => !role.isProductAdmin);

		if (adminRoles?.length > 0 ) {
			return {
				id,
				displayName,
				roles: adminRoles
			};
		} else {
			return null;
		}
	});

	return productsWithAdminRoles.filter(product => product != null);
};

function ProjectRoles({ project, setFocusedProjects, focusedProjects, isEdit, initialState, firstRender, setFirstRender, changesMadeCmp }) {
	const staleTime = 120000;
	const styles = useStyles();
	const { id, label } = project;

	const {
		isLoading,
		isError,
		error,
		data: productRoles = []
	} = useQueryWithAuthorization('all-roles', getAllProductRoles(), {
		select: mapAccountRoles,
		staleTime
	});

	if (isLoading) return <Loader/>;
	if (isError) return <span>Error: {error.message}</span>;

	return (
		<div>
			{productRoles?.length > 0 && <div className={styles.projectContainer}>
				<Accordion
					onChange = {(e,expanded) => {
						const oldFocus = new Set(focusedProjects);

						focusedProjects.has(project.id) ? oldFocus.delete(project.id):oldFocus.add(project.id);

						setFocusedProjects(new Set(oldFocus));
					}}
					expanded={focusedProjects.has(project.id)}
				>
					<AccordionSummary
						expandIcon={<div className={focusedProjects.has(project.id) ? styles.rotateSelected:styles.rotate}><ExpandMoreIcon/></div>}
						className={styles.accordionSummary}
						sx={{ paddingLeft: '11px' }}
						aria-controls="panel1a-content" id="panel1a-header">
						<Typography sx={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '7px', fontFamily: 'Source Sans Pro' }}><div className={styles.projectLabelContainer}>{label}{isEdit && !changesMadeCmp && <img alt={''} src={modifyRoles} className={styles.modify}/>}</div></Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ backgroundColor: '#FAFAFA', minHeight: 100, paddingBottom: 0, paddingTop: 0, top: -10 }}>
						<div className={styles.productsRolesContainer}>
							<ProductsRoles projectId={id} productRoles={productRoles} isEdit={isEdit} initialState={initialState} parentFirstRender={firstRender} setParentFirstRender={setFirstRender}/>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>}
		</div>
	);
}

export default function ProductsPerProjectRoles({ firstRender,setFirstRender,  projects, setFocusedProjects, focusedProjects, isEdit=false, initialState={}, changesMadeCmp = {} }) {
	const styles = useStyles();

	return (<div>
		{projects && projects.length > 0 && (!isEdit || (isEdit && Object.keys(changesMadeCmp).length > 0 )) &&  <div className={styles.root}>
			{projects.map((project, index) => {

				return <ProjectRoles key={project.id} project={project} setFocusedProjects={setFocusedProjects} focusedProjects={focusedProjects} isEdit={isEdit} initialState={initialState} firstRender={firstRender} setFirstRender={setFirstRender} changesMadeCmp={changesMadeCmp?.productRolesByProjectIdCmp?.[project.id]}/>;
			})}
		</div>}
	</div>);

}
