import * as React from 'react'
import styles from './table.module.scss'
import clsx from 'clsx'
// import Header from '../Core/Widgets/Table/Header'
import Table from '../Core/Widgets/Table'
import Contexts from '../Core/Util/Contexts'
import Filter from '../Core/Widgets/Filter'
import Data from '../Core/Util/Data'
import NumPages from '../Core/Widgets/Table/Pagination/NumPages'
import Pagination from '../Core/Widgets/Table/Pagination'
import Tooltip from '../Core/Widgets/Tooltip'

const Grid = (Props) => {
  const pagesLimit = [
    {
      id: 3,
      label: '3'
    },
    {
      id: 10,
      label: '10'
    },
    {
      id: 50,
      label: '50'
    },
    {
      id: 100,
      label: '100'
    },
    {
      id: 200,
      label: '200'
    }
  ]

  if (!Props.onAsync) {
    pagesLimit.push({ id: 0, label: 'All' })
  }
  const defaultFilters = (Props.tableHeaders || []).filter(
    (item) => item.filterable && (Props.defaultFilters || []).includes(item.id)
  )
  let defaultAdditionalFilters = []
  Props.additionalFilters && Props.additionalFilters.map(additionalFilter => {
    defaultAdditionalFilters.push({[additionalFilter.id]: additionalFilter.defaultValue || ''})
  })
  const numPages =
    Props.numPages && Props.numPages.length ? Props.numPages : pagesLimit
  const components = [
    {
      Component: Table,
      Columns: Props.tableHeaders || [],
      Items: Props.tableData || [],
      Sort: Props.sort,
      Search: '',
      SingleSelection: Props.singleSelection,
      Total: Props?.tableData?.length || 0,
      ClickedRow: '',
      Selected: [],
      AdditionalFilters: defaultAdditionalFilters || []
    },
    {
      Component: Filter,
      Items: [...defaultFilters]
    },
    {
      Component: NumPages,
      Items: numPages,
      Selected: Props.selectedPage ? Props.selectedPage : 50
    },
    {
      Component: Data,
      Items: Props.tableData ? JSON.parse(JSON.stringify(Props.tableData)) : [],
      key: Props.tableData || []
    },
    {
      Component: Pagination,
      Offset: 0
    },
    {
      Component: Tooltip,
      Visible: false
    }
  ]
  return (
    <div
      className={clsx(
        styles.root,
        Props.classes && Props.classes.rootContainer
      )}
      data-testid='root'
    >
      <Contexts components={components}>
        <Table itemSize={71} {...Props} />
      </Contexts>
    </div>
  )
}

export default Grid
