import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useState } from "react";
import { useCreateTenantStore } from "../../../../../state-management";
import AddEditTenant from "../../Tenants/AddEditTenant";
import { makeStyles } from "@material-ui/core/styles";
import AppErrorNotification from "../../../../AppErrorNotification";
import AppDeleteConfirmation from "../../../../AppDeleteConfirmation";
import { deleteProject } from "../../../../../external-apis";
import { toast } from "react-toastify";
import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import { useQueryClient } from "react-query";
import AddEditProjectDialog from "../AddEditProjectDialog";
import shallow from "zustand/shallow";
import AppCloseConfirmation from "../../../../AppCloseConfirmation";

const useStyles = makeStyles(() => ({
	serverIcon: {
		position: 'absolute',
		top: -1,
		left: -1,
	},
	serverContainer: {
		position: 'absolute',
		height: 13,
		width: 15,
		top: 10,
		left: 19,
		backgroundColor: 'white',
	},
	cloudIcon: {
		position: 'absolute',
		top: -2,
		left: 15,
	},
}));

function AddTenantIcon() {
	const styles = useStyles();

	return <>
		<CloudOutlinedIcon className={styles.cloudIcon}/>
		<div className={styles.serverContainer}>
			<DnsOutlinedIcon className={styles.serverIcon} sx={{ width: '17px', height: '17px' }}/>
		</div>
	</>;
}

export default function ProjectMenu({ accountId, projectId, projectName, projectDescription, customerId, customersData ,reseller, errorInUpdate }) {

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const [errorMessage, setErrorMessage] = useState();
	const [addTenantDialogVisible, setAddTenantDialogVisible] = useState(false);
	const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(false);
	const [setProjectId, setGeneralSettingStep, resetState] = useCreateTenantStore(state => [state.setProjectId, state.setGeneralSettingStep, state.reset], shallow);
	const [deleteProjectDialogVisible, setDeleteProjectDialogVisible] = useState(false);
	const [editProjectDialogVisible, setEditProjectDialogVisible] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const options = [
		{
			id: 'addTenant',
			label: 'Add Tenant',
			icon:<AddTenantIcon/>,
			cb:() => {
				handleAddTenantClick();
			}
		},
		{
			id: 'editProject',
			label: 'Edit Project',
			icon:<EditOutlinedIcon/>,
			cb:() => {
				editProject();
			}
		},
		{
			id: 'deleteProject',
			label: 'Delete Project',
			icon:<DeleteOutlineIcon/>,
			cb:() => {
				postDeleteProject();
			}
		},
	];
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleAddTenantClick = () => {
		handleClose();
		setAddTenantDialogVisible(true);
		setProjectId(projectId);
		setGeneralSettingStep(1);
	};

	const postDeleteProject = () => {
		handleClose();
		setDeleteProjectDialogVisible(true);
	};

	const editProject = () => {
		handleClose();
		setEditProjectDialogVisible(true);
	};

	const handleDeleteProject = (params) => {
		handleClose();
		setDeleteProjectDialogVisible(false);
		const deleteProjectRes = mutateAsync(deleteProject(accountId, projectId,{ ...params }));

		toast.promise(deleteProjectRes,
			{
				pending: "Deleting Project...",
				success: {
					render() {
						queryClient.invalidateQueries(['projects', accountId]);

						return 'Deleted Successfully';
					}
				},
				error: {
					render({ data: error }) {
						if (error.message.includes('409')) {
							return `Unable to delete project with tenants`;
						}

						return `Error Deleting Project: ${error.message}`;
					}
				}
			}, { position: toast.POSITION.TOP_CENTER }
		);
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				onClick={handleClick}
			>
				<MoreVertIcon/>
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{options.map((option) => (
					<MenuItem key={option.id} onClick={option.cb}>
						<ListItemIcon>
							{option.icon}
						</ListItemIcon>
						<ListItemText primary={option.label}/>
					</MenuItem>
				))}
			</Menu>
			<AppDeleteConfirmation
				visible={deleteProjectDialogVisible}
				label='project'
				value={projectName}
				handleDelete={() => handleDeleteProject()}
				handleClose={() => setDeleteProjectDialogVisible(false)}
			/>
			{editProjectDialogVisible && <AddEditProjectDialog visible={editProjectDialogVisible} setVisible={setEditProjectDialogVisible} accountId={accountId} projectId={projectId} projectName={projectName} projectDescription={projectDescription} customerId={customerId} customersData={customersData} reseller={reseller} errorInUpdate={errorInUpdate}/>}
			<AddEditTenant visible={addTenantDialogVisible && !closeConfirmationVisible} setVisible={setAddTenantDialogVisible} setCloseConfirmationVisible={setCloseConfirmationVisible} setErrorMessage={setErrorMessage}/>
			<AppCloseConfirmation visible={closeConfirmationVisible} setVisible={setCloseConfirmationVisible} setParentDialogVisible={setAddTenantDialogVisible} text={'Are you sure you want to cancel adding a tenant?' } resetState={resetState}/>
			<AppErrorNotification errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
		</div>
	);
}
